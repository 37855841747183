import { Tabs } from "antd";
import React from "react";
import BagdesTab from "./BagdesTab/BagdesTab";
import Favorites from "./Favorites/Favorites";
import ListThanhTich from "./ListThanhTich/ListThanhTich";
import ListFollow from "./ListFollow/ListFollow";
import { typeTrangThaiFllow } from "../../services/userWall.service";
import ScoreStatic from "./ScoreStatic/ScoreStatic";
import { useParams, useSearchParams } from "react-router-dom";
import localStorageServ from "../../services/locaStorage.service";
import { useTranslation } from "react-i18next";
import SpinnerComponent from "../../components/Spinner";

export default function TabTuongNha() {
  const { t } = useTranslation()

  let { userId } = useParams();

  let [searchParams, setSearchParams] = useSearchParams({key:"8"});
  let value = searchParams.get('key');

  let idUserLocal = localStorageServ.userInfo.get()?.id;
  return (
    <div className="w-full">
      <Tabs defaultActiveKey="8" activeKey={value} onChange={(value) => {

        setSearchParams({ key: value })
      }}>

        <Tabs.TabPane tab={t('tuongCuaToi.thongKeDiem')} key="8">
          {userId == idUserLocal ? <ScoreStatic /> : <div className="text-center"><p className="text-title">Bạn không thể xem điểm của người khác!</p></div>}
        </Tabs.TabPane>

        <Tabs.TabPane tab="Dự án" key="1">
          <BagdesTab />
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab={t('tuongCuaToi.yeuThich')} key="2">
          <Favorites />

        </Tabs.TabPane> */}
        <Tabs.TabPane tab={t('tuongCuaToi.thanhTich')} key="5" className="relative">
     
          <ListThanhTich />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('tuongCuaToi.nguoiTheoDoi')} key="6">
          <ListFollow trangThai={typeTrangThaiFllow.DUOC_FOLLOW} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('tuongCuaToi.dangTheoDoi')} key="7">
          <ListFollow trangThai={typeTrangThaiFllow.DANG_FOLLOW} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

import { getStoreJson, http } from "../utils/config";

const GET_PATH = "/nguoidung";

const getDanhSachMentor = () => {
  return http.get(`${GET_PATH}/get-list-mentor`);
};

const dangNhap = (data) => {
  return http.post(`api/nguoidung/login`, data);
};

const layThongTinUser = (id) => {
  return http.get(`${GET_PATH}/laythongtinuser/${id}`);
};

const getNguoiDungPhanTrang = (data) => {
  return http.get(
    `${GET_PATH}/phan-trang?pageIndex=${data.pageIndex}&pageSize=${data.pageSize}&keyword=maNhomQuyen%20LIKE%20%27%40%40${data.maNhomQuyen}%40%40%27%20%20AND%20${data.filter}%20LIKE%20%27%40%40${data.tuKhoa}%40%40%27`
  );
};

const dangKyNguoiDung = (data) => {
  return http.post(`${GET_PATH}`, data);
};

const updateNguoiDung = (data) => {
  console.log(data);
  return http.put(`${GET_PATH}/${data.id}`, data);
};

const deleteNguoiDung = (id) => {
  return http.delete(`${GET_PATH}/${id}`);
};

const checkPassNguoiDung = () => {
  const id = getStoreJson("USER_LOGIN").id;
  const matKhau = getStoreJson("USER_LOGIN").matKhau;
  const data = {
    id: id,
    email: "string",
    hoTen: "string",
    soDt: "string",
    avatar: "string",
    urls: "string",
    thongTinMoRong: "string",
    maNhomQuyen: "string",
    kichHoat: true,
    ngayTao: "2023-04-06T03:44:12.756Z",
    maGioiThieu: "string",
    iPaddress: "string",
    matKhau: matKhau,
    nuocNgoai: true,
    guiMail: true,
    kinhNghiem: 0,
    tichCuc: 0,
    coin: 0,
    ngayDangNhap: "2023-04-06T03:44:12.756Z",
  };
  return http.post(`${GET_PATH}/check-pass`, getStoreJson("USER_LOGIN"));
};

const layDsNguoiDungByString = (ds) => {
  return http.post(`${GET_PATH}/laydanhsachnguoidungbystring`, ds);
}

const GetDSByMentorIdService = (mentorId) => {
  console.log(mentorId)
  return http.get(`${GET_PATH}/getdshocvienbymentorid?mentorId=${mentorId}`)
}

//lấy tất cả user có trong chuyên đề by user id
const layDsNguoiDungChuyenDe = () => {
  return http.get(`${GET_PATH}/getAll`);
}


export const nguoiDungService = {
  getDanhSachMentor,
  dangNhap,
  layThongTinUser,
  getNguoiDungPhanTrang,
  deleteNguoiDung,
  updateNguoiDung,
  dangKyNguoiDung,
  checkPassNguoiDung,
  layDsNguoiDungByString,
  GetDSByMentorIdService,
  layDsNguoiDungChuyenDe
};

import React, { Fragment, useEffect, useState } from "react";

import CollapseTask from "./CollapseTask/CollapseTask";

import servCodingCamp from "../../services/codingCam.service";
import BannerTask from "./Banner/BannerTask";
import { useParams } from "react-router-dom";
import localStorageServ from "../../services/locaStorage.service";
import RankedSystem from "../../components/RankedSystem/RankedSystem";
import { Input, Modal } from "antd";
import { history } from "../../App";
export default function TaskPageTest() {
  const userInfo = localStorageServ.userInfo.get();
  const [dataTaskPage, setDataTaskPage] = useState({});
  const [dataHoanThanh, setDataHoanThanh] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [password, setPassword] = useState('');
  let { chuyenDeId } = useParams();
  useEffect(() => {
    const getData = async () => {
      try {
        // const res = await servCodingCamp.getDataTaskPage(38);
        const res = await servCodingCamp.getDataTaskPage(chuyenDeId);
        let result = res.data.content;
        setDataTaskPage(result);
      } catch (error) {
        window.location.href = "/";
      }
    };
    getData();
  }, [chuyenDeId]);
  useEffect(() => {
    let payload = {
      nguoiDungId: userInfo.id,
      chuyenDeId: chuyenDeId,
      monHocId: 0,
    };
    servCodingCamp
      .postlayDanhSachHoangThanh(payload)
      .then((res) => {
        console.log("😀 - .then - res", res);
        // console.log("hoanthanh", res);
        setDataHoanThanh(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (password === 'Cybersoft@123') {
      setIsModalOpen(false);
    } else {
      history.push('/');
    }
  };
  return (
    // <div contenteditable="true" className="w-full h-full">
    <div className="w-full h-full mx-auto">
      <Modal
          visible={isModalOpen}
          footer={null}
          closable={true}
          width={"max-content"}
          style={{
            top: 10,
          }}
        >
          <form onSubmit={handlePasswordSubmit} className="text-center">
          <Input
          style={{color:'#000'}}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
          />
          <button className="btn_theme_red mt-3" type="submit">Submit</button>
        </form>
        </Modal>
      {!isModalOpen && (
        <Fragment>
          <div className="c-col-full">
            <BannerTask dataTask={dataTaskPage} />
          </div>
          <div className="space-y-5 c-col-full">
            {dataTaskPage?.danhSachMonHoc?.map((item, index) => {
              return (
                <CollapseTask dataContent={item} key={index} index={index} />
              );
            })}
          </div>
        </Fragment>
      )}
    </div>
  );
}

import React, { Fragment, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  updateThongTinMoRongAction,
  selectThongTinMoRong,
  setInforLoginDemo,
} from "../../redux/reducer/authReducer";
import localStorageServ from "../../services/locaStorage.service";
import ItemCoin from "../../components/ItemCoin/ItemCoin";
import { dataHeader } from "../utils";
import { getHinhAnhFB } from "../../utils/GetHinhanh";
import { Select } from "antd";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../hook/useWindowSize";

export default function HeaderTaskFree({}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let userId = localStorageServ.userInfo.get()?.id;
  let { userInfo } = useSelector(selectThongTinMoRong);

  const { widthWindow, heightWindow } = useWindowSize();

  const { t, i18n } = useTranslation();

  return (
    <>
      <div
        onClick={() => {
          navigate("/dash-broad");
        }}
        className="logo w-23 flex-shrink-0 h-ful flex items-center cursor-pointer"
      >
        <img
          src="https://apimentor11.cybersoft.edu.vn//images/24-04-2024-10-46-20-logo-codezuni.png"
          alt=""
        />
      </div>
      {widthWindow < 768 ? (
        ""
      ) : (
        <Fragment>
          <div className="flex rounded-xl relative items-center h-10  ">
            {dataHeader.map((item, index) => {
              // if (index == 1) return <DropdownLoTrinh />;
              return (
                <NavLink key={index} to={item.path}>
                  <button
                    className={`p-1 px-3 flex h-full justify-center text-content text-sm items-center rounded-xl z-10 relative  duration-500 text-button uppercase w-max`}
                  >
                    <i className={`pe-2 ${item.icon}`}> </i>
                    {item.name}
                  </button>
                </NavLink>
              );
            })}
          </div>
          <ItemCoin />
          <div className="w-14 h-14">
            <NavLink to={`/profile/${userId}`}>
              <img
                src={getHinhAnhFB(userInfo.facebookId, userInfo)}
                className="w-full h-full  block rounded-full m-0  object-cover "
                alt=""
              />
            </NavLink>
          </div>
        </Fragment>
      )}
      <div className="flex items-center">
      <div className="lang">
        <Select
          style={{ width: 100, fontSize: 18, color: "white" }}
          value={localStorage.getItem("lang")}
          onChange={(value) => {
            localStorage.setItem("lang", value);
            window.location.reload();
          }}
          options={[
            { value: "vi", label: "🇻🇳 VI" },
            { value: "en", label: "🇺🇸 EN" },
          ]}
        />
      </div>
      <div
        onClick={() => {
          localStorageServ.userInfo?.remove();
          window.location.href = "/login";
        }}
        style={{ color: " rgb(255, 210, 32)" }}
        className=" cursor-pointer flex items-center justify-start space-x-2 flex-shrink-0"
      >
        <p className="">
          {" "}
          {userInfo?.hoTen == "Free Account"
            ? t("header.dangNhap")
            : t("header.dangXuat")}
        </p>{" "}
        <i className="fa fa-sign-out-alt "></i>
      </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { getImgDanhHieu } from "./utils";
// import Modal from "react-bootstrap/Modal";

import RankedSystem from "../../../components/RankedSystem/RankedSystem";
import servCodingCamp from "../../../services/codingCam.service";
import { useDispatch } from "react-redux";
import { getVatPhamUserAction } from "../../../redux/reducer/vatPhamSlice";
import { Modal } from "antd";

export default function ModalDanhHieu({ danhHieu }) {
  const [list, setList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch()

  useEffect(() => {
    servCodingCamp
      .layMocHuyHieu()
      .then((result) => {
        setList(result.data.content);
      })
      .catch((err) => {});
      
  dispatch(getVatPhamUserAction())
  }, []);
  const showModal = () => {
    setIsModalOpen(true);
  };

  
  return (
    <>
      <div className="theme_img">
        <div class="rorate css-940ceo w-full" style={{ maskSize: '100%', transform: 'scale(1.03)', maskImage: `url(${getImgDanhHieu(danhHieu?.hinhDanhHieu)})`, WebkitMaskImage: `url(${getImgDanhHieu(danhHieu?.hinhDanhHieu)})` }}>
          <div class="rotateBorder css-rotateBorder"></div>
          </div>
        <img
          onClick={showModal}
          src={`${getImgDanhHieu(danhHieu?.hinhDanhHieu)}`}
          className="w-full cursor-pointer "
          alt=""
        />
      </div>
      <Modal
        bsPrefix="modal-ranking modal"
        visible={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        // size="lg"
        width={'60%'}
        style={{ top: 20 }}
        className="modalRanking"
        footer={null}
      >
        {/* <Modal.Body className=""> */}
          <div className="hititle py-3 text-center">
            <span className="customH1 shadow">RANKING</span>

          </div>
          <RankedSystem list={list} currentRank={danhHieu} />
        {/* </Modal.Body> */}
      </Modal>
    </>
  );
}

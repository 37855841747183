import React from "react";
import { useSelector } from "react-redux";
import ModalWrapper from "../../../components/ModalWrapper/ModalWrapper";
import { useLstImgTag } from "../../../hook/useLstImgTag";

export default function ListSkill({ listTag }) {
  let img_warp = "md:w-8 md:h-8 w-10 h-10 card-theme rounded p-1";
  const { data:listImgTag , isLoading, error } = useLstImgTag();
  return (
    <div className="flex gap-4  flex-wrap w-full ">
      {listTag?.map((tag) => {
        let item = listImgTag?.find((item) => {
          return item?.value == tag;
        });
        let icon = (
          <div className={img_warp}>
            <img className="w-full h-full object-cover" src={item?.image} alt />
          </div>
        );
        let content = (
          <div className="flex items-center space-x-5">
            {icon} <span>{item?.value}</span>
          </div>
        );
        return (
          <div className="w-10">
            <ModalWrapper icon={icon} content={content} />
          </div>
        );
      })}
    </div>
  );
}
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import BtnGoogleCapcha from "../../../components/BtnGoogleCapcha/BtnGoogleCapcha";
import { DARK_MODE, LIGHT_MODE } from "../../../constants/theme";
import { setModeTheme } from "../../../redux/reducer/themeReducer";
import localStorageServ from "../../../services/locaStorage.service";
import ContainerTuVanDesktop from "./ContainerTuVanDesktop";
import { useSearchParams } from 'react-router-dom';
import servCodingCamp from "../../../services/codingCam.service";
import { setUserInfor } from "../../../redux/reducer/signUpReducer";

export default function FormTVDesktop() {
  
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  let idUser = searchParams.get("id")
  useEffect(() => {
    const root = window.document.documentElement;

    if (root.classList.remove(DARK_MODE)) {
      root.classList.remove(DARK_MODE);
      root.classList.remove(LIGHT_MODE);
    }
    localStorageServ.modeTheme.set(LIGHT_MODE);
    dispatch(setModeTheme(LIGHT_MODE));
  }, []);
  useEffect(()=>{
    if(idUser){
      servCodingCamp.getNguoiDungById(idUser)
      .then((res) => {
        let newInfor =res.data.content
        newInfor.thongTinMoRong = JSON.parse(newInfor.thongTinMoRong)
        console.log(newInfor)
        dispatch(setUserInfor(newInfor));
      })
      .catch((err) => {
        console.log(err);
      });
    }
  },[idUser])
  return (
    <div
      // style={{
      //   backgroundImage: `url(${backGrountTemplate})`,
      // }}
      className="w-full  min-h-screen   overflow-y-auto bg-cover  flex-col  bg-fixed p-3 md:p-7 lg:p-10 flex  "
    >
      <BtnGoogleCapcha />

      <div className=" h-full w-full flex-grow flex flex-col relative overflow-y-auto overflow-x-hidden 2xl:container mx-auto sm:p-3">
        <div className="w-full flex-grow flex flex-col card-theme">
          <ContainerTuVanDesktop />
        </div>
      </div>
    </div>
  );
}

import React from "react";
import clockImg from "./img/clock.png";
import { typeTrangThaiNop } from "../StepProjectInstruction/util";
import "./style.scss";
import moment from "moment";
import localStorageServ from "../../services/locaStorage.service";
import { useParams } from "react-router-dom";
import servCodingCamp from "../../services/codingCam.service";
import { message } from "antd";
export default function TimeRemain({ deadline = 0, trangThaiTongBaiTap, label = "" }) {
  let user = localStorageServ.userInfo.get();
  let { chuyenDeId, taskId, monHocId } = useParams();

  let handleBatDauLamBaiTap = () => {
    let data = { chuyenDeId, taskId, monHocId, nguoiDungId: user.id };
    servCodingCamp
      .batDauLamBaiTap(data)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        message.error(err?.err.response?.data?.message);
      });
  };
  var givenDate = moment(trangThaiTongBaiTap.deadLine);

  // Today's date
  var today = moment();

  // Calculate the difference in days
  var daysDifference = givenDate.diff(today, "days");

  if (trangThaiTongBaiTap?.trangThai == typeTrangThaiNop.CHUA_LAM) {
    return (
      <div className="space-y-3 px-5">
        <p className="text-red-600 text-xl">
          *Lưu ý: <span className="capitalize">{label}</span> sẽ được bắt đầu tính deadline sau khi
          bạn chọn kích hoạt {label}
        </p>
        <button className="btn_theme_red" onClick={handleBatDauLamBaiTap}>
          Bắt đầu làm {label}
        </button>
      </div>
    );
  }

  if (trangThaiTongBaiTap?.trangThai == typeTrangThaiNop.DANG_LAM)
    return (
      <div className="flex items-center space-x-5 justify-start  ">

        <p className="text-title text-danger">
          <div className="icon_theme w-11 h-11  ">
            <i className="fa fa-clock"></i>
          </div>

          Deadline bài tập: {moment(trangThaiTongBaiTap?.deadLine).format("DD/MM/YYYY")}
        </p>
      </div>
    );
  return <></>;
}

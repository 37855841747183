import React from "react";
import { Tabs } from "antd";
import "./ContenTab.css";

export default function ContentTab(props) {
  const {
    paddingHeaderX,
    paddingHeaderY,
    paddingTabItemX,
    paddingTabItemY = 20,
    backgroundHeader = "#2F3237",
    borderHeader = "tranparent",
  } = props;

  const renderContentByTab = () => {
    return props.data.map((d, i) => {
      return (
        <Tabs.TabPane
          tab={
            <span
              style={{ padding: `${paddingTabItemY}px ${paddingTabItemX}px` }}
              className="text-content"
            >
              {d.label}
            </span>
          }
          key={d.key}
        >
          {d.children}
        </Tabs.TabPane>
      );
    });
  };
  return (
    <div className="tabRow w-full   ">
      <Tabs
        {...props}
        size="small"
        tabBarGutter={50}
        tabBarStyle={{
          // background: "linear-gradient(180deg, #66676C 31.25%, #353034 100%)",
          background: backgroundHeader,
          padding: `${paddingHeaderY}px ${paddingHeaderX}px`,
          borderRadius: "10px",
          border: `1px solid ${borderHeader}`,
          height: "100%",
        }}
        defaultActiveKey="0"
      // className="bg-main"
      >
        {renderContentByTab()}
      </Tabs>
    </div>
  );
}

import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import localStorageServ from "../../services/locaStorage.service";
import { Form, Input } from "antd";
import Countdown from "react-countdown";
import { BASE_URL_CODINGCAMP } from "../../services/axios.service";

export default function InputOtp({ formValue }) {
  const [isDisableResent, setIsDisableResent] = useState(true);
  const [countdown, setCountdown] = useState(30);
  const rendererTimer = ({ hours, minutes, seconds }) => {
    if (hours == 0 && minutes == 0 && seconds == 0) {
    }
    return (
      <p className=" text-base text-gray-300  py-5"> Vui lòng chờ {seconds} giây để gửi lại.</p>
    );
  };
  const onFinish = (values) => {
    console.log("Success:", values);
    axios
      .post(`${BASE_URL_CODINGCAMP}/api/nguoidung/login-user-code/${values.opt}`)
      .then((res) => {
        console.log(res);
        toast.success("Đăng nhập thành công");
        localStorageServ.userInfo.set(res.data.content);
        window.location.href = "/";
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleResetnt = () => {
    setIsDisableResent(true);

    axios
      .post(`${BASE_URL_CODINGCAMP}/api/nguoidung/login-user`, formValue)
      .then((res) => {
        console.log(res);
        toast.success("Mã OTP đã được gửi vào email của bạn");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const renderCountDown = () => {
    if (isDisableResent)
      return (
        <Countdown
          // onComplete={console.log("compllete")}
          // date={Date.now() + 500000000}
          date={Date.now() + 60 * 1000}
          // date={Date.now() + 10 * 1000}
          renderer={rendererTimer}
          onComplete={() => {
            setIsDisableResent(false);
          }}
        />
      );
    return (
      <p className="text-white py-5">
        Bạn vẫn chưa nhận được?{" "}
        <span onClick={handleResetnt} className="text-blue-400 font-medium cursor-pointer">
          Gửi lại
        </span>
      </p>
    );
  };
  return (
    <Form
      name="basic"
      layout="vertical"
      labelCol={{
        span: 16,
      }}
      wrapperCol={{
        span: 16,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label={
          <p>
            Mã xác thực sẽ được gửi qua email đến{" "}
            <span className="underline font-medium">{formValue.email}</span>{" "}
          </p>
        }
        name="opt"
        rules={[
          {
            required: true,
            message: "Mã opt không được để trống",
          },
        ]}
      >
        <Input />
      </Form.Item>
      {renderCountDown()}
      <Form.Item
        wrapperCol={{
          span: 16,
        }}
      >
        <button className="btn_theme_red h-10 leading-9 p-0 px-5 pb-5">Xác thực</button>
      </Form.Item>
      {/* <button
        className="btn_theme_red h-10 leading-9 p-0 px-5 pb-5"
        onClick={handleClick}
        disabled={isDisableResent}
      >
        {isDisableResent ? `Resend OTP (${countdown}s)` : "Resend OTP"}
      </button> */}
    </Form>
  );
}

export const maskEmail=(email) =>{
    // Tách phần trước và sau dấu @
    if(email){
        const [localPart, domain] = email?.split('@');
    
    // Kiểm tra độ dài của localPart
    const localPartLength = localPart.length;
    
    // Nếu localPart có ít hơn hoặc bằng 5 ký tự, giấu toàn bộ
    if (localPartLength <= 5) {
        return '*'.repeat(localPartLength) + '@' + domain;
    }
    
    // Nếu localPart có nhiều hơn 5 ký tự, giấu 5 ký tự cuối
    const visiblePart = localPart.slice(0, localPartLength - 5);
    const maskedPart = '*'.repeat(5);
    
    // Kết hợp lại và trả về kết quả
    return visiblePart + maskedPart + '@' + domain;
    }
    return email
}
import React, { useEffect, useState } from 'react'
import './style.css'
import { history } from '../../App'
import useWindowSize from '../../hook/useWindowSize'
import { useLocation } from 'react-router-dom'
const FlashSale = () => {
  const [show,setShow] = useState(true)
  const {widthWindow} = useWindowSize()
  const location = useLocation();

  useEffect(() => {
    // Đặt lại trạng thái khi URL thay đổi
    setShow(true);
  }, [location]);
  return (
    <div className={`flashSale w-40 h-auto ${show?'':'hidden'} ${widthWindow<639?"phone":""}`} >
        <div className="relative">
        <img className='' src="https://apimentor11.cybersoft.edu.vn//images/24-05-2024-03-58-37-flashsale.png" alt="" onClick={() => { 
        history.push('/package')
     }}/>
        <i className='fa fa-close absolute top-0 -right-6 bg-gray-700 rounded-full text-white p-2' onClick={() => { 
          setShow(false)
         }}></i>
        </div>
        
    </div>
  )
}

export default FlashSale
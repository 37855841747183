import React, { useEffect, useState } from "react";
import { Drawer, Layout } from "antd";
import { Outlet } from "react-router-dom";
import HeaderTask from "./HeaderTask/HeaderTask";
import { useSelector } from "react-redux";
import { dataHeader } from "./utils";
import StackChat from "../components/StackChat/StackChat";
import DanhSachChat from "../components/DanhSachChat/DanhSachChat";
import localStorageServ from "../services/locaStorage.service";
import { connection, history } from "../App";
import PackageBottomFix from "../pages/ServicePakages/PackageBottomFix";
import { selectThongTinMoRong } from "../redux/reducer/authReducer";
import { useMediaQuery } from "react-responsive";
import useWindowSize from "../hook/useWindowSize";
import FlashSale from "../components/FlashSale/FlashSale";
import HeaderBottom from "./HeaderBottom/HeaderBottom";
import ZaloBtn from "../components/ZaloOA/ZaloOA";
import FBChat from "../components/FacebookChat/FBChat";
import NotificationSound from "../components/NotificaSound/NotificationSound";
import { checkChatMess } from "./HomeSidebar";
export  const kiemTraThoiGian = () => {
  const thoiGianDaLuu = localStorageServ.timeDemo.get()
  if (thoiGianDaLuu) {
    const thoiGianHienTai = Date.now();
    const thoiGianHienTaiMilliseconds = parseInt(thoiGianHienTai);
    console.log('thoiGianHienTaiMilliseconds: ', thoiGianHienTaiMilliseconds);
    const thoiGianDaLuuMilliseconds = parseInt(Date.parse(thoiGianDaLuu));
    console.log('thoiGianDaLuuMilliseconds: ', thoiGianDaLuuMilliseconds);

    // So sánh thời gian hiện tại với thời gian đã lưu, ví dụ: sau 1 phút
    if (thoiGianHienTaiMilliseconds >= thoiGianDaLuuMilliseconds) {
      // Hiển thị thông báo
      alert('Đã đến thời hạn!');
      localStorageServ.clearLocalStorage()
      history.push('demo')
      // Xóa thời gian đã lưu trong localStorage
      localStorageServ.timeDemo.remove()
    }
  }
};
const { Header, Content, Sider } = Layout;

 function HomeTemplate(props) {
  
  const [playSound, setPlaySound] = useState(false);
  const theme = useSelector((state) => state.theme.theme);
  
  let userInfo = localStorageServ.userInfo.get();
  let root = document.documentElement;
  root.classList.add(theme);
  const { widthWindow, heightWindow } = useWindowSize();
  
  const isPhone = widthWindow < 768 ? true : false
  useEffect(() => {
    const interval = setInterval(() => {
      kiemTraThoiGian();
    }, 600000); // Kiểm tra mỗi 10p

    // Xóa interval khi component unmount
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    try{
      connection.on('ReceiveMessage', (userM) => {
        if(checkChatMess(userM,userInfo?.id)){
          setPlaySound(true)
        setTimeout(() => setPlaySound(false), 1000)
        }
      })
      connection.on('ReceiveMessage2', (userM) => {
        if(checkChatMess(userM,userInfo?.id)){
          setPlaySound(true)
        setTimeout(() => setPlaySound(false), 1000)
        }
      })
    }catch (error) {
      // success("Bạn vừa kết nối thành công chat !");
    }
}, [connection]);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          background: "black",
          padding:isPhone?'0 10px':'0 15px'
        }}
        className="flex h-20  fixed top-0 left-0 w-full z-50 items-center  justify-between space-x-2"
      >
          <HeaderTask/>
      </Header>
      {isPhone && (
        <div
          style={{
            // background:"#fff",
            zIndex: 100,
            // padding: isPhone ? "0 10px" : "",
          }}
          className="flex h-16  fixed bottom-0 left-0 w-full  items-center  justify-between space-x-5"
        >
          <HeaderBottom />
        </div>
      )}
      <Layout>
        <Layout
          style={{
            margin: `80px 0 0 0`,
            padding: "0",
          }}
          className="bg-content"
        >
          <Content>
            <Outlet className="" />
            <ZaloBtn/>
                            <FBChat/>

            {isPhone ? (
                  ""
                ) : (
                  <StackChat showDrawer={props.showDrawer} />
                )}
            {/* {!userInfo.kichHoat && <PackageBottomFix/>} */}
          </Content>
          <FlashSale/>
        </Layout>
            <NotificationSound playSound={playSound} />
      </Layout>
    </Layout>
  );
}
export default HomeTemplate
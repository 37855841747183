import { createSlice } from "@reduxjs/toolkit";
import { chuyenDeService } from "../../services/chuyenDeService";
import { danhSachTagService } from "../../services/danhSachTagService";

const initialState = {
  arrChuyenDe: [],
  arrTag: [],
  DSChuyenDeMentorPhuTrach: [],
  DSChuyenDeHocVienDangKy: [],
  lstCDByTag: [],
  loading: false,
};

const chuyenDeReducer = createSlice({
  name: "chuyenDeReducer",
  initialState,
  reducers: {
    getChuyenDeAction: (state, action) => {
      state.arrChuyenDe = action.payload;
    },
    getListTagAction: (state, action) => {
      state.arrTag = action.payload;
    },
    getDSChuyenDeMentorPhuTrach: (state, action) => {
      state.DSChuyenDeMentorPhuTrach = [...action.payload];
    },
    getDSChuyenDeHocVienDangKy: (state, action) => {
      state.DSChuyenDeHocVienDangKy = [...action.payload];
    },
    getLstByTag: (state, action) => {
      state.lstCDByTag = action.payload;
    }
  },
});

export const {
  getChuyenDeAction,
  getListTagAction,
  getDSChuyenDeMentorPhuTrach,
  getDSChuyenDeHocVienDangKy,
  getLstByTag
} = chuyenDeReducer.actions;
export default chuyenDeReducer.reducer;

//--------- action async ------------

export const getChuyenDeApi = () => {
  return async (dispatch) => {
    try {
      const result = await chuyenDeService.layDanhSachChuyenDe();

      dispatch(getChuyenDeAction(result.data.content));
    } catch (er) {
      console.log(er);
    }
  };
};

// get danh sach tag

export const getListTagApi = () => {
  return async (dispatch) => {
    try {
      const result = await danhSachTagService.getDanhSachTag();

      dispatch(getListTagAction(result.data.content));
    } catch (er) {

    }
  };
};

export const getDSChuyenDeMentorPhuTrachApi = (mentorId) => {
  return async (dispatch) => {
    const result = await chuyenDeService.getChuyenDeMentorPhuTrach(mentorId);
    dispatch(getDSChuyenDeMentorPhuTrach(result.data.content));
  };
};

export const getDSChuyenDeHocVienDangKyApi = () => {
  return async (dispatch) => {
    try {
      const rs = await chuyenDeService.getChuyenDeHocVienDK();
      dispatch(getDSChuyenDeHocVienDangKy(rs?.data?.content));
    } catch (error) {
      
    }
  };
};
export const getLstByTagApi = (tag) => {
  return async (dispatch) => {
    try {
      const rs = await chuyenDeService.getChuyenDeByTag(tag);
      dispatch(getLstByTag(rs?.data?.content));
    } catch (error) {
      
    }
  };
};

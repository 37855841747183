import { ConvertNameUser } from "./ConvertNameUser";
export const gerateDataSetChart = (arrData, canvas, userInfo,t) => {
  const ctx = canvas.getContext("2d");

  const gradient_bg_1 = ctx.createLinearGradient(0, 0, 0, 250);
  // gradient_bg_1.addColorStop(0, "rgba(251, 128, 133,1)");
  // gradient_bg_1.addColorStop(1, "rgba(251, 128, 133,0)");
  /**background: linear-gradient(90deg, #00D16D 0%, #00D18A 4.52%, #00D1D2 14.69%, #00D1FA 19.2%, #005DEC 47.45%, #D31BFF 71.17%, #FF4C04 94.89%, #EDD629 112.96%),
linear-gradient(0deg, #FFFFFF, #FFFFFF);
 */
  gradient_bg_1.addColorStop(0, "rgba(231, 18, 143, 1.0)");
  // A kind of blue for max.
  gradient_bg_1.addColorStop(0.7, "rgba(0, 173, 238, 1.0)");
  gradient_bg_1.addColorStop(1, "#EDD629");
  const arrColor = [
    gradient_bg_1,
    "rgb( 117, 95, 211,0.8)",
    "rgb( 70, 220, 216)",
    "rgb(106, 201, 119,0.9)",
    "rgb(253, 206, 0,0.8)",
  ];

  let userIndex = arrData.findIndex((item) => {
    return item?.hoTen === userInfo?.hoTen;
  });
  if (userIndex !== -1) {
    let temp = arrData[0];
    arrData[0] = arrData[userIndex];
    arrData[userIndex] = temp;
  }

  return arrData.map((dataUser, index) => {
    let newDataUser = { ...dataUser };
    let dataShow = [...newDataUser.lstSoPhut];

    let nameUser =
      dataUser?.hoTen === userInfo?.hoTen ? "Của bạn" : ConvertNameUser(dataUser?.hoTen);
    return {
      label: arrData.length === 1 ? t('trangChu.tocDoXemVideo') : nameUser,
      fill: "start",
      // backgroundColor: index === 0 ? arrColor[index] : "transparent",
      // borderColor: index === 0 ? "#FF6384" : arrColor[index],
      // pointBackgroundColor: index === 0 ? "#FF6384" : arrColor[index],
      // backgroundColor: index === 0 ? arrColor[index] : "transparent",
      backgroundColor: "transparent",
      borderColor: arrColor[index],
      pointBackgroundColor: arrColor[index],
      lineTension: 0.4,
      cubicInterpolationMode: "monotone",
      pointStyle: "circle",
      pointRadius: 0,
      pointHoverRadius: 6,
      borderWidth: 4,
      data: dataShow,
      fontColor: "#ffffff",
    };
  });
};

export const generateOption = (statisArr) => ({
  // devicePixelRatio: 4,
  // fix chữ trên chart bị méo
  responsive: true,
  maintainAspectRatio: false,

  title: {
    display: true,
    text: "TITLE HERE",
    fontSize: 20,
  },
  legend: {
    position: "right",
    labels: {
      fontSize: 5,
    },
  },
  layout: {
    padding: 0,
    marggin: 0,
  },
  datasetStrokeWidth: 2,
  pointDotStrokeWidth: 4,
  scales: {
    xAxes: {
      display: true,

      title: {
        display: false,
      },
      grid: {
        display: false,
      },
      ticks: {
        // color: theme == DARK_MODE ? "#ffffff" : "#000",
        color: "white",
      },
    },
    yAxes: {
      title: {},
      grid: {
        // color: theme == DARK_MODE ? "#ffffff" : "#88888822",
        color: "white",
      },
      ticks: {
        min: 0, // it is for ignoring negative step.
        beginAtZero: true,
        stepSize: 20, // if i use this it always set it '1', which look very awkward if it have high value  e.g. '100'.
        padding: 10,
        fontSize: 14,
        // color: theme == DARK_MODE ? "#ffffff" : "#000",
        color: "white",
      },
      suggestedMin: 0,
      suggestedMax: Math.max(statisArr[0]) + 20,
    },
  },

  plugins: {
    legend: {
      padding: 0,
      align: "end",
      labels: {
        usePointStyle: true,
        fontSize: 8,
        padding: 5,
        boxWidth: 3,
        pointStyle: "circle",
        // color: theme == DARK_MODE ? "#ffffff" : "#000",
        color: "white",
        // color toc do xem video
      },
    },
    xAxes: {
      labels: {
        // color: theme == DARK_MODE ? "#ffffff" : "#000",
        color: "red",
      },
    },
  },
  interaction: {
    intersect: false,
  },
  radius: 0,
  tooltips: {
    yAlign: "bottom",
    xAlign: "bottom",
  },
});

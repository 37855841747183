import React, { useEffect, useState } from "react";
import Banner from "./Banner";
import Content from "./Content";
import { useParams } from "react-router-dom";
import servCodingCamp from "../../services/codingCam.service";

let dataDetail = {
  id: 1859,
  tenBaiHoc: "Cac bai viet tong hop ve css",
  noiDung:
    "<p>Chương redux toolkit:</p>\r\n<p>https://github.com/khaitruong1301/react_cyberlearn_khoahoc_06</p>\r\n<p>Chương react router dom:</p>\r\n<p>https://github.com/khaitruong1301/react_cyberlearn_khoahoc_06_Chuong2</p>\r\n<p>Chương Typescript cơ bản:</p>\r\n<p>https://github.com/khaitruong1301/react_cyberlearn_khoahoc_06_Chuong3</p>\r\n<p>Chương Áp dụng dự án typescript react The Shoes Shop</p>\r\n<p>https://github.com/khaitruong1301/react_cyberlearn_khoahoc_06_Chuong4</p>\r\n<p>Api: https://shop.cyberlearn.vn/swagger/index.html</p>\r\n<p>Layout figma: https://www.figma.com/file/HPyvaEJpBCxXB3p2r59nk6/Capstone-project-full-page?t=imGevZ30jtGYGyr4-0</p>\r\n<p>Link mô tả api và ui : https://drive.google.com/file/d/1kmrKwdGpEdfld6M_eDJjXPIOth7sVr4r/view?usp=share_link</p>\r\n",
  moTa: "",
  ghiChu: "",
  thoiLuong: 0,
  xemDemo: false,
  tagSkill: "[]",
  tag: "[]",
  capDo: 1,
  maLoai: "TAILIEU",
  coin: 0,
  kinhNghiem: 0,
  tichCuc: 0,
  loaiBaiTap: "[]",
  metaData: "",
  ngayTao: "2023-05-26T14:46:27.22",
  luotXem: 1,
};
export default function BestPracticeDetail() {
  const [dataDetail, setDataDetail] = useState({});
  let { id: idBestPractice } = useParams();

  useEffect(() => {
    servCodingCamp
      .getBestPractice(1)
      .then((res) => {
        let detail = res.data.content.danhSachBestPractice.filter((item) => {
          return item.id == idBestPractice;
        });
        setDataDetail(detail[0]);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="flex text-content h-full">
      <div className="c-col-30">
        <Banner data={dataDetail} />
      </div>
      <div className="c-col-70 h-full">
        <Content data={dataDetail} />
      </div>
    </div>
  );
}

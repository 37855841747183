import { Header } from "antd/lib/layout/layout";
import React from "react";
import HeaderTask from "../../template/HeaderTask/HeaderTask";
import { dataHeader } from "../../template/utils";
import localStorageServ from "../../services/locaStorage.service";

export default function HeaderPersonalPage() {
  return (
    <Header
      style={{
        background: "black",
        zIndex: 100,
      }}
      className="flex h-20  fixed top-0 left-0 w-full  items-center  justify-between"
    >
      <HeaderTask />
    </Header>
  );
}

import React, { useEffect, useState } from "react";
import { Modal } from "antd";
// import Modal from "react-bootstrap/Modal";
export default function ModalClickZoomImg() {
  const [modalVisible, setModalVisible] = useState(false);
  const [clickedImageUrl, setClickedImageUrl] = useState("");
  useEffect(() => {
    const handleClick = (event) => {
      if (event.target.tagName === "IMG") {
        // User clicked on an image

        // You can do further processing here
        const imageUrl = event.target.src;
        setClickedImageUrl(imageUrl);
        setModalVisible(true);
      }
    };

    // Attach the click event listener to a common ancestor element
    const container = document.getElementById("containerNoiDungBaiTap");

    if (container) {
      container.addEventListener("click", handleClick);
    }

    // Cleanup the event listener when the component unmounts
    return () => {
      if (container) {
        container.removeEventListener("click", handleClick);
      }
    };
  }, []);
  return (
    <>
      <Modal
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
        footer={null}
        width={'60%'}

      >
        
        {/* <Modal.Body className="flex justify-center"> */}
          <img alt="Clicked Image" style={{ width: "100vw" }} src={clickedImageUrl} />
        {/* </Modal.Body> */}
      </Modal>
    </>
  );
}
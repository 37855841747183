import React, { useEffect, useState } from "react";
import servVatPham from "../../services/vatPham.service";
import { ContentVatPham } from "../VatPham/ContentVatPham";
import HtmlParser from "react-html-parser";

export default function ButtonGiaHanBaiTap({ nopBaiId }) {
  const [data, setData] = useState({});
  useEffect(() => {
    fetchData();
  }, []);
  let fetchData = () => {
    servVatPham
      .showItemGiamThoiGianBaiTap()
      .then((res) => {
        setData(res.data.content);

      })
      .catch((err) => {
        
      });
  };
  let handleSuDungVp = () => {
    servVatPham
      .giamThoiGianChoBaiTap(nopBaiId)
      .then((res) => {
        // fetchThongTinBaiNop();
        window.location.reload();
      })
      .catch((err) => {
        
        // toast(err.response.data.messsage);
      });
  };
  if (data.daSoHuu) {
    let { hinhAnh, tenVatPham, moTa, hanSuDung, chiPhi, id } = data.canMua;
    return (
      <div>
        <div className="flex text-title space-x-24 h-full">
          <div className="w-96 space-y-5 text-center rounded ">
            <img src={hinhAnh} className="w-full h-4/5 object-contain rounded" alt="" />
          </div>
          <div style={{ width: 400 }} className=" space-y-5 px-5">
            <h3 className="text-center text-title">Thông tin vật phẩm</h3>
            <p className="">{HtmlParser(moTa)}</p>
            <p className="flex items-center"></p>
            <button onClick={handleSuDungVp} className="btn_theme_red">
              Sử dụng vật phẩm
            </button>

            {/* show message sau khi mua vật phẩm */}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ height: "max-content" }} className="  w-full px-5">
        <ContentVatPham onFetch={fetchData} item={data.canMua} />
      </div>
    );
  }
}

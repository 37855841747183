import React from "react";
import { Navigate } from "react-router-dom";
import localStorageServ from "../services/locaStorage.service";

export const withAuth = (Component) => {
  let userInfo = localStorageServ.userInfo.get();
  if (!userInfo) {
    return <Navigate to="/login" replace />;
  }
  return (
    <>
      <Component />
    </>
  );
};

import React from "react";
import Practices_SingleChoice from "./Pratices/Practices_SingleChoice";
import Practices_SingleChoiceToFill from "./Pratices/Praticees_ChooseAnserToFill";
import Practices_HTML_CSS from "./Pratices/Practices_HTML_CSS";
import Practices_FillCodeToInput from "./Pratices/Practices_FillCodeToInput";
import Practices_MultipleChoice from "./Pratices/Practices_MultipleChoice";
import _ from "lodash";
  import CryptoJS from 'crypto-js';
export default function List4TypeQuiz({ currentQuestionIndex, allQuestions, setListQuestion, visited }) {

  //decode đáp án trắc nghiệm theo chuẩn Backend
const key = 'b2314589FASe41121fbbce2eQF412916';
const decrypt = (cipherText) => {
  const keyBytes = CryptoJS.enc.Utf8.parse(key);
  const iv = CryptoJS.enc.Hex.parse('00000000000000000000000000000000'); // IV với toàn số 0

  const encrypted = CryptoJS.enc.Base64.parse(cipherText);

  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: encrypted },
    keyBytes,
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }
  );

  return decrypted.toString(CryptoJS.enc.Utf8);
};
  let handle_CheckSingleChoice = (id, userAnsers) => {

    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };

    // if (newCurrentQuestion.noiDung.dapAn[0] == userAnsers[0]?.cauTraLoi) {
      if (decrypt(newCurrentQuestion.noiDung.dapAn[0]) == userAnsers[0]?.cauTraLoi) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }

    newCurrentQuestion.userAnsers = userAnsers;
    let newAllQuestion = [...allQuestions];

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let handle_CheckMultipleChoice = (id, userAnsers) => {
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };
    // let arrDapAn = [...newCurrentQuestion.noiDung.dapAn];
    let deCodeDA = newCurrentQuestion.noiDung.dapAn.map((item)=>decrypt(item))
    let arrDapAn = [...deCodeDA];
    if (arrDapAn.sort().toString() == userAnsers.sort().toString()) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }
    let newAllQuestion = [...allQuestions];
    newCurrentQuestion.userAnsers = userAnsers;

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let handle_CheckFinll_IN_Blank = (id, userAnsers) => {
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };
    let deCodeDA = newCurrentQuestion.noiDung.dapAn.map((item)=>decrypt(item))
    if (
      newCurrentQuestion.noiDung.dapAn.length === userAnsers.length &&
      // newCurrentQuestion.noiDung.dapAn.every(function (value, index) {
        deCodeDA.every(function (value, index) {
        return value == userAnsers[index];
      })
    ) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }
    let newAllQuestion = [...allQuestions];

    newCurrentQuestion.userAnsers = userAnsers;

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let handle_CheckFillInput = (id, userAnsers = []) => {
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };
    let deCodeDA = newCurrentQuestion.noiDung.dapAn.map((item)=>decrypt(item))
    if (
      newCurrentQuestion.noiDung.dapAn.length === userAnsers.length &&
      // newCurrentQuestion.noiDung.dapAn.every(function (value, index) {
        deCodeDA.every(function (value, index) {
        return value == userAnsers[index];
      })
    ) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }
    let newAllQuestion = [...allQuestions];

    newCurrentQuestion.userAnsers = userAnsers;

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let handle_CheckFinll_IN_Blank_CSS = (id, userAnsers) => {
    let currentQuestionIndex = allQuestions.findIndex((item) => {
      return item.id === id;
    });
    let newCurrentQuestion = { ...allQuestions[currentQuestionIndex] };
    let deCodeDA = newCurrentQuestion.noiDung.dapAn.map((item)=>decrypt(item))
    if (
      newCurrentQuestion.noiDung.dapAn.length === userAnsers.length &&
      // newCurrentQuestion.noiDung.dapAn.every(function (value, index) {
        deCodeDA.every(function (value, index) {
        return value == userAnsers[index];
      })
    ) {
      newCurrentQuestion.isCorrect = true;
    } else {
      newCurrentQuestion.isCorrect = false;
    }
    newCurrentQuestion.userAnsers = userAnsers;
    let newAllQuestion = [...allQuestions];
    newAllQuestion.userAnsers = userAnsers;

    newAllQuestion[currentQuestionIndex] = newCurrentQuestion;

    setListQuestion(newAllQuestion);
  };
  let arrRenderQuestion = allQuestions.map((question, index) => {
    let keyIndex = index;
    switch (question?.noiDung.maLoaiBaiTap) {
      case "single":
        return (
          <Practices_SingleChoice
            key={keyIndex}
            question={question}
            handle_CheckSingleChoice={handle_CheckSingleChoice}
            visited={visited}
          />
        );
      case "multiple_choice":
        return (
          <Practices_MultipleChoice
            key={keyIndex}
            handle_CheckMultipleChoice={handle_CheckMultipleChoice}
            question={question}
            visited={visited}
          />
        );
      case "fill_inblank_css":
        return (
          <Practices_HTML_CSS
            key={keyIndex}
            handle_CheckFinll_IN_Blank_CSS={handle_CheckFinll_IN_Blank_CSS}
            question={question}
            visited={visited}
          />
        );
      case "fill_inblank":
        return (
          <Practices_SingleChoiceToFill
            key={keyIndex}
            handle_CheckFinll_IN_Blank={handle_CheckFinll_IN_Blank}
            question={question}
            visited={visited}
          />
        );
      case "fill_input":
        return (
          <Practices_FillCodeToInput
            key={keyIndex}
            question={allQuestions[currentQuestionIndex]}
            handle_CheckFillInput={handle_CheckFillInput}
            visited={visited}
          />
        );

      default:
        break;
    }
  });

  return arrRenderQuestion.length > 0 ? arrRenderQuestion[currentQuestionIndex] : <></>;
}

import React from "react";
import { Modal } from "antd";
import "./dieuKhoanThanhToan.scss";

export default function DieuKhoanThanhToan({ isShowDieuKhoan, handleCloseModal }) {
  return (
    <div className="" id="modal-dieu-khoan">
      <Modal
        style={{ top: 20 }}
        title="Quy Định Học Tập"
        className="rounded-xl  w-4/5 felx flex-col items-center h-5/6    "
        // onOk={handleOk}
        visible={isShowDieuKhoan}
        onCancel={() => {
          handleCloseModal();
        }}
        footer={
          <button
            onClick={() => {
              handleCloseModal();
            }}
            className=" cursor-pointe bg-yellow-theme px-5 py-2 rounded text-black font-medium"
          >
            Đồng ý
          </button>
        }
      >
        <div
          id="form-dieu-khoan"
          m
          className="space-y-2  text-content  text-black bg-white p-10"
        >
          <p className="text-xl font-medium text-center text-gray-900">
            ĐIỀU KHOẢN QUY ĐỊNH HỌC TẬP
          </p>
          <p className="">
            {/* Dưới đây là những điều khoản được áp dụng cho học viên của CodeZuni. Xin hãy đọc kỹ toàn bộ thỏa thuận trước khi tham gia. Một khi bạn đã đăng ký tham
            gia trên CodeZuni chúng tôi sẽ hiểu rằng bạn đã đọc và đồng ý toàn bộ điều khoản được đưa ra trong bản thỏa thuận này. */}
            *Có hiệu lực từ ngày 01.08.2023, và áp dụng cho tất cả học viên đang
            học tại CodeZuni. Phụ Huynh, học viên vui lòng đọc kỹ và ký xác nhận
            tuân thủ những quy định đã được trình bày trong bảng này khi đăng ký
            nhập học. CodeZuni áp dụng bảng nội quy này nhằm tạo điều kiện học tập
            tốt nhất cho Học viên và đảm bảo chất lượng giảng dạy. Trong trường
            hợp Nội Quy Học Tập có nội dung cập nhật mới được gửi qua
            SMS/Zalo/Email mà Phụ Huynh và Học Viên không lập tức có bất kỳ phản
            đối nào, CodeZuni sẽ hiểu rằng Phụ Huynh và Học Viên đã đồng ý với nội
            dung cập nhật của Nội Quy Học Tập
          </p>
          <ol className="list-decimal text-lg">
            {/* big 1 */}
            <li>
              <h3 className="underline">Điều kiện nhập học: </h3>
              <ol id="sub-1" className="list-decimal">
                <li>
                  Học viên phải hoàn thành bài thi xếp lớp trước khi nhập học.
                </li>
                <li>
                  Tham gia khóa học theo lộ trình đã được trao đổi và thống nhất
                  với Bộ Phận Tư Vấn.
                </li>
                <li>
                  Hoàn thành 100% học phí trước khi nhập học. Quy định về học
                  phí:
                </li>
                <li>
                  Học phí:
                  <p>
                    Học phí là khoản tiền mà người học thanh toán để chi trả cho
                    dịch vụ giáo dục, đào tạo theo lộ trình học đã đồng thuận
                    cùng CodeZuni. Học viên xác nhận đã tìm hiểu chương trình học,
                    điều kiện cơ sở vật chất, trang thiết bị phục vụ học tập và
                    các dịch vụ trước khi đăng ký học.
                  </p>
                </li>
                <li>
                  Hoàn lại học phí
                  <ol className="list-decimal" id="sub-1-5">
                    <li>
                      Học phí sẽ KHÔNG được hoàn lại trừ các trường hợp sau:
                      <ol style={{ listStyleType: "lower-alpha" }} className="">
                        <li>
                          Sau ba (03) buổi học đầu tiên học viên cảm thấy không
                          hứng thú với việc học này{" "}
                        </li>
                        <li>Hoặc lỗi xuất phát từ CodeZuni</li>
                      </ol>
                    </li>
                    <li>
                      Quy định hoàn học phí cho các trường hợp được hoàn phí:
                      <ol style={{ listStyleType: "lower-alpha" }} className="">
                        <li>
                          Sau ba (03) buổi học đầu tiên học viên cảm thấy không
                          hứng thú với việc học này: CodeZuni sẽ hoàn 100% học phí
                          các buổi chưa học.{" "}
                        </li>
                        <li>
                          Lỗi xuất phát từ CodeZuni: CodeZuni sẽ hoàn 100% học phí các
                          buổi chưa học nếu phát sinh các tình huống sau:
                          <ul className="list-disc">
                            <li>
                              CodeZuni đã trì hoãn lớp học quá ba (03) tháng kể từ
                              ngày dự kiến khai giảng
                            </li>
                            <li>
                              CodeZuni dịch chuyển Trung tâm CodeZuni ra khỏi phạm vị
                              Quận/Huyện so với địa điểm đăng ký ban đầu của Học
                              Viên.
                            </li>
                          </ul>
                        </li>
                      </ol>
                    </li>
                    <li>
                      Thời gian hoàn học phí và các khoản loại trừ:
                      <ul className="list-disc">
                        <li>
                          Việc hoàn tiền sẽ được thực hiện trong vòng 10 ngày
                          làm việc sau khi CodeZuni nhận đơn yêu cầu hoàn tiền được
                          ký xác nhận bởi Phụ Huynh.
                        </li>
                        <li>
                          CodeZuni chỉ hoàn phần học phí còn lại sau khi trừ đi các
                          chi phí cần thiết gồm phí chuyển đổi trả góp và chương
                          trình khuyến mãi (nếu có)
                        </li>
                        <li>
                          Hồ sơ hoàn phí gồm: Đơn yêu cầu hoàn phí; Căn cước
                          công dân/Chứng minh nhân dân/Giấy phép lái xe; Hồ sơ
                          khác theo yêu cầu từ CodeZuni (nếu có).
                        </li>
                      </ul>
                    </li>
                  </ol>
                </li>
                <li>
                  Bảo lưu học phí:
                  <ol className="list-decimal" id="sub-1-6">
                    <li>
                      Học viên đăng ký 1 khóa học
                      <p>
                        Học viên chỉ được bảo lưu học phí khi đáp ứng đủ các
                        điều kiện sau:
                      </p>
                      <ul className="list-disc">
                        <li>
                          Chỉ được bảo lưu khi lớp khai giảng tối đa 2 tuần và
                          phí bảo lưu là 30% học phí khóa mới sẽ chuyển qua.
                        </li>
                        <li>
                          Nếu học viên bảo lưu trước ngày khai giảng của khóa
                          tối thiểu 20 ngày thì được bảo lưu miễn phí
                        </li>
                        <li>
                          CodeZuni chỉ bảo lưu học phí của những buổi học còn lại
                          chưa diễn ra và KHÔNG bảo lưu chương trình ƯU ĐÃI.
                        </li>
                        <li>
                          Học viên thông báo việc bảo lưu trước ít nhất 48h cho
                          CodeZuni. Học phí được bảo lưu sẽ tính từ ngày Học viên
                          hoàn thành thủ tục bảo lưu với CodeZuni.
                        </li>
                        <li>
                          Học phí bảo lưu chỉ được bảo lưu trong vòng ba (03)
                          tháng kể từ ngày hoàn tất thủ tục bảo lưu. Sau ba (03)
                          tháng, số tiền bảo lưu sẽ hết hạn sử dụng và khách
                          hàng sẽ thanh toán 100% học phí nếu muốn đăng ký học
                          lại.
                        </li>
                        <li>
                          Học phí bảo lưu sẽ KHÔNG được hoàn lại với bất kỳ lý
                          do gì.
                        </li>
                        <li>
                          Học viên chỉ được bảo lưu một (01) lần duy nhất cho
                          khóa học đã đăng ký.
                        </li>
                        <li>
                          Để đảm bảo chất lượng học tập, Học viên khi quay lại
                          nên tham gia khóa học từ đầu khóa và đóng học phí bổ
                          sung nếu có.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Học viên đăng ký lộ trình 2-3-4-5 khóa trở lên (combo)
                      <p>
                        Học viên được khuyến khích chọn lớp tiếp theo sớm để
                        kiến thức được liên tục. Trong trường hợp có việc bận
                        chưa thể tiếp tục ngay sau khi xong khóa học, học viên
                        được bảo lưu tối đa 2 tháng để ghi danh vào khóa học
                        tiếp theo. Trước 1 tháng khi lớp mới bắt đầu, học viên
                        cần thông báo để CodeZuni sắp xếp lớp phù hợp.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  Chuyển đổi học phí sang hình thức tín dụng cho Học viên khác:
                  <p>
                    Trường hợp Học viên không tiếp tục tham gia khóa học mà
                    không thuộc các trường hợp nêu tại mục 2.2, Học viên chỉ
                    được chuyển học phí cho Học viên khác nếu Học viên đó là
                    người có mối quan hệ trong gia đình với Học viên gồm: vợ,
                    chồng, cha đẻ, mẹ đẻ, con đẻ của Học viên; anh, chị, em ruột
                    của Học viên. Học viên bắt buộc cung cấp chứng từ để chứng
                    minh nếu như có yêu cầu từ phía CodeZuni.
                  </p>
                </li>
                <li>
                  Đóng học phí chênh lệch khi Học viên chuyển sang cấp độ khác,
                  chương trình khác, mô hình học tập khác:
                  <ol className="list-decimal" id="sub-1-8">
                    <li>
                      Trong trường hợp Học viên chuyển từ lớp có học phí cao hơn
                      sang lớp có học phí thấp hơn, học phí còn dư sẽ được bảo
                      lưu cho khóa học tiếp theo và không được hoàn trả dưới bất
                      kỳ hình thức nào.
                    </li>
                    <li>
                      Trường hợp Học viên chuyển từ lớp có học phí thấp hơn sang
                      lớp có học phí cao hơn, Học viên phải đóng thêm khoản học
                      phí chênh lệch giữa hai lớp học.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h3 className="underline">
                {" "}
                Quy định chuyển lớp và chuyển đổi mô hình học tập:
              </h3>
              <ol id="sub-2" className="list-decimal">
                <li>
                  Học viên không được chuyển lớp quá hai (02) lần trong một (01)
                  lộ trình học ( Lộ trình học bao gồm nhiều khóa học )
                </li>
                <li>
                  Trường hợp Học viên chuyển từ khóa học có chương trình ưu đãi
                  sang khóa học không ưu đãi hoặc từ khóa học có ưu đãi cao sang
                  khóa học có ưu đãi thấp hơn, thì Học viên sẽ phải bắt buộc
                  đóng bổ sung học phí chênh lệch. Trường hợp Học viên chuyển từ
                  khóa học có ưu đãi thấp hơn sang khóa học có ưu đãi cao thì
                  học phí chênh lệch sẽ không được hoàn lại dưới bất kỳ hình
                  thức nào.
                </li>
                <li>
                  Khi nhận được chỉ đạo từ Sở Giáo Dục của địa phương hoặc các
                  cơ quan nhà nước có thẩm quyền về việc chuyển đổi mô hình học
                  tập từ trực tiếp tại trung tâm (offline) sang mô hình học trực
                  tuyến qua mạng (online), CodeZuni sẽ thông báo cho Học viên về
                  việc chuyển đổi này và sẽ sắp xếp các lớp học hiện tại được
                  diễn ra tiếp tục dưới hình thức online. Trong trường hợp Học
                  viên vắng mặt ở các buổi học online, thì học phí trong giai
                  đoạn học online này vẫn được ghi nhận và sẽ không được hoàn
                  lại dù bất kỳ lý do gì.
                </li>
              </ol>
            </li>
            <li>
              <h3 className="underline"> Quy định vượt lớp:</h3>
              <ol id="sub-3">
                <li>
                  Có được nhận xét tốt và được đề nghị từ giảng viên đứng lớp
                </li>
                <li>
                  Học viên phải hoàn thành bài thi vượt lớp đạt tiêu chuẩn đề ra
                </li>
              </ol>
            </li>
            <li>
              <h3 className="underline"> Thái độ học tập của Học viên: </h3>
              <ol id="sub-4">
                <li>Đi học chuyên cần, đúng giờ quy định.</li>
                <li>
                  Không sử dụng điện thoại trong giờ học trừ khi được giảng viên
                  đứng lớp cho phép.
                </li>
                <li>
                  Phải hoàn thành bài tập về nhà do giảng viên đứng lớp giao
                  trước khi đến lớp.
                </li>
                <li>
                  Nghiêm cấm hành vi thiếu tôn trọng, gây thương tích đối với
                  giáo viên, nhân viên CodeZuni và các Học viên khác. Học viên sẽ
                  không được tiếp tục theo học và KHÔNG được hoàn lại phí nếu vi
                  phạm điều này.
                </li>
                <li>
                  Phải chịu trách nhiệm đối với những hành vi phá hoại tài sản
                  chung của tòa nhà hoặc làm ảnh hưởng đến cơ sở vật chất, môi
                  trường giảng dạy và học tập của CodeZuni.
                </li>
                <li>
                  CodeZuni không chịu trách nhiệm quản lý Học viên ngoài giờ học
                  chính thức và các Học viên tự ý đi ra ngoài khuôn viên CodeZuni
                  mà không có sự cho phép của CodeZuni.
                </li>
              </ol>
            </li>
            <li>
              <h3 className="underline">Tham dự lớp:</h3>
              <p>
                Nếu Học viên xin phép nghỉ phải có sự đồng ý của phụ huynh. Phụ
                huynh phải gọi điện đến xin phép trước khi buổi học bắt đầu. Nếu
                Học viên nghỉ học mà không thông báo cho CodeZuni thì Học viên được
                xem là nghỉ không phép.
              </p>
            </li>
            <li>
              <h3 className="underline"> Bài kiểm tra và bài thi :</h3>
              <ol id="sub-6">
                <li>
                  Học viên bắt buộc phải hoàn thành các bài thi, bài tập, dự án
                  (đã được thông báo trong ngày đầu tiên của khóa học) để đánh
                  giá chất lượng học tập của Học viên.
                </li>
                <li>
                  Nếu Học viên không thể tham dự ngày thi giữa khóa hoặc cuối
                  khóa, Học viên phải thông báo trước 1 tuần với Bộ Phận Học Vụ
                  để sắp xếp ngày thi lại thích hợp nhất và phải có lý do chính
                  đáng (tham dự các kỳ thi học kỳ/thi chứng chỉ, tham gia lớp
                  học khác tại CodeZuni, Học viên đi du học hoặc bị bệnh/tai nạn/sự
                  cố trong ngày thi).
                </li>
                <li>
                  Nếu Học viên vắng thi mà không báo trước với Bộ Phận Học Vụ
                  hoặc vắng thi ngoài những lý do nêu trên thì Học viên phải
                  đóng lệ phí thi bổ sung (200.000 VNĐ/bài thi).
                </li>
                <li>
                  Học viên phải trung thực trong các kỳ thi. Nếu phát hiện gian
                  lận, kết quả Học viên sẽ bị hủy và Học viên phải làm lại bài
                  thi khác
                </li>
              </ol>
            </li>
            <li>
              <h3 className="underline"> Sử dụng hình ảnh truyền thông :</h3>
              <p>
                Trong quá trình học tập và tham gia các hoạt động ngoại khóa, sự
                kiện tại CodeZuni, hình ảnh của phụ huynh và học viên có thể được
                sử dụng với mục đích truyền thông cho CodeZuni, cụ thể về nội dung
                hình ảnh sẽ được đăng tải trên các kênh truyền thông, mạng xã
                hội như: học viên đang học tập, tham gia các sự kiện do CodeZuni tổ
                chức, tương tác, vui chơi với giáo viên, bạn bè và phụ huynh.
                Trong trường hợp phụ huynh và học viên không đồng ý với điều
                khoản trên, Phụ huynh và học viên báo với quản lý trung tâm để
                gạch bỏ và ký tên xác nhận vô hiệu hóa điều khoản này.
              </p>
            </li>
            {/* upate thêm li */}
            <li>
              <h3 className="underline"> Việc bảo mật thông tin :</h3>
              <ol id="sub-8">
                <li>
                  Bạn có trách nhiệm tự mình bảo quản Tài Khoản, nếu Tài Khoản
                  bị lộ ra ngoài dưới bất kỳ hình thức nào, CodeZuni sẽ không chịu
                  trách nhiệm về mọi tổn thất phát sinh. Bạn không được download
                  video, không được chia sẻ video, tài liệu, mã nguồn, tài
                  nguyên mà CodeZuni cung cấp cho bạn lên Internet với bất kỳ hình
                  thức nào. Nếu vi phạm, tài khoản của bạn sẽ bị dừng hoạt động
                  và phải chịu trách nhiệm trước pháp luật về hành vi xâm phạm
                  sở hữu trí tuệ.{" "}
                </li>
                <li>
                  CodeZuni có thể gửi thông báo tình hình học tập, chương trình
                  khuyến mãi (nếu có), thông báo khóa học mới sắp ra mắt để học
                  viên quan tâm có thể đăng ký ngay để được ưu đãi.
                </li>
                <li>
                  Học viên có trách nhiệm bảo mật tài khoản, toàn bộ video, tài
                  liệu mà trung tâm đã cung cấp. Học viên không được cung cấp
                  cho bất kì bên thứ 3 nào mà chưa được sự đồng ý của CodeZuni. Nếu
                  cố tình vi phạm bạn sẽ bị xử phạt 200,000,000 VND (hai trăm
                  triệu Việt Nam Đồng) và CodeZuni sẽ toàn quyền xử lý các vi phạm.
                  Tài khoản đăng nhập hệ thống là tài khoản facebook chính chủ,
                  chỉ xét duyệt và chấp nhận 1 lần duy nhất lúc đăng kí. Bất kì
                  lý do nào liên quan đến việc thay đổi tài khoản đăng nhập sau
                  này đều KHÔNG được hỗ trợ.
                </li>

                <li>
                  Học viên không được dùng các thiết bị ghi âm, ghi hình lại
                  buổi học trên lớp. Nếu cần tài liệu, chú thích, hỗ trợ thì có
                  thể tự viết lại hoặc nhờ giảng viên hoặc mentor tổng hợp lại
                  và cung cấp sau mỗi buổi học. Nếu CodeZuni phát hiện trường hợp
                  học viên cố tình ghi âm, ghi hình lại buổi học thì CodeZuni sẽ
                  toàn quyền xử lý.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </Modal>
    </div>
  );
}

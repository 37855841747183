import { Breadcrumb } from "antd";
import React, { useEffect, useMemo } from "react";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { routesBreadcrumb } from "./configBreadCrumb";
import { useSelector } from "react-redux";
import { selectListLoTrinh } from "../../template/HeaderTask/loTrinhSlice";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import "./style.scss";
import OutlineTaskList from "./OutlineTaskList";
import { useState } from "react";
import servCodingCamp from "../../services/codingCam.service";
import { NavLink } from "react-router-dom";
import { checkDemoUser, checkLearning } from "../../utils/HocDemoUtils";
import { selectThongTinMoRong } from "../../redux/reducer/authReducer";
import { useLstLoTrinhDangHoc, useLstLoTrinhUser } from "../../hook/useLstLoTrinhUser";
import { history } from "../../App";
import localStorageServ from "../../services/locaStorage.service";
let BreadCrumbNav = ({}) => {
  let { chuyenDeId, taskId, monHocId } = useParams();
  let [tenTask, setTenTask] = useState();
  let isDemoUser = useMemo(() => checkDemoUser(), []);
  let userInfo = localStorageServ.userInfo.get();

  const { data: dataDangHoc } = useLstLoTrinhDangHoc(userInfo?.id);
  const isLearning = checkLearning(chuyenDeId, dataDangHoc);
  useEffect(() => {
    if (dataDangHoc) {
      isDemoUser || !userInfo.kichHoat || !isLearning
        ? servCodingCamp
            .getDetailTaskTrial(chuyenDeId, monHocId, taskId)
            .then((res) => {
              setTenTask(res.data.content.tenNhiemVu);
            })
            .catch((err) => {
              
            })
        : servCodingCamp
            .getDetailTask(chuyenDeId, monHocId, taskId)
            .then((res) => {
              setTenTask(res.data.content.tenNhiemVu);
            })
            .catch((err) => {
              
            });
    }
  }, [chuyenDeId, taskId, monHocId, dataDangHoc]);
  const list = useSelector(selectListLoTrinh);
  let loTrinh = list.find((item) => item.chuyenDeId == chuyenDeId);
  const breadcrumbs = useBreadcrumbs(routesBreadcrumb);
  let renderBeadcrumbs = () => {
    if (breadcrumbs.length == 3) {
      return (
        <>
          <Breadcrumb.Item>
            <NavLink key={"1"} to={"/"}>
              <span className="text-yellow">{breadcrumbs[0].breadcrumb}</span>
            </NavLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span
              className={classNames({
                "text-white": breadcrumbs.length == 3,
                "text-yellow": breadcrumbs.length > 3,
              })}
            >
              {breadcrumbs[1].breadcrumb}
            </span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span className="text-white">{loTrinh?.tenChuyenDe}</span>
          </Breadcrumb.Item>
        </>
      );
    }
    return (
      <>
        <Breadcrumb.Item>
          <NavLink key={"1"} to={"/"}>
            <span className="text-yellow">{breadcrumbs[0].breadcrumb}</span>
          </NavLink>
          {/* </NavLink> */}
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <NavLink
            key={breadcrumbs[1].pathname}
            to={"/task-page/" + chuyenDeId}
          >
            <span className="text-yellow">{breadcrumbs[1].breadcrumb}</span>
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span className="text-white">{tenTask}</span>
        </Breadcrumb.Item>
      </>
    );
  };
  return (
    <div className="z-50 h-8 top-16 right-0 flex  pl-2 bg-black   fixed w-full  items-center justify-between px-5 ">
      <div>{renderBeadcrumbs()}</div>
      {breadcrumbs.length > 3 && <OutlineTaskList />}
    </div>
  );
};
export default BreadCrumbNav;

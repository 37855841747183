import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useRef, useState } from "react";
import servCodingCamp from "../../../../services/codingCam.service";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getListBinhLuanServ,
  selectDataComment,
  selectVideoWall,
  setDataComment,
} from "../../tuongNhaSlice";
import localStorageServ from "../../../../services/locaStorage.service";
export let InputComment = () => {
  let { replyTo, payload, commentStatus } = useSelector(selectDataComment);
  let { id } = useParams();
  let formRef = useRef();
  const dispatch = useDispatch();
  //   let { userInfo } = useSelector(selectThongTinMoRong);
  let userInfo = localStorageServ.userInfo.get();

  const inputRef = useRef(null);

  useEffect(() => {
    // inputRef.current.focus();
    return () => {
      dispatch(
        setDataComment({
          replyTo: "",
          commentStatus: 0,
          payload: {
            nopBaiId: 0,
            noiDung: "",
            binhLuanId: 0,
            binhLuanChaId: 0,
          },
        })
      );
    };
  }, []);
  useEffect(() => {
    inputRef.current.focus();
  }, [replyTo]);

  const [comment, setComment] = useState("");
  // rangThai: 0: binh luan, 1: chinh sua binh luan, 2: reply, 3: like binh luan, 4: bo like
  const handleSubmitComment = (e) => {
    e.preventDefault();

    let payloadComent = {
      nopBaiId: id,
      noiDung: comment,
      binhLuanId: 0,
      binhLuanChaId: payload.binhLuanChaId,
    };
    // binhLuanId: like bỏ like
    // binhLuanChaId: reploy
    // binhLuanChaId = binhLuanId reply vs  user khác

    servCodingCamp
      .postCommentVideo(commentStatus, payloadComent)
      .then((res) => {
        setComment("");
        dispatch(
          getListBinhLuanServ({
            nopBaiId: id,
            binhLuanId: 0,
            // binhLuanId=0 => constant
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmitByEnter = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      console.log("yes enter");
      e.preventDefault();
      formRef.submit();
    }
  };
  let preFixReply = replyTo.length > 0 ? `Trả lời @${replyTo}:` : "";

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmitComment}
      className="flex items-center space-x-5 w-full"
    >
      <img src={userInfo?.avatar} className="w-20 h-20 object-cover rounded-full  flex-shrink-0 " />
      <TextArea
        ref={inputRef}
        onKeyDown={(e) => {
          // handleSubmitByEnter(e);
          if (e.key === "Backspace" && comment === "" && preFixReply !== "") {
            setComment("");
            dispatch(
              setDataComment({
                replyTo: "",
                payload: {
                  nopBaiId: 0,
                  noiDung: comment,
                  binhLuanId: 0,
                  binhLuanChaId: 0,
                },
              })
            );
          }
        }}
        onChange={(e) => {
          setComment(e.target.value.substring(preFixReply.length));
        }}
        value={preFixReply + comment}
        autoSize={{
          minRows: 2,
          maxRows: 6,
        }}
        className="bg-gray-800 border-none rounded text-white caret-yellow-400 p-3 care"
        placeholder="Thêm bình luận..."
      />
      <button type="submit" className="text-xl text-gray-500 cursor-pointer hover:text-white">
        Gửi
      </button>
    </form>
  );
};

export let convertData = (danhSachNoiDung) => {
  return danhSachNoiDung?.map((item) => {
    return {
      complete: false,
      poin: "",
      icon: <i class="fa fa-play"></i>,
      ...item,
    };
  });
};

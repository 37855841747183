import { http } from "../utils/config";

const GET_PATH = '/danhgia';

const layDSdanhgiaSer = () => {
    return http.get(GET_PATH);
}

const themDanhgia = (data) => {
    return http.post(GET_PATH, data);
}


export const danhGiaService = {
    layDSdanhgiaSer,
    themDanhgia
}

import React, { Fragment, useEffect, useState } from 'react'
import './style.css'
import { history } from '../../App'
import { useDispatch, useSelector } from 'react-redux'
import { use } from 'i18next'
import { getArrPackageCourseApi } from '../../redux/reducer/packageReducer'

import Slider from 'react-slick';
import localStorageServ from '../../services/locaStorage.service'
import { selectThongTinMoRong } from '../../redux/reducer/authReducer'
import { BASE_URL_CODINGCAMP } from '../../services/axios.service'
import { API_URL_DOMAIN } from '../../utils/urlDomain'
import axios from 'axios'
const PackageTableV2 = ({ id, arrChuyenDe, isCombo }) => {


    const [arrPackageByCourse, setArrPackageByCourse] = useState([])
    // let { arrChuyenDe } = useSelector((state) => state.chuyenDe);
    const [arrRender, setArrRender] = useState()
    const [arrRes, setArrRes] = useState()
    let userInfo = localStorageServ.userInfo.get()

    const dispatch = useDispatch()
    useEffect(() => {
        axios.get(`${API_URL_DOMAIN}/package/course?id=${id}`).then((res) => {
            setArrPackageByCourse(res.data.content)
        }).catch((err) => {
            console.log(err)
        })
        // dispatch(getArrPackageCourseApi(id))
    }, [id])
    useEffect(() => {
        // Tạo mảng chứa các phần tử có danhSachKhoaHoc chỉ chứa một id
        const singleIdArr = arrPackageByCourse.filter(item => JSON.parse(item.danhSachKhoaHoc).length === 1)

        // Tạo mảng chứa các phần tử có danhSachKhoaHoc chứa nhiều hơn một id
        const multipleIdArr = arrPackageByCourse.filter(item => JSON.parse(item.danhSachKhoaHoc).length > 1);
        setArrRender(arrPackageByCourse)
        // setArrRender(isCombo ? multipleIdArr : singleIdArr)
    }, [arrPackageByCourse])
    useEffect(() => {
        let res = []
        arrRender?.forEach(item => {
            const existingItem = res.find(r => r.danhSachKhoaHoc === item.danhSachKhoaHoc);

            if (existingItem) {
                existingItem.info.push(item);
            } else {
                res.push({
                    ...item,
                    info: [item]

                });
            }
        });
        setArrRes(res)
    }, [arrRender])

    console.log('checktype', userInfo?.email == 'freeacc@gmail.com' || !userInfo)
    return (
        <div className=''>
            <div className={`row m-0 gap-3 w-full px-5 ${"flex justify-center mb-2"}`}>

                <Fragment>
                    {(userInfo?.email == 'freeacc@gmail.com' || !userInfo) && <div className="itemPac col">
                        <div>
                            <h4 className='text-white '>Free</h4>
                            <p><span className=" text-xl"><sup>đ</sup>0<sub>{'/Tháng'}</sub></span></p>
                            <ul className="pricing-text">
                                <li><span className="fa fa-check mr-2" />Học free các nội dung có mark free</li>
                                <li><span className="fa fa-check mr-2" />Hỗ trợ BOT chat</li>
                                <li><span className="fa fa-check mr-2" />Tham gia cộng đồng Discord</li>
                            </ul>
                        </div>
                        <div className="btn_footer">
                            <div className="relative inline-block">
                                <button href className="btn_theme_red" onClick={() => {
                                    localStorageServ.regisPackage.set(0)
                                    if (userInfo?.email == 'freeacc@gmail.com' || !userInfo) {
                                        history.push('/signup-user')
                                    }
                                }}>Đăng ký</button>
                            </div>
                        </div>
                    </div>}
                    {(userInfo && userInfo?.email!=='freeacc@gmail.com') && arrRes?.map((item, idx) => {
                        let isBoder = arrPackageByCourse.length == 1
                        let ds = JSON.parse(item.danhSachKhoaHoc)
                        if (ds.length == 1) {
                            return <div key={idx} className={`itemPac col ${isBoder ? ' bg-transparent' : ""}`}>
                                <div className=''>
                                    <img src={`https://apimentor11.cybersoft.edu.vn/${item.hinhAnh}`} alt="" />
                                </div>
                                <div>
                                    <h4 className='text-white my-1'>{item.tenGoi}</h4>
                                    {/* <p><span className=" text-xl"><sup>đ</sup>{item.hocPhi / 1000}<sub>.000{'/' + item.thang + 'Tháng'}</sub></span></p> */}
                                    {/* <p>10,000 monthly visits</p> */}
                                    <ul>
                                        {ds.map((cd, idx1) => {
                                            const cdName = arrChuyenDe?.find(a => a.id === cd)?.tenChuyenDe || ''
                                            return <li key={idx1}><span className="fa fa-check mr-2" />{cdName}</li>
                                        })}
                                    </ul>
                                </div>
                                <div className="btn_footer">
                                    {item.info.map((inf, idx) => {
                                        return <div key={idx} className='relative inline-block'>
                                            <button href className={`btn_theme_red m-1 ${inf.thang == 3 ? 'bestSeller' : ""}`} onClick={() => {
                                                localStorageServ.regisPackage.set(inf)
                                                if (userInfo?.email == 'freeacc@gmail.com' || !userInfo) {
                                                    history.push('/signup-user')
                                                } else {
                                                    history.push('/confirm-payment')
                                                }
                                            }}>{inf.hocPhi / 1000000} triệu/<sub>{inf.thang} tháng</sub></button>
                                            {inf.thang == 3 && <p className='btnBest absolute'>Best Seller</p>}
                                        </div>
                                    })}
                                </div>
                            </div>
                        }

                    })}
                </Fragment>

            </div>

            {userInfo && userInfo?.email!=='freeacc@gmail.com' && <div className={`row m-0 gap-3 w-full px-5 ${"flex justify-center"}`}>
                {arrRes?.map((item, idx) => {
                    let isBoder = arrPackageByCourse.length == 1
                    let ds = JSON.parse(item.danhSachKhoaHoc)
                    if (ds.length > 1) {
                        return <div key={idx} className={`itemPac col ${isBoder ? ' bg-transparent' : ""}`}>
                            <div className=''>
                                <img src={`https://apimentor11.cybersoft.edu.vn/${item.hinhAnh}`} alt="" />
                            </div>
                            <div>
                                <h4 className='text-white my-1'>{item.tenGoi}</h4>
                                {/* <p><span className=" text-xl"><sup>đ</sup>{item.hocPhi / 1000}<sub>.000{'/' + item.thang + 'Tháng'}</sub></span></p> */}
                                {/* <p>10,000 monthly visits</p> */}
                                <ul>
                                    {ds.map((cd, idx1) => {
                                        const cdName = arrChuyenDe?.find(a => a.id === cd)?.tenChuyenDe || ''
                                        return <li key={idx1}><span className="fa fa-check mr-2" />{cdName}</li>
                                    })}
                                </ul>
                            </div>
                            <div className="btn_footer">
                                {item.info.map((inf, idx) => {
                                    return <div key={idx} className='relative inline-block'>
                                        <button href className={`btn_theme_red m-1 ${inf.thang == 3 ? 'bestSeller' : ""}`} onClick={() => {
                                            localStorageServ.regisPackage.set(inf)
                                            if (userInfo?.email == 'freeacc@gmail.com' || !userInfo) {
                                                history.push('/signup-user')
                                            } else {
                                                history.push('/confirm-payment')
                                            }
                                        }}>{inf.hocPhi / 1000000} triệu/<sub>{inf.thang} tháng</sub>

                                        </button>
                                        {inf.thang == 3 && <p className='btnBest absolute'>Best Seller</p>}
                                    </div>
                                })}
                            </div>
                            <div className="absolute top-0 bg-green-500 left-0 p-2 border-0 border rounded-lg">
                                <i className="fa-solid fa-bolt-lightning"></i> Combo
                            </div>
                        </div>
                    }

                })}
            </div>}
        </div>

    )
}

export default PackageTableV2
import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import './CustomInput.css';

const CustomInput = ({ placeholder, type = "text", name, value, isRead, ...props }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (e) => {
    setIsFocused(false);
    setHasValue(e.target.value !== '');
  };

  useEffect(() => {
    if (value) {
      setHasValue(true);
    }
  }, [value]);
  return (
    <div className={`custom-input ${isFocused || hasValue ? 'focused' : ''}`}>
      <Input 
        {...props} 
        type={type}
        value={value}
        onFocus={handleFocus} 
        onBlur={handleBlur}
        readOnly={isRead}
      />
      <label className="labelGG">{placeholder}</label>
    </div>
  );
};

export default CustomInput;

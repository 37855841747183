import { Button, Checkbox, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import "./style.scss";
import axios from "axios";
import toast from "react-hot-toast";
import localStorageServ from "../../services/locaStorage.service";
import InputOtp from "./InputOtp";
import { BASE_URL_CODINGCAMP } from "../../services/axios.service";
import CustomInput from "../../components/CustomInput/CustomInput";
const FormLoginEmailPhone = () => {
  const [isShowOtp, setIsShowOtp] = useState(false);
  const [formValue, setformValue] = useState({});

  const onFinish = (values) => {
    axios
      .post(`${BASE_URL_CODINGCAMP}/api/nguoidung/login-user`, values)
      .then((res) => {
        console.log(res);
        toast.success("Đăng nhập thành công");
        localStorageServ.userInfo.set(res.data.content);
        // window.location.href = "/";
        setformValue(values);
        setIsShowOtp(true);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div id="formLogin">
      {!isShowOtp ? (
        <Form
        style={{width:'100%'}}
          name="basic"
          layout="vertical"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Email không được để trống",
              },
            ]}
          >
            <CustomInput placeholder="Email"/>
          </Form.Item>

          <Form.Item
            name="matKhau"
            rules={[
              {
                required: true,
                message: "Số điện thoại không được để trống",
              },
            ]}
          >
            <CustomInput placeholder="Số điện thoại"/>
          </Form.Item>

          <Form.Item
          
          style={{textAlign:'center'}}
            wrapperCol={{
              span: 16,
            }}
          >
            <button className="btnLoginMobile h-10 leading-9 p-0 px-5 pb-5">Đăng nhập</button>
          </Form.Item>
        </Form>
      ) : (
        <InputOtp formValue={formValue} />
      )}
    </div>
  );
};
export default FormLoginEmailPhone;
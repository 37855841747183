import React, { useMemo, useState } from "react";

import { Tabs } from "antd";

import Static_Public from "./Static_Public";
import CardDetailLoTrinh from "./CardDetailLoTrinh";
import IntroLoTrinh from "./IntroLoTrinh";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLstLoTrinh } from "../../../hook/useLstLoTrinhUser";
import { useLstImgTag } from "../../../hook/useLstImgTag";

const { TabPane } = Tabs;
export default function CardLoTrinh({ btn_tuVan, loTrinh, arrChuyenDe, isBlackFridayDay = false }) {
  const { data, isLoading, error } = useLstLoTrinh();
  const renderTask = (soLuong, maLoai) => {
    let icon = ""
    let title = ""
    switch (maLoai) {
      case 'RUNCODE':
        icon = "fa-code"
        title = "Runcode"
        break
      case 'VIDEO':
        icon = "fa-video"
        title = "Video"
        break
      case 'TASK':
        icon = "fa-tasks"
        title = "Task"
        break
      case 'QUIZ':
        icon = "fa-question-circle"
        title = "Quizz"
        break
      case 'HOMEWORK':
        icon = "fa-question-circle"
        title = "BTVN"
        break
      case 'TAILIEU':
        icon = "fa-book"
        title = "Tài liệu"
        break
      case 'BAITAP':
        icon = "fa-file-alt"
        title = "Bài tập"
        break
      case 'DUAN':
        icon = "fa-gavel"
        title = "Dự án"
        break
      case 'CAPS':
        icon = "fa-users"
        title = "Capstone"
        break
    }
    return <div className='flex flex-col items-center justify-between'><i className={`p-0 m-0 text-lg fas ${icon}`}></i><p className='text-center p-0 m-0'>{soLuong} {title}</p></div>
  }

  const navigate = useNavigate();
  const { data:listImgTag , isLoading:isLoadingTag, error:errorTag } = useLstImgTag();
  const [collapsed, setCollapsed] = useState(true);
  let isDemoUser = useMemo(() => isBlackFridayDay, [])
  let ds = JSON.parse(loTrinh.danhSachKhoaHoc)
  console.log(loTrinh)
  return (
    <div
      className="w-full  mb-16  animation_loTrinh "
      // ref={ref_Props}
      id={loTrinh.id}
    >
      <div
        className="  "
        // data-tour="loTrinh-step2"
        data-tour="loTrinhCuaBan-step1"
      >
        <div
          className=" flex w-full md:h-72 lg:h-72 h-64"
          data-tour="loTrinh-step2"
        // data-tour="loTrinhCuaBan-step1"
        >
          <div
            onClick={() => {
              setCollapsed(!collapsed);
            }}
            className="flex-shrink-0 h-full  relative mr-5"
          >
            <IntroLoTrinh
              isBlackFridayDay={isBlackFridayDay}
              isShowDate={false}
              loTrinh={loTrinh}
              isHoverActive={!collapsed}
              btn_tuVan={btn_tuVan}
            />
          </div>
          <div
            onClick={() => {
              setCollapsed(!collapsed);
            }}
            className="w-full h-full flex  justify-between  relative     cursor-pointer"
          >
            <Static_Public isBlackFridayDay={isBlackFridayDay} loTrinhPublic={loTrinh}></Static_Public>

            <div className="   z-40 absolute bottom-0 translate-y-1/2 left-1/2 transform -translate-x-1/2 ">
              {/* <Curved_Button collapsed={collapsed} /> */}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          height: collapsed ? 0 : "max-content",
          marginTop: collapsed ? "0" : "40px",
          opacity: collapsed ? "0" : "1",
        }}
        className={
          collapsed
            ? "w-full   duration-150  overflow-hidden"
            : "w-full   duration-200 border-fade card_theme"
        }
        data-tour="loTrinh-step3"
      >
        <div data-tour="loTrinhCuaBan-step2" className="loTrinhCuaBan-step2">
          <Tabs defaultActiveKey="1" className="p-3">
            <TabPane tab="Khoá học trong lộ trình" key="1">
              {isLoading && <div className='flex justify-center'> <div className=' loading_progress'></div></div>}
              <div className="grid gap-8 grid-cols-3 md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 place-content-center w-full  place-items-center py-10">
                {data&& ds.map((cd, idx1) => {
                   const item = data?.find(a => a.chuyenDeId === cd)
                   return <div className="course-card cursor-pointer" >
                   <div className="course-header" onClick={() => {
                     navigate(`/task-page-free/${item.chuyenDeId}`);
                   }}>
                     <img className='w-full overflow-hidden' src={`https://apimentor11.cybersoft.edu.vn/${item.hinhAnh}`} alt="Khóa học Fullstack: Dự án Monday từ A đến Z" />
                    
                   </div>
                   <div className="content__group flex flex-col justify-between ">
                     <div className="course-content" onClick={() => {
                       navigate(`/task-page-free/${item.chuyenDeId}`);
                     }}>
                       <h4 className="text-xl font-bold text-gray-800 mt-0 mb-2">{item.tenChuyenDe}</h4>
                       <div className="course-info">
                         {item?.thongKeChuyenDe?.filter(({ tongSoLuong }) => tongSoLuong > 0)
                           .map(({ tongSoLuong, tieuDe, maLoai }, index) => {
                             return (
                               renderTask(tongSoLuong, maLoai)
                             );
                           })}


                       </div>
                       <div className="flex flex-wrap mb-2">
                         {item?.danhSachTag?.map((tag) => {
                           let item1 = listImgTag?.find((item1) => {
                             return item1?.value == tag;
                           });
                           return (
                             <div className='md:w-8 md:h-8 w-10 h-10 p-1'>
                               <img className="w-full h-full object-cover" src={item1?.image} alt />

                             </div>
                           );
                         })}
                       </div>

                     </div>
                   </div>
                 </div>
                })}
              </div>
            </TabPane>

          </Tabs>
        </div>
      </div>
    </div>
  );
}
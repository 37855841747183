import React, { useEffect, useState } from "react";
import ContentTab from "../../components/ContentTab/ContentTab";
import ListCompetitions from "./ListCompetitions/ListCompetitions";
import servCodingCamp from "../../services/codingCam.service";

const dataAll = [
  {
    img: "./img/card-best-pratice.png",
    title: "10 Challange JS fudamemeta",
    diamon: "30,000",
    view: "1.000",
    coin: "30",
    gift: "3",
    participants: 256,
  },
  {
    img: "./img/card-best-pratice.png",
    title: "10 Challange JS fudamemeta",
    diamon: "30,000",
    view: "1.000",
    coin: "30",
    gift: "3",
    participants: 256,
  },
  {
    img: "./img/card-best-pratice.png",
    title: "10 Challange JS fudamemeta",
    diamon: "30,000",
    view: "1.000",
    coin: "30",
    gift: "3",
    participants: 256,
  },
  {
    img: "./img/card-best-pratice.png",
    title: "10 Challange JS fudamemeta",
    diamon: "30,000",
    view: "1.000",
    coin: "30",
    gift: "3",
    participants: 256,
  },
];
const data = [
  {
    label: `All Competitions`,
    key: "1",
    children: <ListCompetitions data={dataAll} />,
  },
  {
    label: `Thirty min challenge`,
    key: "2",
    children: <ListCompetitions data={dataAll} />,
  },
  {
    label: `One hour challenge`,
    key: "3",
    children: <ListCompetitions data={dataAll} />,
  },
  {
    label: `Project team`,
    key: "4",
    children: <ListCompetitions data={dataAll} />,
  },
];
export default function CompetitionsPage() {
  const [competion, setCompetion] = useState({});
  useEffect(() => {
    servCodingCamp
      .getListCompetition()
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <main className="flex mt-36">
      <div className="c-col-full">
        <div className="card-theme">
          <p className="text-title font-extrabold space-x-3 c-card-content border-b-2 border-gray-200">
            <i class="fab fa-earlybirds"></i>
            <span>Competitions</span>
          </p>
          <div className="c-card-content mt-12">
            <ContentTab
              paddingHeaderX={10}
              paddingHeaderY={5}
              paddingTabItemX={8}
              paddingTabItemY={5}
              data={data}
            />
          </div>
        </div>
      </div>
    </main>
  );
}

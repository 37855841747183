import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import localStorageServ from "../../services/locaStorage.service";
import { API_URL_DOMAIN } from "../../utils/urlDomain";
// import servCodingCamp from "../../services/codingCam.service";

const initialState = {
  token: "",
  userInfo: {},
  thongTinMoRong: {
    userInfo: {},
    danhSachThanhTich: [],
    phanTramTichCucKinhNghiem: {},
  },
};

export const updateThongTinMoRongAction = createAsyncThunk(
  "authUser/updateThongTinMoRongAction",
  async (thunkAPI) => {
    let config = {
      token: localStorageServ.userInfo.get()?.token,
      apiKey: "UPD124yRTWF124QJFweUaCYSECETBERS",
      "Content-Type": "application/json",
    };
    const response = await axios.get(
      `${API_URL_DOMAIN}/lichsutuongtac/lay-thongtin-user`,
      {
        headers: config,
      }
    );

    return response.data.content;
  }
);

export const authSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    setUserInfor: (state, action) => {
      state.thongTinMoRong = action.payload;
    },
    setInforLoginDemo: (state, action) => {
      state.userInfo = action.payload;
    },
    setTimeUserDemo: (state, action) => {
      state.userInfo = action.payload;
    },
    setThongTinMoRong: (state, action) => {
      state.thongTinMoRong = action.payload;
    },
  },
  extraReducers: {
    [updateThongTinMoRongAction.fulfilled]: (state, action) => {
      state.thongTinMoRong = action.payload;
    },
  },
});
export const { setUserInfor, setInforLoginDemo, setTimeUserDemo, setThongTinMoRong } =
  authSlice.actions;

export default authSlice.reducer;

// useSelector

export const selectThongTinMoRong = (state) => state.authUser.thongTinMoRong;
export const selectUserInfor = (state) => state.authUser.userInfo;


import React, { useEffect, useMemo } from "react";
import ScreenVideo from "./ScreenVideo";
import SidebarLearnVideo from "./SidebarLearnVideo";
import TabLearnVideo from "./TabLearnVideo";

import { useState } from "react";
import * as signalR from "@microsoft/signalr";
import { useDispatch, useSelector } from "react-redux";
import servCodingCamp from "../../services/codingCam.service";
import { useNavigate, useParams } from "react-router-dom";
import { loaiNhiemVu } from "../../utils/taskUtils";
import ModalConfetti from "../../components/ModalConfetti/ModalConfetti";
import {
  selectThongTinMoRong,
  updateThongTinMoRongAction,
} from "../../redux/reducer/authReducer";
import { BASE_URL_CODINGCAMP } from "../../services/axios.service";
import toast from "react-hot-toast";
import { navigatePageByTaskNext, parentPage } from "../../routes/utitlity";
import { useLstLoTrinhDangHoc, useLstLoTrinhUser } from "../../hook/useLstLoTrinhUser";
import { checkDemoUser, checkLearning } from "../../utils/HocDemoUtils";
import useWindowSize from "../../hook/useWindowSize";
import localStorageServ from "../../services/locaStorage.service";

export default function LearnVideoPage() {
  const dispatch = useDispatch();
  let userInfo = localStorageServ.userInfo.get();
  let { chuyenDeId, taskId, monHocId } = useParams();
  const [listBaiHoc, setListBaiHoc] = useState([]);
  const [baiHoc, setBaiHoc] = useState({});
  
  const { data: dataDangHoc } = useLstLoTrinhDangHoc(userInfo?.id);
  const isLearning = checkLearning(chuyenDeId, dataDangHoc);
  useEffect(() => {
    getListBaiHoc();
  }, [taskId]);
  // ! check trạng thái bài học
  useEffect(() => {
    // getListHoanThanhHoc();
  }, [baiHoc?.content?.id]);
  // ! lấy danh sách bài học
  
  let isDemoUser = useMemo(() => checkDemoUser(), [])
  const { data, isLoading, error } = useLstLoTrinhUser(userInfo);
  let getListBaiHoc = (isFristFetch = true) => {
    isDemoUser || !userInfo.kichHoat || !isLearning?
    servCodingCamp
      .getDetailTaskTrial(chuyenDeId, monHocId, taskId)
      .then((res) => {
        let { noiDungNhiemVu: listData, maLoaiNhiemVu, tenNhiemVu } = res.data.content;
        let listBaiHoc = [];
        let index = 0;
        // if (maLoaiNhiemVu == loaiNhiemVu.RECORD) {
        //   listData.forEach((baiHoc, index) => {
        //     listBaiHoc.push({
        //       content: {
        //         tenBaiHoc: tenNhiemVu,
        //         id: baiHoc.baiHocId,
        //         noiDung: baiHoc.noiDung,
        //       },
        //       type: loaiNhiemVu.RECORD,
        //       isVideo: true,
        //       viTri: index++,
        //     });
        //   });
        // } else {
          listData.forEach((chuongHoc) => {
            listBaiHoc.push({
              content: chuongHoc.tenChuong,
              isVideo: false,
              viTri: index++,
            });

            chuongHoc.noiDungBaiHoc.forEach((baiHoc) => {
              listBaiHoc.push({
                chuongHocId: chuongHoc.chuongHoc,
                content: baiHoc,
                isVideo: true,
                viTri: index++,
                type: baiHoc.maLoai,
              });
            });
          });
        // }
        let firstVideoIndex = listBaiHoc.findIndex((item) => {
          return item.isVideo;
        });
        isFristFetch && setBaiHoc(listBaiHoc[firstVideoIndex]);

        setListBaiHoc(listBaiHoc);
      })
      .catch((err) => {
        console.log(err);
      })
      :
    servCodingCamp
      .getDetailTask(chuyenDeId, monHocId, taskId)
      .then((res) => {
        let { noiDungNhiemVu: listData, maLoaiNhiemVu, tenNhiemVu } = res.data.content;
        let listBaiHoc = [];
        let index = 0;
        // if (maLoaiNhiemVu == loaiNhiemVu.RECORD) {
        //   listData.forEach((baiHoc, index) => {
        //     listBaiHoc.push({
        //       content: {
        //         tenBaiHoc: tenNhiemVu,
        //         id: baiHoc.baiHocId,
        //         noiDung: baiHoc.noiDung,
        //       },
        //       type: loaiNhiemVu.RECORD,
        //       isVideo: true,
        //       viTri: index++,
        //     });
        //   });
        // } else {
          listData.forEach((chuongHoc) => {
            listBaiHoc.push({
              content: chuongHoc.tenChuong,
              isVideo: false,
              viTri: index++,
            });

            chuongHoc.noiDungBaiHoc.forEach((baiHoc) => {
              listBaiHoc.push({
                chuongHocId: chuongHoc.chuongHoc,
                content: baiHoc,
                isVideo: true,
                viTri: index++,
                type: baiHoc.maLoai,
              });
            });
          });
        // }
        let firstVideoIndex = listBaiHoc.findIndex((item) => {
          return item.isVideo;
        });
        isFristFetch && setBaiHoc(listBaiHoc[firstVideoIndex]);

        setListBaiHoc(listBaiHoc);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  const navigate = useNavigate();
  let handleChangeBaiHoc = (baiHoc) => setBaiHoc(baiHoc);
  const handleHoanThanhBaiHoc = () => {
    // khi nào ấn sẽ lấy được monHocId mới nhất

    const payloadHoanThanhTask = {
      nguoiDungId: userInfo.id,
      chuyenDeId: chuyenDeId * 1,
      monHocId: monHocId * 1,
      taskId: taskId * 1,
      maLoai: baiHoc.type,
      baiHocId: baiHoc.content.id * 1,
      soPhutThucHien: 0,
    };

    servCodingCamp
      .postHoanThanhHoc(payloadHoanThanhTask)
      .then((res) => {
        // dispatch(setPhanThuong(res.data.content));
        // getListHoanThanhHoc();
        getListBaiHoc(false);
        handleChangeNext();
        dispatch(updateThongTinMoRongAction());
        toast.success("Đã hoàn thành video!")
        if(baiHoc.viTri ==(listBaiHoc.length-1) && isLearning){
          navigatePageByTaskNext(res.data.content, navigate, parentPage.task, monHocId, chuyenDeId);
          toast.success(" Hãy đến với nhiệm vụ tiếp theo!")
        }

      })
      .catch((err) => {
        console.log(err);
      });
  };
  let handleChangeNext = () => {
    for (let index = baiHoc.viTri + 1; index < listBaiHoc.length; index++) {
      if (index == listBaiHoc.length) {
          // navigatePageByTaskNext(res.data.content, navigate, parentPage.task, monHocId, chuyenDeId);
          toast.success(" Hãy đến với nhiệm vụ tiếp theo!")
        
        return;
      }
      const baiHoc = listBaiHoc[index];
      if (baiHoc.isVideo) {
        handleChangeBaiHoc(baiHoc);
        return;
      }
    }
  };
  let handleChangePrevious = () => {
    for (let index = baiHoc.viTri - 1; index >= 0; index--) {
      const baiHoc = listBaiHoc[index];
      if (baiHoc.isVideo) {
        handleChangeBaiHoc(baiHoc);
        return;
      }
    }
  };
  const { widthWindow, heightWindow } = useWindowSize();
  // let widthVideo = collapsed ? "c-col-full" : "c-col-70";
  let widthVideo = "";
  return (
    <div className="c-card-content overflow-hidden relative  w-full ">
      <ModalConfetti />
      <div className="flex flex-col  relative pt-10 ">
        <div className={`flex transition duration-700   ${widthVideo} ${widthWindow<768?"flex-col":""} flex-grow`}>
          <ScreenVideo
            baiHoc={baiHoc}
            handleChangeBaiHoc={handleChangeBaiHoc}
            listBaiHoc={listBaiHoc}
            handleHoanThanhBaiHoc={handleHoanThanhBaiHoc}
            handleChangeNext={handleChangeNext}
            handleChangePrevious={handleChangePrevious}
          />

          <SidebarLearnVideo
            listBaiHoc={listBaiHoc}
            handleChangeBaiHoc={handleChangeBaiHoc}
            activeID={baiHoc?.content?.id}
          />
        </div>



        <TabLearnVideo baiHoc={baiHoc} />
      </div>
    </div>
  );
}

// import { http } from "../util/config";

import { http } from "../utils/config";

const GET_PATH = "/api/baihoc";

const layDanhSachBaiHoc = () => {
  return http.get(GET_PATH);
};

const themBaiHoc = (data) => {
  return http.post(GET_PATH, data);
};
const suaBaiHoc = (data) => {
  return http.put(`${GET_PATH}/${data.id}`, data);
};
const xoaBaiHoc = (id) => {
  return http.delete(`${GET_PATH}/${id}`);
};

const postDanhSachBaiHocByListId = (ds) => {
  return http.post(`${GET_PATH}/dsbaihocbylistid`, ds);
};

export const baiHocService = {
  layDanhSachBaiHoc,
  themBaiHoc,
  suaBaiHoc,
  xoaBaiHoc,
  postDanhSachBaiHocByListId,
};

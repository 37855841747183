import { useQuery } from "@tanstack/react-query";
import axios from 'axios';

export function useArrCourseCybersoft() {
  return useQuery({
    queryKey: ['arrCyber'],
    queryFn: () => {
      return axios
        .get("https://apicrm.cybersoft.edu.vn/api/gioithieu")
        .then(res => res.data.content);
    },
    staleTime: 7 * 24 * 60 * 60 * 1000, // Dữ liệu được coi là mới trong 24h
    cacheTime: 14 * 24 * 60 * 60 * 1000, // Dữ liệu còn trong bộ nhớ cache 7 ngày sau khi không còn được sử dụng
    refetchOnWindowFocus: false,
    retry: false,
  });
}

import React, { useEffect, useState } from "react";
import servCodingCamp from "../../../services/codingCam.service";
import { useSelector } from "react-redux";
import { selectVideoWall } from "../tuongNhaSlice";
import localStorageServ from "../../../services/locaStorage.service";
import { useParams } from "react-router-dom";

export default function BtnFollow() {
  const [isFllow, setIsFllow] = useState(true);
  //   let { thongTinUser: owner } = useSelector(selectVideoWall);

  let { userId } = useParams();
  let id = localStorageServ.userInfo.get()?.id;
  let fetchCheckFollow = () => {
    servCodingCamp
      .checkFollow(userId)
      .then((res) => {
        setIsFllow(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    userId && fetchCheckFollow();
  }, [userId]);

  const handleFollowUser = (status) => {
    servCodingCamp
      .toggleFlollow(userId, status)
      .then((res) => {
        console.log(res);
        fetchCheckFollow();
      })
      .catch((err) => {
        console.log(err);
      });
    // status: true: thêm follow, false: xóa follow
  };
  //   alert(id + userId);
  if (id == userId) {
    // ần follow
    return <></>;
  }
  if (!id) {
    return <></>;
  }
  return isFllow ? (
    <button
      className="btnFollow  "
      onClick={() => {
        handleFollowUser(false);
      }}
    >
      Đã theo dõi
    </button>
  ) : (
    <button
      className="btnFollow not-follow"
      onClick={() => {
        handleFollowUser(true);
      }}
    >
      Theo dõi
    </button>
  );
}

import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TooltipContentNhiemVu from "../TooltipContentNhiemVu/TooltipContentNhiemVu";
import {
  navigatePageByTask,
  parentPage,
  typeTask,
} from "../../routes/utitlity";
import { BASE_URL_CODINGCAMP } from "../../services/axios.service";
import servCodingCamp from "../../services/codingCam.service";
import { markTaskAsCompleted, nhiemVuColor } from "../../utils/taskUtils";
import { Tooltip } from "antd";
import { typeTrangThaiNop } from "../StepProjectInstruction/util";
import { useDispatch, useSelector } from "react-redux";
import { getDataTaskPageAction, getDataTaskPageTrialAction } from "../../redux/reducer/taskPageSlice";
import { checkDemoUser, checkLearning } from "../../utils/HocDemoUtils";
import { selectThongTinMoRong } from "../../redux/reducer/authReducer";
import { useLstLoTrinhUser } from "../../hook/useLstLoTrinhUser";
import { isIconColappse } from "../../pages/TaskPage/CollapseTask/ItemTask";

export default function TitleTaskNavigate({ task, showModal }) {
  const { userInfo } = useSelector(selectThongTinMoRong);
  const { data, isLoading, error } = useLstLoTrinhUser(userInfo);
  let { chuyenDeId } = useParams();
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const isLearning =checkLearning(chuyenDeId,data)
  const handleOpenTaiLieu = async ({ chuyenDeId, monHocId, taskId }) => {
    isDemoUser  || !userInfo.kichHoat ?
    servCodingCamp
      .getDetailTaskTrial(chuyenDeId, monHocId, taskId)
      .then((res) => {
        console.log('mở file')
        window.open(
          `${BASE_URL_CODINGCAMP}/${res.data.content.noiDungNhiemVu[0].noiDung}`
        );
      })
      .catch((err) => {
        console.log(err);
      })
      :
      servCodingCamp
      .getDetailTask(chuyenDeId, monHocId, taskId)
      .then((res) => {
        window.open(
          `${BASE_URL_CODINGCAMP}/${res.data.content.noiDungNhiemVu[0].noiDung}`
        );
      })
      .catch((err) => {
        console.log(err);
      });
    await markTaskAsCompleted(
      chuyenDeId,
      monHocId,
      taskId,
      typeTask.TAILIEU,
      taskId
    );
    isDemoUser || !userInfo.kichHoat ?
    dispatch(getDataTaskPageTrialAction(chuyenDeId))
    :
    dispatch(getDataTaskPageAction(chuyenDeId));
  };
  let isAllow = task.truyCap || window.location.href.includes("test");

  let renderTrangThaiBaiTap = () => {
    if (!task.maLoaiNhiemVu == typeTask.BAITAP) return <></>;
    switch (task.trangThaiBaiTap) {
      case typeTrangThaiNop.CHO_CHAM:
        return <span className="text-yellow-theme">Chờ chấm</span>;
      case typeTrangThaiNop.DA_CHAM:
        return (
          <span style={{ color: "#39ff14" }} className="icon-green">
            Đã chấm
          </span>
        );
      default:
        return <></>;
        return <span className="text-green-theme">{task.trangThaiBaiTap}</span>;
    }
  };
  let isDemoUser = useMemo(() => checkDemoUser(), []);
  return (
    <button className="flex justify-between w-full text-black">
      <div className="flex">
        <div className="flex items-center space-x-3">
          <TooltipContentNhiemVu maLoaiNhiemVu={task.maLoaiNhiemVu} />
          {isDemoUser || !userInfo.kichHoat || !isLearning ? (
            task.xemDemo ? (
              userInfo.email=='freeacc@gmail.com'?
              
                <p className="font-semibold text-left text-content " onClick={() => { 
                  if(!isIconColappse(task.maLoaiNhiemVu)){
                    showModal()
                  }
                 }}>
                  {" "}
                  {task.tenNhiemVu}
                  <span className="pl-2">({task.hoanThanhLabel})</span>
                  <span className="mark__free">Free</span>
                </p>
              :
              <p
                onClick={() => {
                  if (task.maLoaiNhiemVu == typeTask.TAILIEU) {
                    let { chuyenDeId, monHocId, id: taskId } = task;
                    handleOpenTaiLieu({ chuyenDeId, monHocId, taskId });
                  }
                  navigatePageByTask(task, navigate, parentPage.task);
                }}
                className="font-semibold text-left text-content "
              >
                {task.tenNhiemVu}
                <span className="pl-2">({task.hoanThanhLabel})</span>
                <span className="mark__free">Free</span>
              </p>
            ) : (
              // <Tooltip
              //   trigger={"click"}
              //   placement="topLeft"
              //   title={
              //     <p className="bg-white text-black p-1 rounded" onClick={showModal}>
              //       Bạn cần đăng ký khóa học để xem nội dung này.
              //     </p>
              //   }
              //   arrowPointAtCenter
              // >
                <p className="font-semibold text-left text-gray-500 "   onClick={() => { 
                  if(!task.xemDemo){
                    showModal()
                  }
                 }}>
                  {" "}
                  {task.tenNhiemVu}
                  <span className="pl-2">({task.hoanThanhLabel})</span>
                </p>
              // </Tooltip>
            )
          ) :
          isAllow ? (
            <p
              onClick={() => {
                if (task.maLoaiNhiemVu == typeTask.TAILIEU) {
                  let { chuyenDeId, monHocId, id: taskId } = task;
                  handleOpenTaiLieu({ chuyenDeId, monHocId, taskId });
                }
                navigatePageByTask(task, navigate, parentPage.task);
              }}
              className="font-semibold text-left text-content "
            >
              {task.tenNhiemVu}
              <span className="pl-2">({task.hoanThanhLabel})</span>
            </p>
          ) : (
            <Tooltip
              trigger={"click"}
              placement="topLeft"
              title={
                <p className="bg-white text-black p-1 rounded">
                  Bạn cần hoàn thành bài học trước đó trước khi tiếp tục.
                </p>
              }
              arrowPointAtCenter
            >
              <p className="font-semibold text-left text-gray-500 ">
                {" "}
                {task.tenNhiemVu}
                <span className="pl-2">({task.hoanThanhLabel})</span>
              </p>
            </Tooltip>
          )}
          {renderTrangThaiBaiTap()}
        </div>
      </div>
    </button>
  );
}
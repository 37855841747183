import React, { useEffect, useMemo, useState } from "react";
import servCodingCamp from "../../../services/codingCam.service";
import { Item } from "./Item";
import "./style.scss";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setListImageTag } from "../../../redux/reducer/dashboardSlice";
import { useTranslation } from "react-i18next";
import { checkDemoUser } from "../../../utils/HocDemoUtils";
import { selectThongTinMoRong } from "../../../redux/reducer/authReducer";
import SpinnerComponent from "../../../components/Spinner";
import { useLstLoTrinhUser } from "../../../hook/useLstLoTrinhUser";
export default function ListModule() {

  // const [listLoTrinh, setListLoTrinh] = useState([]);

  const dispatch = useDispatch();

  // gọi api lấy danh sách chuyển đề (lộ trình) đã đăng ký

  const { t } = useTranslation()

  let isDemoUser = useMemo(() => checkDemoUser(), [])

  const { userInfo } = useSelector(selectThongTinMoRong);

  const [load, setLoad] = useState(false)


  useEffect(() => {
    // setLoad(true)
    // isDemoUser || !userInfo.kichHoat ?
    //   servCodingCamp
    //     .layAllChuyenDeTrial()
    //     .then((res) => {
    //       setListLoTrinh(res.data.content);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     }).finally(() => {
    //       setLoad(false)
    //     })
    //   :
    //   servCodingCamp
    //     .layLotrinhUser()
    //     .then((res) => {
    //       setListLoTrinh(res.data.content);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     }).finally(() => {
    //       setLoad(false)
    //     })

    axios
      .get("https://apimentor11.cybersoft.edu.vn/api/danh-sach-tag/lay-hinh-tag")
      .then((res) => {
        dispatch(setListImageTag(res.data.content));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userInfo]);
  const { data, isLoading, error } = useLstLoTrinhUser(userInfo);
  return (
    <div className=" c-col-full  relative space-y-4">
      {isLoading && <SpinnerComponent />}
      <p className="font-bold text-title text-left"> {t('trangChu.loTrinhDangHoc')}</p>

      <div className="grid list_module  w-full ">
        {data?.map((item, index) => {
            return <Item data={item} key={index} />;
        })}
      </div>
    </div>
  );
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import servCodingCamp from "../../services/codingCam.service";
import { message } from "antd";
import { history } from "../../App";
import axios from "axios";
import { BASE_URL_CODINGCAMP } from "../../services/axios.service";
import localStorageServ from "../../services/locaStorage.service";

const initialState = {
  dataTaskPage: {},
  loading: false,
  error: null,
};
export const getDataTaskPageAction = createAsyncThunk(
  "task/getDataTaskPage",
  async (chuyenDeId, thunkAPI) => {
    try {
      const res = await servCodingCamp.getDataTaskPage(chuyenDeId);
      return res.data.content;
    } catch (error) {
      history.push("/");
      message.warning("Bạn đã hết thời gian tham gia khóa học");
    }
  }
);
export const getDataTaskPageTrialAction = createAsyncThunk(
  "task/getDataTaskPage",
  async (chuyenDeId, thunkAPI) => {
    try {
      const res = await servCodingCamp.getDataTaskPageTrial(chuyenDeId);
      return res.data.content;
    } catch (error) {
      history.push("/");
      message.warning("Bạn đã hết thời gian tham gia khóa học");
    }
  }
);
export const getDataTaskPageFreeAction = createAsyncThunk(
  "task/getDataTaskPage",
  async (chuyenDeId, thunkAPI) => {
    try {
          const values = {
            email: "freeacc@gmail.com",
            matKhau: "111111",
          };
              const res = await servCodingCamp.getDataTaskFreePage(chuyenDeId,values);
              return res.data.content;
           
        }
        catch(er){

        }
        }
  
);
const taskPageSlice = createSlice({
  name: "taskPageSlice",
  initialState,
  reducers: {},
  extraReducers: {
    [getDataTaskPageAction.fulfilled]: (state, action) => {
      state.dataTaskPage = action.payload;
      state.loading = false;
    },
    [getDataTaskPageAction.pending]: (state) => {
      state.loading = true;
    },
    [getDataTaskPageAction.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {} = taskPageSlice.actions;
export default taskPageSlice.reducer;

import { Button } from "antd";
import React from "react";

export default function HoanThanhTV() {
  return (
    <div className="w-full flex flex-col space-y-10 justify-center items-center p-5 text-white">
      <>
        <p className="text-lg lg:text-2xl">ĐĂNG KÝ THÀNH CÔNG</p>
        <Button className="px-5 bg-yellow-400 border-none text-black" type="primary" htmlType="submit">
          <a href="/login"> Về trang chủ</a>
        </Button>
      </>
    </div>
  );
}

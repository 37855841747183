import React, { useState, useEffect } from "react";

import _ from "lodash";

import Navigate_Footer_Pratices_DaoVao from "./Navigate_Footer_Pratices_DaoVao";
import servCodingCamp from "../../services/codingCam.service";
import RuncodeContainer from "../../pages/Runcode/Runcode";
import { useSelector } from "react-redux";
import { convertDataQuizz, canClickNextQuestion } from "../List4TypeQuiz/utils";
import List4TypeQuiz from "../List4TypeQuiz/List4TypeQuiz";
import List4TypeQuizDauVao from "../List4TypeQuiz/List4TypeQuizDauVao";
export default function Content_Quizz_TestDauVao() {
  let [currentQuestionIndex, setCurrentQuestsionIndex] = useState(0);
  let [scoreQuiz, setScoreQuiz] = useState(null);
  const [allQuestions, setListQuestion] = useState([]);
  let { maBaiTest } = useSelector((state) => state.testDauVaoSlice.info);

  useEffect(() => {
    servCodingCamp
      .getDataBaiTest(maBaiTest)
      .then((res) => {
        setListQuestion(convertDataQuizz(res.data.content));
      })
      .catch((err) => {
        
      });
  }, []);

  let handleClickNextQuestion = () => {
    setCurrentQuestsionIndex(currentQuestionIndex + 1);
  };

  // nếu có điểm thì show điểm
  let isDisableNextBtn = canClickNextQuestion(
    allQuestions,
    currentQuestionIndex
  );
  return (
    <div className=" c-card-content min-h-screen bg c-card-content flex flex-col space-y-4 px-10 ">
      <div className="w-full mb-5 ">
        {/* {arrRenderQuestion.length > 0 &&
          arrRenderQuestion[currentQuestionIndex]} */}
        <List4TypeQuizDauVao
          setListQuestion={setListQuestion}
          currentQuestionIndex={currentQuestionIndex}
          allQuestions={allQuestions}
        />
      </div>
      <Navigate_Footer_Pratices_DaoVao
        current={currentQuestionIndex + 1}
        total={allQuestions.length}
        handleClickNextQuestion={handleClickNextQuestion}
        isDisableBtn={isDisableNextBtn}
        listQuestion={allQuestions}
        setScoreQuiz={setScoreQuiz}
      />
    </div>
  );
}

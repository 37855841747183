import { Button } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import BtnLoginFacebook from "../../../components/BtnLoginFacebook/BtnLoginFacebook";
import FormLoginEmailPhone from "../../LoginPageEmail/FormLoginEmailPhone";

export default function HoanThanh() {
  return (
    <div className="w-full flex flex-col space-y-10 justify-center items-center p-5 text-white">
      <>
        <p className="text-lg lg:text-2xl">ĐĂNG KÝ THÀNH CÔNG</p>
        <p className="text-base md:text-lg lg:text-2xl">
          Cảm ơn bạn đã đăng ký! CodeZuni sẽ xét duyệt hồ sơ và liên hệ sớm nhất đến các hồ sơ đạt yêu cầu! Các hồ sơ đạt yêu cầu sẽ được gởi email và liên lạc qua
          điện thoại/zalo để hướng dẫn trực tiếp cho bạn!
        </p>

        {/* <Button className="px-5 bg-yellow-400 border-none text-black" type="primary" htmlType="submit">
          <a href="/login"> Về trang chủ</a>
        </Button> */}
        <div className=" w-full flex-shrink-0 border-none p-5 flex flex-col items-start ">
            <div className="  flex-grow  w-full flex flex-col justify-center space-y-7 pb-24 relative z-10 items-center">
              <p className="title_login text-3xl text-white text-center">Hệ thống học tập CodeZuni</p>
              <BtnLoginFacebook />
              <span className="pt-3">HOẶC</span>
              <div style={{width:'90%'}}>
              <FormLoginEmailPhone/>
              </div>
             
            </div>
          </div>
        {/* <BtnLoginFacebook /> */}
      </>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Checkbox } from "antd";
import servCodingCamp from "../../services/codingCam.service";
import { useTranslation } from "react-i18next";
const onChange = (e) => {
  // console.log(`checked = ${e.target.checked}`);
};

export default function ShopFilter({ activeFilter, setActiveFilter }) {
  const { t } = useTranslation()

  const [options, setOptions] = useState([]);
  useEffect(() => {
    servCodingCamp
      .loaiVatPham()
      .then((res) => {
        setOptions(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className=" w-80  2xl:w-64 space-y-5">
      <h4
        style={{
          borderBottom: " 1px solid rgba(249, 211, 128, 1)",
        }}
        className="text-content font-bold uppercase"
      >
        {t('vatPham.chonLoc')}

      </h4>

      <div className="space-y-5 ">
        <div className="item_filter">
          <Checkbox
            onClick={() => { }}
            className="transform "
            onChange={() => {
              setActiveFilter(0);
            }}
            checked={activeFilter == 0}
          >
            <span className="pl-2 text-content uppercase">tất cả</span>
          </Checkbox>
        </div>
        {options.map(({ label, value }) => {
          return (
            <div className="flex items-center space-x-5 item_filter">
              <Checkbox
                checked={activeFilter == value}
                onClick={() => { }}
                className="transform "
                onChange={() => {
                  setActiveFilter(value);
                }}
              >
                <span className="pl-2 text-content uppercase">{label}</span>
              </Checkbox>
            </div>
          );
        })}
      </div>
    </div>
  );
}

import React from "react";
import TitleTaskNavigate from "../../TitleTaskNavigate/TitleTaskNavigate";

export default function ItemTask({ task }) {
  let iconIsAllow = task.truyCap ? (
    <i class="fa fa-unlock-alt text-green-600"></i>
  ) : (
    <i class="fa fa-lock text-red-500"></i>
  );
  let iconIsFinish = task.hoanThanh ? (
    <i class="fa-solid text-white rounded-full bg-green-600 h-5 w-5 text-center leading-6 fa-check"></i>
  ) : (
    <i class="fa-solid fa-triangle-exclamation text-red-500"></i>
  );
  return (
    <div className="flex items-center justify-between">
      <TitleTaskNavigate task={task} />
      <div className=" flex space-x-3 items-center justify-between w-72 ">
        <span className=" text-xs">
          {task.tongExp} XP + {task.tongCoin} COIN
        </span>
        <div className="space-x-5 w-14 flex justify-between">
          {/* {iconIsAllow} */}
          {iconIsFinish}
        </div>
      </div>
    </div>
  );
}

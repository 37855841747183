import React from "react";
const arrContentChallenge = [
  {
    title: "Tham gia trắc nghiệm hằng ngày 1/1",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio nulla sit illo mollitia voluptatibus tenetur repudiandae officiis quam",
    complete: true,
    poin: "",
    icon: <i class="fa fa-play"></i>,
  },
  {
    title: "Tham gia bài tập thảo luận nhóm 0/1",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio nulla sit illo mollitia voluptatibus tenetur repudiandae officiis quam",
    complete: true,
    poin: "10 coin - 30 xp",
    icon: <i class="fa fa-check"></i>,
  },
  {
    title: "Hoàn thành các yêu cầu project sau 0/1",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio nulla sit illo mollitia voluptatibus tenetur repudiandae officiis quam",
    complete: false,
    poin: "10 coin - 30 xp",
    icon: "3",
  },
  {
    title: "Hoàn thành bài tập nộp 0/1",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio nulla sit illo mollitia voluptatibus tenetur repudiandae officiis quam",
    complete: false,
    poin: "10 coin - 30 xp",
    icon: "4",
  },
  {
    title: "",
    content: "",
    complete: false,
    poin: "10 coin - 30 xp",
    icon: <i class="fa fa-graduation-cap"></i>,
  },
];
export default function ContentChallenge() {
  const renderDailyActives = () => {
    return arrContentChallenge.map((item, index) => {
      const isEnd = index === arrContentChallenge.length - 1;
      const title = isEnd ? item.poin : item.title;
      // kế thằng item cuối cùng
      const heightProcess =
        index === arrContentChallenge.length - 2 ? "h-16" : "h-32";
      const btnTiepTucHoc = (
        <button className="absolute top-10 right-0 px-2 py-1 text-sub-title  font-medium  bg-main rounded ">
          Bắt đầu làm hoặc tiếp tục
        </button>
      );
      return (
        <div className="border-b-1  c-card-content space-y-6 relative">
          {item.complete ? null : btnTiepTucHoc}
          {!isEnd && <div className="text-title font-medium">{item.poin}</div>}
          <div className="text-title font-medium relative">
            <p>{title}</p>
            <div className="absolute top-0 -left-3 transform -translate-x-full space-y-2">
              <div
                className={`${
                  item.complete ? "bg-main" : "bg-gray-200"
                } text-black text-sub-title  rounded-full flex items-center justify-center h-10 w-10`}
              >
                {item.icon}
              </div>
              {!isEnd && (
                <div
                  className={`${heightProcess} w-1.5 ${
                    item.complete ? "bg-main" : "bg-gray-200"
                  } transform translate-x-4`}
                ></div>
              )}
            </div>
          </div>
          <div className="text-content">{item.content}</div>
        </div>
      );
    });
  };
  return (
    <div className="c-col-62">
      <div className="h-full bg-white relative">
        <div className="absolute top-0 left-0 transform -translate-y-full c-card-content text-title font-medium inline-block border-b-0 border-2 py-2  bg-sp">
          Hoạt động hằng ngày
        </div>
        <div className="card-theme rounded-none c-card-padding">
          <div className="px-20">{renderDailyActives()}</div>
        </div>
      </div>
    </div>
  );
}

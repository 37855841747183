import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import SideNavigation from "./SideNavigation/SideNavigation";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useWindowSize from "../hook/useWindowSize";
import BreadCrumbNav from "../components/BreadCrumbNav/BreadCrumbNav";
import StackChat from "../components/StackChat/StackChat";
import HeaderTaskFree from "./HeaderTaskFree/HeaderTaskFree";
import axios from "axios";
import { BASE_URL_CODINGCAMP } from "../services/axios.service";
import localStorageServ from "../services/locaStorage.service";
import { updateThongTinMoRongAction } from "../redux/reducer/authReducer";
import HeaderBottom from "./HeaderBottom/HeaderBottom";
import FlashSale from "../components/FlashSale/FlashSale";
import ZaloBtn from "../components/ZaloOA/ZaloOA";
import FBChat from "../components/FacebookChat/FBChat";
const { Header, Content, Sider } = Layout;

export default function HomeSidebarFree(props) {
  let userInfo = localStorageServ.userInfo.get();
  const [collapsed, setCollapsed] = useState(false);
  const theme = useSelector((state) => state.theme.theme);
  let root = document.documentElement;
  root.classList.add(theme);
  const { widthWindow, heightWindow } = useWindowSize();

  const isPhone = widthWindow < 768 ? true : false;
  const extractLayoutSideBar = (collapsed) => {
    if (widthWindow > 1024 && !collapsed) {
      return 120;
    } else {
      return 40;
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    async function fectdata() {
      const values = {
        email: "freeacc@gmail.com",
        matKhau: "111111",
      };
      await axios
        .post(`${BASE_URL_CODINGCAMP}/api/nguoidung/loginv2`, values)
        .then((res) => {
          localStorageServ.userInfo.set(res.data.content);
          dispatch(updateThongTinMoRongAction());
          // window.location.href = "/";
        })
        .catch((err) => {});
    }
    if (!userInfo) {
      fectdata();
    } else {
      dispatch(updateThongTinMoRongAction());
    }
  }, []);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          background: "black",
          zIndex: 100,
          padding: isPhone ? "0 10px" : "",
        }}
        className="flex h-16  fixed top-0 left-0 w-full  items-center  justify-between space-x-5"
      >
        <HeaderTaskFree />
      </Header>
      {isPhone && (
        <div
          style={{
            // background:"#fff",
            zIndex: 100,
            // padding: isPhone ? "0 10px" : "",
          }}
          className="flex h-16  fixed bottom-0 left-0 w-full  items-center  justify-between space-x-5"
        >
          <HeaderBottom />
        </div>
      )}
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={extractLayoutSideBar(collapsed)}
          className=" fixed top-20 left-0 "
        >
          {/* <SideNavigation isCollapsed={collapsed} /> */}
        </Sider>
        <Layout
          style={{
            margin: `0px 0 0 0px`,
            paddingTop: isPhone?'10px':"64px",
            // paddingBottom: "64px",
          }}
          className="bg-content"
        >
          <Content className="  relative">
            <div className="pt-8">
              {/* <BreadCrumbNav /> */}
              <div className={`${isPhone ? "phone" : ""} page_container`}>
                <Outlet />
                
                <ZaloBtn/>
                <FBChat/>

              </div>
            </div>
          </Content>
          <FlashSale/>
        </Layout>
      </Layout>
    </Layout>
  );
}

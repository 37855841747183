import React from "react";
import { getImgDanhHieu } from "../../pages/DashBroad/OverviewInfoUser/utils";
import classNames from "classnames";
import { Tag, Tooltip } from "antd";
import bgColumn from "./columBg.png";
import { getHinhAnh } from "../../utils/GetHinhanh";
import { useSelector } from "react-redux";
import { selectThongTinMoRong } from "../../redux/reducer/authReducer";
export default function ItemRank({ item, isActive, index, owner }) {
  console.log('item: ', item);
  
  const { danhSachThanhTich } = useSelector(selectThongTinMoRong);
  
  let listVatPham = useSelector((state) => state.vatPhamSlice.vatPhamUser) || [];

  let height = (5 - index) * 15;
  let heightPercent = 100 - height + "%";

  return (    
    <div
      style={{ width: `${12 + index}%` }}
      // style={{ width: `${12}%` }}
      className=" flex items-center flex-col justify-end h-full  relative   "
    >
      <div className="relative  w-full ">
        <Tooltip
         overlayInnerStyle={{ transform: 'translateY(-20px)' }} 
          title={
            <div className={classNames("tooltipCustom",
              {"filter grayscale": !owner})}>
              <p className="font-black text-base">Giải thích:</p>
              <p className="">{item.ghiChu}</p>
              {item?.thanhTichCan.length > 0 && (
                <>
                  <p className=" font-black text-base">Thành tích cần: </p>
                  {item?.thanhTichCan?.map((tt, idx) => {
                    
                    const number = parseInt(tt?.moTa.match(/\s(\d+)\s/)[0])
                    
                    return (
                      <div className="flex items-center gap-2 pb-1"  >
                        <div style={{background:'#ee9200',padding:'2px',borderRadius:'5px'}}>
                          <div className="bg-dark w-16 h-16 " style={{borderRadius:'5px'}}>
                          <img
                          className="w-full h-full"
                          src={getHinhAnh(tt?.hinhAnh)}
                        /> 
                          </div>
                          {danhSachThanhTich.findIndex(item => item.id == tt?.id)!=-1?
                          <></>
                        :
                        <p className="text-center font-black">+{number?number:0}</p>}
                        </div>
                       
                        <div className="">
                          <p className="font-medium">{tt?.tenThanhTich}</p>
                          <p>{tt?.moTa}</p>
                        {danhSachThanhTich.findIndex(item => item.id == tt?.id)==-1?<Tag color="#494949">Not yet</Tag >:<Tag color="#498c4a">Done</Tag>}
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
              {item?.vatPhamCan && (
                <>
                  <p className=" font-black text-base">Vật phẩm cần: </p>
                  <div className="flex items-center gap-2">
                    <div style={{background:'#ee9200',padding:'2px',borderRadius:'5px'}}>
                          <div className="bg-dark w-16 h-16 " style={{borderRadius:'5px'}}>
                          <img
                            className="w-full h-full"
                            src={item?.vatPhamCan?.hinhAnh}
                          />
                          </div>
                        </div>
                    <div className="">
                      <p className="font-medium">
                        {item?.vatPhamCan?.tenVatPham.substring(0,item?.vatPhamCan?.tenVatPham.length-5)} <span className="font-black">{item?.vatPhamCan?.tenVatPham.substring(item?.vatPhamCan?.tenVatPham.length - 5)}</span>
                        
                      </p>
                      {listVatPham.findIndex(tt => item.vatPhamCan.id == tt?.id)==-1?<Tag color="#494949">Not yet</Tag >:<Tag color="#498c4a">Done</Tag>}
                    </div>
                  </div>
                </>
              )}
            </div>
          }
          placement="rightTop"
        >
          <div
            className={classNames("item relative z-10 transform -translate-y-4", {
              "borderBoxDS ": isActive,
              "filter grayscale": !owner,
            })}
            style={{ paddingBottom: index * 50,borderRadius:'15px' }}
          >
            <div className="itemBlur" style={{backgroundColor:`${owner?item?.mauSac:''}`}}></div>
            <div className={classNames("itemLine",{
              "line1":item.capDo==1,
              "line2":item.capDo==10,
              "line3":item.capDo==20,
              "line4":item.capDo==30,
              "line5":item.capDo==40,
              "line6":item.capDo==50
            })}></div>
            <img
              className={classNames("w-full rounded")}
              dff
              src={getImgDanhHieu(item.hinhDanhHieu)}
              alt=""
            />
            <p className="text-sm text-center font-medium text-white mx-auto">
              {item.tenDanhHieu}
            </p>
           
          </div>
        </Tooltip>
        <div
              style={{
                background: `linear-gradient(to bottom, ${item.mauSac} 0%,rgba(255,255,255, 0) 100%)`, opacity:0.9, 
              }}
              className={classNames("h-full w-3/5  object-cover absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-8 transform",{
                "filter grayscale": !owner
                })}
            ></div>
      </div>
    </div>



  
  );
}

import React from "react";
import servVatPham from "../../../services/vatPham.service";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getVatPhamUserAction } from "../../../redux/reducer/vatPhamSlice";

export default function VatPhamMo() {
  const { t } = useTranslation()
  let dispatch = useDispatch();

  let handleMoHopQua = () => {
    servVatPham
      .moHopQua()
      .then((res) => {
        
        dispatch(getVatPhamUserAction());
       })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <button onClick={handleMoHopQua} className="btn_theme_red">
        {t('vatPham.moVatPham')}

      </button>
    </div>
  );
}
/**
 * {
  "id": 14,
  "tenVatPham": "Nón Cybersoft",
  "chiPhi": 0,
  "hanSuDung": 0,
  "hinhAnh": "https://apimentor11.cybersoft.edu.vn/images/non_bao_hiem.png",
  "moTa": " Hướng dẫn đổi: Sau khi mua, kiểm tra vật phẩm đã có trong hòm đồ và đến gặp tư vấn để đổi quà.",
  "dieuKienGhep": "[]",
  "maNhom": 2,
  "maLoai": "XEM",
  "metaData": ""
}
 */

import React from "react";

export default function UserComment({ dataComment }) {
  return (
    <div className=" flex space-x-3">
      <div className="flex flex-col items-end">
        <div className="h-12 w-12">
          <img
            className="h-full w-full object-cover"
            src={dataComment.avatar}
            alt=""
          />
        </div>
        <div className="w-0.5 h-full bg-blue-500 mr-2"></div>
      </div>
      <div className=" w-full h-full space-y-3 pb-6">
        <p className="text-pink-violet text-lg">
          {dataComment.name} ({dataComment.role}) - {dataComment.time} phút
          trước
        </p>
        <p className="text-lg">{dataComment.content}</p>
      </div>
    </div>
  );
}

import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useLstLoTrinhDangHoc } from "../../hook/useLstLoTrinhUser";
import servCodingCamp from "../../services/codingCam.service";
import localStorageServ from "../../services/locaStorage.service";
import { checkDemoUser, checkLearning } from "../../utils/HocDemoUtils";
import List4TypeQuiz from "../List4TypeQuiz/List4TypeQuiz";
import { canClickNextQuestion, convertDataQuizz } from "../List4TypeQuiz/utils";
import Navigate_Footer_PraticesResult from "./Navigate_Footer_PraticesResult";
import "./Praticess.css";

export default function ResultTracNghiem_Start({ data, onSuccess,setNextTask, visited}) {
  let { chuyenDeId, taskId, monHocId } = useParams();
  let [currentQuestionIndex, setCurrentQuestsionIndex] = useState(0);
  let [scoreQuiz, setScoreQuiz] = useState(null);
  const [allQuestions, setListQuestion] = useState([]);
  let isDemoUser = useMemo(() => checkDemoUser(), [])
  let userInfo = localStorageServ.userInfo.get();
  const { data: dataDangHoc } = useLstLoTrinhDangHoc(userInfo?.id);
  const isLearning =checkLearning(chuyenDeId,dataDangHoc)
  useEffect(() => {
   if(dataDangHoc){
    isDemoUser || !userInfo.kichHoat || !isLearning?
    servCodingCamp
      .getDetailTaskTrial(chuyenDeId, monHocId, taskId)
      .then((res) => {
        setListQuestion(convertDataQuizz(res.data.content.noiDungNhiemVu));
      })
      .catch((err) => {
        
      })
    :
    servCodingCamp
      .getDetailTask(chuyenDeId, monHocId, taskId)
      .then((res) => {
        setListQuestion(convertDataQuizz(res.data.content.noiDungNhiemVu));
      })
      .catch((err) => {
        
      });
   }
  }, [chuyenDeId, taskId, monHocId, dataDangHoc]);
  let handleClickNextQuestion = () => {
    setCurrentQuestsionIndex(currentQuestionIndex + 1);
  };
  let handleClickPreQuestion = () => {
    if(currentQuestionIndex==0){
        setCurrentQuestsionIndex(0);
    }
    else{
        setCurrentQuestsionIndex(currentQuestionIndex - 1);
    }
  };
  let isDisableNextBtn = canClickNextQuestion(allQuestions, currentQuestionIndex);
  const datares = JSON.parse(data.baiLam).noiDungBaiNop[currentQuestionIndex]
  return (
    <div className="w-full c-card-content  ">
      <div className="w-full   ">
        <List4TypeQuiz
          setListQuestion={setListQuestion}
          currentQuestionIndex={currentQuestionIndex}
          allQuestions={allQuestions}
          visited={visited}
        />
      </div>
      {<div className="flex w-full mb-4 p-3">
            <div className="h-1/2 space-y-5 w-full">
              <div className="w-full flex items-center justify-between space-x-5">
                <p className="practice_tieuDe  text-content font-medium my-3">Câu trả lời bạn chọn: </p>
              </div>
              <div
                className="c-card-content card-theme rounded  text-content space-y-1 leading-7"
              >
                {datares.maLoaiBaiTap=="single" && datares.userAnsers.map((item) => {
                    return (
                        <button
                          className="px-4 my-2 mx-1 flex-shrink-0  text-base h-10 font-medium tracking-wide text-white  transition-colors duration-200 transform bg-blue-600 rounded-md dark:bg-gray-800 hover:bg-blue-500 dark:hover:bg-gray-700 focus:outline-none focus:bg-blue-500 dark:focus:bg-gray-700 "
                        >
                          {item.noiDung}
                        </button>
                      );
                })}
                {(datares.maLoaiBaiTap=="multiple_choice" || datares.maLoaiBaiTap=="fill_inblank"
) && datares.userAnsers.map((item) => {
                    let nd = datares.noiDung.cauTraLoi.find(ans =>ans.ma==item)
                 if(nd){
                    return (
                        <button
                          className="px-4 my-2 mx-1 flex-shrink-0  text-base h-10 font-medium tracking-wide text-white  transition-colors duration-200 transform bg-blue-600 rounded-md dark:bg-gray-800 hover:bg-blue-500 dark:hover:bg-gray-700 focus:outline-none focus:bg-blue-500 dark:focus:bg-gray-700 "
                        >
                          {nd.noiDung}
                        </button>
                      );
                 }
                })}
                {datares.maLoaiBaiTap=="fill_input" &&datares.userAnsers.map((item) => {
                    
                    return (
                        <button
                          className="px-4 my-2 mx-1 flex-shrink-0  text-base h-10 font-medium tracking-wide text-white  transition-colors duration-200 transform bg-blue-600 rounded-md dark:bg-gray-800 hover:bg-blue-500 dark:hover:bg-gray-700 focus:outline-none focus:bg-blue-500 dark:focus:bg-gray-700 "
                        >
                          {item}
                        </button>
                      );
                 
                }) }
                <span>{datares.isCorrect?<i className="text-2xl text-green-600 fa-solid fa-check"></i>:<i className="text-2xl text-red-600 fa-solid fa-xmark"></i>}</span>
                <button className="flex-grow"></button>
              </div>
            </div>
          </div>}
      <Navigate_Footer_PraticesResult
        current={currentQuestionIndex + 1}
        total={allQuestions.length}
        handleClickNextQuestion={handleClickNextQuestion}
        handleClickPreQuestion={handleClickPreQuestion}
        isDisableBtn={isDisableNextBtn}
        listQuestion={allQuestions}
        setScoreQuiz={setScoreQuiz}
        onSuccess={onSuccess}
        setNextTask={setNextTask}
        data={data}
      />
    </div>
  );
}

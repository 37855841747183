import React, { useEffect } from "react";
import ItemBanner from "./ItemBanner";
import { getThongKetChiTietChuyenDe } from "./utils";
import ItemNoiDungChuyenDe from "./ItemNoiDungChuyenDe";
import ThongKeTienDo from "./ThongKeTienDo";
import BangXeHangThanhTich from "../../../components/BangXeHangThanhTich/BangXeHangThanhTich";
import ModalWrapper from "../../../components/ModalWrapper/ModalWrapper";
import { CHUYEN_DE_INTERN } from "../utils/utils";
import "./style.scss";
import { Trans, useTranslation } from "react-i18next";
import SpinnerComponent from "../../../components/Spinner";
import useWindowSize from "../../../hook/useWindowSize";

export default function BannerTask({ dataTask }) {
  let { arrayItemNoiDungChuyenDe, phanThuong } =
    getThongKetChiTietChuyenDe(dataTask);
  let contentNoiDung = (
    <div className=" grid grid-cols-2 gap-1">
      {arrayItemNoiDungChuyenDe
        ?.filter(({ total: tongNoiDung }) => tongNoiDung > 0)
        .map((item, index) => {
          return <ItemNoiDungChuyenDe key={index} item={item} />;
        })}
    </div>
  );
  const { widthWindow, heightWindow } = useWindowSize();
  const isPhone = widthWindow < 639 ? true : false
  let contentPhanThuong = (
    <div className=" space-y-5 w-full h-full  ">
      <p className="tenChuyenDe text-center">{phanThuong?.tongCoin} COIN</p>
      <p className="tenChuyenDe text-center">{phanThuong?.tongExp} XP</p>
    </div>
  );
  const { t } = useTranslation();

  return (
    <div style={{ height: 'auto' }} className="flex justify-between  ">
      <div className={`${isPhone?'c-col-full':'c-col-60'} card-theme space-y-2    relative`}>
        <h2 className="tenChuyenDe ">{dataTask?.tenChuyenDe}</h2>
        <p className="text-center text-title">
          <Trans i18nKey="loTrinh.tienDo" />{" "}
          {dataTask?.loaiChuyenDe == CHUYEN_DE_INTERN ? (
            <Trans i18nKey="loTrinh.congVien" />
          ) : (
            <Trans i18nKey="loTrinh.khoaHoc" />
          )}
        </p>
        {/* <ProgressLine percent={40} type="blue" /> */}
        <div className={`flex ${isPhone?'space-x-2':'space-x-10'} text-center `}>
          <ItemBanner
            width={isPhone?'100%':'70%'}
            title={t("trangChu.noiDungModule")}
            content={contentNoiDung}
          />

          <ItemBanner
          width={isPhone?'100%':'30%'}
            title={t("loTrinh.phanThuong")}
            content={
              <ModalWrapper
                content={<div>{t("loTrinh.moTaPhanThuong")}</div>}
                icon={<div className="space-y-1">{contentPhanThuong}</div>}
              />
            }
          />
        </div>
        <ThongKeTienDo
          isChuyenDeIntern={dataTask?.loaiChuyenDe == CHUYEN_DE_INTERN}
        />
        {/* <div className="text-white  c-card-content gioiThieuTask"></div> */}
      </div>

      {isPhone? (
        ""
      ) : (
        <div className="c-col-38 card-theme h-full  relative">
          <BangXeHangThanhTich
            btnFilter={[
              {
                title: t("trangChu.tichCuc"),
                key: 1,
              },
              {
                title:
                  dataTask?.loaiChuyenDe == CHUYEN_DE_INTERN
                    ? t("loTrinh.thanhTichCao")
                    : t("trangChu.hocGioi"),
                key: 2,
              },
            ]}
            heightList={150}
            isGetAll={false}
          />
        </div>
      )}
    </div>
  );
}

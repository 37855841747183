import React from "react";
import { typeTaskIcon } from "../../../utils/taskUtils";
import { useTranslation } from "react-i18next";

export default function ItemBanner({ thongKeChuyenDe }) {
  const { t } = useTranslation()

  return (
    <div className="item_banner  p-1 py-3 w-full">
      <h4 className="uppercase text-content text-center ">{t('trangChu.noiDungModule')}</h4>
      <div class="grid grid-cols-3 xl:grid-cols-2 xl:gap-1 gap-2 ">
        {thongKeChuyenDe
          .filter(({ tongSoLuong }) => tongSoLuong > 0)
          .map(({ tongSoLuong, tieuDe, maLoai }, index) => {
            if (!typeTaskIcon[maLoai]?.icon) {
              console.log("missing", maLoai);
            }
            return (
              <div key={index} class="flex justify-between items-center">
                <div class="flex items-center">
                  <div class="icon_item_theme mr-2 ">
                    <div id="tooltip_modal " class="w-full">
                      {typeTaskIcon[maLoai]?.icon}
                    </div>
                    <span class="bg_icon_item_theme"></span>
                  </div>
                  <p class="text-content text-sm ">
                    <div>
                      {tongSoLuong} {tieuDe}
                    </div>
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Tabs, Tag } from "antd";
import "./problemAndSolution.scss";
import ContentProblemText from "./Content/ContentProblemText";
import { isJson } from "../../utils";
import { updateThongTinMoRongAction } from "../../../../redux/reducer/authReducer";
import { useDispatch } from "react-redux";
import servCodingCamp from "../../../../services/codingCam.service";
import { parentPage } from "../../../../routes/utitlity";
import ContentHintRunCode, {
  HINT,
  RESULT,
  SOLUTION,
  SOLUTION_VIDEO,
} from "./Content/ContentHintRunCode";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
const { TabPane } = Tabs;

function callback(key) {
  // console.log(key);
}

export default function ProblemAndSolution({
  question,
  setLanguages,
  languages,
  idCauHoi,
  detailTask,
}) {
  console.log("idCauHoi: ", idCauHoi);
  console.log("question: ", question);

  const [defaultHint, setDefaultHint] = useState("");
  const [defaultSolution, setDefaultSolution] = useState("");
  let { chuyenDeId, taskId, monHocId } = useParams();

  const [listStatus, setListStatus] = useState([]);
  let dispatch = useDispatch();
  //hint
  const currentUrl = window.location.href;
  const isPracticePage = currentUrl.includes(parentPage.pratice);
  const isTaskPage = currentUrl.includes(parentPage.task);
  const listGuide = [
    // loaiHint: 0: mở hint, 1: mở solution, 2: mở solution video
    { label: "Gợi ý", value: defaultHint, type: HINT, typeId: 0, coin: 2 },
    {
      label: "Hướng dẫn",
      value: "solution",
      type: SOLUTION_VIDEO,
      typeId: 1,
      coin: 4,
    },
  ];
  let servCheckHint;
  let servMoHint;
  if (isPracticePage) {
    servCheckHint = servCodingCamp.checkTrangThaiHintPractice;
    servMoHint = servCodingCamp.moHintPratice;
  }
  if (isTaskPage) {
    servCheckHint = servCodingCamp.checkTrangThaiHint;
    servMoHint = servCodingCamp.moHint;
  }
  let fetchListStatus = () => {
    let payload = {
      requireId: idCauHoi,
      loaiHint: 0,
      maLoai: "RUNCODE",
      monHocId,
      taskId,
      chuyenDeId,
    };
    servCheckHint(payload)
      .then((res) => {
        setListStatus(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleBuyGuide = (payload) => {
    servMoHint(payload)
      .then((res) => {
        if (res.data.content) {
          // mua thành công
          fetchListStatus();
          dispatch(updateThongTinMoRongAction());
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // loaiHint: 0: mở hint, 1: mở solution, 2: mở solution video
  };
  useEffect(() => {
    const renderDefaultCode = () => {
      let defaultCode = question?.init_code.find(
        (item) => item._language == languages
      );
      if (defaultCode) {
        const formattedValue = defaultCode.hint;
        const formattedSolution = defaultCode.video_solution;

        isJson(formattedValue);
        isJson(formattedSolution);
        if (isJson(formattedValue)) {
          setDefaultHint(JSON.parse(formattedValue));
          // setCode(JSON.parse(formattedValue));
        }
        setDefaultHint(formattedValue);
        if (isJson(formattedSolution)) {
          setDefaultSolution(JSON.parse(formattedSolution));
          // setCode(JSON.parse(formattedValue));
        }
        setDefaultSolution(formattedSolution);
        // setCode(formattedValue);
        if (idCauHoi.baiLamTracNghiem) {
          setDefaultHint(JSON.parse(idCauHoi.baiLamTracNghiem));
          // setCode(JSON.parse(idCauHoi.baiLamTracNghiem));
        }
      }
    };
    renderDefaultCode();
  }, [languages, question]);
  useEffect(() => {
    fetchListStatus();
  }, [question]);
  console.log("defaultCode", defaultHint);
  return (
    <>
      <Tabs
        defaultActiveKey="1"
        className="bg-theme-runcode-problem text-white rounded"
        onChange={callback}
        tabBarGutter={5}
      >
        <TabPane tab={<span className="text-white ">Bài toán</span>} key="1">
          <ContentProblemText question={question} />
        </TabPane>
        {/* {defaultHint?.content !="" && <TabPane
          tab={<span className="text-white ">Hint</span>}
          key="2"
        >
          <div className="p-2">
            <Tag color="green" style={{cursor:'pointer'}} onClick={() => { 
              window.open(defaultHint?.content)
             }}>Click to open</Tag>
          </div>
          
        </TabPane>} */}
        {listGuide.map(({ label, value, type, typeId, coin }, index) => {
          let dataBuyHint = {
            chuyenDeId,
            monHocId,
            taskId,
            requireId: idCauHoi,
            maLoai: "RUNCODE",
            loaiHint: typeId,
          };
          if (type == HINT && defaultHint?.content != "") {
            return (
              <TabPane
                tab={<span className="text-white ">{label}</span>}
                key={2}
              >
                 <div className="px-4 py-2 h-full overflow-y-scroll">
                <ContentHintRunCode
                  handleBuyGuide={() => {
                    handleBuyGuide(dataBuyHint);
                  }}
                  dataBuyHint={dataBuyHint}
                  listStatus={listStatus}
                  type={type}
                  valueGuide={value}
                  coin={coin}
                  label={label}
                />
                </div>
              </TabPane>
            );
          }
          if (type == SOLUTION_VIDEO && defaultSolution != "") {
            return (
              <TabPane
                tab={<span className="text-white ">{label}</span>}
                key={2}
              >
                <div className="px-4 py-2 h-full overflow-y-scroll">
                <ContentHintRunCode
                  handleBuyGuide={() => {
                    handleBuyGuide(dataBuyHint);
                  }}
                  dataBuyHint={dataBuyHint}
                  listStatus={listStatus}
                  type={type}
                  valueGuide={value}
                  coin={coin}
                  label={label}
                />
                </div>
              </TabPane>
            );
          }
        })}
        {/*<TabPane
          disabled
          tab={
            <span className="text-white ">
              Hint <i className="text-red-500 fa-solid fa-lock ml-2"></i>
            </span>
          }
          key="3"
        >
          <div className="p-2">Tính năng chưa update</div>
        </TabPane>
        <TabPane
          disabled
          tab={
            <span className="text-white ">
              Solution <i className="text-red-500 fa-solid fa-lock ml-2"></i>
            </span>
          }
          key="4"
        >
          <div className="p-2">Tính năng chưa update</div>
        </TabPane> */}
      </Tabs>
      ,
    </>
  );
}

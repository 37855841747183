import React, { useMemo, useState } from "react";
import TitleTaskNavigate from "../../../components/TitleTaskNavigate/TitleTaskNavigate";
import "./style.scss";
import { checkDemoUser } from "../../../utils/HocDemoUtils";
import { selectThongTinMoRong } from "../../../redux/reducer/authReducer";
import { useSelector } from "react-redux";
import useWindowSize from "../../../hook/useWindowSize";
import { emailFree } from "../../../utils/urlDomain";
import { typeTask } from "../../../routes/utitlity";
export const isIconColappse = (type) => { 
  switch(type){
    case typeTask.VIDEO:
      return true
    case typeTask.QUIZ:
      return true
    case typeTask.RUNCODE:
      return true
    default :
    return false
  }
 }
export default function ItemTask({ task, showModal,icon }) {
  
  const { widthWindow, heightWindow } = useWindowSize();
  let iconIsAllow = task.truyCap ? (
    <i class="fa fa-unlock-alt green-icon"></i>
  ) : (
    <i class="fa fa-lock red-icon"></i>
  );
  let iconIsDemo = task.xemDemo ? (
    <i class="fa fa-unlock-alt green-icon"></i>
  ) : (
    <i class="fa fa-lock red-icon"></i>
  );
  let iconIsFinish = task.hoanThanh ? (
    <i class="fa-solid text-black rounded-full green-icon-bg h-5 w-5 text-center leading-6 fa-check"></i>
  ) : (
    <i class="fa-solid fa-triangle-exclamation red-icon"></i>
  );
  
  let isDemoUser = useMemo(() => checkDemoUser(), [])
  const { userInfo } = useSelector(selectThongTinMoRong);
  return (
    <div className="flex items-center justify-between item-task">
      <TitleTaskNavigate task={task} showModal={showModal} />
      {(isDemoUser  || !userInfo.kichHoat ||userInfo.emal==emailFree )&& isIconColappse(task.maLoaiNhiemVu) &&task.xemDemo && icon}
      {widthWindow<639?
      ""
    :
    <div className={` flex space-x-3 items-center lg justify-between ${widthWindow<992?'w-80':'w-72'} `}>
    <span>{}</span>
    <span className="tenChuyenDe text-title">
      {task.tongExp} XP + {task.tongCoin} COIN
    </span>
    <div className="space-x-5">
      {/* {iconIsAllow} */}
      {(isDemoUser  || !userInfo.kichHoat ||userInfo.emal==emailFree ) && iconIsDemo}
      {iconIsFinish}
    </div>
  </div>
    }
    </div>
  );
}
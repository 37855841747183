import React, { useState } from 'react'
import './style.css'
import { history } from '../../App'
const PackageBottomFix = () => {
    const [active, setActive] = useState(false)
    return (
        <div className={`package ${active ? "show" : ""}`} >
            <div className="arrowBtn" onClick={() => { setActive(!active) }}>
                {active ? <i className="fa-solid fa-angle-down"></i> : <i className="fa-solid fa-angle-up"></i>}
            </div>
            <div className="package_content">
                <button className='btn_register' >Free</button>
                <button className='btn_register' onClick={() => { 
                                console.log('medium')
                                history.push('confirm-payment?g=Medium')
                             }}>Medium</button>
                <button className='btn_register' onClick={() => { 
                                console.log('medium')
                                history.push('confirm-payment?g=VIP')
                             }}>VIP</button>
            </div>
            <div className="row">
                <div className="col-md-4 ftco-animate fadeInUp ftco-animated h-full">
                    <div className="block-7">
                        <div className="img" style={{ backgroundColor: '#FFB6C1' }} >
                        <span className="excerpt d-block">Free</span>
                            <span className="price"><sup>$</sup> <span className="number">0</span> <sub>/year</sub></span>
                        </div>
                        <div className="bottom_content text-center p-4">
                            <ul className="pricing-text mb-5">
                                <li><span className="fa fa-check mr-2" />Học free các nội dung có mark free</li>
                                <li><span className="fa fa-check mr-2" />Hỗ trợ BOT chat</li>
                                <li><span className="fa fa-check mr-2" />Tham gia cộng đồng Discord</li>
                            </ul>
                            <a href="#" className="disable btn btn-primary d-block px-2 py-3">Buy</a>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 ftco-animate fadeInUp ftco-animated h-full">
                    <div className="block-7">
                        <div className="img" style={{ backgroundColor: '#87CEFA' }} >
                        <span className="excerpt d-block">Business</span>
                            <span className="price"><sup>$</sup> <span className="number">250</span> <sub>/year</sub></span>
                        </div>
                        <div className="bottom_content text-center p-4">
                            <ul className="pricing-text mb-5">
                                <li><span className="fa fa-check mr-2" />Học free các nội dung có mark free</li>
                                <li><span className="fa fa-check mr-2" />Hỗ trợ BOT chat</li>
                                <li><span className="fa fa-check mr-2" />Tham gia cộng đồng Discord</li>
                                <li><span className="fa fa-check mr-2" />Chọn 1 khóa combo 3 môn</li>
                                <li><span className="fa fa-check mr-2" />Chọn 1 khóa combo 3 môn</li>
                            </ul>
                            <button className="btn btn-primary d-block px-2 py-3" onClick={() => { 
                                console.log('medium')
                                history.push('confirm-payment?g=Medium')
                             }}>Buy</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 ftco-animate fadeInUp ftco-animated h-full">
                    <div className="block-7">
                        <div className="img" style={{ backgroundColor: '#FF7F50' }} >
                        <span className="excerpt d-block">Ultimate</span>
                            <span className="price"><sup>$</sup> <span className="number">500</span> <sub>/year</sub></span>
                        </div>
                        <div className="bottom_content text-center p-4">
                            <ul className="pricing-text mb-5">
                            <li><span className="fa fa-check mr-2" />Học free các nội dung có mark free</li>
                                <li><span className="fa fa-check mr-2" />Hỗ trợ BOT chat</li>
                                <li><span className="fa fa-check mr-2" />Tham gia cộng đồng Discord</li>
                                <li><span className="fa fa-check mr-2" />Chọn 1 khóa combo 3 môn</li>
                                <li><span className="fa fa-check mr-2" />Được học tất cả các khóa</li>
                                <li><span className="fa fa-check mr-2" />Được tham gia Workshop</li>
                                <li><span className="fa fa-check mr-2" />Được mentor hỗ trợ chấm bài</li>
                                <li><span className="fa fa-check mr-2" />Được trung tâm hỗ trợ việc làm</li>
                            </ul>
                            <button className="btn btn-primary d-block px-2 py-3" onClick={() => { 
                                history.push('confirm-payment?g=VIP')
                             }}>Buy</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default PackageBottomFix
import React, { useEffect, useState } from "react";
import servCodingCamp from "../../services/codingCam.service";
import ModalShopItem from "./ModalShopItem";

export default function ShopList({ activeFilter }) {
  const [listVatPham, setListVatPham] = useState([]);
  useEffect(() => {
    servCodingCamp
      .getDanhSachVatPham()
      .then((res) => {
        setListVatPham(res.data.content);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  let filteredVatPham = listVatPham.filter(({ maNhom }) =>
    activeFilter == 0 ? true : maNhom == activeFilter
  );
  return (
    <div
      className=" c-card-content w-full rounded"
      style={{
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 0.08) -12.05%, rgba(0, 0, 0, 0.08) 111.83%)",
      }}
    >
      {/* <div className="grid grid-cols-3 gap-10 w-full"> */}
      <div className="grid gap-8 grid-cols-3 md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-2">
        {filteredVatPham.map((item, index) => {
          return <ModalShopItem item={item} key={index} />;
        })}
        {!filteredVatPham.length && <h2 className="text-title">Không có vật phẩm</h2>}
      </div>
    </div>
  );
}
// {background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) -12.05%, rgba(0, 0, 0, 0.08) 111.83%);

//     "id": 1,
//     "tenVatPham": "Voucher 3%",
//     "chiPhi": 30,
//     "hanSuDung": 0,
//     "hinhAnh": "https://kimconcept.com/wp-content/uploads/2022/07/anh-1-Gift-voucher-la-gi.jpeg",
//     "moTa": "Khi ghi danh lộ trình mới bạn được giảm giá 3% khi sở hữu voucher này",
//     "dieuKienGhep": [],
//     "maNhom": 1,
//     "danhSachVatPhamGhep": []
// }

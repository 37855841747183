import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectThongTinMoRong } from "../../../redux/reducer/authReducer";
import { getHinhAnh } from "../../../utils/GetHinhanh";
import servCodingCamp from "../../../services/codingCam.service";
import {
  selectListThanhTich,
  setListThanhTich,
  setPersonalState,
} from "../tuongNhaSlice";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { Popover, Tooltip } from "antd";
import { getImgDanhHieu } from "../../DashBroad/OverviewInfoUser/utils";
import './style.scss'
export default function ListThanhTich() {
  let { userId } = useParams();
  let { profile } = useSelector((state) => state.tuongNhaSlice.personal);
  const listThanhTich = useSelector(selectListThanhTich);
  let dispatch = useDispatch();
  const [lst, setlst] = useState([]);
  // useEffect(() => {
  //   servCodingCamp
  //     .getThongTinTuongNha(userId, 1)
  //     .then((res) => {
  //       dispatch(setListThanhTich(res.data.content.danhSachThanhTich));
  //       dispatch(setPersonalState(res.data.content));
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [userId]);
  const conv = () => {
    const dsnhom = [];
    listThanhTich.forEach((thanhTich) => {
      if (!dsnhom[thanhTich.maNhom]) {
        dsnhom[thanhTich.maNhom] = []; // Nếu chưa tồn tại mảng con, tạo mới
      }
      dsnhom[thanhTich.maNhom].push(thanhTich); // Thêm thành tích vào mảng con tương ứng
    });
    setlst(dsnhom);
    console.log(dsnhom);
  };

  const { danhSachThanhTich } = useSelector(selectThongTinMoRong);
  const renderThanhTichNhom = () => {
    return lst.map((item, idx) => {
      let itemcuoi = null;
      for (let i = item.length - 1; i >= 0; i--) {
        const thanhTich = item[i];
        if (thanhTich?.soHuu === true) {
          itemcuoi = { ...thanhTich };
          break; // Thoát khỏi vòng lặp khi tìm thấy phần tử có soHuu = true
        }
      }
      // console.log(itemcuoi);
      return (
        <Popover className="popThanhTich"
          content={
            <div className="bg-black text-dark p-2 rounded grid grid-cols-3 gap-5">
              {item.map((tt, i) => {
                return (
                  <div
                    className={classNames(
                      "space-y-2 text-sub-title card-theme  rounded-lg flex  items-center p-2 space-x-3",
                      { "filter grayscale opacity-50": !tt?.soHuu }
                    )}
                  >
                    <img
                      src={getHinhAnh(tt?.hinhAnh)}
                      className="w-16 flex-shrink-0"
                      alt=""
                    />
                    <div className="text-center flex-grow">
                      <span className="text-center textm-title">
                        {tt?.tenThanhTich}
                      </span>
                      <p className="text-content">{tt?.moTa}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          }
        >
          <div
            className={classNames(
              "space-y-2 text-sub-title card-theme  rounded-lg flex  items-center p-2 space-x-3",
              { "filter grayscale opacity-50": !itemcuoi?.soHuu }
            )}
          >
            <img
              src={getHinhAnh(itemcuoi ? itemcuoi?.hinhAnh : item[0]?.hinhAnh)}
              className="w-16 flex-shrink-0"
              alt=""
            />
            <div className="text-center flex-grow">
              <span className="text-center textm-title">
                {itemcuoi ? itemcuoi?.tenThanhTich : item[0]?.tenThanhTich}
              </span>
              <p className="text-content">
                {itemcuoi ? itemcuoi?.moTa : item[0]?.moTa}
              </p>
            </div>
          </div>
        </Popover>
      );
    });
  };
  const renderThanhTich = () => {
    if (danhSachThanhTich.length == 0) {
      return <p className="text-content">Bạn chưa có thành tích </p>;
    }
    return listThanhTich?.map(({ hinhAnh, tenThanhTich, moTa, soHuu }) => {
      return (
        <div
          className={classNames(
            "space-y-2 text-sub-title card-theme  rounded-lg flex  items-center p-2 space-x-3",
            { "filter grayscale opacity-50": !soHuu }
          )}
        >
          <img
            src={getHinhAnh(hinhAnh)}
            className="w-16 flex-shrink-0"
            alt=""
          />
          <div className="text-center flex-grow">
            <span className="text-center textm-title">{tenThanhTich}</span>
            <p className="text-content">{moTa}</p>
          </div>
        </div>
      );
    });
  };
  useEffect(() => {
    conv();
  }, [listThanhTich]);
  return <div>
  {/* <div className="achievement_top">
      <div class="grid grid-cols-2 md:grid-cols-1 gap-4">
        
        <div class="md:col-span-1 lg:col-span-3 flex justify-center items-center">
        <img  src={`${getImgDanhHieu(profile.danhHieu?.hinhDanhHieu)}`} className="imgRank h-1/2"alt=""/>
        </div>
        <div class="md:col-span-3 lg:col-span-5">
        
        </div>
      </div>
    </div> */}
  <div className="grid grid-cols-3 gap-5">
    

   {renderThanhTichNhom()}
 
  </div>
  </div>;
}

import { http } from "../utils/config";


const GET_PATH = "/groupfortask";

const getListGroupForTask = () => {
    return http.get(GET_PATH);
}

const addGroupForTask = (data) => {
    return http.post(GET_PATH, data);
}

const getGroupForTaskById = (id) => {
    return http.get(GET_PATH + "/" + id);
}

const editGroupForTask = (id, data) => {
    return http.put(GET_PATH + "/" + id, data);
}

const delGroupForTask = (id) => {
    return http.delete(GET_PATH + "/" + id);
}

const getBaiHocById = (id) => {
    return http.get("/api/baihoc/" + id);
}

const getThongTinNguoiDung = (id) => {
    return http.get("/api/nguoidung/" + id);
}

const GetGroupForTaskByMentorId = (mentorId) => {
    return http.get(`${GET_PATH}/bymentor?mentorId=${mentorId}`)
}
const getDsGroupForTaskHocVienService = () => {
    return http.get(`${GET_PATH}/ds_grouptask_hocvien`)
}

export const groupForTaskService = {
    getListGroupForTask,
    addGroupForTask,
    getGroupForTaskById,
    editGroupForTask,
    delGroupForTask,
    getBaiHocById,
    getThongTinNguoiDung,
    GetGroupForTaskByMentorId,
    getDsGroupForTaskHocVienService
}

import { createSlice } from '@reduxjs/toolkit'
import { danhGiaService } from '../../services/danhGiaIssue';
import { message } from 'antd';

const initialState = {
    dsDanhGia:[]

}

const danhGiaReducer = createSlice({
  name: 'danhGiaReducer',
  initialState,
  reducers: {
    setDSdanhGia:(state,action)=>{
        state.dsDanhGia = action.payload
    }
  }
});

export const {setDSdanhGia} = danhGiaReducer.actions

export default danhGiaReducer.reducer


export const postDanhGia = (obj) => {
    return async (dispatch) => {
      try{
        await danhGiaService.themDanhgia(obj);
        message.success("Đã gửi đánh giá!")
      }catch(err){

      }
    };
};
import React, { useEffect, useMemo, useState } from "react";
import InformationProject from "../../components/ListRequireNopBai/InformationProject";
import ListRequireNopBai from "../../components/ListRequireNopBai/ListRequireNopBai";
import ContentTabCol from "../../components/ContentTabCol/ContentTabCol";
import { createPayloadLayThongTinBaiTap, loaiNhiemVu } from "../../utils/taskUtils";
import servCodingCamp from "../../services/codingCam.service";
import { useParams } from "react-router-dom";
import ModalClickZoomImg from "../../components/ModalClickZoomImg/ModalClickZoomImg";
import { useTranslation } from "react-i18next";
import { checkDemoUser, checkLearning } from "../../utils/HocDemoUtils";
import { typeTrangThaiNop } from "../../components/StepProjectInstruction/util";

import * as signalR from "@microsoft/signalr";
import { selectThongTinMoRong } from "../../redux/reducer/authReducer";
import { useSelector } from "react-redux";
import { BASE_URL_CODINGCAMP } from "../../services/axios.service";
import { useLstLoTrinhDangHoc, useLstLoTrinhUser } from "../../hook/useLstLoTrinhUser";
import { baiHocService } from "../../services/baiHocService";
import localStorageServ from "../../services/locaStorage.service";

export default function DetailExercise() {
  const { t } = useTranslation()
  let isDemoUser = useMemo(() => checkDemoUser(), [])
  let userInfo = localStorageServ.userInfo.get();
  const { data, isLoading, error } = useLstLoTrinhUser(userInfo);
  const { data: dataDangHoc } = useLstLoTrinhDangHoc(userInfo?.id);
  const [lstBaiHoc,setLstBaiHoc] = useState([])
  let { chuyenDeId, taskId, monHocId } = useParams();
  const [detailPage, setDetailPage] = useState({});
  const [trangThaiTongBaiTap, setTrangThaiTongBaiTap] = useState({
    noiDung: [],
  });

  const isLearning =checkLearning(chuyenDeId,dataDangHoc)
  const maLoaiNhiemVu = detailPage.maLoaiNhiemVu;
  const [listTabPane, setListTabPane] = useState([]);
  let listRequire = [];
  if (detailPage.noiDungNhiemVu?.noiDungBaiTap?.noiDung) {
    listRequire = JSON.parse(detailPage.noiDungNhiemVu.noiDungBaiTap.noiDung);
  }

  let getListPanel = (trangThaiTongBaiTap) => {
    let listTabPane = listRequire.map((required, index) => {
      // kiểm tra bài đã nộp hay chưa

      // -1 => false => chưa nộp

      return {
        label: required.requireId,
        key: required.requireId,
        children: (
          <ListRequireNopBai
            countRequire = {listRequire.length}
            monHocId={monHocId}
            taskId={taskId}
            chuyenDeId={chuyenDeId}
            trangThaiTongBaiTap={trangThaiTongBaiTap}
            required={required}
            orderNumber={index}
            getThongTinBaiTapNop={getThongTinBaiTapNop}
            maLoaiNhiemVu={maLoaiNhiemVu}
            tongCoin={detailPage.tongCoin}
            tongExp={detailPage.tongExp}

            noiDungNhiemVu={detailPage.noiDungNhiemVu}
          />
        ),
      };
    });
    setListTabPane(listTabPane);
  };
  let getThongTinBaiTapNop = () => {
    let payload = createPayloadLayThongTinBaiTap(taskId, chuyenDeId, monHocId);
    servCodingCamp
      .getLayThongTinBaiTapNop(payload)
      .then((res) => {
        setTrangThaiTongBaiTap(res.data.content);
        getListPanel(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getDataTask=() => { 
   if(dataDangHoc){
    isDemoUser || !userInfo.kichHoat || !isLearning?
    servCodingCamp
      .getDetailTaskTrial(chuyenDeId, monHocId, taskId)
      .then((res) => {
        console.log(res);

          setDetailPage(res.data.content);
        })
        .catch((err) => {
          console.log(err);
        })
      :
      servCodingCamp
        .getDetailTask(chuyenDeId, monHocId, taskId)
        .then((res) => {

          
          setDetailPage(res.data.content);
        })
        .catch((err) => {
          console.log(err);
        });
   }
   }
  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${BASE_URL_CODINGCAMP}/chat`)
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection
      .start()
      .then(() => console.log("SignalR Connected"))
      .catch((err) => console.error("SignalR Connection Error: ", err));

    connection.on("updateBH", message => {
      // getDataTask();
  });
    // Cleanup function
    return () => {
      connection.off("updateBH"); 
      connection.stop();
    };
  }, []);
  useEffect(() => {
      getDataTask()
  }, [chuyenDeId, taskId, monHocId,dataDangHoc]);
  useEffect(() => {
    getThongTinBaiTapNop();
  }, [detailPage]);
  return (
    <div id="containerNoiDungBaiTap" className="flex w-full flex-wrap c-col-full">
      <ModalClickZoomImg />
      <div className="c-col-full">
        <p className="text-title font-medium my-2">
          <i className="fas fa-graduation-cap mr-2"></i>
          <span>
            {t('chiTietNhiemVu.thongTinDuAn')}

          </span>
          {/* {detailPage.tenBaiHoc} */}
        </p>
        <InformationProject
          trangThaiTongBaiTap={trangThaiTongBaiTap}
          maNhiemVu={maLoaiNhiemVu}
          detail={detailPage}
        />
      </div>

      {
        trangThaiTongBaiTap.trangThai != typeTrangThaiNop.CHUA_LAM &&
        <div className="tabCol w-full">
          <ContentTabCol data={listTabPane} />
        </div>
      }
     
    </div>
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import Collapse from "./Collapse";

export default function WorkShop() {
  const [listWorkShop, setListWorkShop] = useState([]);
  useEffect(() => {
    axios
      .get(`https://apicrm.cybersoft.edu.vn/api/khoahoc/danh-sach-khoa-hoc-free`)
      .then((res) => {

        setListWorkShop(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
    // /`´
  }, []);
  return (
    <div className="container space-y-5 mt-5">
      {listWorkShop.map((item, index) => {
        return <Collapse data={item} key={index} />;
      })}

      <a href="https://www.youtube.com/watch?v=lMUaLfzOb5I&list=PLwO_PVO47V1ZlPs3CLfSEkII8JdIbU8fn&index=1" target="_blank" >
        <div className="mt-5 c-col-full card-theme p-0 relative">
          <button

            aria-controls="example-panel"

            className="w-full space-x-2 c-card-content flex items-center justify-between px6 "
          >
            <p className="font-bold text-title text-left">
              Agile/Scrum Skill dành cho dev pass phỏng vấn
            </p>

          </button>
        </div>
      </a>
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { packageService } from "../../services/packageService";

const initialState = {
  arrPackageByCourse: [],
  arrPackage: [],
};

const packageReducer = createSlice({
  name: "packageReducer",
  initialState,
  reducers: {
    getArrPackageCourse: (state, action) => {
      state.arrPackageByCourse = action.payload;
    },
    getArrPackage: (state, action) => {
      state.arrPackage = action.payload;
    },
  },
});

export const { getArrPackageCourse,getArrPackage } = packageReducer.actions;

export default packageReducer.reducer;

export const getArrPackageCourseApi = (id) => {
  return async (dispatch) => {
    try{
      const res = await packageService.getPackageByIdCourse(id);
    const action = getArrPackageCourse(res.data.content);
    dispatch(action);
    }catch(er){
      console.log(er)
    }
  };
};
export const getArrPackageApi = () => {
  return async (dispatch) => {
   try{
    const res = await packageService.getPackageService();
    const action = getArrPackage(res.data.content);
    dispatch(action);
   }catch(er){
    console.log(er)
  }
  };
};

import React from "react";
import { useSelector } from "react-redux";
import ThongTinChiTietTV from "./ThongTinChiTietTV";
import ThongTinChinhTV from "./ThongTinChinhTV";
import HoanThanhTV from "./HoanThanhTV";
import StepTuVan from "./StepTuVan";
export default function ContainerTuVanDesktop() {
  const { currentStep } = useSelector((state) => state.signUp);

  const renderConTentStep = () => {
    switch (currentStep) {
      case 0:
        return <ThongTinChinhTV />;
      case 1:
        return <ThongTinChiTietTV />;
      case 2:
        return <HoanThanhTV />;
      default:
        break;
    }
  };
  return (
    <div className="w-full h-full  p-3 lg:p-5 space-y-10  flex-grow card-theme  border-none ">
      <StepTuVan />
      <div className="lg:w-full  w-2/3 mx-auto">{renderConTentStep()}</div>
    </div>
  );
}

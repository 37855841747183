import localStorageServ from "../services/locaStorage.service";
export let dataUserDemo = {
    "id": "de4e647b-2273-4947-b980-4b52e09f0191",
    "email": "pnga62782@gmail.com",
    "hoTen": "Phuong Ngatst",
    "dienThoai": "0944057056",
    "avatar": "https://graph.facebook.com//picture?type=large",
    "thongTinMoRong": "{\"NamSinh\":\"2002\",\"SoCmnd\":\"123123123\",\"HinhCmnd\":[],\"CongViecHienTai\":\"hoc sinh\",\"NoiCongTacHienTai\":\"ho chi minh\",\"NguonGioiThieu\":\"\",\"FacebookUrl\":\"\",\"LuongMongMuon\":0,\"DongYHoTroTimViec\":true,\"GioiThieu\":\"string\",\"TruongHoc\":[],\"KinhNghiem\":[],\"ThanhTich\":[],\"capDanhHieu\":1,\"tagSkill\":[],\"HoTenPH\":\"string\",\"EmailPH\":\"string\",\"SoDTPH\":\"string\"}",
    "kinhNghiem": 0,
    "capDo": 1,
    "tichCuc": 0,
    "coin": 0,
    "danhHieu": {
      "capDo": 1,
      "tenDanhHieu": "Học viên lập trình",
      "tenDanhHieuEN": "Newbie",
      "dieuKien": {
        "vatPhamId": 0,
        "thanhTichId": [
          0
        ],
        "thamGiaEvent": false
      },
      "hinhDanhHieu": "hh_1.png",
      "mauSac": "#C2C2C2",
      "ghiChu": "Đây là cấp độ đầu tiên, thường dành cho những người mới bắt đầu học lập trình. Họ có kiến thức cơ bản và ít hoặc không có kinh nghiệm thực tế."
    },
    "kinhNghiemToiDa": 400,
    "tichCucToiDa": 1000,
    "diaChiIp": "",
    "nuocNgoai": false,
    "capTangDanhHieu": 0,
    "ngayDangNhap": "2024-04-03T13:29:39.5342948+07:00"
    
}
export const checkDemoUser = () => {
  // console.log('yess');
  if (localStorageServ.userDemo.get()) {
    return true;
  }
  return false;
};
export const checkLearning = (id, arr) => arr?.find(item => item.chuyenDeId == id)


export const removeRealUser = () => {
  let userInfo = localStorageServ.userInfo;
  if (userInfo) {
    userInfo.remove();
  }

  localStorageServ.userDemo.set(dataUserDemo);
  localStorageServ.userInfo.set(dataUserDemo);
};
export const removeDemoUser = () => {
  let dataUserDemo = localStorageServ.userDemo;
  let codeDemo = localStorageServ.codeDemo;
  let nuocngoaiDemo = localStorageServ.nuocngoaiDemo;
  let timeDemo = localStorageServ.timeDemo;
  // let timeDemo = localStorageServ.timeDemo;
  if (dataUserDemo) {
    dataUserDemo.remove();
  }
  codeDemo && codeDemo.remove();
  timeDemo && timeDemo.remove();
  nuocngoaiDemo && nuocngoaiDemo.remove();
};

export const initialDemoTaskDeadline = () => {
  return [
    {
      tenKhoaHoc: "Khóa 1: Tư duy lập trình & nền tảng",
      tenBaiTap: "Bài tập chương 2",
      ngayHetHan: `10/16/2021 1:13:47 AM`,
      hetHan: true,
    },
    {
      tenKhoaHoc: "Khóa 2: Lập trình xử lý Mảng căn bản chuyên sâu",
      tenBaiTap: "Bài tập chương 1 (mảng)",
      ngayHetHan: "10/20/2021 6:27:10 PM",
      hetHan: true,
    },
  ];
};

import React, { useState } from "react";
import FormDieuKhoan from "../SignUpDesktop/FormDieuKhoan";
import ModalDieuKhoan from "../SignUpDesktop/ModalDieuKhoan";
import { useMediaQuery } from "react-responsive";
import FormDieuKhoanPhone from "../SignUpDesktop/FormDieuKhoanPhone";
import FormDieuKhoanUser from "../SignUpDesktop/FormDieuKhoanUser";

export default function ThongTinChinhFree() {
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 640 });
    return isDesktop ? children : null;
  };
  const Phone = ({ children }) => {
    const isTablet = useMediaQuery({maxWidth: 639 });
  
    return isTablet ? children : null;
  };
  const [isShowDieuKhoan, setIsShowDieuKhoan] = useState(false);
  const handleCloseModal = () => {
    setIsShowDieuKhoan(false);
  };
  const handleOpenModal = () => {
    setIsShowDieuKhoan(true);
  };

  return (
    <div className="w-full h-full p-3 flex flex-col items-center space-y-5 text-white">
      <p className="uppercase  text-lg lg:text-xl ">ĐĂNG KÝ THÔNG TIN CHÍNH</p>
      {/* <Desktop>
      <FormDieuKhoanUser handleOpenModal={handleOpenModal} />
      </Desktop>
      <Phone> */}
      <FormDieuKhoanPhone handleOpenModal={handleOpenModal} />
      {/* </Phone> */}
      
      <ModalDieuKhoan isShowDieuKhoan={isShowDieuKhoan} handleCloseModal={handleCloseModal} />
    </div>
  );
}
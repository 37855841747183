import React from "react";
import "./sideNavigation.css";
import classNames from "classnames";

import { NavLink, useParams } from "react-router-dom";
import useWindowSize from "../../hook/useWindowSize";
import { checkActiveNavlink } from "../../routes/utitlity";
export default function ItemSidebar({ item, isCollapsed }) {
  let { chuyenDeId } = useParams();
  const { widthWindow, heightWindow } = useWindowSize();
  let isActive = checkActiveNavlink(item.path);
  const handleClick = (e) => {
    if (item.key == '4') {
      e.preventDefault();
      window.open(item.path, '_blank');
    }
  };
  return (
    <li>
      <NavLink
        to={item.key=='4'?"#":`${item.path}/${chuyenDeId}`}
        isActive={true}
        onClick={handleClick}
        className={() => {
          return classNames(
            "flex flex-col items-center p-2 text-paragraph rounded-lg justify-center uppercase  text-center",
            {
              " bg-gray-200 text-black": isActive,
              " hover:text-black hover:bg-gray-200 text-white": !isActive,
            },
            {
              "lg:justify-start": !isCollapsed,
            }
          );
        }}
      >
        <span className="lg:text-xl xl:text-xl text-2xl">{item?.icon}</span>
        {widthWindow > 1024 && !isCollapsed ? <span className="ml-0">{item.name}</span> : ""}
      </NavLink>
    </li>
  );
}

import React from "react";
// import "./index.css";
import DashboardDesktop from "./DashboardDesktop";
import DashboardMobile from "./DasboardPhone";
import { useMediaQuery } from "react-responsive";

export default function Dashboard() {
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 768 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({maxWidth: 767 });

    return isTablet ? children : null;
  };

  return (
    <>
      <Desktop>
        <DashboardDesktop />
      </Desktop>
      <Tablet>
        <DashboardMobile />
      </Tablet>
    </>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  info: null,
};

const testDauVaoSlice = createSlice({
  name: "testDauVaoSlice",
  initialState,
  reducers: {
    setDataTestDauVao: (state, action) => {
      state.info = action.payload;
    },
  },
});

export const { setDataTestDauVao } = testDauVaoSlice.actions;

export default testDauVaoSlice.reducer;
// https://localhost:3000/test-quiz?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoiVEVTVF9DQVAyIiwiaWF0IjoxNjkwMzQxOTI5LCJleHAiOjE2OTAzNDQwMjl9.pGJZIPjgpv62EU-lU23OAQFETrSlS5ds10r5dl6r6ds

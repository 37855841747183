import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import SignUpDesktop from "./SignUpDesktop/SignUpDesktop";
import "./Sigup.css";
import localStorageServ from "../../services/locaStorage.service";
import FormTVDesktop from "./FormTuVan/FormTVDesktop";
var qs = require("qs");

export default function FormTuVan() {
  return <FormTVDesktop />;
}

import React from "react";
import "./ranking.css";
import classNames from "classnames";

import { useNavigate } from "react-router-dom";
import { ConvertNameUser } from "../../utils/ConvertNameUser";
import { getHinhAnhFB } from "../../utils/GetHinhanh";
import { Tooltip } from "antd";
export default function ItemRanking({ data, index, activeIndex, setActiveIndex }) {
  let navigate = useNavigate();
  return (
    <div
      onClick={() => {
        setActiveIndex(index);
        navigate(`/profile/${data.nguoiDungId}`);
      }}
      className={classNames(
        " flex items-center justify-between px-2 space-x-4 py-2 text-content item_ranking"
        // {
        //   active_item_ranking: activeIndex == index,
        // }
      )}
    >
      <span className="font-medium">#{index + 1}</span>
        
      <div className="flex text-right flex-grow items-center">
        <img
          src={getHinhAnhFB(data?.avatar,data)}
          className="w-10 h-10 object-cover rounded-full mr-2"
          alt=""
        />

        <p className=" capitalize">{ConvertNameUser(data.hoTen)}</p>
      </div>
      <div className="font-bold text-left flex flex-col items-end">
        <Tooltip placement="topLeft" title={" kinh nghiệm"}>
          <p className="text-sm">
            <i className="fa fa-star mr-1"></i>
            {data.kinhNghiem}
          </p>
        </Tooltip>
        <Tooltip placement="topLeft" title={"cấp độ"}>
          <p> Lv {data.capDo}</p>
        </Tooltip>
      </div>
    </div>
  );
}

const skillLanguage = {
  HTML: "html",
  CSS: "css",
  PYTHON: "python",
  DOTNET: "dotnet",
  JS: "javascript",
};
export const extractSkillLanguage = (language) => {
  let skill = "";
  switch (language) {
    case skillLanguage.HTML:
      skill = "./img/skill/html.png";
      break;
    case skillLanguage.CSS:
      skill = "./img/skill/css.png";
      break;
  }

  return <img className="w-full h-full object-cover" src={skill} />;
};

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import servCodingCamp from "../../../services/codingCam.service";
import { selectPersonalState, setPersonalState } from "../tuongNhaSlice";
import toast from "react-hot-toast";
import TableScore from "./TableScore";
import "./style.scss";
export default function ScoreStatic() {
  let { userId } = useParams();
  let { project, danhSachVideo } = useSelector(selectPersonalState);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    servCodingCamp
      .getThongTinTuongNha(userId, 1)
      .then((res) => {
        console.log("😀 - .then - res", res);
        dispatch(setPersonalState(res.data.content));
      })
      .catch((err) => {
        toast.error("Đã có lỗi xảy ra");
        navigate("/");
      });
  }, [userId]);
  // console.log(project?.chiTietChuyenDe);

  let renderListChuyenDe = () => {
    return project?.chiTietChuyenDe?.map((chuyenDe, index) => {
      return <TableScore key={index} chuyenDe={chuyenDe} />;
    });
  };
  return <div id="scoreStatic">{renderListChuyenDe()}</div>;
}

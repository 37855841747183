import React from "react";
import HtmlParser from "react-html-parser";
import servCodingCamp from "../../../services/codingCam.service";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { getVatPhamUserAction } from "../../../redux/reducer/vatPhamSlice";
import { useDispatch } from "react-redux";

export default function VatPhamGhep({ item }) {
  const { t } = useTranslation()
  let dispatch = useDispatch();

  let handleGhepVatPham = (vatPhamId) => {
    servCodingCamp
      .ghepVatPham(vatPhamId)
      .then((res) => {
        if (res.data.content) {
          toast.success("Ghép thành công");
          dispatch(getVatPhamUserAction());
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      {/* <button classNcfame="btn_swap">Ghép vật phẩm</button> */}
      <button
        onClick={() => {
          handleGhepVatPham(item.id);
        }}
        className="btn_theme_red"
      >
        {t('vatPham.ghepVatPham')}

      </button>
      {/* ghép vật phẩm */}
      <p className="text-title">

        {t('vatPham.ketQuaSauKhiGhep')}

      </p>
      <div className="card-theme ">
        {item?.danhSachVatPhamGhep.map((item, key) => {
          return (
            <div className="text-title flex items-center rounded p-5 space-x-5">
              <img src={item?.hinhAnh} className=" rounded object-cover h-28 block" alt="" />
              <div className="space-y-2">
                <p>
                  <span> {t('vatPham.tenVatPham')}:</span> <span>{item?.tenVatPham}</span>
                </p>
                <p>
                  <span> {t('vatPham.moTa')}:</span>
                  <span>{HtmlParser(item?.moTa)}</span>
                </p>
                {/* <p>
                  <span>Số lượng:</span> <span>{item?.soLuong}</span>
                </p> */}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

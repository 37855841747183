import React from "react";

export default function IconReminder({ icon, issue }) {
  return (
    <div className="relative ">
      {issue ? (
        <span className="absolute top-0 right-0  rounded-full  z-50 text-content w-5 h-5 bg-red-500 text-center leading-5 transform translate-x-1/2 -translate-y-1/3">
          {issue}
        </span>
      ) : (
        ""
      )}
      <div style={{ color: "rgba(255, 210, 32, 1)" }} className="text-xl ">
        {icon}
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import List from "../VideoList/List";
import servCodingCamp from "../../../services/codingCam.service";
import { selectPersonalState, setPersonalState } from "../tuongNhaSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";

export default function BagdesTab() {
  // let { id } = localStorageServ.userInfo.get();
  let { userId } = useParams();
  let { profile, danhSachVideo } = useSelector(selectPersonalState);
  let { personal } = useSelector((state) => state.tuongNhaSlice);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    // .getThongTinTuongNha(userId, 1)
    servCodingCamp.getThongTinTuongNha(userId, 4)
      .then((res) => {
        let newListVideo = {...personal}
        newListVideo.danhSachVideo = res.data.content.danhSachVideo
        newListVideo.danhSachVideoDaLuu = res.data.content.danhSachVideoDaLuu
        dispatch(setPersonalState(newListVideo));
      })
      .catch((err) => {
    
        navigate("/");
      });
  }, []);

// console.log(danhSachVideo)
  return (
    <div id="bagdes_tab">
      <List videoList={danhSachVideo} />
    </div>
  );
}

import React, { useEffect } from "react";
import servCodingCamp from "../../../../services/codingCam.service";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getDetailVideoWallServ,
  getListBinhLuanServ,
  selectListBinhLuan,
  selectVideoWall,
} from "../../tuongNhaSlice";
import localStorageServ from "../../../../services/locaStorage.service";
import classNames from "classnames";
import { GuestLogin } from "./GuestLogin";
import { UserReplied } from "./UserReplied";
import { InputComment } from "./InputComment";

let ItemTodal = ({ icon, number, handleClick = () => {} }) => {
  return (
    <div onClick={handleClick} id="total" className="space-x-2 text-2xl card-theme">
      <span>{icon}</span>
      <span className="text-lg ">{number}</span>
    </div>
  );
};

export default function Discuss() {
  let { id: idVideo } = useParams();
  // const [listBinhLuan, setlistBinhLuan] = useState([]);
  const listBinhLuan = useSelector(selectListBinhLuan);
  const videoWall = useSelector(selectVideoWall);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getListBinhLuanServ({
        nopBaiId: idVideo,
        binhLuanId: 0,
      })
    );
  }, []);

  let isLogin = localStorageServ.userInfo.get();
  let handleToggleLike = () => {
    servCodingCamp
      .toogleLikeVideo(idVideo, !videoWall.daLike)
      .then((res) => {
        console.log(res);
        dispatch(getDetailVideoWallServ(idVideo));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let handleToggleSaveVideo = () => {
    console.log("yeys");
    servCodingCamp
      .toogleSaveVideo(idVideo, !videoWall.daLuu)
      .then((res) => {
        dispatch(getDetailVideoWallServ(idVideo));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div id="discuss" className="text-content space-y-5 items-center p-5  ">
      <div className="flex space-x-5 ">
        <ItemTodal icon={<i className="fa fa-eye"></i>} number={videoWall.luotXem} />
        <ItemTodal
          icon={
            <i
              className={classNames("fa fa-heart", {
                "text-yellow-300": videoWall.daLike,
              })}
            ></i>
          }
          handleClick={handleToggleLike}
          number={videoWall.luotLike}
        />
        <ItemTodal
          icon={
            <i
              className={classNames("fa fa-bookmark", {
                "text-yellow-300": videoWall.daLuu,
              })}
            ></i>
          }
          handleClick={handleToggleSaveVideo}
          number={videoWall.luotLuu}
        />
      </div>

      <h2 className="text-title">{listBinhLuan.length} bình luận</h2>

      {isLogin ? <InputComment /> : <GuestLogin />}
      <div className="w-4/5 h-px bg-gray-400 mx-auto "></div>
      <div className="space-y-5 w-full bg-black rounded-lg p-5">
        {listBinhLuan.map((item, index) => {
          return <UserReplied data={item} key={item.binhLuanId} />;
        })}
      </div>
    </div>
  );
}

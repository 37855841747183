import React from 'react'
import { Comments, CustomChat, FacebookProvider, ShareButton } from 'react-facebook'

const FBChat = () => {
  return (
//     <FacebookProvider appId='303284849320308' chatSupport>
//     <CustomChat pageId='100046318225685' minimized='false' />
//   </FacebookProvider>
  
<FacebookProvider appId="303284849320308" chatSupport>
{/* <ShareButton href="http://www.facebook.com" className="my-classname">
  Share
</ShareButton> */}
     {/* <CustomChat pageId='100046318225685' minimized='false' >chat</CustomChat> */}
     {/* <Comments href="http://www.facebook.com" /> */}
     {/* <CustomChat pageId="100046318225685" minimized={false}/> */}

</FacebookProvider>
)
}

export default FBChat


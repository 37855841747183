import React from "react";
import Lottie from "lottie-react";
import coin_lottie from "../../assets/lottie_json/18089-gold-coin.json";
import { Tooltip } from "antd";
import { checkDemoUser } from "../../utils/HocDemoUtils";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectThongTinMoRong } from "../../redux/reducer/authReducer";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ToolTipCoin({ loop = true }) {
  const { t } = useTranslation()

  let { userInfo } = useSelector(selectThongTinMoRong);
  // const { userInfo } = useSelector(state => state.authUser)
  // return (
  //   <Lottie
  //     loop={loop}
  //     animationData={coin_lottie}
  //     style={{ cursor: "pointer" }}
  //     className="h-14 w-14"
  //   />
  // );
  return (
    <Tooltip
      mouseEnterDelay={0.1}
      mouseLeaveDelay={0.1}
      trigger={["click"]}
      placement="top"
      animation="zoom"
      overlayClassName=" w-max "
      overlayStyle={{ maxWidth: "900px" }}
      color="white"
      title={
        <div className="flex items-center w-max p-3 justify-center">
          <div className=" text-base  w-64 space-y-3 text-gray-800">
            <p>

              {t('header.congDung')}

              {/* hoặc tăng điểm tích cực và điểm kinh nghiệm. Bạn sẽ nhận được coin
              sau khi học bài, hoàn thành bài tập , hoàn hành trắc nghiệm,... và
              ngày tích lũy. */}
            </p>
            <NavLink
              to={"/shop-page"}
              className="btn_theme_red hover:text-white px-2 py-1 btn_theme_red"
            >
              {t('header.diDenCuaHang')}

            </NavLink>
          </div>
        </div>
      }
    >
      <Lottie
        loop={loop}
        animationData={coin_lottie}
        style={{ cursor: "pointer" }}
        className="h-16 w-16"
      />
    </Tooltip>
  );
}
export default ToolTipCoin = React.memo(ToolTipCoin);

import { createSlice } from "@reduxjs/toolkit";
import { groupChatService } from "../../services/groupChatService"
import jsonB from "json-bigint";
import { nguoiDungService } from "../../services/nguoiDungService";

const initialState = {
  ActiveChat1: false,
  ActiveChat2: false,
  ActiveChatBot: false,
  //ActiveCurrent:false là active1 và ngược lại là active2
  ActiveCurrent:false,

  dataChat1: null,
  dataChat2: null,
  dataChatBot: null,

  //tab1
  groupChats: [],
  groupChatsByChuyenDe: [],
  chats: [],
  lastGroupChat: {
    id: 1,
    danhSachChat: "",
    noiDungChat: "",
    ngayTao: "",
    groupForTaskId: 0,
    chuyenDeId: 0,
    issueId: 0
  },
  lastChat: [],
  userId: 0,
  joinRoom: { room: "", taskid: 0 },
  joinRoomCd: { room: "", chuyenDeId: 0 },


  //tab2
  groupChats2: [],
  groupChatsByChuyenDe2: [],
  chats2: [],
  lastGroupChat2: {
    id: 1,
    danhSachChat: "",
    noiDungChat: "",
    ngayTao: "",
    groupForTaskId: 0,
    chuyenDeId: 0,
    issueId: 0
  },
  lastChat2: [],
  userId2: 0,
  joinRoom2: { room: "", taskid: 0 },
  joinRoomCd2: { room: "", chuyenDeId: 0 },
  listUser :[],
  //chatBbot
  chatBot:[]
};
const stackChatReducer = createSlice({
  name: "stackchat",
  initialState,
  reducers: {
    ChangeActiveChat1: (state, action) => {
      state.ActiveChat1 = !state.ActiveChat1;
    },
    ChangeActiveChat2: (state, action) => {
      state.ActiveChat2 = !state.ActiveChat2;
    },
    ChangeActiveChatBot: (state, action) => {
      state.ActiveChatBot = !state.ActiveChatBot;
    },
    updateDataChat1: (state, action) => {
      state.dataChat1 = { ...action.payload };
    },
    updateDataChat2: (state, action) => {
      state.dataChat2 = { ...action.payload };
    },
    updateDataChatBot: (state, action) => {
      state.dataChatBot = {...action.payload };
    },
    changeCurrentActive:(state,action)=>{
      state.ActiveCurrent=!state.ActiveCurrent
    },

    //tab1
    getGroupChatByDsChat: (state, action) => {
      state.groupChats = action.payload
      console.log(state.groupChats && state.groupChats.length != 0, "task")
      if (state.groupChats && state.groupChats.length != 0) {
        state.chats = action.payload
          .map(gc => jsonB.parse(gc.noiDungChat))
          .flat()
        state.lastGroupChat = state.groupChats[state.groupChats.length - 1]
        const lastChat = [...jsonB.parse(state.lastGroupChat.noiDungChat)]
        state.lastChat = lastChat
      }
      // else
      // state.chats=[];
    },
    getGroupChatByDsChatByChuyenDeId: (state, action) => {
      state.groupChatsByChuyenDe = action.payload
      // console.log(
      //   state.groupChatsByChuyenDe && state.groupChatsByChuyenDe.length != 0,
      //   "chuyende"
      // )
      if (
        state.groupChatsByChuyenDe &&
        state.groupChatsByChuyenDe.length != 0
      ) {
        state.chats = action.payload
          .map(gc => jsonB.parse(gc.noiDungChat))
          .flat()
        state.lastGroupChat =
          state.groupChatsByChuyenDe[state.groupChatsByChuyenDe.length - 1]
        const lastChat = [...jsonB.parse(state.lastGroupChat.noiDungChat)]
        state.lastChat = lastChat
      }
      // else
      // state.chats=[];
    },
    setChats: (state, action) => {
      state.chats = action.payload
    },
    addGroupChat: (state, action) => {
      console.log(action.payload)
    },
    editGroupChat: (state, action) => {
      console.log(action.payload)
    },
    addUserId: (state, action) => {
      state.userId = action.payload
      localStorage.setItem("userId", jsonB.stringify(action.payload))
    },
    setJoinRoom: (state, action) => {
      state.joinRoom = { ...action.payload }
      localStorage.setItem("currentRoom", JSON.stringify(action.payload))
    },
    setJoinRoomCd: (state, action) => {
      state.joinRoomCd = { ...action.payload }
      localStorage.setItem("currentRoom", JSON.stringify(action.payload))
    },

    //Tab2
    getGroupChatByDsChat2: (state, action) => {
      state.groupChats2 = action.payload
      console.log(state.groupChats2 && state.groupChats2.length != 0, "task")
      if (state.groupChats2 && state.groupChats2.length != 0) {
        state.chats2 = action.payload
          .map(gc => jsonB.parse(gc.noiDungChat))
          .flat()
        state.lastGroupChat2 = state.groupChats2[state.groupChats2.length - 1]
        const lastChat = [...jsonB.parse(state.lastGroupChat2.noiDungChat)]
        state.lastChat2 = lastChat
      }
      // else
      // state.chats=[];
    },
    getGroupChatByDsChatByChuyenDeId2: (state, action) => {
      state.groupChatsByChuyenDe2 = action.payload
      console.log(
        state.groupChatsByChuyenDe2 && state.groupChatsByChuyenDe2.length != 0,
        "chuyende"
      )
      if (
        state.groupChatsByChuyenDe2 &&
        state.groupChatsByChuyenDe2.length != 0
      ) {
        state.chats2 = action.payload
          .map(gc => jsonB.parse(gc.noiDungChat))
          .flat()
        state.lastGroupChat2 =
          state.groupChatsByChuyenDe2[state.groupChatsByChuyenDe2.length - 1]
        const lastChat = [...jsonB.parse(state.lastGroupChat2.noiDungChat)]
        state.lastChat2 = lastChat
      }
      // else
      // state.chats=[];
    },
    getGroupChatBot: (state, action) => {
        state.chatBot = action.payload
          .map(gc => jsonB.parse(gc.noiDungChat))
          .flat()
    },
    setChats2: (state, action) => {
      state.chats2 = action.payload
    },
    addGroupChat2: (state, action) => {
      console.log(action.payload)
    },
    editGroupChat2: (state, action) => {
      console.log(action.payload)
    },
    addUserId2: (state, action) => {
      state.userId = action.payload
      localStorage.setItem("userId2", jsonB.stringify(action.payload))
    },
    setJoinRoom2: (state, action) => {
      state.joinRoom = { ...action.payload }
      localStorage.setItem("currentRoom2", JSON.stringify(action.payload))
    },
    setJoinRoomCd2: (state, action) => {
      state.joinRoomCd2 = { ...action.payload }
      localStorage.setItem("currentRoom2", JSON.stringify(action.payload))
    },
    setListUser:(state, action)=>{
      state.listUser = action.payload
    }

    
  },
});
export const {
  ChangeActiveChat1,
  ChangeActiveChat2,
  ChangeActiveChatBot,
  updateDataChat1,
  updateDataChat2,
  updateDataChatBot,
  changeCurrentActive,
  // tab1
  getGroupChatByDsChat,
  getGroupChatByDsChatByChuyenDeId,
  addGroupChat,
  editGroupChat,
  addUserId,
  setJoinRoom,
  setJoinRoomCd,
  setChats,

  //tab2

  getGroupChatByDsChat2,
  getGroupChatByDsChatByChuyenDeId2,
  addGroupChat2,
  editGroupChat2,
  addUserId2,
  setJoinRoom2,
  setJoinRoomCd2,
  setChats2,
  //list user thuộc chuyên đề
  setListUser,
  getGroupChatBot
} = stackChatReducer.actions;

export default stackChatReducer.reducer;


//!--------- action async ------------
export const getGroupChatByDsChatApi = (room, taskid) => {
  return async dispatch => {
    try {
      const result = await groupChatService.getGroupChatByDSChat(room, taskid)

      const action = getGroupChatByDsChat(result.data.content)
      dispatch(action)
    } catch (error) {
      console.log("getGroupChatByDsChatApi", error)
    }
  }
}
//*Láy ds groupchat by chuyende
export const getGroupChatByDsChatByChuyenDeApi = (room, chuyenDeId) => {
  return async dispatch => {
    try {
      const result = await groupChatService.getGroupChatByChuyenDeId(
        room,
        chuyenDeId
      )

      const action = getGroupChatByDsChatByChuyenDeId(result.data.content)
      dispatch(action)
    } catch (error) {
      console.log("getGroupChatByDsChatByChuyenDeApi", error)
    }
  }
}
export const postGroupChatApi = (groupChat,dsUser) => {
  return async dispatch => {
    try {
      const res = await groupChatService.addGroupChat(groupChat,dsUser)
      const action = addGroupChat(res.status)
      dispatch(action)
    } catch (err) {
      console.error("postGroupChatApi", err)
    }
  }
}
export const putGroupChatApi = (groupChatId, groupChat,dsUser) => {
  return async dispatch => {
    try {
      const res = await groupChatService.editGroupChat(groupChatId, groupChat,dsUser)
      const action = editGroupChat(res.status)
      dispatch(action)
    } catch (err) {
      console.error("putGroupChatApi", err)
    }
  }
}

export const getGroupChatByDsChatApi2 = (room, taskid) => {
  return async dispatch => {
    try {
      const result = await groupChatService.getGroupChatByDSChat(room, taskid)

      const action = getGroupChatByDsChat2(result.data.content)
      dispatch(action)
    } catch (error) {
      console.log("getGroupChatByDsChatApi", error)
    }
  }
}
//*Láy ds groupchat by chuyende
export const getGroupChatByDsChatByChuyenDeApi2 = (room, chuyenDeId) => {
  return async dispatch => {
    try {
      const result = await groupChatService.getGroupChatByChuyenDeId(
        room,
        chuyenDeId
      )

      const action = getGroupChatByDsChatByChuyenDeId2(result.data.content)
      dispatch(action)
    } catch (error) {
      console.log("getGroupChatByDsChatByChuyenDeApi", error)
    }
  }
}
export const postGroupChatApi2 = (groupChat,dsUser) => {
  return async dispatch => {
    try {
      const res = await groupChatService.addGroupChat(groupChat,dsUser)
      const action = addGroupChat2(res.status)
      dispatch(action)
    } catch (err) {
      console.error("postGroupChatApi", err)
    }
  }
}
export const putGroupChatApi2 = (groupChatId, groupChat,dsUser) => {
  return async dispatch => {
    try {
      const res = await groupChatService.editGroupChat(groupChatId, groupChat,dsUser)
      const action = editGroupChat2(res.status)
      dispatch(action)
    } catch (err) {
      console.error("putGroupChatApi", err)
    }
  }
}
export const getAllUserInChuyenDeApi = () => {
  return async dispatch => {
    try {
      const res = await nguoiDungService.layDsNguoiDungChuyenDe()
      const action = setListUser(res.data.content)
      dispatch(action)
    } catch (err) {
      console.error("get all user chuyende", err)
    }
  }
}

import { useSelector } from "react-redux";
import { selectListLoTrinh } from "../../template/HeaderTask/loTrinhSlice";
export const routesBreadcrumb = [
  {
    path: "/",
    breadcrumb: function HomeIcon(params) {
      return (
        <span>
          <i class="fa fa-home" aria-hidden="true"></i> <span>Dashboard</span>
        </span>
      );
    },
  },
  { path: "/task-page", breadcrumb: TaskBreadcumb },
  { path: "/task-page/:chuyenDeId", breadcrumb: ModuleName },
  {
    path: "/pratice-page",
    breadcrumb: PracticeBreadcumb,
  },
  { path: "/pratice-page/:chuyenDeId", breadcrumb: ModuleName },

  {
    path: "/learn-video",
    breadcrumb: "Video",
  },
  {
    path: "/task-page/detail-screen-bai-tap/",
    breadcrumb: "Bài tập",
  },
  {
    path: "/task-page/detail-screen-bai-tap/:id",
    breadcrumb: "",
  },
];

function PracticeBreadcumb() {
  return (
    <span>
      <i class="fa fa-dumbbell"></i> <span>Lyện tập</span>
    </span>
  );
}
function TaskBreadcumb() {
  return (
    <span>
      <i class="fa fa-book-reader"></i> <span>Nhiệm vụ</span>
    </span>
  );
}
function ModuleName({ match }) {
  const list = useSelector(selectListLoTrinh);
  let { chuyenDeId } = match.params;
  let loTrinh = list.find((item) => item.chuyenDeId == chuyenDeId);

  return <span>{loTrinh?.tenChuyenDe}</span>;
}

import React, { useState } from "react";
import FormDieuKhoanTV from "./FormDieuKhoanTV";

export default function ThongTinChinhTV() {
  const [isShowDieuKhoan, setIsShowDieuKhoan] = useState(false);
  const handleCloseModal = () => {
    setIsShowDieuKhoan(false);
  };
  const handleOpenModal = () => {
    setIsShowDieuKhoan(true);
  };

  return (
    <div className="w-full h-full p-3 flex flex-col items-center space-y-5 text-white">
      <p className="uppercase  text-lg lg:text-xl ">ĐĂNG KÝ THÔNG TIN CHÍNH</p>
      

      <FormDieuKhoanTV handleOpenModal={handleOpenModal} />
    </div>
  );
}

import React, { Fragment, useEffect, useState } from 'react'
import './style.css'
import { history } from '../../App'
import { useDispatch, useSelector } from 'react-redux'
import { use } from 'i18next'
import { getArrPackageCourseApi } from '../../redux/reducer/packageReducer'

import Slider from 'react-slick';
import localStorageServ from '../../services/locaStorage.service'
import { selectThongTinMoRong } from '../../redux/reducer/authReducer'
import { BASE_URL_CODINGCAMP } from '../../services/axios.service'
import axios from 'axios'
import { API_URL_DOMAIN } from '../../utils/urlDomain'
const PackageTable = ({ id, arrChuyenDe, isCombo }) => {

  const [arrPackageByCourse, setArrPackageByCourse] = useState([])
  const [arrRender, setArrRender] = useState()
  const [arrRes, setArrRes] = useState()
  let userInfo = localStorageServ.userInfo.get()
  const shouldShowSlider = arrRes?.length > 1

  const dispatch = useDispatch()
  useEffect(() => {
    axios.get(`${API_URL_DOMAIN}/package/course?id=${id}`).then((res) => {
      setArrPackageByCourse(res.data.content)
    })
    // dispatch(getArrPackageCourseApi(id))
  }, [id])
  console.log(arrPackageByCourse)
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // speed: 10000,
    // autoplaySpeed: 1000,
    cssEase: "linear",
    arrows: false
  };
  useEffect(() => {
    setArrRender(arrPackageByCourse)
  }, [arrPackageByCourse])
  useEffect(() => {
    let res = []
    arrRender?.forEach(item => {
      const existingItem = res.find(r => r.danhSachKhoaHoc === item.danhSachKhoaHoc);

      if (existingItem) {
        existingItem.info.push(item);
      } else {
        res.push({
          ...item,
          info: [item]

        });
      }
    });
    setArrRes(res)
  }, [arrRender])
  return (
    <div className={`row  w-full ${shouldShowSlider ? "" : "flex justify-center"}`}>

      {shouldShowSlider ?
        <Slider {...settings} className='h-full'>


          {arrRes.map((item, idx) => {
            let isBoder = arrPackageByCourse.length == 1
            let ds = JSON.parse(item.danhSachKhoaHoc)
            return <div key={idx} className={`itemPac col ${isBoder ? ' bg-transparent' : ""}`}>
              <div className=''>
                <img src={`https://apimentor11.cybersoft.edu.vn/${item.hinhAnh}`} alt="" />
              </div>
              <div>
                <h4 className='text-white my-1'>{item.tenGoi}</h4>
                {/* <p><span className=" text-xl"><sup>đ</sup>{item.hocPhi / 1000}<sub>.000{'/' + item.thang + 'Tháng'}</sub></span></p> */}
                {/* <p>10,000 monthly visits</p> */}
                <ul>
                  {ds.map((cd, idx1) => {
                    const cdName = arrChuyenDe?.find(a => a.id === cd)?.tenChuyenDe || ''
                    return <li key={idx1}><span className="fa fa-check mr-2" />{cdName}</li>
                  })}
                </ul>
              </div>
              <div className="btn_footer">
                {item.info.map((inf, idx) => {
                  return <div key={idx} className='relative inline-block'>
                    <button href className={`btn_theme_red m-1 ${inf.thang == 3 ? 'bestSeller' : ""}`} onClick={() => {
                      localStorageServ.regisPackage.set(inf)
                      if (userInfo?.email == 'freeacc@gmail.com' || !userInfo) {
                        history.push('/signup-user')
                      } else {
                        history.push('/confirm-payment')
                      }
                    }}>{inf.hocPhi / 1000000} triệu/<sub>{inf.thang} tháng</sub></button>
                    {inf.thang == 3 && <p className='btnBest absolute'>Best Seller</p>}
                  </div>
                })}
              </div>
            </div>


          })}
        </Slider>
        :
        arrRes?.map((item, idx) => {
          let isBoder = arrPackageByCourse.length == 1
          let ds = JSON.parse(item.danhSachKhoaHoc)
          return <div key={idx} className={`itemPac col ${isBoder ? ' bg-transparent' : ""}`}>
            <div className=''>
              <img src={`https://apimentor11.cybersoft.edu.vn/${item.hinhAnh}`} alt="" />
            </div>
            <div>
              <h4 className='text-white my-1'>{item.tenGoi}</h4>
              {/* <p><span className=" text-xl"><sup>đ</sup>{item.hocPhi / 1000}<sub>.000{'/' + item.thang + 'Tháng'}</sub></span></p> */}
              {/* <p>10,000 monthly visits</p> */}
              <ul>
                {ds.map((cd, idx1) => {
                  const cdName = arrChuyenDe?.find(a => a.id === cd)?.tenChuyenDe || ''
                  return <li key={idx1}><span className="fa fa-check mr-2" />{cdName}</li>
                })}
              </ul>
            </div>
            <div className="btn_footer">
              {item.info.map((inf, idx) => {
                return <div key={idx} className='relative inline-block'>
                  <button href className={`btn_theme_red m-1 ${inf.thang == 3 ? 'bestSeller' : ""}`} onClick={() => {
                    localStorageServ.regisPackage.set(inf)
                    if (userInfo?.email == 'freeacc@gmail.com' || !userInfo) {
                      history.push('/signup-user')
                    } else {
                      history.push('/confirm-payment')
                    }
                  }}>{inf.hocPhi / 1000000} triệu/<sub>{inf.thang} tháng</sub></button>
                  {inf.thang == 3 && <p className='btnBest absolute'>Best Seller</p>}
                </div>
              })}
            </div>
          </div>


        })}


    </div>

  )
}

export default PackageTable
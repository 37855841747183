
import { Dropdown, Layout, Menu, Modal, Select } from 'antd';
import { Content, Header } from 'antd/lib/layout/layout';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { Element, Link } from 'react-scroll';
import { useLstLoTrinh, useLstLoTrinhUser } from '../../../hook/useLstLoTrinhUser';
import { setListImageTag } from '../../../redux/reducer/dashboardSlice';
import { checkActiveNavlink, parentPage } from '../../../routes/utitlity';
import localStorageServ from '../../../services/locaStorage.service';
import { dataHeaderPackage } from '../../../template/utils';
import { API_URL_DOMAIN, emailFree } from '../../../utils/urlDomain';
import CardLoTrinh from '../CardLoTrinh/CardLoTrinh';
import PackageTableV2 from '../PackageTableV2';
import './style.css';
import useWindowSize from '../../../hook/useWindowSize';
import { history } from '../../../App';
import HeaderBottom from '../../../template/HeaderBottom/HeaderBottom';
import { useLstImgTag } from '../../../hook/useLstImgTag';
import DropdownLoTrinh from '../../../template/HeaderTask/DropdownLoTrinh';
import { checkLearning } from '../../../utils/HocDemoUtils';
import ZaloBtn from '../../../components/ZaloOA/ZaloOA';
import FBChat from '../../../components/FacebookChat/FBChat';
const ViewPackage = () => {
  const { widthWindow, heightWindow } = useWindowSize();
  const isPhone = widthWindow < 639 ? true : false
  const refs = useRef([]);
  let userInfo = localStorageServ.userInfo.get();
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const navigate = useNavigate();
  let userId = localStorageServ.userInfo.get()?.id;

  const [arrPackage, setArrPackage] = useState()
  const [arrChuyenDe, setArrChuyenDe] = useState()
  const [cdDangHoc, setCdDangHoc] = useState()
  const { data, isLoading, error } = useLstLoTrinh();

  const { data:listImgTag , isLoading:isloadTag, error:errorTag } = useLstImgTag();
  let img_warp = "md:w-8 md:h-8 w-10 h-10 p-1";
  //onclick chuyende
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chuyenDeId, setChuyenDeId] = useState(false);
  const [isComBo, setIsCombo] = useState(false);
  console.log(isComBo)
  const showModal = (id, bool) => {
    setChuyenDeId(id)
    setIsCombo(bool)
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const renderTask = (soLuong, maLoai) => {
    let icon = ""
    let title = ""
    switch (maLoai) {
      case 'RUNCODE':
        icon = "fa-code"
        title = "Runcode"
        break
      case 'VIDEO':
        icon = "fa-video"
        title = "Video"
        break
      case 'TASK':
        icon = "fa-tasks"
        title = "Task"
        break
      case 'QUIZ':
        icon = "fa-question-circle"
        title = "Quizz"
        break
      case 'HOMEWORK':
        icon = "fa-question-circle"
        title = "BTVN"
        break
      case 'TAILIEU':
        icon = "fa-book"
        title = "Tài liệu"
        break
      case 'BAITAP':
        icon = "fa-file-alt"
        title = "Bài tập"
        break
      case 'DUAN':
        icon = "fa-gavel"
        title = "Dự án"
        break
      case 'CAPS':
        icon = "fa-users"
        title = "Capstone"
        break
    }
    return <div className='flex flex-col items-center justify-between'><i className={`p-0 m-0 text-lg fas ${icon}`}></i><p className='text-center p-0 m-0'>{soLuong} {title}</p></div>
  }

  const [arrRes, setArrRes] = useState()
  useEffect(() => {
    let res = []
    arrPackage?.forEach(item => {
      const existingItem = res.find(r => r.danhSachKhoaHoc === item.danhSachKhoaHoc);

      if (existingItem) {
        existingItem.info.push(item);
      } else {
        res.push({
          ...item,
          info: [item]

        });
      }
    });
    setArrRes(res)
  }, [arrPackage])
  useEffect(() => {
    axios
      .get(`${API_URL_DOMAIN}/package`)
      .then((res) => {
        setArrPackage(res.data.content)
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${API_URL_DOMAIN}/chuyende`)
      .then((res) => {
        setArrChuyenDe(res.data.content)
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${API_URL_DOMAIN}/dangkyhoc/get-theo-user/${userId}`)
      .then((res) => {
        setCdDangHoc(res.data.content)
      })
      .catch((err) => {
        console.log(err);
      });
  }, [])
  const menu = (
    <Menu className="p-4 space-y-5 rounded dropdown-lo-trinh">
      {data?.map(({ tenChuyenDe, chuyenDeId, notify, isActive }) => {
        if (isActive) {
          return (
            <div key={chuyenDeId} className="shadow cursor-pointer relative">

              {notify && <span class="dot-notify  absolute inline-flex h-4 w-4 -top-1 -right-1 rounded-full  z-10 "></span>}
              <NavLink
                to={`/${parentPage.taskFree}/${chuyenDeId}`}

              >


                {tenChuyenDe.toUpperCase()}
              </NavLink>
            </div>
          )
        }
      })}
    </Menu>
  );
  const menuGoi = (
    <Menu className="p-4 space-y-5 rounded dropdown-lo-trinh">
      {arrRes?.map((item, idx) => {
        let ds = JSON.parse(item.danhSachKhoaHoc)
        if (ds.length > 1) {
          return (
            <div key={idx} className="shadow cursor-pointer relative">
              <Link to={item.danhSachKhoaHoc} smooth={true} duration={500}>{item.tenGoi.toUpperCase()}</Link>
            </div>
          )
        }
      })}
    </Menu>
  )
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          background: "black",
          padding:isPhone?'0 10px':''
        }}
        className="flex h-20  fixed top-0 left-0 w-full z-50 items-center  justify-between space-x-5"
      >
        <div
          onClick={() => {
            navigate("/dash-broad");
          }}
          className="logo w-23 flex-shrink-0 h-ful flex items-center cursor-pointer"
        >
          <img src="https://apimentor11.cybersoft.edu.vn//images/24-04-2024-10-46-20-logo-codezuni.png" alt="" />
        </div>
        {isPhone ? "" :

          <div className="flex rounded-xl relative items-center h-10  ">
            {(!userInfo || userInfo?.email==emailFree) ? dataHeaderPackage.map((item, index) => {
              if (item.key == 1) {
                return (
                  <div className="relative">
                    {data?.map((({ notify }) => notify &&

                      <span class="dot-notify  absolute inline-flex h-4 w-4 -top-1 -right-1 rounded-full  z-10 "></span>
                    ))}
                    <Dropdown arrow overlay={menu}>
                      <p className="p-1 px-3 flex  justify-center text-content text-sm items-center rounded-xl z-10 relative  duration-500 text-button uppercase whitespace-nowrap cursor-pointer space-x-2">
                        <span className={checkActiveNavlink(parentPage.taskFree) ? "active-link" : ""}>
                          {" "}
                          <i className="pe-2 fa-solid fa-road"> </i>

                          {t('header.loTrinh')}
                          <i className="pl-2 fa fa-caret-down "></i>
                        </span>
                      </p>
                    </Dropdown>
                  </div>
                )
              }
              else if (item.key == 2) {
                return (
                  <div className="relative">
                    {data?.map((({ notify }) => notify &&

                      <span class="dot-notify  absolute inline-flex h-4 w-4 -top-1 -right-1 rounded-full  z-10 "></span>
                    ))}
                    <Dropdown arrow overlay={menuGoi}>
                      <p className="p-1 px-3 flex  justify-center text-content text-sm items-center rounded-xl z-10 relative  duration-500 text-button uppercase whitespace-nowrap cursor-pointer space-x-2">
                        <span>
                          <i className="pe-2 fa-solid fa-road"> </i>

                          {t('header.combo')}
                          <i className="pl-2 fa fa-caret-down "></i>
                        </span>
                      </p>
                    </Dropdown>
                  </div>
                )
              }
            })
            :
            <DropdownLoTrinh/>
            }
          </div>

        }
        <div className='flex'><div className="lang">
          <Select
            style={{ width: 100, fontSize: 18, color: "white" }}
            value={localStorage.getItem("lang")}
            onChange={(value) => {
              localStorage.setItem("lang", value)
              window.location.reload()
            }}
            options={[{ value: 'vi', label: '🇻🇳 VI' }, { value: 'en', label: '🇺🇸 EN' }]}


          />
        </div>
        <div
        onClick={() => {
          localStorageServ.userInfo?.remove();
          window.location.href = "/login";
        }}
        style={{ color: " rgb(255, 210, 32)" }}
        className=" cursor-pointer flex items-center justify-start space-x-2 flex-shrink-0"
      >
        <p className="">
          {" "}
          {userInfo?.email == emailFree || !userInfo
            ? t("header.dangNhap")
            : t("header.dangXuat")}
        </p>{" "}
        <i className="fa fa-sign-out-alt "></i>
      </div>
        </div>

      </Header>
      {isPhone && (
        <div
          style={{
            // background:"#fff",
            zIndex: 100,
            // padding: isPhone ? "0 10px" : "",
          }}
          className="flex h-16  fixed bottom-0 left-0 w-full  items-center  justify-between space-x-5"
        >
          <HeaderBottom />
        </div>
      )}
      <Layout>
        <Layout
          style={{
            margin: `80px 0 0 0`,
            padding: "0",
          }}
          className="bg-content"
        >
          <Content>

            <div className='container'>
              <h2 className="tenChuyenDe text-center">Danh sách khóa học</h2>
              {isLoading && <div className='flex justify-center'> <div className=' loading_progress'></div></div>}
              <div className="grid gap-8 grid-cols-3 md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-2">

                {data && data.map((item, index) => {
                  const cd = arrChuyenDe?.find(a => a.id === item.chuyenDeId)
                  let isLearning = checkLearning(item.chuyenDeId,cdDangHoc )
                  console.log('isLearning: ', isLearning);

                  if (item.isActive) {
                    return <div className="course-card cursor-pointer" >
                      <div className="course-header" onClick={() => {
                       if(isLearning){
                        history.push(`/task-page/${item.chuyenDeId}`)
                      }
                      else{
                        history.push(`/task-page-free/${item.chuyenDeId}`)
                      }
                      }}>
                        <img className='w-full overflow-hidden' src={`https://apimentor11.cybersoft.edu.vn/${item.hinhAnh}`} alt={item?.tenChuyenDe} />
                        <div className="discount-badge">{-(100 - ((cd?.hocPhiGiam / cd?.hocPhi) * 100)).toFixed(0)}%</div>
                      </div>
                      <div className="content__group flex flex-col justify-between ">
                        <div className="course-content" onClick={() => {
                          if(isLearning){
                            history.push(`/task-page/${item.chuyenDeId}`)
                          }
                          else{
                            history.push(`/task-page-free/${item.chuyenDeId}`)
                          }
                        }}>
                          <h4 className="text-xl font-bold text-gray-800 mt-0 mb-2">{item.tenChuyenDe}</h4>
                          <div className="course-info">
                            {item.thongKeChuyenDe
                              .filter(({ tongSoLuong }) => tongSoLuong > 0)
                              .map(({ tongSoLuong, tieuDe, maLoai }, index) => {
                                return (
                                  renderTask(tongSoLuong, maLoai)
                                );
                              })}


                          </div>
                          <div className="flex flex-wrap mb-2">
                            {item?.danhSachTag.map((tag) => {
                              let item1 = listImgTag?.find((item1) => {
                                return item1?.value == tag;
                              });
                              return (
                                <div className={img_warp}>
                                  <img className="w-full h-full object-cover rounded" src={item1?.image} alt />

                                </div>
                              );
                            })}
                            {/* <img src={`https://skillicons.dev/icons?i=${item?.danhSachTag.join(',').toLowerCase()}`} alt="" /> */}
                          </div>

                        </div>
                        <div className="text-center pb-4">
                          <div className='mb-2 text-left px-4'>
                            <p className='mentor-support text-lg font-bold '>
                              <i className="fa-regular fa-user-group-simple "></i> Mentor support 1 - 1
                            </p>
                            <p className='ai-support text-lg font-bold'>
                              <i className="fa-regular fa-user-robot-xmarks"></i> AI support 24/7
                            </p>
                          </div>
                          <div className='flex justify-around'>
                            {/* <button className="btn_theme_red" onClick={() => {
                            showModal(item.chuyenDeId,false)
                          }}>Mua lẻ</button>
                          <button className="btn_theme_red" onClick={() => {
                            showModal(item.chuyenDeId,true)
                          }}>Combo</button> */}
                           <button className="btn_theme_package" onClick={() => {
                              // showModal(item.chuyenDeId, true)
                              if(isLearning){
                                history.push(`/task-page/${item.chuyenDeId}`)
                              }
                              else{
                                history.push(`/task-page-free/${item.chuyenDeId}`)
                              }
                            }}>{isLearning?'Tiếp tục học':'Học thử miễn phí'}</button>
                          </div>
                        </div>
                        {/* <div className="course-footer">
                          <div className="price-container">
                            <span className="price-discount">{cd?.hocPhi.toLocaleString()}đ</span>
                            <span className="price">{cd?.hocPhiGiam.toLocaleString()}đ</span>
                          </div>
                          <button className="course-button" onClick={() => { 
                            showModal(item.chuyenDeId)
                           }}>Học thử miễn phí</button>
                        </div> */}
                      </div>
                    </div>


                  }
                })}
              </div>
            </div>
            <div className="py-8">
              <h2 className="tenChuyenDe text-center">Danh sách combo</h2>
              <div className="container group ">


                <div className="w-full mt-14 ">
                  <div className="lg:w-full xl:container xl:px-10  mx-auto text-color-title mt-10 ">
                    <div className="flex justify-center flex-wrap  w-full ">
                      {arrRes && arrRes.map((item, idx) => {
                        let ds = JSON.parse(item.danhSachKhoaHoc)
                        if (ds.length > 1) {
                          return <Element name={item.danhSachKhoaHoc}>
                            <CardLoTrinh

                              arrChuyenDe={arrChuyenDe}
                              ref_Props={(element) => {
                                refs.current[idx] = element;
                              }}
                              btn_tuVan={!false}

                              title={item.tenGoi.toUpperCase()}
                              key={idx}
                              loTrinh={item}
                            ></CardLoTrinh>
                          </Element>

                        }

                      })}
                    </div>
                  </div>

                </div>
              </div>


            </div>
            <Modal visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}
              className='modelPackage'
              footer={null}
              //  bodyStyle={{
              //   overflow: "hidden",
              // }}
              width='90%'
              style={{
                // maxWidth: "900px",
                // minWidth: "400px",
                top: 10,
                // width: "90%",
              }}>
              <PackageTableV2 id={chuyenDeId} arrChuyenDe={arrChuyenDe} isCombo={isComBo} />
            </Modal>
          </Content>
          
          <ZaloBtn/>
          <FBChat/>

        </Layout>
      </Layout>
    </Layout>
  )
}

export default ViewPackage

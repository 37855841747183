import classNames from "classnames";
import React, { useState } from "react";
import closeBg from "./close.png";
import openBg from "./open.png";
import ModalConfetti from "../../../components/ModalConfetti/ModalConfetti";
import "./loot.scss";
import servCodingCamp from "../../../services/codingCam.service";
import { message } from "antd";
export default function LootBox({ isUsed, isDisabled, item }) {
  const [isOpen, setIsOpen] = useState(false);

  let handleMoHopQua = () => {
    servCodingCamp
      .postMoHopQuaPratice(1, item.point)
      .then((res) => {
        setIsOpen(true);
      })
      .catch((err) => {
        // message.error("Điểm hoạt động không đủ !");
        setIsOpen(true);
        message.error(err.err.response.data.message);
        console.log(err);
      });
  };
  return (
    <div className="w-14 h-14  relative cursor-pointer lootContainer">
      {isOpen && (
        <div
          onClick={() => {
            setIsOpen(false);
          }}
          className="w-screen h-screen fixed  left-0 top-0"
        >
          {" "}
          <ModalConfetti />
        </div>
      )}

      <img
        style={{
          filter: isDisabled ? "grayscale(100%)" : "none",
        }}
        onClick={() => {
          console.log(!isUsed && !isDisabled);
          !isUsed && !isDisabled && handleMoHopQua();
        }}
        src={isUsed ? openBg : closeBg}
        alt=""
        className="w-full absolute  h-full top-0 left-0  z-20"
      />
      {!isUsed && !isDisabled && (
        <div className="circle_box absolute w-full h-full "></div>
      )}
    </div>
  );
}

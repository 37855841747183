import React from "react";
import HtmlParser from "react-html-parser";
import VatPhamGhep from "./VatPhamGhep";
import VatPhamMo from "./VatPhamMo";
import { useTranslation } from "react-i18next";

let typeVatPham = {
  XEM: "XEM",
  MO: "MO",
  GHEP: "GHEP",
};
export default function SwapItem({ item }) {
  const { t } = useTranslation()

  return item?.tenVatPham ? (
    <div className="h-full w-full  space-y-5 bg-transparent rounded p-5">
      {item?.maLoai == typeVatPham.GHEP && (
        <p className="text-title">
          {t('vatPham.banCan')} {item?.danhSachVatPhamGhep[0]?.soLuong}  {t('vatPham.vatPhamHienTai')}
        </p>
      )}
      <div className="card-theme p-5">
        <div className=" text-title flex items-center rounded  space-x-5">
          <img src={item?.hinhAnh} className=" rounded object-cover h-28 block" alt="" />
          <div className="space-y-2">
            <p>
              <span> {t('vatPham.tenVatPham')}:</span> <span>{item?.tenVatPham}</span>
            </p>
            <p>
              <span>{t('vatPham.moTa')}: </span>
              <span>{HtmlParser(item?.moTa)}</span>
            </p>
            <p>
              {" "}
              <span>{t('vatPham.soLuong')}:</span> <span>{item?.soLuong}</span>
            </p>
          </div>
        </div>
      </div>
      {item?.maLoai == typeVatPham.GHEP && <VatPhamGhep item={item} />}
      {item?.maLoai == typeVatPham.MO && <VatPhamMo item={item} />}
    </div>
  ) : (
    <></>
  );
}

import { useMediaQuery } from "react-responsive";
import React from 'react'
import PackageDesktop from "./PackageDesktop";
import PackageTable from "./PackageTable";

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  
    return isTablet ? children : null;
  };
const ListService = () => {
  return (
    <>
        <Desktop>
        <PackageDesktop />
      </Desktop>
      <Tablet>
        <PackageTable />
      </Tablet>
    </>
  )
}

export default ListService
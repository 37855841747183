import React from "react";
import { extractSkillLanguage } from "./extractIcon";
import { NavLink } from "react-router-dom";
/***
 * "id": 1,
            "tieuDe": "bai viet 1",
            "noiDung": "Course Description\r\n\r\nIn this introduction to R course, you'll master the basics of this widely used open source language—including vectors, factors, lists, and data frames. With the coding skills you'll gain in this course, you'll be ready to undertake your own data analysis in R. There are millions of R users worldwide, cementing it as a leading programming language in statistics and data science. Begin your coding journey in one of DataCamp's most popular courses today!",
            "tagSkill": "[\"HTML\"]",
            "trangThai": 1,
            "loaiBaiViet": "[]",
            "nguoiTao": "Bùi Tiến Dũng",
            "nguoiDuyet": "36dc81cf-71d0-4a6c-864b-5b802d42a976",
            "chuyenDeId": 1,
            "baiVietCha": 0,
            "luotXem": 0,
            "luotLike": 0,
            "luotTraLoi": 0,
            "luotVote": 0,
            "bookMark": true
 */
let styleButton = {
  // width: "96px",
  height: "41px",
  background: "linear-gradient(360deg, #FFC94C 14.14%, #F4A408 100%)",
  borderRadius: "9px",
  color: "white",
  fontWeight: 700,
};
export default function ItemPosted({ dataDocment, isLayoutGrid }) {
  const renderSkill = () => {
    return;
    return dataDocment.skill.map((s) => {
      return (
        <div className="w-8 h-8 shadow rounded border bg-main p-1">
          {extractSkillLanguage(s)}
        </div>
      );
    });
  };
  const renderInteractiveBar = (
    <div className="flex  text-content flex-1 space-x-3">
      <p className="flex-1 space-x-1 flex items-center">
        <i class="fa fa-eye text-sub-title font-bold"></i>
        <span className="font-normal">{dataDocment.luotXem}</span>
      </p>
      <p className="flex-1 space-x-1 flex items-center">
        <i class="fa fa-heart text-sub-title font-bold"></i>
        <span className="font-normal">{dataDocment.luotLike}</span>
      </p>
      <p className="flex-1 space-x-1 flex items-center">
        <i class="fa fa-comment-dots text-sub-title font-bold"></i>
        <span className="font-normal">{dataDocment.luotTraLoi}</span>
      </p>
    </div>
  );
  return (
    <section className={`${isLayoutGrid ? "c-col-25" : "c-col-full"} relative`}>
      <i class="fa fa-thumbtack absolute right-7 top-7 icon-main text-title cursor-pointer"></i>
      <main className="card-theme">
        <div className="c-card-content">
          {/*  */}
          <div className="grid  grid-rows-10 grid-cols-10 gap-3">
            <div
              className={`${
                isLayoutGrid ? "row-span-2 col-span-4" : "row-span-7 col-span-2"
              } `}
            >
              <img
                src={dataDocment.img}
                className="w-full h-full object-cover"
                alt=""
              />
            </div>
            {/*  */}
            <div
              className={`${
                isLayoutGrid
                  ? "row-span-2 col-span-6 "
                  : "row-span-2 col-span-8"
              } space-y-3`}
            >
              <p
                className={`${
                  isLayoutGrid ? "text-sub-title" : "text-title"
                } font-medium w-5/6 mr-auto`}
              >
                {dataDocment.tieuDe}
              </p>
              {isLayoutGrid && renderInteractiveBar}
            </div>
            {/*  */}
            <div
              className={`${
                isLayoutGrid
                  ? "row-span-6 col-span-10"
                  : "row-span-5 col-span-8"
              } text-content`}
            >
              {dataDocment.noiDung}
            </div>
            {/*  */}
            <div
              className={`${
                isLayoutGrid
                  ? "row-span-2 col-span-10"
                  : "col-start-3 row-span-3 col-span-8"
              } space-y-2`}
            >
              <div className="flex">
                <div className="flex-1">
                  <div className="flex space-x-3">{renderSkill()}</div>
                </div>
                <div className="flex items-center space-x-2">
                  {/* Comment */}
                  {!isLayoutGrid && renderInteractiveBar}
                  {/*  */}

                  <button style={styleButton}>
                    <NavLink
                      to={`/detail-page-document/${dataDocment.id}`}
                      className="px-2 py-1 bg-main text-white rounded"
                    >
                      Xem Chi Tiết
                    </NavLink>
                  </button>
                </div>
              </div>
              <button className="bg-sp text-white px-2 py-1 rounded font-bold">
                # {dataDocment.userName}
              </button>
            </div>
          </div>
        </div>
      </main>
    </section>
  );
}

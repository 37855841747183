import React from "react";
import "./sideNavigation.css";

import ItemSidebar from "./ItemSidebar";
import { subItems } from "../utils";

export default function SideNavigation({ isCollapsed }) {
  return (
    <div id="sideNavigation">
      <aside className="w-full h-full" aria-label="Sidenav">
        <div className="overflow-y-auto py-5 px-1 h-full side_navigation_theme">
          <ul className="space-y-2">
            {subItems.map((item, index) => {
              return <ItemSidebar item={item} isCollapsed={isCollapsed} key={index} />;
            })}
          </ul>
        </div>
      </aside>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import ShopFilter from "./ShopFilter";
import ShopList from "./ShopList";
import ContentTab from "../../components/ContentTab/ContentTab";
import YourList from "./YourList";
import "./style.scss";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../hook/useWindowSize";

export default function ShopPage() {
  const { t } = useTranslation()
  
  const { widthWindow, heightWindow } = useWindowSize();
  const isPhone = widthWindow < 768 ? true : false;

  const [activeFilter, setActiveFilter] = useState(0);
  const renderTabs = () => {
    return [
      {
        label: (
          <span className="text-content px-5 font-bold">
            <i class="fa fa-store mr-2"></i>
            {t('vatPham.cuaHang')}

          </span>
        ),
        key: "1",
        children: <ShopList activeFilter={activeFilter} />,
      },
      {
        label: (
          <span className="text-content px-5 font-bold">
            <i class="fa fa-box-open mr-2"></i>
            {t('vatPham.vatPhamSoHuu')}

          </span>
        ),
        key: "3",
        children: <YourList activeFilter={activeFilter} />,
      },
    ];
  };
  return (
    <div id="shop_page" className="flex  c-card-content space-x-3">
      {!isPhone && <ShopFilter activeFilter={activeFilter} setActiveFilter={setActiveFilter} />}
      <ContentTab
        backgroundHeader="transparent"
        // paddingHeaderX={10}
        paddingHeaderX={0}
        paddingHeaderY={0}
        // paddingHeaderY={widthWindow > 1024 ? 12 : 7}
        paddingTabItemX={10}
        paddingTabItemY={15}
        data={renderTabs()}
      />
    </div>
  );
}

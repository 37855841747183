import React from "react";
import CardCompetitions from "./CardCompetitions";

export default function ListCompetitions({ data }) {
  const renderListCard = () => {
    return data.map((d) => {
      return <CardCompetitions data={d} />;
    });
  };
  return (
    <div>
      <div className="c-card-content flex items-center text-title font-bold space-x-4">
        <i class="fa fa-user-friends"></i>
        <span>Activies</span>
      </div>
      <div className="c-card-content">
        <div className="flex flex-wrap">{renderListCard()}</div>
      </div>
    </div>
  );
}

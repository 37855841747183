import React, { useState } from "react";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper";
import bgCoin from "./dollar.png";
import squareBG from "./effect.png";
import { ContentVatPham } from "../../components/VatPham/ContentVatPham";
import { useTranslation } from "react-i18next";
let ShopItem = ({ item }) => {
  let { hinhAnh, tenVatPham, chiPhi } = item;
  const { t } = useTranslation()

  return (
    <>
      <div className=" rounded-xl w-full    card-theme flex p-2  items-center">
        <div className="w-28 h-28 relative p-2 flex-shrink-0">
          <img src={hinhAnh} className=" rounded object-cover h-full w-full block" alt="" />
          <img className="absolute w-full h-full top-0 left-0 " src={squareBG} alt="" />
        </div>
        {/* <button className="w-full  py-3 text-white uppercase font-bold text-xl btn_vat_pham">
          Đổi vật phẩm
        </button> */}
        <div className="p-1  text-content pl-2 space-y-2 w-full">
          <p className="   mt-3">{tenVatPham}</p>
          <p className="flex items-center">
            {/* Giá vật phẩm: */}
            <span className="mr-2"> {chiPhi} </span>
            <img src={bgCoin} className="w-4" alt="" />
          </p>
          <div className="text-right w-full">
            <button
              // onClick={handleBuy}
              className=" py-1  text-white uppercase font-bold text-sm btn_vat_pham px-3 rounded text-left"
            >
            {t('vatPham.muaVatPham')}

            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default function ModalShopItem({ item }) {
  let icon = <ShopItem item={item} />;
  return (
    <div className="w-full">
      <ModalWrapper icon={icon} content={<ContentVatPham item={item} />} />
    </div>
  );
}
/**
 * {
    "id": 5,
    "tenVatPham": "Vé làm lại trắc nghiệm",
    "": 500,
    "hanSuDung": 0,
    "hinhAnh": "https://cdn3d.iconscout.com/3d/premium/thumb/ticket-discount-6849609-5607199.png",
    "moTa": "Tăng thanh tích cực (HP) lên 3000 và khi bị trừ tích cực giảm 50% số điểm bị trừ",
    "dieuKienGhep": "[]",
    "maNhom": 3
}
 */

import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";
import SignUpPage from "./pages/SignUp/SignUpPage";
import TaskPage from "./pages/TaskPage/TaskPage";
import DetailScreenCapstone from "./pages/DetailScreenCapstone/DetailScreenCapstone";
import DetailScreenQuizz from "./pages/DetailScreenQuizz/DetailScreenQuizz";
import HomeTemplate from "./template/HomeTemplate";
import DaskBroad from "./pages/DashBroad/Dashboard";
import Discussion from "./pages/Discussion/Discussion";
import Runcode from "./pages/Runcode/Runcode";
import Upgrade_Notify_Page from "./pages/Upgrade_Notify_Page";
import DetailExercise from "./pages/DetailExercise/DetailExercise";
import LearnVideoPage from "./pages/LearnVideoPage/LearnVideoPage";
import PracticePage from "./pages/PracticePage/PracticePage";
import DocumentPage from "./pages/DocumentPage/DocumentPage";
import BestPracticePage from "./pages/BestPracticePage/BestPracticePage";
import CompetitionsPage from "./pages/CompetitionsPage/CompetitionsPage";
import LiveEventPage from "./pages/LiveEventPage/LiveEventPage";
import DetailDocumentPage from "./pages/DetailDocumentPage/DetailDocumentPage";
import DetailCompetitionsPage from "./pages/DetailCompetitionsPage/DetailCompetitionsPage";
import SpinnerComponent from "./components/Spinner/index";
import BestPracticeDetail from "./pages/BestPraticeDetail/BestPracticeDetail";
import ShopPage from "./pages/ShopPage/ShopPage";
import TestQuizPage from "./pages/TestQuizPage/TestQuizPage";
import { parentPage } from "./routes/utitlity";
import TestQuizPage_ShowDapAn from "./pages/TestQuizPage_ShowDapAn/TestQuizPage_ShowDapAn";
import TuongNhaPage from "./pages/TuongNhaPage/TuongNhaPage";
import { createBrowserHistory } from "history";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import HomeSidebar from "./template/HomeSidebar";

import { Toaster } from "react-hot-toast";
import TaskPageTest from "./pages/TaskPage/TaskPageTest";
import { useEffect, useState } from "react";
import DanhSachChat from "./components/DanhSachChat/DanhSachChat";
import LoginPageEmail from "./pages/LoginPageEmail/LoginPageEmail";
import DetailWall from "./pages/TuongNhaPage/DetailWall/DetailWall";
import OnlineTimeTracker from "./components/TimeTracker/TimeTracker";
import { BlogPage } from "./pages/BlogPage/BlogPage";
import WorkShop from "./pages/WorkShop/WorkShop";
import "antd/dist/antd.css";
import "./index.css";
import { withAuth } from "./hook/auth";
import LoginPageDemo from "./pages/LoginPage/LoginHocDemo";
import ListService from "./pages/ServicePakages/ListService";
import ConfirmPayment from "./pages/ServicePakages/ConfirmPayment";
import TaskPageFree from "./pages/TaskPage/TaskPageFree";
import HomeSidebarFree from "./template/HomeSideBarFree";
import { selectThongTinMoRong } from "./redux/reducer/authReducer";
import PaymentSuccess from "./pages/ServicePakages/PaymentSuccess";
import ViewPackage from "./pages/ServicePakages/ViewPackage/ViewPackage";

import 'video-react/dist/video-react.css'
import SignUpPageFree from "./pages/SignUp/SignUpPageFree";
import FormTuVan from "./pages/SignUp/FormTuVan";

import MessengerCustomerChat from 'react-messenger-customer-chat';

import * as signalR from '@microsoft/signalr';
import { API_URL_DOMAIN } from "./utils/urlDomain";
import { BASE_URL_CODINGCAMP } from "./services/axios.service";
export const history = createBrowserHistory();
export const connection = new signalR.HubConnectionBuilder().withUrl(`${BASE_URL_CODINGCAMP}/chat`).withAutomaticReconnect().build();
connection.start().then(() => { }).catch(err=>{
  // console.log(err)
 })
function App() {
  const theme = useSelector((state) => state.theme.theme);
  const [open, setOpen] = useState(false);


  const { userInfo } = useSelector(selectThongTinMoRong);
  const closeDrawer = () => {
    setOpen(false);
  };
  const showDrawer = () => {
    setOpen(true)
  }

  return (
    <div className="backgoundTemplate bg-cover  ">
   
   
      <MessengerCustomerChat
        pageId="231169113737422"
        appId="303284849320308"
      />

      <OnlineTimeTracker />
      {/* <SpinnerComponent /> */}

      <Toaster position="top-right" />

      {userInfo && <DanhSachChat open={open} closeDrawer={closeDrawer} />}
      {/* Tắt để làm responsive */}
      {/* <Upgrade_Notify_Page /> */}

      <HistoryRouter history={history}>

        <Routes>


          <Route path="" element={<HomeTemplate showDrawer={showDrawer} />}>
            <Route path="/" element={<Navigate to="/dash-broad" replace />} />
            <Route path="/dash-broad" element={withAuth(DaskBroad)} />
            
            <Route path="/dash-broad/:id" element={withAuth(DaskBroad)} />

            <Route path="/discussion" element={withAuth(Discussion)} />
            <Route path="/run-code" element={withAuth(Runcode)} />
            <Route path="/learn-video" element={withAuth(LearnVideoPage)} />
            <Route path="/shop-page" element={withAuth(ShopPage)} />

            {/* new page */}
          </Route>
          <Route path="/" element={<HomeSidebar showDrawer={showDrawer} />}>
            <Route path="/blog/:page/:chuyenDeId" element={<BlogPage />} />
            <Route path="/workshop/:chuyenDeId" element={<WorkShop />} />
            <Route path="/task-page-test/:chuyenDeId" element={withAuth(TaskPageTest)} />
            <Route path="/task-page/:chuyenDeId" element={withAuth(TaskPage)} />
            <Route
              path={`/${parentPage.task}/learn-video/:chuyenDeId/:monHocId/:taskId`}
              element={withAuth(LearnVideoPage)}
            />
            <Route
              path={`/${parentPage.task}/detai-screen-quiz/:chuyenDeId/:monHocId/:taskId`}
              element={withAuth(DetailScreenQuizz)}
            />
            <Route path={`/all-practice`} element={withAuth(PracticePage)} />
            <Route
              path={`/${parentPage.task}/detail-screen-capstone/:chuyenDeId/:monHocId/:taskId`}
              element={withAuth(DetailScreenCapstone)}
            />
            <Route path={`/${parentPage.task}/all-document`} element={withAuth(DocumentPage)} />
            <Route
              path={`/${parentPage.task}/detail-page-document/:id`}
              element={withAuth(DetailDocumentPage)}
            />
            <Route
              path={`${parentPage.task}/detail-screen-bai-tap/:chuyenDeId/:monHocId/:taskId`}
              element={withAuth(DetailExercise)}
            />
            <Route
              path={`${parentPage.task}/run-code/:chuyenDeId/:monHocId/:taskId`}
              element={withAuth(Runcode)}
            />
            {/* pratice page */}
            <Route path={`${parentPage.pratice}/:chuyenDeId`} element={withAuth(PracticePage)} />
            <Route path={`${parentPage.pratice}/task-page`} element={withAuth(TaskPage)} />
            <Route path={`${parentPage.pratice}/learn-video`} element={withAuth(LearnVideoPage)} />
            <Route
              path={`${parentPage.pratice}/detai-screen-quiz/:chuyenDeId`}
              element={withAuth(DetailScreenQuizz)}
            />
            <Route
              path={`${parentPage.pratice}/detail-screen-capstone/:chuyenDeId`}
              element={withAuth(DetailScreenCapstone)}
            />
            <Route path={`${parentPage.pratice}/all-document`} element={withAuth(DocumentPage)} />
            <Route
              path={`${parentPage.pratice}/detail-page-document/:id`}
              element={withAuth(DetailDocumentPage)}
            />
            <Route
              path={`${parentPage.pratice}/detail-screen-bai-tap/:chuyenDeId`}
              element={withAuth(DetailExercise)}
            />
            <Route
              path={`${parentPage.pratice}/run-code/:chuyenDeId`}
              element={withAuth(Runcode)}
            />
            {/* best practices */}
            <Route path={"/detail-best-pratice/:id"} element={withAuth(BestPracticeDetail)} />
            <Route path={`/all-best-practice/:chuyenDeId`} element={withAuth(BestPracticePage)} />
            <Route path={"/detail-screen-competitions"} element={withAuth(CompetitionsPage)} />
            <Route path="/all-competitions" element={withAuth(DetailCompetitionsPage)} />
            <Route path="/detail-screen-live-event" element={withAuth(LiveEventPage)} />
          </Route>
          <Route element={<HomeSidebarFree showDrawer={showDrawer} />}>
            <Route path="/task-page-free/:chuyenDeId" element={<TaskPageFree />} />
          </Route>
          <Route path="demo" element={<LoginPageDemo />} />

          <Route path="/quiz-test" element={<TestQuizPage />} />
          <Route path="signup" element={<SignUpPage />} />
          <Route path="signup-user" element={<SignUpPageFree />} />
          <Route path="form-tuvan" element={<FormTuVan />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/login-email" element={<LoginPageEmail />} />
          <Route path="/quiz_dap_an" element={<TestQuizPage_ShowDapAn />} />
          {/* tường nhà */}
          <Route path="/profile/:userId" element={<TuongNhaPage />} />
          <Route path="/wall/:id/:userId" element={<DetailWall />} />
          {/* gói dịch vụ */}
          <Route path="/service" element={<ListService />} />
          <Route path="/confirm-payment" element={<ConfirmPayment />} />
          <Route path="/success" element={<PaymentSuccess />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          {/* package */}
          <Route path="/package" element={<ViewPackage />} />
        </Routes>


      </HistoryRouter>
    </div>
  );
}

export default App;
import React from "react";
import { selectThongTinMoRong } from "../../../redux/reducer/authReducer";
import { useSelector } from "react-redux";
import ModalWrapper from "../../../components/ModalWrapper/ModalWrapper";
import { getHinhAnh } from "../../../utils/GetHinhanh";
import { useTranslation } from "react-i18next";
export default function ThanhTichDashBoard() {
  const { danhSachThanhTich } = useSelector(selectThongTinMoRong);

  // const renderThanhTich = () => {
  //   if (danhSachThanhTich.length == 0) {
  //     return <p className="text-content">Bạn chưa có thành tích </p>;
  //   }
  //   return danhSachThanhTich?.map(({ hinhAnh, tenThanhTich, moTa }) => {
  //     let icon = (
  //       <div className="space-y-2 text-sub-title card-theme p-2 rounded-lg flex flex-col items-center">
  //         <img src={getHinhAnh(hinhAnh)} className="w-1/3" alt="" />

  //         <span className="text-center text-xs´">{tenThanhTich}</span>
  //       </div>
  //     );
  //     return <ModalWrapper icon={icon} content={moTa} />;
  //   });
  // };
  const renderThanhTich = () => {
    if (danhSachThanhTich.length === 0) {
      return <p className="text-content">Bạn chưa có thành tích </p>;
    }
  
    return danhSachThanhTich.map(({ hinhAnh, tenThanhTich, moTa }, index) => {
      let icon = (
        <div className="space-y-2 text-sub-title card-theme p-2 rounded-lg flex flex-col items-center">
          <img src={getHinhAnh(hinhAnh)} className="w-1/3" alt="" />
          <span className="text-center text-xs">{tenThanhTich}</span>
        </div>
      );
  
      // Assuming `moTa` is the content to display in the modal
      let content = (
        <div>
          <p>{moTa}</p>
        </div>
      );
  
      return <ModalWrapper key={index} icon={icon} content={content} />;
    });
  };
  const { t } = useTranslation()

  return (
    <div className=" card-theme w-full">
      <div className="c-card-content flex items-center justify-between xl:flex-col xl:space-y-2">
        <p className="text-title space-x-2 capitalize w-full text-left">
          <i className="fa fa-medal text-white text-2xl"></i>
          <span className="capitalize">{t('trangChu.thanhTich')}</span>
        </p>
      </div>
      <div className="c-card-content  grid grid-cols-2 xl:grid-cols-2 gap-4">
        {renderThanhTich()}
      </div>
    </div>
  );
}

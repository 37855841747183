import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Select, Radio, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import {
  setCurrentStep,
  setUserInfor,
} from "../../../redux/reducer/signUpReducer";
import httpServ from "../../../services/http.service";
import servCodingCamp from "../../../services/codingCam.service";
import localStorageServ from "../../../services/locaStorage.service";
import axios from "axios";
import { history } from "../../../App";
import useWindowSize from "../../../hook/useWindowSize";
import CustomInput from "../../../components/CustomInput/CustomInput";
export default function FormDieuKhoanPhone({ handleOpenModal }) {
  // start
  
  const { widthWindow, heightWindow } = useWindowSize();
  const isPhone = widthWindow< 768?true:false
  const regisPackage = localStorageServ.regisPackage.get();
  const khoa_hoc = localStorageServ.khoahoc.get();
  const [congViecHienTai, setCongViecHienTai] = useState("SV");
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      congViecHienTai1: "SV",
      luongMongMuon: 0,
      // hoTenPH: "",
      // emailPH: "",
      // emailPH: "",
    });
  }, []);
  // end
  const { Option } = Select;
  let { currentStep } = useSelector((state) => state.signUp);
  const { userInfo } = useSelector((state) => state.signUp);
  const onFinish = (values) => {
    let newUserInfor = JSON.parse(JSON.stringify(userInfo));

    let sdt = values.soDT;
    if (sdt.length > 0 && sdt[0] === "+") {
      sdt = sdt.split(""); // or newStr = [...str];
      sdt.splice(0, 1);

      sdt = sdt.join("");
    }
    newUserInfor.maGioiThieu = localStorage.getItem("maGioiThieu")?localStorage.getItem("maGioiThieu"):""
    newUserInfor.email = values.email;
    newUserInfor.hoTen = values.hoTen;
    newUserInfor.soDT = sdt;
    newUserInfor.thongTinMoRong.noiCongTacHienTai = values.noiCongTac;
    newUserInfor.thongTinMoRong.congViecHienTai = JSON.stringify([
      values.congViecHienTai1,
      values.congViecHienTai2,
      values.congViecHienTai3,
    ]);
    // thong tin phụ huynh
    if (values.hoTenPH) {
      newUserInfor.thongTinMoRong.hoTenPH = values.hoTenPH;
      newUserInfor.thongTinMoRong.emailPH = values.emailPH;
      newUserInfor.thongTinMoRong.soDTPH = values.soDTPH;
    }

    httpServ
      .getCheckGmail(values.email)
      .then((res) => {
        if (res.data.content) {
          message.error("Email đã tồn tại");
        } else {
          httpServ
            .getCheckSDT(sdt)
            .then((res) => {
              if (res.data.content) {
                message.error("Số điện thoại đã tồn tại");
              } else {
                // dispatch(setUserInfor(newUserInfor));
                const obj2 = {
                  "id": 0,
                  "hoTen": newUserInfor.hoTen,
                  "email": newUserInfor.email,
                  "soDT": newUserInfor.soDT,
                  "diaDiem": khoa_hoc?khoa_hoc:"Học free",
                  "loaiForm": "codezuni"
                }
                axios.post("https://apicrm.cybersoft.edu.vn/api/leadform", obj2, {
                  headers: {
                    Authorization:
                      "Bearer eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL2V4cGlyZWQiOiI0LzE1LzIwNDcgNDowMjowOCBQTSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWUiOiIxZDcxYmZlMi1jOWQ0LTQxMTEtYjZmMy1mOTJlZGVmZDgxYjIiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9lbWFpbGFkZHJlc3MiOiJwaHVvbmdhMHBuQGdtYWlsLmNvbSIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6WyJRTF9CTSIsIkNIRUNLX01FTlRPUl9MT1AiLCJSX0xPUCIsIlFMX0dDIiwiUUxfQ0hUTCIsIlFMX1JNIiwiUUxfVEtEIiwiUUxfTENOIiwiRF9CVCIsIkZfR0MiLCJSX1ZMIiwiUl9CSCIsIkZfTE9QIiwiQU5ZIiwiRF9ORCIsIlVfTkQiLCJDX05EIiwiUl9ORCIsIkRfS0giLCJVX0tIIiwiQ19LSCIsIlJfS0giLCJHRF9MSCIsIlhfS0hfTCIsIkRfREFOSCIsIlFMX0NMIiwiUUxfQkwiLCJRTF9MVCIsIlFMX0tIIiwiQ19MT1AiLCJVX0xPUCIsIktfQ0hFQ0tfTCIsIlFMX0NDTiIsIlFMX0tLSFYiLCJVX05HQVlfQkgiLCJVX0hBTl9CVCIsIlFMX0hNVCIsIkRLX01UIiwiWF9MRk9STSJdLCJuYmYiOjE3MTMxNzE3MjgsImV4cCI6MTcxMzE3NTMyOH0.84tVM6RM1yIamp-ma1BpiTAysbj1YY99l4TEkyHcMSw",
                  },
                });
                servCodingCamp.postDangKyUserV2(newUserInfor).then((res) => {
                  localStorageServ.inforSignUp.set(res.data.content);
                  dispatch(setCurrentStep(1));
                  
                  if (regisPackage && regisPackage != 0) {
                    history.push("/confirm-payment");
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
              }
            })
            .catch((err) => {
              // console.log("yes");
            });
        }
      })
      .catch((err) => {});
  };
  const dispatch = useDispatch();

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const [thanks, setThanks] = useState(true);
  const [isVietNam, setIsVietNam] = useState(true);
  const handleNoiHocTap = (value) => {
    value === "vn" ? setIsVietNam(true) : setIsVietNam(false);
  };
  const handleClick = () => {
    setThanks(false)
    const currentUrl = new URL(window.location.href);
    const linkMoTa = currentUrl.searchParams.get('linkMoTa');
    if (linkMoTa) {
      if (window.innerWidth > 768) {
        window.open(linkMoTa, '_blank');
      }
    }
  }
  const handleValidateEmail = (_, value, callback) => {
    httpServ
      .getCheckGmail(value)
      .then((res) => {
        if (res.data.content) {
          message.error("Email đã tồn tại");
        }
      })
      .catch((err) => {
        httpServ
          .getCheckSDT(value)
          .then((res) => {
            if (res.data.content) {
              message.error("Số điện thoại đã tồn tại");
            }
          })
          .catch((err) => {
            // console.log("yes");
          });
      });
  };
  const handleValidateSDT = (_, value, callback) => {
    httpServ
      .getCheckSDT(value)
      .then((res) => {
        if (res.data.content) {
          callback("Số điện thoại đã tồn tại");
        }
      })
      .catch((err) => {
        callback();
      });
  };
  let subFormHocVien = (
    <>
      <Form.Item
        // label="Họ và tên"
        name="hoTen"
        rules={[{ required: true, message: "Trường này không được để trống" }]}
      >
        <CustomInput placeholder="Họ và tên"/>
      </Form.Item>
      <Form.Item
        // label="Email"
        name="email"
        rules={[
          {
            type: "email",
            message: "Email không đúng định dạng",
          },
          {
            required: true,
            message: "Trường này không được để trống",
          },
        ]}
      >
        {/* <Input /> */}
        <CustomInput placeholder="Email" />
      </Form.Item>
      <Form.Item
        // label="Số ĐT"
        name="soDT"
        rules={[
          { required: true, message: "Trường này không được để trống" },
          // { type: "number", message: "Số điện thoại phải là chữ số" },
          () => ({
            validator(_, value) {
              if (isNaN(value)) {
                return Promise.reject("Số điện thoại phải là chữ số");
              }

              return Promise.resolve();
            },
          }),
          {
            max: 15,
            message: "Số điện thoại phải có độ dài từ 9 - 15",
          },
          {
            min: 9,
            message: "Số điện thoại phải có độ dài từ 9 - 15",
          },
        ]}
      >
        <CustomInput placeholder="Số DT"/>
      </Form.Item>
    </>
  );
  let renderFormPhuHuynh = () => {
    if (congViecHienTai == "C2" || congViecHienTai == "C3") {
      return (
        <>
          <p className="text-white py-2">
            {" "}
            Bạn vui lòng cung cấp thông tin phụ huynh của bạn để CodeZuni thông báo
            thông tin học tập của bạn đến Phụ huynh khi cần nhé ( Sau đây gọi
            tắt là Phụ Huynh ) :
          </p>

          <Form.Item
            name="hoTenPH"
            rules={[
              {
                required: true,
                message: "Trường này không được để trống",
              },
              { message: "Trường này không được để trống" },
            ]}
          >
            <CustomInput placeholder="Họ và tên"/>
          </Form.Item>
          <Form.Item
            name="emailPH"
            rules={[
              {
                required: true,
                message: "Trường này không được để trống",
              },
              {
                type: "email",
                message: "Email không đúng định dạng",
              },
              {
                message: "Trường này không được để trống",
              },
            ]}
          >
            <CustomInput placeholder="Email"/>
          </Form.Item>
          <Form.Item
            name="soDTPH"
            rules={[
              {
                required: true,
                message: "Trường này không được để trống",
              },
              { message: "Trường này không được để trống" },
              // { type: "number", message: "Số điện thoại phải là chữ số" },
              () => ({
                validator(_, value) {
                  if (isNaN(value)) {
                    return Promise.reject("Số điện thoại phải là chữ số");
                  }

                  return Promise.resolve();
                },
              }),
              {
                max: 15,
                message: "Số điện thoại phải có độ dài từ 9 - 15",
              },
              {
                min: 9,
                message: "Số điện thoại phải có độ dài từ 9 - 15",
              },
            ]}
          >
            <CustomInput placeholder="Số DT"/>
          </Form.Item>
        </>
      );
    }
  };
  return (
    <div className="w-full flex justify-center flex-col items-center h-full">
      <Form
        layout="vertical"
        name="basic"
        className={`${isPhone?'':'container'}`}
        labelCol={{ sm: { span: 6 }, xl: { span: 4 } }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {/* <Form.Item className="mb-2" label="Bạn đang là:" name="congViecHienTai1" rules={[]}>
          <Radio.Group
            onChange={(e) => {
              setCongViecHienTai(e.target.value);
              // console.log(e.target.value);
            }}
            defaultValue="SV"
          >
            <Radio className="text-white" value="SV">
              Sinh viên CNTT
            </Radio>
            <Radio className="text-white" value="DL">
              CNTT đã đi làm{" "}
            </Radio>
            <Radio className="text-white" value="TN">
              Trái ngành
            </Radio>
            <Radio className="text-white" value="C2">
              Học sinh cấp 2
            </Radio>
            <Radio className="text-white" value="C3">
              Học sinh cấp 3
            </Radio>
          </Radio.Group>
        </Form.Item> */}

        {/* <Form.Item
          rules={[
            { required: true, message: "Trường này không được để trống" },
          ]}
          name="congViecHienTai2"
        >
          <Input
            placeholder={
              congViecHienTai === "SV"
                ? "Bạn học trường nào...?"
                : congViecHienTai === "DL"
                ? "Bạn làm công ty nào...?"
                : congViecHienTai == "TN"
                ? "Bạn làm ngành gì...?"
                : "Bạn học trường nào?"
            }
          />
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: "Trường này không được để trống" },
          ]}
          name="congViecHienTai3"
        >
          <Input
            placeholder={
              congViecHienTai === "SV"
                ? "Bạn là sinh viên năm mấy...?"
                : congViecHienTai === "DL"
                ? "Bao nhiêu năm kinh nghiệm...?"
                : congViecHienTai == "TN"
                ? "Đã làm bao lâu...?"
                : "Bạn học lớp mấy?"
            }
          />
        </Form.Item> */}
        {renderFormPhuHuynh()}

        {/* from phụ huynh */}
        <h4 className="text-white my-1">
          Thông tin học viên ( Sau đây gọi tắt là Học Viên ):
        </h4>
        {subFormHocVien}
        <Form.Item className="mb-0" label="Nơi học tập/ làm việc">
          <Input.Group compact>
            <Select
              onChange={handleNoiHocTap}
              defaultValue="Việt Nam"
              style={{ width: 'auto' }}
            >
              <Option value="vn">Việt Nam</Option>
              <Option value="tg">Quốc gia khác</Option>
            </Select>
            {isVietNam ? (
              <Form.Item
                name="noiCongTac"
                rules={[
                  {
                    required: true,
                    message: "Trường này không được để trống",
                  },
                ]}
              >
                <Select placeholder="Nơi ở/ làm việc">
                  <Option value="Hồ Chí Minh">Hồ Chí Minh</Option>
                  <Option value="Hà Nội">Hà Nội</Option>
                  <Option value="Cần Thơ">Cần Thơ</Option>
                  <Option value="Nha Trang">Nha Trang</Option>
                  <Option value="Huế">Huế </Option>
                  <Option value="Vinh">Vinh</Option>
                  <Option value="Khác">Khác</Option>
                </Select>
              </Form.Item>
            ) : (
              <Form.Item
                name="noiCongTac"
                rules={[
                  {
                    required: true,
                    message: "Trường này không được để trống",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
          </Input.Group>
        </Form.Item>

        {/* <Form.Item label="Mã giới thiệu/Ưu đãi" name="maGioiThieu">
          <Input defaultValue="" />
        </Form.Item> */}
        <Form.Item
          name="agreement"
          label=""
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: "Hãy đọc và đồng ý các điều khoản của CodeZuni",
            },
          ]}
        >
          <Checkbox className="text-white">
            Tôi đồng ý với các{" "}
            <span
              onClick={() => {
                handleOpenModal();
              }}
              className="text-yellow-400 underline cursor-pointer"
            >
              ĐIỀU KHOẢN
            </span>{" "}
            của CodeZuni
          </Checkbox>
        </Form.Item>
        <Form.Item
          wrapperCol={{ sm: { offset: 0 }, md: { offset: 4 }, span: 16 }}
        >
          <div className="w-full flex justify-center space-x-3 ">
            <Button
              className="px-5 bg-yellow-400 border-none text-black"
              type="primary"
              htmlType="submit"
            >
              Tiếp theo
            </Button>
          </div>
        </Form.Item>
      </Form>
              <p className="cursor-pointer" onClick={() => { 
                history.push('/login')
               }}>Đã có tài khoản. <span className="underline">Đăng nhập</span></p>
      <div></div>
      <Modal
          visible={thanks}
          style={{
            top: 20,
          }}
          closable={false}

          footer={null}
        >
          <div className='text-center'>
            <p >Cảm ơn bạn đã chọn <b>CodeZuni</b>! Bạn vui lòng để lại thông tin để được tư vấn <b>hỗ trợ</b> và <b>nhận ưu đãi</b> nhé</p>
            <button className='mt-2 btn_theme_red' onClick={handleClick}>Tiếp tục</button>
          </div>
        </Modal>
    </div>
  );
}
import React, { Component, useEffect, useRef } from 'react'
import { Player, ControlBar, ReplayControl, PlaybackRateMenuButton } from 'video-react';
import HLSSource from './HLSSource';

import { Anchor } from 'antd';
import useWindowSize from '../../hook/useWindowSize';

export default function VideoPlayer({ source }) {
    const { widthWindow, heightWindow } = useWindowSize();

    const playerRef = useRef(null);
    useEffect(() => {
      if (playerRef.current) {
        playerRef.current.load();
      }
    }, [source]);
    
    return (
        <div >

            {source ?
                <Player playsInline fluid={false} width={"100%"} height={widthWindow<768?300:550}>

                    <HLSSource
                        isVideoChild
                        src={source && source}
                    />
                    <ControlBar>
                        <PlaybackRateMenuButton rates={[3, 2.5, 2, 1.5, 1]} order={10} />
                    </ControlBar>

                </Player>
                : ""}

        </div>
    )

}

import React, { useEffect, useState } from 'react'
import TabChat from './TabChat';
import { useSelector } from 'react-redux';


const TabsChat=()=> {
    const { ActiveChat1, ActiveChat2, ActiveCurrent, dataChat1, dataChat2, ActiveChatBot, dataChatBot } =
    useSelector((state) => state.stackChatReducer);
  
    const [numTab,setNumTab]= useState(2);
    const [width,setWidth]=useState(window.innerWidth)

    const renderTab = ()=>{
       return numTab===2?
       <>
           {ActiveChat1&& <TabChat activeChat={ActiveChat1} data={dataChat1} current={false} key={'chat'+ 1}/>}
           {ActiveChat2&& <TabChat activeChat={ActiveChat2}  data={dataChat2} current={true} key={'chat'+ 2}/>}
           {ActiveChatBot&& <TabChat activeChat={ActiveChatBot}  data={dataChatBot} current={false} key={'chat'+ 3}/>}
       </>
       :<>
             <TabChat activeChat={ActiveChat1}  key={'chat'+ 1}/>
       </>
    }

    
   useEffect(()=>{
    setWidth(window.innerWidth)

    const resize = ()=>{
        setWidth(window.innerWidth)
        if(window.innerWidth>992){
            setNumTab(2)
        }
        else {
            setNumTab(1)
        }
    }
    window.onresize = resize
    return () => {
        window.removeEventListener('resize',resize);
    }
   }) 

  return (
    <div className='TabsChat'>
        {renderTab()}
    </div>
  )
}

export default TabsChat
import React, { useEffect } from "react";
import "./style.css";
import { Avatar, Button, Collapse, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getDsGroupForTaskHocVienApi } from "../../redux/reducer/groupForTaskReducer";
import {
  ChangeActiveChat1,
  ChangeActiveChat2,
  changeCurrentActive,

  getAllUserInChuyenDeApi,
  updateDataChat1,
  updateDataChat2,
} from "../../redux/reducer/stackChatReducer";
import { config } from "../../utils/config";
import { BASE_URL_CODINGCAMP } from "../../services/axios.service";
import { useTranslation } from "react-i18next";
import { getChatBot } from "../../redux/reducer/groupChatReducer";

const { Panel } = Collapse;
export const areObjectsEqual = (obj1, obj2) => {
  if (obj1 === obj2) {
    return true; // Cùng một tham chiếu đến đối tượng
  }

  if (typeof obj1 !== "object" || typeof obj2 !== "object") {
    return false; // Một trong hai không phải là đối tượng
  }

  if (obj1 === null || obj2 === null) {
    return false; // Một trong hai là null
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false; // Số lượng thuộc tính khác nhau
  }

  for (let key of keys1) {
    if (!areObjectsEqual(obj1[key], obj2[key])) {
      return false; // Kiểm tra đệ quy cho các thuộc tính lồng nhau
    }
  }

  return true; // Nếu tất cả các kiểm tra trước đó không trả về false, thì hai đối tượng giống nhau.
};
export const isObjectInArray = (array, element) => {
  for (const item of array) {
    if (areObjectsEqual(item, element)) {
      return true;
    }
  }
  return false;
};
export const findObjectIndexInArray = (array, element) => {
  return array.findIndex(item => areObjectsEqual(item, element));
};
const ColorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];
// Tạo một số nguyên ngẫu nhiên từ 0 đến 3
const randomNumber = Math.floor(Math.random() * ColorList.length); // 0, 1, 2 hoặc 3

const DanhSachChat = ({ open, closeDrawer }) => {
  const dispatch = useDispatch();
  const { ActiveChat1, ActiveChat2, ActiveCurrent, dataChat1, dataChat2 } =
    useSelector((state) => state.stackChatReducer);

  const { issueHocVienTheoTungChuyenDe } = useSelector((state) => state.issue);
  const { DSChuyenDeHocVienDangKy } = useSelector((state) => state.chuyenDe);
  const { dsGroupForTaskHocVien } = useSelector((state) => state.groupForTask);
  const styleDraw = {
    animation: open ? "" : "",
  };



  const PushDataTabChat = (e) => {
    if (areObjectsEqual(e, dataChat1)) {
      !ActiveChat1 && dispatch(ChangeActiveChat1());
    } else if (areObjectsEqual(e, dataChat2)) {
      !ActiveChat2 && dispatch(ChangeActiveChat2());
    } else {
      if (ActiveCurrent) {
        dispatch(updateDataChat2(e));
        dispatch(ChangeActiveChat2());
        dispatch(changeCurrentActive());
      } else {
        dispatch(updateDataChat1(e));
        dispatch(ChangeActiveChat1());
        dispatch(changeCurrentActive());
      }
    }


  };

  const handleClickGroupChuyenDe = (e) => {
    // if (e == -1) {
    //   message.info("Vui lòng tạo issue để tiếp tục chat trong chuyên đề này")
    // }

    const index = issueHocVienTheoTungChuyenDe?.findIndex(
      (issue) => issue?.chuyenDeId == e?.id)
    if (index == -1 && e.dsMentor) { message.info("Vui lòng gửi tin nhắn để tạo issue mới") }
    PushDataTabChat(e);
    const danhSachPopup = config.getStoreJson("LIST_POPUP");
    if (danhSachPopup) {

      if (!isObjectInArray(danhSachPopup, e)) {

        
        danhSachPopup.unshift(e);
      }
      if (danhSachPopup.length > 4) {

        danhSachPopup.pop()
      }
      config.setStoreJson("LIST_POPUP", [...danhSachPopup]);
    }
    else
      config.setStoreJson("LIST_POPUP", [e]);




    //CheckTab

    // Nếu ko có issue
    //Đóng draw
    closeDrawer();

  };
  // if(open)
  //The goupTask
  const renderDsChatG = () => {
    return dsGroupForTaskHocVien?.map((e, i) => {
      return (
        <div
          onClick={() => {
            handleClickGroupChuyenDe(e);
          }}
          key={i + " draw_group_chat_item"}
          className="draw_group_chat_item p-2 flex gap-2 items-center"
        >

          <img src={`https://ui-avatars.com/api/?name=${e?.tenBaiHoc}&background=random&bold=true`}
            className="w-10 h-10 object-cover rounded-full mr-2"
            alt=""
          />
          <div className="flex flex-col gap-1">
            <p>Chuyên Đề : {e?.tenChuyenDe}</p>
            {e.tenBaiHoc && <p>{`Task: ${e.tenBaiHoc}`}</p>}
          </div>
        </div>
      );
    });
  };
  //Theo tung chuyen de
  const renderDsChatCd = () => {
    return DSChuyenDeHocVienDangKy?.map((e, i) => {
      const index = issueHocVienTheoTungChuyenDe?.findIndex(
        (issue) => issue?.chuyenDeId == e?.id
      );
      return (
        <div
          onClick={() => {
            handleClickGroupChuyenDe(e)
          }}
          key={i + " draw_group_chat_item"}
          className="draw_group_chat_item p-2 flex gap-2 items-center"
        >
          <img
            style={{ borderRadius: '100rem', width: '35px', height: '35px' }}
            className="shape-avatar me-1"
            shape="square"
            size={40}
            src={`https://apimentor11.cybersoft.edu.vn${e?.hinhAnh}`}
          />

          <div className="flex flex-col gap-1">
            <p>Chuyên Đề : {e?.tenChuyenDe}</p>
            {index >= 0 ? (
              <p>Issue: {issueHocVienTheoTungChuyenDe[index]?.title}</p>
            ) : (
              <></>
            )}
          </div>
        </div >
      );
    });
  };

  useEffect(() => {
    dispatch(getDsGroupForTaskHocVienApi());
    dispatch(getAllUserInChuyenDeApi());
    dispatch(getChatBot())
  }, []);
  const { t } = useTranslation()

  return (
    <div
      className={`danhsachchat`}
      style={{
        zIndex: open ? "10000" : "-1",
        opacity: open ? "1" : "0",
      }}
    >
      <div
        style={{
          opacity: open ? "1" : "0",
          display: open ? "block" : "none",
          transition: "all 1s",
        }}
        className="overLay_dschat"
        onClick={closeDrawer}
      >
        DanhSachChat
      </div>
      <div
        className={`draw_dsChat`}
        style={{
          transform: open ? "translateX(0)" : "translateX(100%)",
          transition: "all 0.3s",
        }}
      >
        <div className="draw_dsChat_head text-lg flex justify-between py-1 px-2">
          <h5 className="font-bold text-blue-500">{t('drawChat.danhSachChat')}</h5>
          <button
            className="flex justify-center text-blue-500   items-center border-0 btn btn-sm btn-outline-light"
            onClick={closeDrawer}
          >
            <CloseOutlined />
          </button>
        </div>
        <div className="draw_dsChat_content text-lg font-bold text-[#7E65D0]">
          <Collapse
            accordion
            defaultActiveKey={
              DSChuyenDeHocVienDangKy && DSChuyenDeHocVienDangKy?.length > 0
                ? ["1"]
                : []
            }
          >
            {DSChuyenDeHocVienDangKy && DSChuyenDeHocVienDangKy?.length > 0 ? (
              <Panel
                className="draw_group_chat "
                header={t('drawChat.danhSachTheoChuyenDe')}
                key="1"

              >
                <div>{renderDsChatCd()}</div>
              </Panel>
            ) : (
              <></>
            )}
            {dsGroupForTaskHocVien && dsGroupForTaskHocVien?.length > 0 ? (
              <Panel
                className="draw_group_chat"
                header={t('drawChat.danhSachTheoTask')}
                key="2"
              >
                <div>{renderDsChatG()}</div>
              </Panel>
            ) : (
              <></>
            )}
          </Collapse>
        </div>
      </div>
    </div>
  );
  // else
  // return <></>
};

export default DanhSachChat;

import { http } from "../utils/config";

const GET_PATH = "/issue";

const getAllIssue = () => {
  return http.get(GET_PATH);
};

const getIssueById = (id) => {
  return http.get(`${GET_PATH}/${id}`);
};

const postIssue = (data) => {
  return http.post(`${GET_PATH}`, data);
};

const putIssue = (id,data) => {
  return http.put(`${GET_PATH}/putoptional/${id}`,data);
};

const delIssue = (id) => {
  return http.delete(`${GET_PATH}/${id}`);
};

const getAllIssueByMentor = (mentorId) => {
  return http.get(`${GET_PATH}/getbymentor/${mentorId}`);
};

const getIssueHocVienTheoTungChuyenDeService=()=>{
  return http.get(GET_PATH+'/getbyhocvien');
}

export const issueService = {
  getAllIssue,
  getIssueById,
  postIssue,
  putIssue,
  delIssue,
  getAllIssueByMentor,
  getIssueHocVienTheoTungChuyenDeService
};

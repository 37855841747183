import React, { useState, useEffect } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";
import TabSubContentQuiz from "./TabSubContentQuiz";
import { SingleChoice_Answer } from "../../../model/QuizzModel";
const entities = require("entities");

const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export default function Practices_SingleChoice({ question, handle_CheckSingleChoice,visited }) {
  const noiDung = entities.decodeHTML(question.noiDung.inPut);
  const answers = question.noiDung.cauTraLoi.map((item, index) => {
    // console.log("😀 - answers - item", item);
    return new SingleChoice_Answer(item.ma, item.ma, item.noiDung);
  });
  const [activeBtnArr, setActiveBtnArr] = useState(
    question.userAnsers
    // []
  );
  // console.log("😀 - Practices_SingleChoice - activeBtnArr", activeBtnArr);
  useEffect(() => {
    setActiveBtnArr(question.userAnsers);
  }, [question.id]);
  const handle_ChooseAnser = () => {
    // if (activeBtnArr.length === 1) {
    //   // console.log("yes 1 choice");
    //   handle_CheckSingleChoice(question.id, activeBtnArr[0].luaChon, activeBtnArr);
    // } else {
    handle_CheckSingleChoice(question.id, activeBtnArr);
    // }
  };
  useEffect(() => {
    handle_ChooseAnser();
  }, [activeBtnArr]);

  const contentAnserOption = (
    <div className="w-full h-max-content grid auto-rows-auto grid-auto-rows gap-5 grid-cols-2">
      {answers.map((item, index) => {
        let activeCss = "";
        let result = activeBtnArr.find((activeItem) => {
          return activeItem.id === item.id;
        });
        if (result) {
          activeCss = "bg-blue-600 border-blue-600 border-white text-white";
        } else {
          activeCss = " ";
        }

        return (
          <div
            className={
              `text-content  w-full ${!visited?"cursor-pointer":"cursor-not-allowed"}  flex  shadow-lg  items-center space-x-5  rounded-lg transition   card-theme p-5 text-black  py-2 hover:shadow-xl duration-200 `
            }
            onClick={() => {
              if(!visited){
                let index = activeBtnArr.findIndex((activeBtn) => {
                  return activeBtn.id === item.id;
                });
                if (index !== -1) {
                  let temp = [...activeBtnArr];
                  temp.splice(index, 1);
                  setActiveBtnArr(temp);
                } else {
                  setActiveBtnArr([item]);
                }
              }
            }}
          >
            <span
              className={
                "h-9 border-2 flex-shrink-0  border-white w-9 text-center font-bold leading-8  rounded-full " +
                activeCss
              }
            >
              {alphabet[index]}
            </span>
            <p className="text-sm ">{item.noiDung}</p>
          </div>
        );
      })}
    </div>
  );
  // console.log(answers);
  return (
    <div
      onCopy={(e) => {
        e.preventDefault();
        return false;
      }}
      className="w-full flex-grow flex flex-col  "
    >
      <div className="relative w-full h-full p-3 flex-grow justify-center flex-col space-y-5 ">
        <div className="text-content text____title font-medium">{question.noiDung.tieuDe}</div>
        <div className="w-full rounded-xl  overflow-hidden">
          <SyntaxHighlighter
            language="javascript"
            className="p-10"
            style={dracula}
            codeTagPros={{
              fontSize: "20",
            }}
            customStyle={{
              lineHeight: "0.75",
              fontSize: "1.2em",
            }}
            wrapLines={true}
            lineProps={{
              style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
            }}
          >
            {noiDung}
          </SyntaxHighlighter>
        </div>
        <p className="text-lg text-content capitalize	">{question.noiDung.outPut}</p>
        <TabSubContentQuiz anserOption={contentAnserOption} />
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import SideNavigation from "./SideNavigation/SideNavigation";
import { Outlet } from "react-router-dom";
import HeaderTask from "./HeaderTask/HeaderTask";
import { useSelector } from "react-redux";
import useWindowSize from "../hook/useWindowSize";
import BreadCrumbNav from "../components/BreadCrumbNav/BreadCrumbNav";
import StackChat from "../components/StackChat/StackChat";
import FlashSale from "../components/FlashSale/FlashSale";
import HeaderBottom from "./HeaderBottom/HeaderBottom";
import ZaloBtn from "../components/ZaloOA/ZaloOA";
import FBChat from "../components/FacebookChat/FBChat";
import { connection } from "../App";
import NotificationSound from "../components/NotificaSound/NotificationSound";
import { selectThongTinMoRong } from "../redux/reducer/authReducer";
import localStorageServ from "../services/locaStorage.service";
const { Header, Content, Sider } = Layout;
export const checkChatMess = (content, searchValue) =>
  (Array.isArray(content) && content.includes(searchValue)) ||
  (typeof content === 'string' && content === searchValue);

export default function HomeSidebar(props) {
  const [playSound, setPlaySound] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const theme = useSelector((state) => state.theme.theme);
  let root = document.documentElement;
  let userInfo = localStorageServ.userInfo.get();
  root.classList.add(theme);
  const { widthWindow, heightWindow } = useWindowSize();
  const isPhone = widthWindow < 768 ? true : false;
  const extractLayoutSideBar = (collapsed) => {
    if (widthWindow > 1024 && !collapsed) {
      return 120;
    } else {
      return 40;
    }
  };
  useEffect(() => {
    
    try{
      connection.on('ReceiveMessage', (userM) => {
        if(checkChatMess(userM,userInfo?.id)){
          setPlaySound(true)
        setTimeout(() => setPlaySound(false), 1000)
        }
      })
      connection.on('ReceiveMessage2', (userM) => {
        if(checkChatMess(userM,userInfo?.id)){
          setPlaySound(true)
        setTimeout(() => setPlaySound(false), 1000)
        }
      })
    }catch (error) {
      // success("Bạn vừa kết nối thành công chat !");
    }
}, [connection]);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          background: "black",
          zIndex: 100,
          padding: isPhone ? "0 10px" : "0 15px",
        }}
        className="flex h-16  fixed top-0 left-0 w-full  items-center  justify-between space-x-5"
      >
        <HeaderTask />
      </Header>
      {isPhone && (
        <div
          style={{
            // background:"#fff",
            zIndex: 100,
            // padding: isPhone ? "0 10px" : "",
          }}
          className="flex h-16  fixed bottom-0 left-0 w-full  items-center  justify-between space-x-5"
        >
          <HeaderBottom />
        </div>
      )}
      <Layout>
        {isPhone ? (
          ""
        ) : (
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={extractLayoutSideBar(collapsed)}
            className=" fixed top-20 left-0 "
          >
            <SideNavigation isCollapsed={collapsed} />
          </Sider>
        )}

        <Layout
          style={{
            margin: `0px 0 0 ${isPhone?0:extractLayoutSideBar(collapsed)}px`,
            paddingTop: "64px",
          }}
          className="bg-content"
        >
          <Content className="  relative">
            <div className="pt-8">
              <BreadCrumbNav />
              <div className={`${isPhone?"phone":""} page_container`}>
                <Outlet />
                <ZaloBtn/>
                <FBChat/>
                {isPhone ? (
                  ""
                ) : (
                  <StackChat showDrawer={props.showDrawer} />
                )}
              </div>
            </div>
          </Content>
          <FlashSale/>
        </Layout>
                <NotificationSound playSound={playSound} />
      </Layout>
    </Layout>
  );
}

import React, { useEffect, useState } from "react";
import localStorageServ from "../../services/locaStorage.service";
import servCodingCamp from "../../services/codingCam.service";
import { Spin, Tabs } from "antd";
import TabTuongNha from "./TabTuongNha";
import "./index.scss";
import Avatar from "./AvatarWall/AvatarWall";
import GuestHeader from "./GuestHeader";
import Header from "./Header";
import { useNavigate, useParams } from "react-router-dom";
import { setListThanhTich, setPersonalState } from "./tuongNhaSlice";
import { useDispatch } from "react-redux";
import useWindowSize from "../../hook/useWindowSize";
export default function TuongNhaPage() {
  const [loading, setLoading] = useState(false);
  let isLogin = localStorageServ.userInfo.get();
  let { userId } = useParams();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { widthWindow, heightWindow } = useWindowSize();
  const isPhone = widthWindow<768?true:false
  useEffect(() => {
    setLoading(true);
    servCodingCamp
      .getThongTinTuongNha(userId, 1)
      .then((res) => {
        console.log("😀 - .then - res", res);
        dispatch(setPersonalState(res.data.content));
        dispatch(setListThanhTich(res.data.content.danhSachThanhTich));
        setLoading(false);
      })
      .catch((err) => {
        // toast.error("Đã có lỗi xảy ra");
        setLoading(false);
        navigate("/");
      });
  }, [userId]);
  return (
    <div id="profilePage" className={`c-card-content min-h-screen pt-24 ${isPhone?'px-3':'px-20'}`}>
      {isLogin ? <Header /> : <GuestHeader />}
      <Spin spinning={loading} tip="Loading...">
        <Avatar />
      </Spin>
      <TabTuongNha />
    </div>
  );
}

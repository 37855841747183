import React, { useState } from "react";
import { Modal } from "antd";
import "./ModalContentIcon.css";
import { useTranslation } from "react-i18next";
export default function ModelContentIcon({ loai }) {
  const { t, i18n } = useTranslation()

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div id="tooltip_modal " className="w-full">
      <div
        className="cursor-pointer flex flex-col justify-center w-full items-center "
        onClick={showModal}
      >
        {loai.icon}
      </div>
      <Modal
        title=""
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer=""
        bodyStyle={{
          borderRadius: "20px",
          overflow: "hidden",
        }}
        style={{
          borderRadius: "20px",
          maxWidth: "700px",
          minWidth: "200px",
        }}
      >
        <div className="flex space-x-5  items-center ">
          <div className="h-full w-48 flex items-center justify-center flex-shrink-0">
            {" "}
            {loai.title}
          </div>
          {/* <div className="c-card-content text-3xl  w-full">{loai.title}</div> */}
          <div className=" h-full   text-sub-title">
            <h4 className="text-title">{t('header.thongTinVatPham')} :</h4>
            <p>{loai.desc}</p>
          </div>
        </div>
      </Modal>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import HtmlParser from "react-html-parser";
import ButtonNopBai from "../ButtonNopBai/ButtonNopBai";
import { createPayloadNopBai, loaiNhiemVu, markTaskAsCompleted } from "../../utils/taskUtils";
import "./style.scss";
import servCodingCamp from "../../services/codingCam.service";
import { typeTrangThaiNop } from "./util";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import localStorageServ from "../../services/locaStorage.service";
import { useTranslation } from "react-i18next";
import { Button, message, Upload } from 'antd';

import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { API_URL_DOMAIN } from "../../utils/urlDomain";
import ContentTab from "../ContentTab/ContentTab";
import ReactPlayer from "react-player";
import VideoPlayer from "../video/VideoPlayer";

import { navigatePageByTaskNext, parentPage } from "../../routes/utitlity";
import { checkLearning } from "../../utils/HocDemoUtils";
import { useLstLoTrinhDangHoc } from "../../hook/useLstLoTrinhUser";
let inputCaps =
  " bg-gray-50 border border-gray-300 text-gray-900 text-content rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 italic block";


const { Dragger } = Upload;

let styleBtn = {
  left: "1137px",
  top: "658px",
  background:
    "radial-gradient(226.46% 280.26% at 41.98% 268.42%, #FF0C0C 8.4%, #301D09 89.92%) ",
  borderRadius: "11px",
  border: "2px solid #F5BC7A",
};

export default function StepProjectInstruction({
  noiDung,
  orderNumber,
  requireId = 1,
  trangThaiTongBaiTap = {},
  getThongTinBaiTapNop = () => { },
  tongCoin,
  tongExp,
  maLoaiNhiemVu,
  required,
  countRequire
}) {
  const { t } = useTranslation()
  
  const navigate = useNavigate();
  let user = localStorageServ.userInfo.get();
  let indexThongTinNop = trangThaiTongBaiTap.noiDung.findIndex(
    (thongTinNop) => thongTinNop.requireId == required.requireId
  );

  let thongTinBaiTap = trangThaiTongBaiTap?.noiDung[indexThongTinNop] || {};
  let { chuyenDeId, taskId, monHocId } = useParams();
  let { baiLam, diem, nhanXet, trangThai, id: nopBaiId } = thongTinBaiTap;
  let [arrFile,setArrFile] = useState([])
  
  const { data: dataDangHoc } = useLstLoTrinhDangHoc(user?.id);
  const isLearning = checkLearning(chuyenDeId, dataDangHoc);


  // convert heart to input html
  let handleNopLaiBai = () => {
    const inputs = document.querySelectorAll(`#noiDungCaps_${orderNumber} #input_box`);
    // các link input render then trái tim
    const baiLam = [...arrFile];
    let isValid = true;
    Array.from(inputs).forEach((input) => {
      if (input.value.trim() === "") {
        isValid = false;
      }
      baiLam.push(input.value);
    });
    if (isValid == false) {
      toast.error("Bạn vui lòng nộp đầy đủ các link theo yêu cầu");
      return;
    }
    // validate
    let payload = {
      nopBaiId,
      baiLam: JSON.stringify(baiLam),
    };
    servCodingCamp
      .postNopLaiBaiTap(payload)
      .then((res) => {
        getThongTinBaiTapNop();
        toast.success("Bạn đã cập nhật bài tập thành công");
      })
      .catch((err) => {
        
      });
  };
  let handleNopBai = () => {
    const inputs = document.querySelectorAll(`#noiDungCaps_${orderNumber} #input_box`);
    // các link input render then trái tim
    const baiLam = [...arrFile];

    let isValid = true;
    Array.from(inputs).forEach((input) => {

      if (input.value.trim() === "") {
        isValid = false;
      }
      baiLam.push(input.value);
    });
    if (isValid == false) {
      toast.error("Bạn vui lòng nộp đầy đủ các link theo yêu cầu");

      return;
    }

    let payload = createPayloadNopBai(
      taskId,
      maLoaiNhiemVu,
      JSON.stringify(baiLam),
      requireId,
      chuyenDeId,
      monHocId
    );
    console.log("payload", payload)
    servCodingCamp
      .postNopBaiTap(payload)
      .then((res) => {
        getThongTinBaiTapNop();
        toast.success("Bạn đã nộp bài tập thành công!");

        //chuyển trang
        if(maLoaiNhiemVu!=loaiNhiemVu.QUIZ && maLoaiNhiemVu!=loaiNhiemVu.RUNCODE && countRequire==requireId && isLearning ){
          navigatePageByTaskNext(res.data.content, navigate, parentPage.task, monHocId, chuyenDeId);
          toast.success(" Hãy đến với nhiệm vụ tiếp theo!")
        }
        
      })
      .catch((err) => {
        
      });
  };


  const props = {
    name: 'file',
    multiple: true,
    action: `${API_URL_DOMAIN}/file`,
    accept: "application/zip,.jpg,.png,.gif",

    beforeUpload(file) {
      const isZip =  file.type === 'application/zip' ||
      file.type === 'application/x-zip-compressed' ||
      file.type === 'application/x-compressed-zip' ||
      file.type === 'application/zip-compressed';
      if (!isZip) {
        message.error('Bạn chỉ có thể upload file ZIP!');
      }
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        message.error('File phải nhỏ hơn 1MB!');
      }
      return isZip && isLt1M;
    },

    onChange(info) {
      const { status } = info.file;
      let newArr = [...arrFile]
      if (status !== 'uploading') {
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        const response = info.file.response;
        Array.from(response).forEach((file) => {
          newArr.push(`https://apimentor11.cybersoft.edu.vn/${file}`);
        });
        setArrFile(newArr);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      
    },
  };

  let addInputToLayout = (noiDung) => {



    let arrayBaiLam = JSON.parse(baiLam || "[]");
    if (noiDung.includes("&hearts;")) {
      // list input cần convert
      var currentIndex = 0;

      var noiDung = noiDung.replace(/&hearts;/g, function (match) {
        if (baiLam?.length) {
          var replacement = `   <input type="text"  class='practice_fill_input text-black ${inputCaps}'  placeholder='${arrayBaiLam[currentIndex] || ""
            }'  id='input_box'  /> `;
          currentIndex++;

          return replacement;
        }
        var replacement = `<input type="text" class='practice_fill_input text-black ${inputCaps}' placeholder='${arrayBaiLam[currentIndex] || "Dán link nộp bài vào đây"
          }'  id='input_box'  /> `;
        currentIndex++;
        return replacement;
      });
    }

    let indexSquare = noiDung.split("&spades;️");

    return indexSquare?.map((item, index) => {

      return <>
        {HtmlParser(item)}

        {index != indexSquare.length - 1 && <Upload {...props}  >
          <button className="px-5 py-2 text-white" style={styleBtn}><i className="fa fa-upload"></i> Click or drag file</button>
        </Upload>} </>;
    })
  };


  let handleBatDauLamBaiTap = () => {
    let data = { chuyenDeId, taskId, monHocId, nguoiDungId: user.id };
    servCodingCamp
      .batDauLamBaiTap(data)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        
      });
  };
  let renderTrangThai = () => {
    if (trangThaiTongBaiTap.trangThai == typeTrangThaiNop.CHUA_LAM) {
      return <></>;
    }
    if (trangThaiTongBaiTap.trangThai == typeTrangThaiNop.HET_HAN) {
      return <></>;
    }
    switch (trangThai) {
      case typeTrangThaiNop.DA_CHAM: {
        return (
          <div className="text-yellow card-theme p-2 rounded">
            <p>
              {" "}
              {t('chiTietNhiemVu.diemCuaBan')}
              : <span className="font-medium">
                {diem}
              </span>
              {t('chiTietNhiemVu.diem')}

            </p>
            {nhanXet && <p>
              {t('chiTietNhiemVu.nhanXet')} {HtmlParser(nhanXet)}</p>}
          </div>
        );
      }
      case typeTrangThaiNop.NOP_LAI: {
        return (
          <div>
            <div className="text-yellow">
              {t('chiTietNhiemVu.banCanBoSungLai')}

            </div>
            <ButtonNopBai text="Cập nhật" onClick={handleNopLaiBai} />
          </div>
        );
      }
      case typeTrangThaiNop.CHO_CHAM: {
        return <div>
          <div className="text-danger">
            <p class="text-red-600 text-xl">Bài của bạn đang được chấm</p>
          </div>
          <ButtonNopBai text="Cập nhật" onClick={handleNopLaiBai} />
        </div>;
      }
      case typeTrangThaiNop.HET_HAN: {
        return <div className=""></div>;
      }
      default:
        return <ButtonNopBai onClick={handleNopBai} />;
    }
  };


  const listGuide = [
    {
      label: "Đề bài",
      children: (
        <>

          <div>
            <p
              id={`noiDungCaps_${orderNumber}`}
              className="c-card-content text-content  p-0  stepListRequireNopBai leading-7"
            >
              {addInputToLayout(noiDung)}
            </p>
          </div>
          {/* !!!!!!! đã chấm rồi thì ko cho nộp lại */}

          <div className="text-xl text-left ">{renderTrangThai()}</div>
        </>
      )
    }
  ]

  const [source, setSource] = useState("");
  let lstVideoHuongDan = required && required.huongDan ? JSON.parse(required.huongDan) : []
  if (lstVideoHuongDan.length > 0) {
    listGuide.push({
      label: "Hướng dẫn nộp bài",
      key: 1,
      children: (
        <div className="c-card-content text-content space-y-1 leading-7">
          {/* <ReactPlayer
            playbackRate={1}
            playing={true}
            url={source}
            width="100%"
            height="100%"
            // style={{ borderRadius: "100px" }}
            controls={true}
            config={{
              file: {
                forceHLS: true,
              },
            }}
          /> */}
          <VideoPlayer source={source} />
          {Array.from({ length: lstVideoHuongDan.length }, (_, index) => {

            return <button onClick={() => {

              servCodingCamp.getUrlVideoSolution_FPT(lstVideoHuongDan[index].noiDung).then(res => {
                setSource(res.data)
              })

            }} style={styleBtn} className="m-2 px-3 py-1">
              {index + 1}
            </button>
          }
          )
          }
        </div>
      ),
    })
  }

  const renderDetailTask = () => {
    return listGuide.map(({ label, children }, index) => {

      return {
        label,
        key: index,
        children,

      };
    });
  };

  return (
    <div className="p-3 h-full w-full">

      <ContentTab
        paddingHeaderX={15}
        paddingHeaderY={3}
        paddingTabItemX={15}
        paddingTabItemY={5}
        data={renderDetailTask()}
        onTabClick={(key) => {

          // set video tự động chạy lân2 đầu
          if (key == 1 && source == "") {
            servCodingCamp.getUrlVideoSolution_FPT(lstVideoHuongDan[0].noiDung).then(res => {
              setSource(res.data)
            })
          }
        }}
      />


    </div>
  );
}

import React, { useEffect, useState } from "react";
import PopupChat from "./components/PopupChat";
import "./style.css";
import TabsChat from "./components/TabsChat/TabsChat";
// import { getDSChuyenDeHocVienDangKyApi } from "../../redux/reducer/chuyenDeReducer";
import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "antd";
// import { getIssueHocVienTheoTungChuyenDeApi } from "../../redux/reducer/issueReducer";
import { AuthProvider } from "../../hook/chatHub";
import { getDSChuyenDeHocVienDangKyApi } from "../../redux/reducer/chuyenDeReducer-Chat";
import { getIssueHocVienTheoTungChuyenDeApi } from "../../redux/reducer/issueReducer-Chat";
const styleStackChat = {};

const StackChat = ({ showDrawer }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDSChuyenDeHocVienDangKyApi());
    dispatch(getIssueHocVienTheoTungChuyenDeApi())
  }, []);

  return (
    <div className="StackChat">
      <AuthProvider >
        <PopupChat
          showDrawer={showDrawer}
        />
        <TabsChat
        />
      </AuthProvider>


    </div>
  );
};

export default StackChat;

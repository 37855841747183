import React from 'react'
import Static_Public_Desktop from './Static_Public_Desktop'
import Static_Public_Tablet from './Static_Public_Tablet'
import { useMediaQuery } from 'react-responsive';

export default function Static_Public({ loTrinhPublic, isBlackFridayDay = false }) {
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 640 });
    return isDesktop ? children : null;
  };
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({maxWidth: 639 });
  
    return isTablet ? children : null;
  };
  return (
    <>
      <Desktop>
        <Static_Public_Desktop loTrinhPublic={loTrinhPublic} isBlackFridayDay={isBlackFridayDay} />
      </Desktop>
      <Tablet>
        
      </Tablet>
    </>
  )
}

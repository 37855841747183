import {
  ArrowsAltOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  MinusOutlined,
  MoreOutlined,
  PlusOutlined,
  SendOutlined,
  ShrinkOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Avatar, Button, Form, Input, Modal, Popover, Rate, Tabs, Tooltip, message } from "antd";
import InputEmoji from 'react-input-emoji'
import { min } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ChangeActiveChat1,
  ChangeActiveChat2,
  ChangeActiveChatBot,
  getGroupChatByDsChatApi,
  getGroupChatByDsChatApi2,
  getGroupChatByDsChatByChuyenDeApi,
  getGroupChatByDsChatByChuyenDeApi2,
  postGroupChatApi,
  postGroupChatApi2,
  putGroupChatApi,
  putGroupChatApi2,
} from "../../../../redux/reducer/stackChatReducer";
import { config } from "../../../../utils/config";
import { findObjectIndexInArray } from "../../../DanhSachChat/DanhSachChat";
import UseConnection from "../../../../hook/chatHub";
import {
  HubConnectionBuilder,
  LogLevel,
  HubConnection,
} from "@microsoft/signalr";
import { API_URL_DOMAIN } from "../../../../utils/urlDomain";
import { getIssueHocVienTheoTungChuyenDeApi, putoptionalApi } from "../../../../redux/reducer/issueReducer-Chat";
import { getHinhAnh2, getHinhAnhFB } from "../../../../utils/GetHinhanh";
import TextArea from "antd/lib/input/TextArea";
import { layDsNguoiDungByStringApi } from "../../../../redux/reducer/groupForTaskReducer";
import { postDanhGia } from "../../../../redux/reducer/danhGiaReducer";
import { issueService } from "../../../../services/issueService";
import { BASE_URL_CODINGCAMP } from "../../../../services/axios.service";
import CheckableTag from "antd/lib/tag/CheckableTag";
import { getChatBot, sendChatBotApi } from "../../../../redux/reducer/groupChatReducer";
import { toAliasString } from "../../../../utils/convertAlias";
import HtmlParser from "react-html-parser";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";
import { connection } from "../../../../App";
import { useLstLoTrinhDangHoc } from "../../../../hook/useLstLoTrinhUser";


export const getTodate = () => {
  const currentDate = new Date();
  return currentDate.toLocaleString();
};
export const convertTime = (dateTimeString) => {
  const dateTime = new Date(dateTimeString)
  const hour = dateTime.getHours()
  const minute = dateTime.getMinutes()

  let formattedHour = hour % 12
  if (formattedHour === 0) {
    formattedHour = 12
  }

  const period = hour >= 12 ? 'PM' : 'AM'
  return `${formattedHour}:${minute < 10 ? '0' + minute : minute} ${period}`
}
const apiUrl = API_URL_DOMAIN;
const { TabPane } = Tabs;
const TabChat = ({ activeChat, data, current }) => {
  const [conection, setConnection] = useState(null);

  const [messageApi, contextHolder] = message.useMessage();


  const dispatch = useDispatch();
  const dspopup = config.getStoreJson("LIST_POPUP");
  const [form] = Form.useForm();
  const [inputValue, setInputValue] = useState("");
  const [checkImgGuest, setcheckImgGuest] = useState(true)
  const [checkImgUser, setcheckImgUser] = useState(true)
  // console.log("😀 ~ file: TabChat.jsx:39 ~ TabChat ~ inputValue:", inputValue);
  // const inputRef = useRef(null)

  //-------------------regex link------------------------------
  const replaceWithLinks = (text) => {
    const regex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(regex, function (match) {
      return '<a href="' + match + '">' + match + '</a>';
    });
  }

  //-----------------end regex link---------------------------------

  const {
    groupChats,
    groupChatsByChuyenDe,
    chats,
    lastGroupChat,
    lastChat,
    userId,
    // joinRoom,
    // joinRoomCd,
    groupChats2,
    groupChatsByChuyenDe2,
    chats2,
    lastGroupChat2,
    lastChat2,
    userId2,
    chatBot,
    dataChatBot,
    // joinRoom2,
    // joinRoomCd2,
    listUser
  } = useSelector((state) => state.stackChatReducer);
  const { arrDSNguoiDung } = useSelector((state) => state.groupForTask)
  const { chatBots } = useSelector((state) => state.groupChat)
  const [countTime, setCountTime] = useState(0)
  const [lastTime, setLastTime] = useState(0)
  
const [dsUser,setDsUser] = useState("")
  const limit = 10
  const duration = 3600000
  // console.log(current ? chats2 : chats);
  let myUser = config.getStoreJson("USER_INFOR_TECH_X");

  const { data: dataDangHoc } = useLstLoTrinhDangHoc(myUser?.id);
  let dsMentorDanhGia = JSON.parse(dataDangHoc?.find(item=>item.chuyenDeId==data?.id)?.danhSachMentor||'[]')
  const [room,setRoom] = useState('[]')
  // room = JSON.stringify([
  //   "00699925-3395-448b-9851-3202a3742964",
  //   "d74eba9e-56fb-4673-96ef-edfd0f5d211c",
  // ]);

  // console.log("room", room);

  const { issueHocVienTheoTungChuyenDe } = useSelector((state) => state.issue);

  const [issue, setIssue] = useState("");
  // console.log("😀 ~ file: TabChat.jsx:24 ~ TabChat ~ issue:", issue);
  const messageRef = useRef();

  const { ActiveChat1, ActiveChat2 } = useSelector((state) => state.stackChatReducer)
  
  const joinRoom = async () => {
    try {
      //Tạo sự kiên lắng nghe khi có người kết nối
      connection.on("onJoinRoom", (userM) => {
        if (myUser?.id !== userM?.userid) {
          success(userM?.userid ?? "Có người vừa kết nối group task");
        }
      });
      connection.on("onJoinRoom2", (userM) => {
        if (myUser?.id !== userM?.userid) {
          success(userM?.userid ?? "Có người vừa kết nối group chuyên đề");
        }
      });
      connection.on('onUpdateIssue', () => {
        loadApi()
      })
      //Sự kiện có tin nhắn đến
      connection.on("ReceiveMessage", (userM) => {
        if (myUser?.id !== userM?.userid)
          loadApi()
      });
      connection.on("ReceiveMessage2", (userM) => {
        if (myUser?.id !== userM?.userid) {
          // sendNotify("Có tin nhắn mới !","")
          //khi tag chat đuoc

          const ApiCHat = current ? config.getStoreJson('ApiChat2') : config.getStoreJson('ApiChat1')


          if (ApiCHat && ApiCHat.id !== data.id) {
            // message.info('Có tin nhắn mới !', '')
          } else {
            const checkActive = current ? ActiveChat2 : ActiveChat1
            if (!checkActive) {
              // message.info('Có tin nhắn mới !', '')
            }
          }
        }
        loadApi()
      });
      //Sk khi có người out
      connection.on("outReceiveMessage", (userM) => {
        if (myUser?.id === userM?.userid) {
          // info("Bạn vừa tắt chat !");
        } else {
          info(`Tk ${userM?.userId} vừa tắt`);
        }
      });
      connection.on('outReceiveMessage2', (userM) => {
        if (myUser?.id === userM?.userid) {
          // message.info('Bạn vừa tắt chat !')
        } else {
          // message.info(`Tk ${userM?.userId} vừa tắt`);
        }
      })
      //ds chat
      connection.on("UsersInRoom", (list) => {
        
      });
      //Sk chạy khi connection đóng !
      // connection.onclose((e) => {
      //   console.log(`Connectid ${connection?.connectionId} close`);
      //   setConnection(null);
      // });
      //Start connection
      // await connection.start();
      let userConnection = null;
      if (checkTypeChat(true, false)) {
        userConnection = {
          userid: myUser.id,
          room,
          chuyenDeId: Number(data.id)
          // userid: myUser.id,
          // hocVienId: newD.hocVienId,
          // chuyenDeId: Number(data.id)
        };
        await connection.invoke("JoinRoom2", userConnection);
      } else {
        userConnection = { userid: myUser.id, taskid: Number(data.id) };
        await connection.invoke("JoinRoom", userConnection);
      }
      // success('kn thanh cong');
      setConnection(connection);
      // success("Bạn vừa kết nối thành công chat !");

    } catch (error) {
      

    }
  };

  const sendMessage = async () => {
    try {
      if (conection instanceof HubConnection)
        if (checkTypeChat(true, false)) {
          await conection.invoke("SendMessage2", {
            userid: myUser?.id,
            room,
            chuyenDeId: Number(data?.id),
            dsUser:dsUser
          });
        } else {
          await conection.invoke("SendMessage", {
            userid: myUser?.id,
            room,
            taskid: Number(data?.id),
          });
        }
    } catch (e) {
      
    }
  };

  const sendUsersConnected = async (room) => {
    try {
      if (conection instanceof HubConnection)
        await conection.invoke("SendUsersConnected", room);
    } catch (error) {
      
    }
  };

  const closeConnection = async () => {
    try {
      if (conection instanceof HubConnection) {
        await conection.stop();
        setConnection(null);
      }
    } catch (e) {
    }
  };

  const issueChat = (chuyenDeId) => {
    const index = issueHocVienTheoTungChuyenDe?.findIndex(
      (e) => e.chuyenDeId === chuyenDeId
    );
    if (index >= 0) return issueHocVienTheoTungChuyenDe[index];
    return null;
  };

  const success = (content) => {
    messageApi.open({
      type: "success",
      content,
      duration: 5,
    });
  };
  const info = (content) => {
    messageApi.open({
      type: "info",
      content,
      duration: 5,
    });
  };

  //Check chat chuyen de
  const CheckIssue = () => {
    if (data?.dsMentor) {
      //check có issue ko
      const index = issueHocVienTheoTungChuyenDe?.findIndex(
        (e) => e?.chuyenDeId == data?.id
      );
      if (index >= 0) {
        setIssue(issueHocVienTheoTungChuyenDe[index].title);
      } else setIssue("");

      //Cập nhập nội dung chat cho content
    } else if (data?.danhSachHocVien) {
      setIssue("");
    }
  };

  const loadApi = () => {
    // if(chat)
    if (messageRef && messageRef.current) {
      const { scrollHeight, clientHeight } = messageRef.current;
      messageRef.current.scrollTo({
        left: 0,
        top: scrollHeight - clientHeight,
        behavior: "smooth",
      });
    }
    //load data khi người dùng bật lên
    if (checkTypeChat(true, false) === true) {
      //chuyende
      if (current) {
        dispatch(getGroupChatByDsChatByChuyenDeApi2(JSON.stringify([myUser?.id,...dsMentorDanhGia]), data?.id));
      } else {
        dispatch(getGroupChatByDsChatByChuyenDeApi(JSON.stringify([myUser?.id, ...dsMentorDanhGia]), data?.id));
      }
    } else if (checkTypeChat(true, false) === false) {
      if (current) {
        // console.log("chat 2", room, data.groupForTaskId);
        dispatch(getGroupChatByDsChatApi2(dsUser, Number(data?.id)));
      } else {
        // console.log("chat 1", room, data.groupForTaskId);
        dispatch(getGroupChatByDsChatApi(dsUser, Number(data?.id)));
      }
    }
  };

  const checkTypeChat = (a, b) => {
    if (data?.dsMentor) return a;
    else if (data?.danhSachHocVien) return b;
    return null;
  };
  const lastName = (fullname) => {
    const parts = fullname?.split(' ') // Tách chuỗi thành mảng các phần tử, tách theo khoảng trắng

    if (parts?.length > 0) {
      return parts[parts?.length - 1]
    } else {
      return null
    }
  }
  const convertHoTen = (id) => {
    const index = arrDSNguoiDung?.findIndex((item) => item.id == id)
    return arrDSNguoiDung[index]
  }
  const showContentChat = (mess) => {
    console.log('mess: ', mess);
    return mess?.map((e, i) => {
      if (e?.UserSendId === myUser?.id)
        return (
          <div key={i + " user_chat"} className="user_chat">
            <div className="user_avatar avatar">
              {checkImgUser ? (
                // <img
                //   src={getHinhAnhFB(myUser.avatar,myUser)}
                //   onError={() => {
                //     setcheckImgUser(false)
                //   }}
                //   alt=''
                // /> https://ui-avatars.com/api/?name
                // <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                <Popover key={i} content={myUser?.hoTen}>
                  <Avatar
                    style={{ borderRadius: '100rem', width: '35px', height: '35px' }}
                    className="shape-avatar me-1"
                    shape="square"
                    size={40}
                    src={`https://ui-avatars.com/api/?name=${myUser?.hoTen}`}
                  ></Avatar>
                </Popover>
              ) : (
                // <Avatar style={{ backgroundColor: '#87d068' }}  >{lastName(myUser?.hoTen)}</Avatar>
                <Popover key={i} content={myUser?.hoTen}>
                  <Avatar
                    style={{ borderRadius: '100rem', width: '35px', height: '35px' }}
                    className="shape-avatar me-1"
                    shape="square"
                    size={40}
                    src={`https://ui-avatars.com/api/?name=${myUser?.hoTen}`}
                  ></Avatar>
                </Popover>
              )}
            </div>
            <div className="user_content">
              <p className='d-flex flex-column align-items-end items-end'>
                <span className='text-base' dangerouslySetInnerHTML={{ __html: e?.Content }}></span>
                <span className='text-xs'>{convertTime(e?.TimeCreate)}</span>
              </p>
            </div>
          </div>
        );
      else
        return (
          <div key={i + ' guest_chat'} className='guest_chat'>
            <div className='guest_avatar avatar'>


              <Popover key={i} content={convertHoTen(e?.UserSendId)?.hoTen}>
                <Avatar
                  style={{ borderRadius: '100rem', width: '35px', height: '35px' }}
                  className="shape-avatar me-1"
                  shape="square"
                  size={40}
                  src={`https://ui-avatars.com/api/?name=${convertHoTen(e?.UserSendId)?.hoTen}`}
                ></Avatar>
              </Popover>
            </div>
            <div className='guest_content'>
              {/* <span className=" text-xs">{loadTen(e.UserSendId)}</span> */}
              <p className='d-flex flex-column  align-items-start'>
                <span className='text-base'> {e?.Content}</span>
                <span className='text-xs'>{convertTime(e?.TimeCreate)}</span>
              </p>
            </div>
          </div>
        );
    });
  };
  const tagsData = ['Fix bug', 'Giải thích code', 'Hỏi đáp'];
  const [selectedTags, setSelectedTags] = useState('');
  const [openFillCode, setOpenFillCode] = useState(false);
  const handleChange = async (tag) => {
    setSelectedTags(tag)
    if ((tag == 'Fix bug' || tag == 'Giải thích code')) {
      setOpenFillCode(true)
    }
    if (chatBots.length == 0) {
      //create chatbot
      const obj = {
        "id": 0,
        "danhSachChat": JSON.stringify([myUser.id, 'bot']),
        "noiDungChat": "",
        "ngayTao": new Date().toISOString(),
        "groupForTaskId": 0,
        "chuyenDeId": 0,
        "issueId": 0
      }
      await dispatch(postGroupChatApi2(obj));
      await dispatch(getChatBot());
    }
  };
  const getFistWordAndCheck = (str) => {
    //bổ sung thêm những ngôn ngữ khác 
    return str.startsWith("`javascript")
      || str.startsWith("`python")
      || str.startsWith("`csharp")
      || str.startsWith('`java')
      || str.startsWith('`typescript')
      || str.startsWith('`cpp')
      || str.startsWith('`ruby')
      || str.startsWith('`css')
      || str.startsWith('`jsx')
      || str.startsWith('`html')
      || str.startsWith('`js')


  }
  const [openBot, setOpenBot] = useState(false)
  const [contentBot, setContentBot] = useState(false)
  const openContentCode = (text) => {
    setContentBot(text)
    setOpenBot(true)
  }
  const showContentChatBot = (mess) => {
    //  if(mess.length>0){
    return mess?.map((e, i) => {
      if (e?.UserSendId === myUser?.id)
        return (
          <div key={i + " user_chat"} className="user_chat">
            <div className="user_avatar avatar">
              {checkImgUser ? (
                // <img
                //   src={getHinhAnhFB(myUser.avatar,myUser)}
                //   onError={() => {
                //     setcheckImgUser(false)
                //   }}
                //   alt=''
                // /> https://ui-avatars.com/api/?name
                // <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                <Popover key={i} content={myUser?.hoTen}>
                  <Avatar
                    style={{ borderRadius: '100rem', width: '35px', height: '35px' }}
                    className="shape-avatar me-1"
                    shape="square"
                    size={40}
                    src={`https://ui-avatars.com/api/?name=${myUser?.hoTen}`}
                  ></Avatar>
                </Popover>
              ) : (
                // <Avatar style={{ backgroundColor: '#87d068' }}  >{lastName(myUser?.hoTen)}</Avatar>
                <Popover key={i} content={myUser?.hoTen}>
                  <Avatar
                    style={{ borderRadius: '100rem', width: '35px', height: '35px' }}
                    className="shape-avatar me-1"
                    shape="square"
                    size={40}
                    src={`https://ui-avatars.com/api/?name=${myUser?.hoTen}`}
                  ></Avatar>
                </Popover>
              )}
            </div>
            <div className="user_content">
              <p className='d-flex flex-column align-items-end items-end'>
                <span className='text-base' dangerouslySetInnerHTML={{ __html: e?.Content }}></span>
                <span className='text-xs'>{convertTime(e?.TimeCreate)}</span>
              </p>
            </div>
          </div>
        );
      else {
        let arrContent = e?.Content.split('``')
        // let arrContent = e?.Content.match(/[^`]+|`[^`]+`/g)
        return (
          <div key={i + ' guest_chat'} className='guest_chat'>
            <div className='guest_avatar avatar'>


              <Popover key={i} content={'Trợ lý AI'}>
                <Avatar
                  style={{ borderRadius: '100rem', width: '35px', height: '35px' }}
                  className="shape-avatar me-1"
                  shape="square"
                  size={40}
                  src={`https://ui-avatars.com/api/?name=AI`}
                ></Avatar>
              </Popover>
            </div>
            <div className='guest_content'>
              {/* <span className=" text-xs">{loadTen(e.UserSendId)}</span> */}
              <p className='d-flex flex-column  align-items-start'>
                {arrContent.map((item, idx) => {
                  const lstW = item.split(" ");
                  if (item.startsWith('`') && getFistWordAndCheck(lstW[0])) {
                    // lấy chuỗi code có dấu ` ở đầu
                    //lấy loại ngôn ngữ lấy ra chữ đầu gắn vào vị trí language của SyntaxHightlighter

                    let text = item.substring(1, item.length);
                    return <div className="relative w-full blockContentBot" onClick={() => { openContentCode(text) }}>
                      {/* <span className='text-base'>{Html(item)}</span> */}
                      <SyntaxHighlighter
                        // className="p-10"
                        language="javascript"
                        style={dracula}
                        wrapLines={true}
                        showLineNumbers={true}
                      >
                        {text}
                        {/* {HtmlParser(text)} */}
                      </SyntaxHighlighter>
                      <div className="hoverCode">
                        <button>Xem thêm</button>
                      </div>
                    </div>
                  }
                  return <span className='text-base'>{item}</span>
                }
                )}
                <span className='text-xs'>{convertTime(e?.TimeCreate)}</span>
              </p>
            </div>
          </div>
        );
      }
    });
    //  }

  };

  const handleClearPopup = () => {
    const index = findObjectIndexInArray(dspopup, data);
    if (index >= 0) {
      if (dspopup.length <= 1) {
        config.removeStore("LIST_POPUP");
      } else {
        dspopup.splice(index, 1);
        config.setStoreJson("LIST_POPUP", dspopup);
      }
    }
  };

  //*so sanh ngày truyền vào có format ngay thang nam trung với ngày hiện tại hay không
  const isSameDate = (dateA) => {
    const currentDate = new Date();
    const date = new Date(dateA);

    // So sánh ngày, tháng, năm
    return (
      date.getFullYear() === currentDate.getFullYear() &&
      date.getMonth() === currentDate.getMonth() &&
      date.getDate() === currentDate.getDate()
    );
  };

  const handleInputChange = (event) => {
    // setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    form.submit();
  };

  const handleClearInput = () => {
    form.setFieldsValue({
      Content: "",
      TimeCreate: "",
      IsDelete: false,
      UserChat: "user",
    });
    // inputRef.current?.focus()
  };

  //Gưi tin nhắn đi

  const onFinish = async (values) => {
    const index = issueHocVienTheoTungChuyenDe?.findIndex(
      (issue) => issue?.chuyenDeId == data.id)
    values.Content = replaceWithLinks(values.Content)
    // console.log(replaceWithLinks(values.Content))
    values.TimeCreate = getTodate();
    values.IsDelete = false;
    values.UserSendId = myUser.id;
    values.Id = 1;
    const newGC = current ? { ...lastGroupChat2 } : { ...lastGroupChat };

    newGC.ngayTao = new Date().toISOString();
    if (data?.danhSachHocVien)
      newGC.groupForTaskId = Number(data?.id);
    else if (data?.dsMentor) {
      newGC.chuyenDeId = +data.id;
      if (issueChat(newGC.chuyenDeId)) {
        newGC.issueId = issueChat(newGC.chuyenDeId)?.id;
      }
      else {
        newGC.issueId = 0
        let currentDate = new Date().toISOString();

        const issueStatus = "CAN_HO_TRO"
        const objtesst = {
          id: 0,
          noiDung: values.Content,
          hocVienId: myUser.id,
          title: values.Content,
          trangThai: issueStatus,
          chuyenDeId: newGC.chuyenDeId,
          ngayTao: currentDate,
          path: ""

        }
        try {
          const response = await issueService.postIssue(objtesst);

          if (response.data.statusCode === 201) {
            message.success("Issue created successfully");
            newGC.issueId = response.data.content.id
            dispatch(getIssueHocVienTheoTungChuyenDeApi());
          } else {
            
          }
        } catch (error) {
          
        }
      }
    }
    if (room) newGC.danhSachChat = dsUser;


    if (current) {
      if (
        groupChatsByChuyenDe2.length !== 0 &&
        isSameDate(lastGroupChat2.ngayTao)
      ) {
        newGC.id = lastGroupChat2.id;
        newGC.noiDungChat = JSON.stringify([...lastChat2, values]);
        //todo
        await dispatch(putGroupChatApi2(newGC.id, newGC,dsUser));
        await dispatch(
          getGroupChatByDsChatByChuyenDeApi2(room, Number(data.id))
        );
      } else if (lastGroupChat) {
        newGC.noiDungChat = JSON.stringify([values]);
        await dispatch(getGroupChatByDsChatByChuyenDeApi2(room, Number(data.id)));
        await dispatch(postGroupChatApi2(newGC,dsUser));
      }
    } else {
      // console.log(values)
      if (
        groupChatsByChuyenDe.length !== 0 &&
        isSameDate(lastGroupChat.ngayTao)
      ) {
        newGC.id = lastGroupChat.id;
        newGC.noiDungChat = JSON.stringify([...lastChat, values]);
        //todo
        await dispatch(putGroupChatApi(newGC.id, newGC,dsUser));
        await dispatch(
          getGroupChatByDsChatByChuyenDeApi(room, Number(data.id))
        );
      } else if (lastGroupChat) {
        newGC.noiDungChat = JSON.stringify([values]);
        await dispatch(postGroupChatApi(newGC,dsUser));
        await dispatch(
          getGroupChatByDsChatByChuyenDeApi(room, Number(data.id))
        );
      }
    }
    sendMessage();

    handleClearInput();
    setInputValue("");
    if (data?.id) {
      // Gọi sự kiên
      // sendMessage(myUser.id, newGC.danhSachChat, newGC.groupForTaskId)
      loadApi();
    } else if (data?.groupTaskId) {
      // sendMessage2(myUser.id, newGC.danhSachChat, newGC.groupForTaskId)
      loadApi();
    }
  };

  //Gửi mess Bot
  const canCallAPI = () => {
    const now = Date.now();
    if (now - lastTime <= duration) {
      if (countTime >= limit) {
        return false;
      }
    } else {
      // Nếu đã vượt quá khoảng thời gian, reset counttime
      setCountTime(0);
    }
    return true;
  };
  //input fill code
  const [code, setCode] = useState("");
  const [bug, setBug] = useState("");

  const handleChangeCode = (e) => {
    setCode(e.target.value);
  };
  const handleChangeBug = (e) => {
    setBug(e.target.value);
  };
  const onFinishBot = async (values) => {
    values.Content = values.Content
    values.TimeCreate = getTodate();
    values.IsDelete = false;
    values.UserSendId = myUser.id;
    values.Id = 1;
    let newGC = { ...data }
    newGC.ngayTao = new Date().toISOString();
    let objbot = {
      "id": data.id,
      "content": values.Content,
      "type": toAliasString(selectedTags)
    }
    if (selectedTags == "") {
      message.info("Vui lòng chọn tag để được hỗ trợ chính sác nhất 0!")
      return
    }
    if (selectedTags == "Fix bug") {
      let cnt = 'Tôi có đoạn code sau : ' + code + 'và lỗi tôi gặp phải' + bug + '. Hãy giúp tôi khắc phục nó.'
      objbot.content = cnt
    }
    if (selectedTags == "Giải thích code") {
      let cnt = code
      objbot.content = cnt
    }
    newGC.noiDungChat = JSON.stringify([...chatBot, values]);
    //todo
    if (canCallAPI()) {
      var res = await dispatch(putGroupChatApi2(newGC.id, newGC));
      await dispatch(
        getGroupChatByDsChatByChuyenDeApi2(room, Number(data.id))
      );
      setCountTime(countTime + 1);
      setLastTime(Date.now());
      if (!res) {
        dispatch(
          getGroupChatByDsChatByChuyenDeApi2(room, Number(data.id))
        );

        dispatch(getChatBot())
        dispatch(sendChatBotApi(objbot))
      }
      sendMessage();

      handleClearInput();
      setInputValue("");
      if (data?.id) {
        // Gọi sự kiên
        // sendMessage(myUser.id, newGC.danhSachChat, newGC.groupForTaskId)
        loadApi();
      } else if (data?.groupTaskId) {
        // sendMessage2(myUser.id, newGC.danhSachChat, newGC.groupForTaskId)
        loadApi();
      }
    }
    else {
      message.warning(`Bạn đã hết số lần đặt câu hỏi vui lòng quay lại sau `)
    }

  };
  const onFinishBotFromModal = async () => {
    let values = {}
    values.Content = ""
    values.TimeCreate = getTodate();
    values.IsDelete = false;
    values.UserSendId = myUser.id;
    values.Id = 1;
    let newGC = { ...data }
    newGC.ngayTao = new Date().toISOString();
    let objbot = {
      "id": data.id,
      "content": values.Content,
      "type": toAliasString(selectedTags)
    }
    if (selectedTags == "") {
      message.info("Vui lòng chọn tag để được hỗ trợ chính sác nhất 1!")
      return
    }
    if (selectedTags == "Fix bug") {
      let cnt = 'Tôi có đoạn code sau : ' + code + 'và lỗi tôi gặp phải' + bug + '. Hãy giúp tôi khắc phục nó.'
      objbot.content = cnt
      values.Content = cnt
    }
    if (selectedTags == "Giải thích code") {
      let cnt = code
      objbot.content = cnt
      values.Content = cnt
    }
    newGC.noiDungChat = JSON.stringify([...chatBot, values]);
    //todo
    if (canCallAPI()) {
      var res = await dispatch(putGroupChatApi2(newGC.id, newGC));
      await dispatch(
        getGroupChatByDsChatByChuyenDeApi2(room, Number(data.id))
      );
      setCountTime(countTime + 1);
      setLastTime(Date.now());
      if (!res) {
        dispatch(
          getGroupChatByDsChatByChuyenDeApi2(room, Number(data.id))
        );
        dispatch(getChatBot())
        dispatch(sendChatBotApi(objbot))
      }
      sendMessage();

      handleClearInput();
      setInputValue("");
      setSelectedTags("");
      setBug('')
      setCode('')
      loadApi()
    }
    else {
      message.warning(`Bạn đã hết số lần đặt câu hỏi vui lòng quay lại sau `)
    }
    setOpenFillCode(false)

  };
  useEffect(() => {
    if (messageRef && messageRef.current) {
      const { scrollHeight, clientHeight } = messageRef.current;
      messageRef.current.scrollTo({
        left: 0,
        top: scrollHeight - clientHeight,
        behavior: "smooth",
      });
    }
  }, [chats,chatBot]);
  useEffect(() => {
    CheckIssue();
    // if(data){
    //   if(myUser && data?.danhSachHocVien){
    //     setDsUser(JSON.stringify(data.danhSachHocVien))
    //     setRoom(JSON.stringify(data.danhSachHocVien))
    //   } else if (myUser && data?.dsMentor){
    //     let danhSachMentorPhuTrach = JSON.parse(dataDangHoc?.find(item=>item.chuyenDeId==data.id).danhSachMentor||'[]')
    //   setRoom(JSON.stringify([myUser?.id, ...danhSachMentorPhuTrach]))
    //   setDsUser(JSON.stringify([myUser?.id, ...danhSachMentorPhuTrach]))
    // }
    // }
    if(dataDangHoc){
      // dsMentorDanhGia = JSON.parse(dataDangHoc?.find(item=>item.chuyenDeId==data?.id)?.danhSachMentor||'[]')
    }
  }, [data?.id, data, issueHocVienTheoTungChuyenDe?.length,dataDangHoc]);

  useEffect(() => {
      loadApi();
  }, [data,room]);
  useEffect(() => {
    if(data){
    dispatch(layDsNguoiDungByStringApi(data?.dsMentor))
      if(myUser && data?.danhSachHocVien){
        setDsUser(JSON.stringify(data.danhSachHocVien))
        setRoom(JSON.stringify(data.danhSachHocVien))
      } else if (myUser && data?.dsMentor){
        let danhSachMentorPhuTrach = JSON.parse(dataDangHoc?.find(item=>item.chuyenDeId==data.id).danhSachMentor||'[]')
      setRoom(JSON.stringify([myUser?.id, ...danhSachMentorPhuTrach]))
      setDsUser(JSON.stringify([myUser?.id, ...danhSachMentorPhuTrach]))
    }
    }
  }, [data?.id]);
  //Tạo realtime
  useEffect(() => {
    //Khi người dùng chuyển chat thì sẽ clear connect cu đi
    if (conection instanceof HubConnection) {
      // closeConnection();
    }
    joinRoom();

    return () => {
      // closeConnection();
      // setConnection(null);
    };
  }, []);

  const mini = () => {
    if (data == null || data === dataChatBot) {
      dispatch(ChangeActiveChatBot())
    }
    else if (current) dispatch(ChangeActiveChat2());
    else dispatch(ChangeActiveChat1());
  };
  const close = async () => {
    // await closeConnection();
    handleClearPopup();
    if (current) dispatch(ChangeActiveChat2());
    else dispatch(ChangeActiveChat1());
  };
  const [showFull, setShowFull] = useState(false)
  //vote mentor update issue
  const [thisIssue, setThisIssue] = useState()
  const [ndDanhGia, setNDdanhGia] = useState()
  const [mentorDuocDanhGia, setMentorDuocDanhGia] = useState(arrDSNguoiDung[0]?.id)
  const handleChangeTabs = (e) => {
    setMentorDuocDanhGia(e)
  }
  const handelUpdateIssue = async (trangThai) => {
    const issue = issueChat(data?.id)
    if (issue && 'trangThai' in issue) {
      const newIssue = { ...issue }
      setThisIssue(issue)
      newIssue.trangThai = trangThai

      // issue.trangThai=typeOfIssue.DA_HO_TRO
      try {
        // await UpdateIssuee(issue?.id, newIssue)
        // dispatch()
        setOpen(true)
        dispatch(putoptionalApi(newIssue.id, newIssue))
        message.success('Update State Succes !')
      } catch (error) {
        message.info('OOP! Kiểm tra lại đường truyền')
        // console.log(error)
      }
    } else {
      message.info('OOP! Kiểm tra lại đường truyền')
    }
  }
  const [valueVote, setValueVote] = useState({
    chamBaiDungHan: 5,
    chiaSeKienThuc: 5,
    kienThucCao: 5,
    nangDong: 5,
    hoTroHocVien: 5,
    thanThien: 5,
  })
  const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];
  const handleVote = (type, e) => {
    const newobj = { ...valueVote }
    newobj[type] = e
    setValueVote(newobj)
  }
  const changeDanhGia = (e) => {
    setNDdanhGia(e.target.value)
  }
  const [open, setOpen] = useState()
  const handleOk = () => {
    const obj = {
      "id": 0,
      "noiDungDanhGia": ndDanhGia,
      "danhGiaRank": JSON.stringify(valueVote),
      "nguoiDuocDanhGiaId": mentorDuocDanhGia,
      "nguoiDanhGiaId": myUser.id,
      "vaiTro": "",
      "issueId": thisIssue.id,
      "meta": "",
      "ngayTao": new Date()
    }
    dispatch(postDanhGia(obj))

    dispatch(getIssueHocVienTheoTungChuyenDeApi());
    setOpen(false)
  }
  const handleCancel = () => {
    setOpen(false)
  }
  return activeChat ? (
    data?.tenChuyenDe ?
      (
        issue ? (
          <div className={`TabChat ${showFull ? 'showFull' : ''}`}>
            {contextHolder}
            <div className="TabChat_container">
              <div className="TabChat_head">
                <div className="title">
                  <img src={getHinhAnh2(data)} alt="" className="w-10 h-10 object-cover rounded-full mr-2" />

                  <p className="TabChat_name" title={data?.tenChuyenDe}>{data?.tenChuyenDe.substring(0, 20) + " ..."}</p>
                </div>
                <div className="action">
                  <MoreOutlined />
                  <MinusOutlined
                    onClick={() => {
                      mini();
                    }}
                  />
                  <CloseOutlined
                    onClick={() => {
                      close();
                    }}
                  />
                  <i onClick={() => {
                    setShowFull(!showFull)
                  }} className={`${showFull ? "fa-solid fa-down-left-and-up-right-to-center" : "fa-solid fa-up-right-and-down-left-from-center"}`}></i>
                </div>
              </div>
              <div className="TabChat_content">
                <div ref={messageRef} className="content">
                  {showContentChat(current ? chats2 : chats)}
                </div>
              </div>
              <div className="relative">
                <Form form={form} onFinish={onFinish} className="TabChat_typing">
                  <Form.Item name="Content" className="w-full">
                    <Input
                      value={inputValue}
                      disabled={!conection && !inputValue}
                      onChange={handleInputChange}
                      // onPressEnter={handleSubmit}
                      className="typing"
                      placeholder="Nhập nội dung chat"
                    />
                    {/* <InputEmoji
                    name="Content"
                    disabled={!conection && !inputValue && issueChat(data?.chuyenDeId)}
                    value={inputValue}
                    onChange={setInputValue}
                    onEnter={form.submit}
                    className="typing w-full"
                    placeholder="Nhập nội dung chat"
                  /> */}

                  </Form.Item>

                  <div className="action">
                    <Button htmlType="submit" disabled={!conection && !inputValue} className={`send ${inputValue.trim().length > 0 ? 'hover' : ''}`}>
                      <SendOutlined />
                    </Button>

                  </div>{issue && (
                    <div className="action">
                      <Button htmlType="button" className="done" onClick={() => {
                        handelUpdateIssue("DA_HO_TRO")
                      }}>
                        <CheckCircleOutlined />
                      </Button>
                    </div>
                  )}
                </Form>
              </div>
            </div>
            {issue && (
              <div className="issue_container">
                <Tooltip title={issue}>
                  <p>{issue.length>20?issue.substring(0,17)+"...":issue} </p>
                </Tooltip>
              </div>
            )}
            <Modal
              width={800}
              className='modalDanhGia'
              footer={null}
              visible={open}
              okText='Gửi'
              onCancel={handleCancel}

            >
              <p className="tenChuyenDe text-2xl">Đánh giá mentor</p>
              <Tabs onChange={handleChangeTabs}  tabBarGutter={30}
        >
                {dsMentorDanhGia?.map((itm, idx) => {
                  let item = arrDSNguoiDung?.find(a=>a.id ==itm)
                  return <TabPane style={{ color: '#fff' }} tab={item?.hoTen} key={item?.id}>
                    <ul className="list-group bg-transparent">
                      <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center text-sm">
                        <b>Chấm bài đúng hạn</b>

                        <Rate tooltips={desc} defaultValue={valueVote.chamBaiDungHan} onChange={(e) => {
                          handleVote('chamBaiDungHan', e)
                        }} />
                      </li>
                      <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center text-sm">
                        <b>Chia sẻ kiến thức</b>
                        <Rate tooltips={desc} defaultValue={valueVote.chiaSeKienThuc} onChange={(e) => {
                          handleVote('chiaSeKienThuc', e)
                        }} />
                      </li>
                      <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center text-sm">
                        <b>Chia sẻ kiến thức nâng cao</b>
                        <Rate tooltips={desc} defaultValue={valueVote.kienThucCao} onChange={(e) => {
                          handleVote('kienThucCao', e)
                        }} />
                      </li>
                      <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center text-sm">
                        <b>Hỗ trợ trên học viên</b>
                        <Rate tooltips={desc} defaultValue={valueVote.hoTroHocVien} onChange={(e) => {
                          handleVote('hoTroHocVien', e)
                        }} />
                      </li>
                      <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center text-sm">
                        <b>Năng động</b>
                        <Rate tooltips={desc} defaultValue={valueVote.nangDong} onChange={(e) => {
                          handleVote('nangDong', e)
                        }} />
                      </li>
                      <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center text-sm">
                        <b>Thân thiện</b>
                        <Rate tooltips={desc} defaultValue={valueVote.thanThien} onChange={(e) => {
                          handleVote('thanThien', e)
                        }} />
                      </li>
                      <li>
                        <textarea className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={changeDanhGia} rows={3} placeholder="Vui lòng để lại vài dòng suy nghĩ của bạn nhé ! "></textarea>
                      </li>
                    </ul>
                    <button className="btn_theme_red" onClick={handleOk}>Gửi</button>
                  </TabPane>
                })}

              </Tabs>
            </Modal>
          </div>
        ) : (
          <div className={`TabChat ${showFull ? 'showFull' : ''}`}>
            <div className="TabChat_container">
              <div className="TabChat_head">
                <div className="title">
                  <img src={getHinhAnh2(data)} alt="" className="w-10 h-10 object-cover rounded-full mr-2" />

                  <p className="TabChat_name">{data?.tenBaiHoc ? `Group ${data?.tenBaiHoc?.substring(0, 20) + ' ...'}` : `${data?.tenChuyenDe?.substring(0, 20) + ' ...'}`}</p>
                </div>
                <div className="action">
                  <MoreOutlined />
                  <MinusOutlined
                    onClick={() => {
                      mini();
                    }}
                  />
                  <CloseOutlined
                    onClick={() => {
                      close();
                    }}
                  />
                  <i onClick={() => {
                    setShowFull(!showFull)
                  }} className={`${showFull ? "fa-solid fa-down-left-and-up-right-to-center" : "fa-solid fa-up-right-and-down-left-from-center"}`}></i>
                </div>
              </div>
              <div className="TabChat_content">
                <div className="content">
                  {showContentChat(current ? chats2 : chats)}
                </div>
              </div>
              <div className="relative">
                <Form form={form} onFinish={onFinish} className="TabChat_typing">
                  <Form.Item name="Content" className="w-full">
                    <Input
                      value={inputValue}
                      disabled={!conection && !inputValue}
                      onChange={handleInputChange}
                      // onPressEnter={handleSubmit}
                      className="typing"
                      placeholder="Nhập nội dung chat"
                    />
                    {/* <InputEmoji
                    name="Content"
                    disabled={!conection && !inputValue}
                    value={inputValue}
                    onChange={setInputValue}
                    onEnter={form.submit}
                    className="typing w-full"
                    placeholder="Nhập nội dung chat"
                  /> */}

                  </Form.Item>

                  <div className="action">
                    <Button htmlType="submit" disabled={!conection && !inputValue} className={`send ${inputValue.trim().length > 0 ? 'hover' : ''}`}>
                      <SendOutlined />
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )
      )
      :
      (
        <div className={`TabChat ${showFull ? 'showFull' : ''}`}>
          {contextHolder}
          <div className="TabChat_container">
            <div className="TabChat_head">
              <div className="title">
                <img src='https://play-lh.googleusercontent.com/3dh_AD3dKeum4QVdqjmHILF0JXXuQbe7PxUW9j0VAuOL9R8DXrk06InQhaq0HJ5AeA' alt="" className="w-10 h-10 object-cover rounded-full mr-2" />

                <p className="TabChat_name">Trợ lý AI</p>
              </div>
              <div className="action">
                <MoreOutlined />
                <MinusOutlined
                  onClick={() => {
                    mini();
                  }}
                />
                {showFull ? <ShrinkOutlined onClick={() => {
                  setShowFull(!showFull)
                }} /> : <ArrowsAltOutlined onClick={() => {
                  setShowFull(!showFull)
                }} />}


              </div>
            </div>
            <div className="TabChat_content botChat">
              <div ref={messageRef} className="content">
                {showContentChatBot(chatBot)}
              </div>
            </div>

            <div className="relative">
              <Form form={form} onFinish={onFinishBot} className="TabChat_typing">
                <Form.Item name="Content" className="w-full">
                  <Input
                    value={inputValue}
                    disabled={!conection && !inputValue}
                    onChange={handleInputChange}
                    // onPressEnter={handleSubmit}
                    className="typing"
                    placeholder="Nhập nội dung chat"
                  />
                  {/* <InputEmoji
                  name="Content"
                  disabled={!conection && !inputValue && issueChat(data?.chuyenDeId)}
                  value={inputValue}
                  onChange={setInputValue}
                  onEnter={form.submit}
                  className="typing w-full"
                  placeholder="Nhập nội dung chat"
                /> */}

                </Form.Item>

                <div className="action">
                  <Button htmlType="submit" disabled={!conection && !inputValue} className={`send ${inputValue.trim().length > 0 ? 'hover' : ''}`}>
                    <SendOutlined />
                  </Button>

                </div>

              </Form>
              <div className="bot">
                {tagsData.map((tag) => (
                  <CheckableTag
                    style={{
                      color: '#fff', backgroundColor: '#8762e6b4',
                      borderRadius: '10px'
                    }}
                    key={tag}
                    checked={selectedTags.indexOf(tag) > -1}
                    onChange={(checked) => handleChange(tag)}
                  >
                    {tag}
                  </CheckableTag>
                ))}
              </div>
            </div>
          </div>

          <Modal
            width={800}
            className='modalDanhGia'
            visible={open}
            okText='Gửi'
            onCancel={handleCancel}
            footer={null}
          >
            <p className="tenChuyenDe text-2xl">Đánh giá mentor</p>
            <Tabs onChange={handleChangeTabs} >
              {dsMentorDanhGia?.map((itm, idx) => {
                let item = arrDSNguoiDung?.find(a=>a.id ==itm)
                  // let infor = arrDSNguoiDung.find(nd=>nd.id==item)
                return <TabPane style={{ color: '#fff' }} tab={item?.hoTen} key={item?.id}>
                  <ul className="list-group bg-transparent">
                    <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center text-sm">
                      <b>Chấm bài đúng hạn</b>

                      <Rate tooltips={desc} defaultValue={valueVote.chamBaiDungHan} onChange={(e) => {
                        handleVote('chamBaiDungHan', e)
                      }} />
                    </li>
                    <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center text-sm">
                      <b>Chia sẻ kiến thức</b>
                      <Rate tooltips={desc} defaultValue={valueVote.chiaSeKienThuc} onChange={(e) => {
                        handleVote('chiaSeKienThuc', e)
                      }} />
                    </li>
                    <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center text-sm">
                      <b>Chia sẻ kiến thức nâng cao</b>
                      <Rate tooltips={desc} defaultValue={valueVote.kienThucCao} onChange={(e) => {
                        handleVote('kienThucCao', e)
                      }} />
                    </li>
                    <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center text-sm">
                      <b>Hỗ trợ trên học viên</b>
                      <Rate tooltips={desc} defaultValue={valueVote.hoTroHocVien} onChange={(e) => {
                        handleVote('hoTroHocVien', e)
                      }} />
                    </li>
                    <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center text-sm">
                      <b>Năng động</b>
                      <Rate tooltips={desc} defaultValue={valueVote.nangDong} onChange={(e) => {
                        handleVote('nangDong', e)
                      }} />
                    </li>
                    <li className="list-group-item bg-transparent d-flex justify-content-between align-items-center text-sm">
                      <b>Thân thiện</b>
                      <Rate tooltips={desc} defaultValue={valueVote.thanThien} onChange={(e) => {
                        handleVote('thanThien', e)
                      }} />
                    </li>
                    <li>
                      <textarea className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={changeDanhGia} rows={3} placeholder="Vui lòng để lại vài dòng suy nghĩ của bạn nhé ! "></textarea>                    
                      </li>
                  </ul>
                  <button className="btn_theme_red" onClick={handleOk}>Gửi</button>
                </TabPane>
              })}

            </Tabs>
          </Modal>
          {openBot && <Modal
            className=''
            visible={openBot}
            onCancel={() => {
              setOpenBot(false)
            }}
            footer={null}

          >
            <SyntaxHighlighter
              language="javascript"
              style={dracula}
              wrapLines={true}
              showLineNumbers={true}
            >
              {/* {HtmlParser(contentBot)} */}
              {contentBot}
            </SyntaxHighlighter>
          </Modal>}
          {openFillCode && <Modal
            title="Điền thông tin để được hỗ trợ chính xác"
            className=''
            visible={openFillCode}
            onCancel={() => {
              setSelectedTags('')
              setOpenFillCode(false)
            }}
            footer={null}
            width={'80%'}
          >
            <div className="">
              <div className="">
                <textarea
                  value={code}
                  onChange={handleChangeCode}
                  style={{
                    fontFamily: "'Fira Code', monospace",
                    fontSize: "14px",
                    backgroundColor: "#282a36",
                    color: "#f8f8f2",
                    border: "none",
                    width: "100%",
                    minHeight: "300px",
                    padding: "10px",
                  }}
                  placeholder="Enter your code here..."
                />
                {selectedTags == 'Fix bug' && <textarea
                  value={bug}
                  onChange={handleChangeBug}
                  style={{
                    fontFamily: "'Fira Code', monospace",
                    fontSize: "14px",
                    backgroundColor: "#282a36",
                    color: "#f8f8f2",
                    border: "none",
                    width: "100%",
                    minHeight: "100px",
                    padding: "10px",
                  }}
                  placeholder="Enter your bug here..."
                />}<div className="text-right">
                  <button className="btn_theme_red" onClick={onFinishBotFromModal}>Xác nhận</button>
                </div>
              </div>

            </div>
          </Modal>}
          {openBot && <Modal
            className=''
            visible={openBot}
            onCancel={() => {
              setOpenBot(false)
            }}
            footer={null}

          >
            <SyntaxHighlighter
              language="javascript"
              style={dracula}
              wrapLines={true}
              showLineNumbers={true}
            >
              {/* {HtmlParser(contentBot)} */}
              {contentBot}
            </SyntaxHighlighter>
          </Modal>}

        </div>
      )

  ) : (
    <></>
  );
};

export default TabChat;

import { Button } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import BtnLoginFacebook from "../../../components/BtnLoginFacebook/BtnLoginFacebook";
import FormLoginEmailPhone from "../../LoginPageEmail/FormLoginEmailPhone";
import useWindowSize from "../../../hook/useWindowSize";

export default function HoanThanhFree() {
  const { widthWindow, heightWindow } = useWindowSize();
  const isPhone = widthWindow<768?true:false
  return (
    <div className="w-full flex flex-col space-y-10 justify-center items-center p-5 text-white">
      <>
        <p className="text-lg lg:text-2xl">ĐĂNG KÝ THÀNH CÔNG</p>

        {/* <Button className="px-5 bg-yellow-400 border-none text-black" type="primary" htmlType="submit">
          <a href="/login"> Về trang chủ</a>
        </Button> */}
        {/* <BtnLoginFacebook /> */}
        <div className=" w-full flex-shrink-0 border-none p-5 flex flex-col items-start ">
            <div className="  flex-grow  w-full flex flex-col justify-center space-y-7 pb-24 relative z-10 items-center">
              <BtnLoginFacebook />
             {isPhone && <>
              <span className="pt-3">HOẶC</span>
              <div style={{width:'90%'}}>
              <FormLoginEmailPhone/>
              </div>
             </>}
            </div>
          </div>
      </>
    </div>
  );
}
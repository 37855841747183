import React, { useState } from "react";
import { useSelector } from "react-redux";
import ThongTinChinhFree from "./ThongTinChinhFree";
import ThongTinChiTietFree from "./ThongTinChiTietFree";
import HoanThanhFree from "./HoanThanhFree";
import StepSignupFree from "./StepSignupFree";
import useWindowSize from "../../../hook/useWindowSize";
export default function ContainerSigupDesktopFree() {
  
  const { widthWindow, heightWindow } = useWindowSize();
  const isPhone = widthWindow < 639 ? true : false
  const { currentStep } = useSelector((state) => state.signUp);

  const renderConTentStep = () => {
    switch (currentStep) {
      case 0:
          return <ThongTinChinhFree />;

      case 1:
        return <HoanThanhFree />;
      default:
        break;
    }
  };
  return (
    <div className={`w-full h-full  p-3 lg:p-5 ${isPhone?'space-y-1':'space-y-5'}  flex-grow card-theme  border-none `}>
      <StepSignupFree />
      <div className="lg:w-full  w-2/3 mx-auto ">{renderConTentStep()}</div>
    </div>
  );
}
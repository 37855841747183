import React from "react";
import ContentTab from "../../../components/ContentTab/ContentTab";
import DetailTaskByTime from "../DailyXpByTime/DetailTaskByTime";
import { Trans } from "react-i18next";
const data = [
  {
    label: <Trans i18nKey='trangChu.ngay' />,
    key: "1",
    children: <DetailTaskByTime task={20} course={37} project={12} typeFilter={0} />,
  },
  {
    label: <Trans i18nKey='trangChu.thang' />,
    key: "2",
    children: <DetailTaskByTime task={24} course={35} project={17} typeFilter={1} />,
  },
  {
    label: <Trans i18nKey='trangChu.nam' />,
    key: "3",
    children: <DetailTaskByTime task={55} course={27} project={32} typeFilter={2} />,
  },
  {
    label: <Trans i18nKey='trangChu.tatCa' />,
    key: "4",
    children: <DetailTaskByTime task={82} course={31} project={82} typeFilter={3} />,
  },
];
export default function Static() {
  return (
    <div className=" px-2   ">
      <ContentTab paddingHeaderX={25} paddingHeaderY={3} paddingTabItemX={15} paddingTabItemY={20} data={data} />
    </div>
  );
}

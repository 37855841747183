import { http } from "../utils/config"

const GET_PATH = "/package"
const GET_PATH_TRACKING = "/trackingPayment"

const getPackageService = function(){
    return http.get(`${GET_PATH}`)
}
const getPackageByIdCourse = function(id){
    return http.get(`${GET_PATH}/course?id=${id}`)
}
const sendRedirectONEPAY = function(obj){
    return http.post(`${GET_PATH}/redirect`,obj)
}
const ghiDanhPackage = function(obj){
    return http.post(`/dangkyhoc/ghiDanhPackage`,obj)
}
const insertTrackingPayment = function(obj){
    return http.post(`/trackingPayment`,obj)
}
const sendMail = function(obj){
    return http.post(`${GET_PATH_TRACKING}/sendMailPaymentInfor`,obj)
}
const addLeadformCybersoft = function(obj){
    return http.post(`https://apicrm.cybersoft.edu.vn/api/leadform`,obj)
}

export const packageService = {
    getPackageByIdCourse,
    sendRedirectONEPAY,
    ghiDanhPackage,
    getPackageService,
    insertTrackingPayment,
    sendMail,
    addLeadformCybersoft
}
import environment from "../environments/environment";

export const getHinhAnh = (url) => {
  return `https://apimentor11.cybersoft.edu.vn/images/${url}`;
};

export let getHinhAnhFB = (id,data) => {

  if (!id) {
    return `https://ui-avatars.com/api/?name=${data?.hoTen}&background=random&bold=true`;
  }
  return `https://graph.facebook.com/${id}/picture?type=large`;
};
export const getHinhAnh2 = (data) => {
  if(data.hinhAnh){
    return `https://apimentor11.cybersoft.edu.vn/${data.hinhAnh}`;
  }
  else{
    return `https://ui-avatars.com/api/?name=${data?.tenChuyenDe}&background=random&bold=true`;
  }
  
};

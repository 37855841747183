import React from "react";
export default function ItemBanner({ title, content, width = "30%" }) {
  return (
    <div style={{ width: width }} className="item_banner  p-2  ">
      <h4 className="uppercase text-content ">{title}</h4>

      <div className="space-y-1">{content}</div>
    </div>
  );
}

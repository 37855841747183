import React from "react";
import "./CardLoTrinh_design_code.css";
import StartEndDay from "./StartEndDay";
import LazyLoad from "react-lazyload";
import { getHinhAnh } from "../../../utils/GetHinhanh";
import { BASE_URL_CODINGCAMP } from "../../../services/axios.service";

export default function IntroLoTrinh({
  loTrinh,
  isHoverActive,
  btn_tuVan,
  isBlackFridayDay = false,
}) {
  return (
    <div
      className={
        isHoverActive
          ? `HandbookCard__Wrapper-sc-7b5434-1 hoyCxl  cursor-pointer card_design_code activeHover h-full`
          : `HandbookCard__Wrapper-sc-7b5434-1 hoyCxl  cursor-pointer card_design_code h-full  `
      }
    >

      <div className=" iusqHF flex items-center justify-between ">
        <div className="HandbookCard__TextWrapper-sc-7b5434-5 kqcNFY">

          <h3 className="TextStyles__H3H5-h7d1e3-6 HandbookCard__Title-sc-7b5434-7 hpcBLU md:text-lg lg:text-xl">
            {loTrinh.tenGoi.toUpperCase()}
          </h3>
          <p className="TextStyles__SmallText-h7d1e3-12 HandbookCard__Description-sc-7b5434-8 jslvrh"></p>

          {/* <div className="flex  space-y-2 items-start  flex-col flex-shrink-0  font-medium">
              Thời hạn truy xuất : {loTrinh.thoiHan} tháng
            </div> */}

        </div>
      </div>
      <div
        color="linear-gradient(180deg, #408DD5 0%, #630B8C 100%)"
        className="HandbookCard__SecondBackground-sc-7b5434-3 lcvTnr  cursor-pointer card_design_code_bg"
      />
    </div>
  );
}

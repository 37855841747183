import { http } from "../utils/config";

const GET_PATH = "/groupchat";

const getListGroupChat = () => {
  return http.get(GET_PATH);
};

const addGroupChat = (data,DSUser) => {
  return http.post(`${GET_PATH}?DSUser=${DSUser}`, data);
};

const getGroupChatById = (groupChatId) => {
  return http.get(`${GET_PATH}/${groupChatId}`);
};

const editGroupChat = (groupChatId, data, dsUser) => {
  return http.put(`${GET_PATH}/${groupChatId || data.id}?DSUser=${dsUser}`, data);
};

const delGroupChat = (groupChatId) => {
  return http.delete(`${GET_PATH}/${groupChatId}`);
};

const getGroupChatByDSChat = (dschat1, gftid) => {
  let dschat2 = JSON.stringify(dschat1);

// Mã hóa chuỗi JSON để sử dụng trong query params
let dschat = encodeURIComponent(dschat1);
  // console.log(dschat, gftid)
  return http.get(`${GET_PATH}/getDsChat?dsChat=${dschat}&gftid=${gftid}`);
};

const getGroupChatByChuyenDeId = (dschat, chuyenDeId) => {
  // console.log(dschat,chuyenDeId)
  return http.get(`${GET_PATH}/getDsChatByChuyenDeId`, { params: { dschat, chuyenDeId } });
};
const getGroupChatBot = () => {
  // console.log(dschat,chuyenDeId)
  return http.get(`${GET_PATH}/chatBot`);
};
const sendGroupChatBot = (obj) => {
  // console.log(dschat,chuyenDeId)
  return http.put(`${GET_PATH}/sendChatBot`,obj);
};

export const groupChatService = {
  getListGroupChat,
  addGroupChat,
  getGroupChatById,
  editGroupChat,
  delGroupChat,
  getGroupChatByDSChat,
  getGroupChatByChuyenDeId,
  getGroupChatBot,
  sendGroupChatBot
};

import { Button, Modal } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import ReactPlayer from "react-player";
import { ControlBar, PlaybackRateMenuButton, Player } from "video-react";
import VideoPlayer from "../../components/video/VideoPlayer";

export default function Collapse({ data }) {
  const [height, setHeight] = useState(0);
  const [source, setSource] = useState("");
  const [visible, setVisible] = useState(false);

  const [listVideo, setListVideo] = useState([]);
  useEffect(() => {
    axios
      .get(`https://apicrm.cybersoft.edu.vn/api/khoahoc/free/${data.id}`)
      .then((res) => {
        console.log(res.data.content.thongTinChuongHoc[0]?.thongTinBaiHoc);
        setListVideo(res.data.content.thongTinChuongHoc[0]?.thongTinBaiHoc);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [data.id]);
  function handleGetVideoTrongNuoc(item) {
    axios
      .get(`https://apicrm.cybersoft.edu.vn/api/file/ftp-video/${item.noiDung}`)
      .then((res) => {
        setSource(res.data);
        setVisible(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleGetVideoNuocNgoai(item) {
    axios
      .get(`https://apicrm.cybersoft.edu.vn/api/file/ftp-video-digital/${item.noiDung}`)
      .then((res) => {
        setSource(res.data);
        setVisible(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="">
      <Modal
      width="80%"
      style={{ top: 10 }}
        // title={titleVideo}
        visible={visible}
        onCancel={() => {
          setVisible(false);
          setSource("");
        }}
        footer={null}
        centered
      >
        {source !== "" ? (
          <div style={{ height: "100%", width: "100%" }}>

            {/* <ReactPlayer
                playbackRate={1}
                playing={true}
                url={source}
                width="100%"
                height="100%"
                // style={{ borderRadius: "100px" }}
                controls={true}
                config={{
                  file: {
                    forceHLS: true,
                  },
                }}
              /> */}

            <VideoPlayer source={source} />


          </div>
        ) : null}
      </Modal>
      <div className=" c-col-full card-theme p-0 relative">
        <button
          aria-expanded={height !== 0}
          aria-controls="example-panel"
          onClick={() => setHeight(height === 0 ? "auto" : 0)}
          className="w-full space-x-2 c-card-content flex items-center justify-between px6 "
        >
          <p className="font-bold text-title text-left">
            {data.tenKhoaHoc} <span className="text-yellow-theme">{data.soLuotXem} lượt xem</span>
          </p>
          <div className="flex items-center space-x-10 ">
            <i
              className={`fa fa-angle-up transform text-white  ${height === "auto" ? "rotate-180" : "rotate-0"
                }`}
            ></i>
          </div>
        </button>
        <AnimateHeight id="example-panel" duration={500} height={height}>
          <div className="">
            <div className="space-y-5 c-card-content">
              {listVideo.map((item, index) => {
                return (
                  <div className="flex items-center justify-between px-10">
                    <p className="text-white">
                      {item.tenBaiHoc}{" "}
                      <span className="text-yellow-theme">{item.soLuotXem} lượt xem</span>
                    </p>
                    <div className="space-x-5">
                      <button
                        onClick={() => handleGetVideoTrongNuoc(item)}
                        className="bg-blue-600 border-none text-white text-xl px-5 py-2"
                      >
                        Server 1
                      </button>
                      <button
                        onClick={() => handleGetVideoNuocNgoai(item)}
                        className="bg-red-600 border-none text-white text-xl px-5 py-2"
                      >
                        Server 2
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </AnimateHeight>
      </div>
    </div>
  );
}

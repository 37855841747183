import React, { createContext, useContext, useRef, useState } from "react"
import {
  HubConnectionBuilder,
  LogLevel,
  HubConnection
} from "@microsoft/signalr"
// import { useAppDispatch, useAppSelector } from "../redux/hooks"
// import {
//   getGroupChatByDsChatApi,
//   getGroupChatByDsChatByChuyenDeApi
// } from "../redux/GroupChatReducer/groupChatReducer"


import { message } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { API_URL_DOMAIN } from "../utils/urlDomain"

const AuthContext = createContext({
  connection: null,
  myRoom: { room: "", taskid: 0 },
  myUser: "",
  messages: { type: "success", message: "", description: "" },
  joinRoom: async function() {},
  sendMessage: async function() {},
  sendUsersConnected: async function() {},
  closeConnection: async function() {},
  changeConn: () => {},
  findConnection: () => "",
  changeMyRoomRef: () => {},
  getMyRoomRef: () => ({ room: "", taskid: 0 }),
  connection2: null,
  myRoom2: { room: "", chuyenDeId: 0 },
  myUser2: "",
  joinRoom2: async function() {},
  sendMessage2: async function() {},
  sendUsersConnected2: async function() {},
  closeConnection2: async function() {},
  changeConn2: () => {},
  findConnection2: () => "",
  changeMyRoomRef2: () => {},
  getMyRoomRef2: () => ({ room: "", chuyenDeId: 0 })
})

const apiUrl = API_URL_DOMAIN

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch()
  const { arrDSNguoiDung, thongTinMentor } = useSelector(
    state => state.groupForTask
  )
  const myRoomRef = useRef({
    room: "",
    taskid: 0
  })
  const myRoomRef2 = useRef({
    room: "",
    chuyenDeId: 0
  })
  const myUserIdRef = useRef("")
  const myUserIdRef2 = useRef("")

  const [messages, setMessages] = useState({
    type: "success",
    message: "",
    description: ""
  })

  const [connection, setConnection] = useState()
  // const [connections, setConnections] = useState([])
  const [connection2, setConnection2] = useState()
  // const [connections2, setConnections2] = useState([])

  // Chuyển room
  // const changeMyRoomRef = (room, taskid) => {
  //   console.log("room change")
  //   myRoomRef.current = { room, taskid }
  // }
  // const changeMyRoomRef2 = (room, chuyenDeId) => {
  //   console.log("room change")
  //   myRoomRef2.current = { room, chuyenDeId }
  // }
  //lây room hiện tại
  // const getMyRoomRef = () => {
  //   return myRoomRef.current
  // }
  // const getMyRoomRef2 = () => {
  //   return myRoomRef2.current
  // }

  //---- xu ly connections 1----
  // const addConn = (room, taskid, connection) => {
  //   setConnections(conns => [...conns, { room, taskid, connection }])
  // }
  // const delConn = (room, taskid) => {
  //   setConnections(conns => {
  //     const index = conns.findIndex(e => e.room === room && e.taskid === taskid)
  //     conns.splice(index, 1)
  //     return [...conns]
  //   })
  // }
  // const changeConn = connectionId => {
  //   const index = connections.findIndex(
  //     conn => conn.connection.connectionId === connectionId
  //   )
  //   if (index !== -1) setConnection(connections[index].connection)
  // }
  // const findConnection = (room, taskid) => {
  //   const index = connections.findIndex(
  //     e => e.room === room && e.taskid === taskid
  //   )
  //   if (index !== -1 && connections[index].connection.state === "Connected")
  //     return connections[index].connection.connectionId
  //   return null
  // }
  //----------------------------
  //---- xu ly connections 1----
  // const addConn2 = (room, chuyenDeId, connection) => {
  //   setConnections2(conns => [...conns, { room, chuyenDeId, connection }])
  // }
  // const delConn2 = (room, chuyenDeId) => {
  //   setConnections2(conns => {
  //     const index = conns.findIndex(
  //       e => e.room === room && e.chuyenDeId === chuyenDeId
  //     )
  //     conns.splice(index, 1)
  //     return [...conns]
  //   })
  // }
  // const changeConn2 = connectionId => {
  //   const index = connections2.findIndex(
  //     conn => conn.connection.connectionId === connectionId
  //   )
  //   if (index !== -1) setConnection(connections2[index].connection)
  // }
  // const findConnection2 = (room, chuyenDeId) => {
  //   const index = connections2.findIndex(
  //     e => e.room === room && e.chuyenDeId === chuyenDeId
  //   )
  //   if (index !== -1 && connections2[index].connection.state === "Connected")
  //     return connections2[index].connection.connectionId
  //   return null
  // }
  //----------------------------

  // Get api noi dung chat về reuducer
  const handleGetGroupChat = async (dsChat, taskid) => {
    // await dispatch(getGroupChatByDsChatApi(dsChat, taskid))
  }
  const handleGetGroupChat2 = async (dsChat, chuyenDeId) => {
    // await dispatch(getGroupChatByDsChatByChuyenDeApi(dsChat, chuyenDeId))
  }

  // coverten người dùng
  const returnTen = (id, arr) => {
    const index = arr.findIndex(e => e.id === id)
    return arr[index].hoTen
  }

  //-----hubconnection-----
  const joinRoom = async ({ userid, room, taskid }) => {
    try {
      console.log("join conection", userid, room)
      //?tao một connect map với hub trên sv
      const connection = new HubConnectionBuilder()
        .withUrl(`${apiUrl}/chat`)
        .configureLogging(LogLevel.Information)
        .build()
      connection.on("onJoinRoom", userM => {
        if (userid !== userM.userid) {
          setMessages({
            ...messages,
            message: `Bạn ${returnTen(
              userM.userid,
              arrDSNguoiDung
            )} vừa On đó !`
          })
        }
      })

      connection.on("ReceiveMessage", userM => {
        if (
          myRoomRef.current.room === room &&
          myRoomRef.current.taskid === taskid
        ) {
          console.log("tin nhắn mới")
          handleGetGroupChat(room, taskid)
        }

        if (myUserIdRef.current !== userM.userid) {
          if (userM.userid === thongTinMentor.id)
            setMessages({
              ...messages,
              message: `Mentor ${thongTinMentor.hoTen} vừa gữi tin !`,
              type: "info",
              description: ""
            })
          else
            setMessages({
              ...messages,
              message: `Bạn ${returnTen(
                userM.userid,
                arrDSNguoiDung
              )} vừa gữi tin !`,
              type: "info",
              description: ""
            })
        }
      })

      connection.on("outReceiveMessage", userM => {
        if (userid === userM.userid) {
          setMessages({
            ...messages,
            message: `Bạn bị distconnect ra khỏi room ${userM.room}`,
            type: "warning"
          })
        } else
          setMessages({
            ...messages,
            message: `${returnTen(userM.userid, arrDSNguoiDung)} vừa Off`
          })
      })
      connection.on("UsersInRoom", list => {
        console.log(list)
      })
      connection.onclose(e => {
        console.log(`Connectid ${connection.connectionId} close`)
        myRoomRef.current = { room: "", taskid: 0 }
        myUserIdRef.current = ""
        // delConn(room, taskid)
        setConnection(undefined)
        setMessages({ type: "success", message: "", description: "" })
      })
      await connection.start()
      let userConnection = { userid, room, taskid: Number(taskid) }
      console.log(userConnection)
      await connection.invoke("JoinRoom", userConnection)
      myRoomRef.current = { room, taskid }
      myUserIdRef.current = userid
      setConnection(connection)
      // addConn(room, taskid, connection)
      setMessages({
        ...messages,
        message: `Bạn vừa kết nối thành công success`,
        type: "success",
        description: ""
      })
      handleGetGroupChat(room, taskid)
    } catch (e) {
      message.error("Lỗi báo BE")
      setMessages({
        ...messages,
        message: "Connecting not successful",
        type: "error",
        description: "Cần xem lại đường truyền mạng !"
      })
    }
  }
  // tạo phương thức thông báo có tin nhắn mới
  const sendMessage = async (userid, room, taskid) => {
    try {
      if (connection instanceof HubConnection)
        await connection.invoke("SendMessage", {
          userid,
          room,
          taskid: Number(taskid)
        })
    } catch (e) {
      console.log(e)
    }
  }
  const sendUsersConnected = async room => {
    try {
      if (connection instanceof HubConnection)
        await connection.invoke("SendUsersConnected", room)
    } catch (error) {
      console.log(error)
    }
  }
  const closeConnection = async () => {
    try {
      if (connection) {
        // đóng connection hiện tại
        await connection.stop()
      }
    } catch (e) {
      message.error("Lỗi báo BE")
    }
  }

  //-----hubconnection2-----
  const joinRoom2 = async ({ userid, room, chuyenDeId }) => {
    try {
      console.log("join conection", userid, room)
      //?tao một connect map với hub trên sv
      const connection = new HubConnectionBuilder()
        .withUrl(`${apiUrl}/chat`)
        .configureLogging(LogLevel.Information)
        .build()
      connection.on("onJoinRoom2", userM => {
        if (userid !== userM.userid) {
          setMessages({
            ...messages,
            message: `Bạn ${
              userM ? returnTen(userM.userid, arrDSNguoiDung) : "Nhật"
            } vừa On đó !`
          })
        }
      })

      connection.on("ReceiveMessage2", userM => {
        if (
          myRoomRef2.current.room === room &&
          myRoomRef2.current.chuyenDeId === chuyenDeId
        ) {
          console.log("tin nhắn mới")
          handleGetGroupChat2(room, chuyenDeId)
        }
        if (myUserIdRef2.current !== userM.userid) {
          //!Cần check lại
          if (userM.userid === thongTinMentor.id)
            setMessages({
              ...messages,
              message: `Mentor ${thongTinMentor.hoTen} vừa gữi tin !`,
              type: "info",
              description: ""
            })
          else
            setMessages({
              ...messages,
              message: `Bạn ${returnTen(
                userM.userid,
                arrDSNguoiDung
              )} vừa gữi tin !`,
              type: "info",
              description: ""
            })
        }
      })

      connection.on("outReceiveMessage2", userM => {
        if (userid === userM.userid) {
          setMessages({
            ...messages,
            message: `Bạn bị distconnect ra khỏi room ${userM.room}`,
            type: "warning"
          })
        } else
          setMessages({
            ...messages,
            message: `${
              userM ? returnTen(userM.userid, arrDSNguoiDung) : "Ai đó"
            } vừa Off`
          })
      })
      connection.on("UsersInRoom2", list => {
        console.log(list)
      })
      connection.onclose(e => {
        console.log(`Connectid ${connection.connectionId} close`)
        myRoomRef2.current = { room: "", chuyenDeId: 0 }
        myUserIdRef2.current = ""
        // delConn2(room, chuyenDeId)
        setMessages({ type: "success", message: "", description: "" })
      })
      //Start
      await connection.start()
      let userConnection = { userid, room, chuyenDeId: Number(chuyenDeId) }
      console.log(userConnection)
      await connection.invoke("JoinRoom2", userConnection)
      myRoomRef2.current = { room, chuyenDeId }
      myUserIdRef2.current = userid
      //? Lưu connect vào state
      setConnection2(connection)
      //? Lưu connect vào connections
      // addConn2(room, chuyenDeId, connection)
      setMessages({
        ...messages,
        message: `Bạn vừa kết nối thành công success`,
        type: "success",
        description: ""
      })

      handleGetGroupChat2(room, chuyenDeId)
    } catch (e) {
      console.log(e)
      setMessages({
        ...messages,
        message: "Connecting not successful",
        type: "error",
        description: "Cần xem lại đường truyền mạng !"
      })
    }
  }
  // tạo phương thức thông báo có tin nhắn mới
  const sendMessage2 = async (userid, room, chuyenDeId) => {
    try {
      if (connection2 instanceof HubConnection)
        await connection2.invoke("SendMessage2", {
          userid,
          room,
          chuyenDeId: Number(chuyenDeId)
        })
    } catch (e) {
      console.log(e)
    }
  }
  const sendUsersConnected2 = async room => {
    try {
      if (connection2 instanceof HubConnection)
        await connection2.invoke("SendUsersConnected2", room)
    } catch (error) {
      console.log(error)
    }
  }
  const closeConnection2 = async () => {
    try {
      if (connection2) {
        await connection2.stop()
      }
    } catch (e) {
      console.log(e)
    }
  }
  //Khai báo values context
  const Values = {
    connection,
    myRoom: myRoomRef.current,
    myUser: myUserIdRef.current,
    messages,
    joinRoom,
    sendMessage,
    closeConnection,
    // changeConn,
    // findConnection,
    // changeMyRoomRef,
    // getMyRoomRef,
    sendUsersConnected,
    connection2,
    myRoom2: myRoomRef2.current,
    myUser2: myUserIdRef2.current,
    joinRoom2,
    sendMessage2,
    closeConnection2,
    // changeConn2,
    // findConnection2,
    // changeMyRoomRef2,
    // getMyRoomRef2,
    sendUsersConnected2
  }

  return <AuthContext.Provider value={Values}>{children}</AuthContext.Provider>
}

export default function UseConnection() {
  return useContext(AuthContext)
}

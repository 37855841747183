import classNames from "classnames";
import React, { useState } from "react";

export default function LootBox({ day, index, currentWeek, postDiemDanh, isActive }) {
  let activeColor = "linear-gradient(180deg, #0A0D14 0%, #29292F 100%)";
  let notActioveColor = " linear-gradient(180deg, #66676C 31.25%, #353034 100%)";
  return (
    <>
      <div
        onClick={() => {
          postDiemDanh();
        }}
        style={{ width: "13%" }}
        className="relative h-10"
      >
        <span

          class="dot-notify  absolute inline-flex h-4 w-4 -top-1 -right-1 rounded-full  z-10 "
        ></span>
        <span
          style={{
            background: day.isActive ? activeColor : notActioveColor,
            border: day.isActive ? "1px solid #FFC94C" : "none",
          }}
          className={classNames(
            " cursor-pointer h-8 w-full relative top-0 left-0   text-content  font-bold flex items-center justify-center  ",
            {
              "rounded-tl-xl rounded-bl-xl": index == 0,
            },
            {
              "rounded-tr-xl rounded-br-xl": index == currentWeek.length - 1,
            }
          )}
        >
          {day.letter}
        </span>
      </div>
    </>
  );
}

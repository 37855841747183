import React, { useEffect, useState } from "react";
import squareBG from "./effect.png";
import classNames from "classnames";
import SwapItem from "./SwapItem/SwapItem";
import { useDispatch, useSelector } from "react-redux";
import { getVatPhamUser, getVatPhamUserAction } from "../../redux/reducer/vatPhamSlice";

export default function YourList({ activeFilter }) {
  let listVatPham = useSelector((state) => state.vatPhamSlice.vatPhamUser) || [];
  const [selectedIndex, setSelectedIndex] = useState(0);
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getVatPhamUserAction());
  }, []);
  useEffect(() => {
    setSelectedIndex(0);
  }, [activeFilter]);

  const renderGridLayout = () => {
    let newList = listVatPham?.filter(({ maNhom }) => {
      if (activeFilter == 0) return true;
      return maNhom == activeFilter;
    });
    let gridContainer = [];
    for (let i = 0; i < 16; i++) {
      let item = (
        <div className="card-theme h-32 w-32 rounded">
          {newList[i] && (
            <div
              onClick={() => {
                setSelectedIndex(i);
              }}
              className={classNames("w-full h-full relative p-2 flex-shrink-0 your_item", {
                "bg-yellow-400": selectedIndex == i,
              })}
            >
              <img
                src={newList[i].hinhAnh}
                className=" rounded object-cover h-full w-full block"
                alt=""
              />
              <img className="absolute w-full h-full top-0 left-0 " src={squareBG} alt="" />
              <p className="  absolute -bo  ttom-1 -right-1 w-5 h-5 rounded-full bg-yellow-theme text-black font-bold text-center">
                {newList[i].soLuong}
              </p>
            </div>
          )}
        </div>
      );
      gridContainer.push(item);
    }
    return gridContainer;
  };

  return (
    <div
      className=" c-card-content w-full rounded flex md:flex-col-reverse sm:flex-col-reverse"
      style={{
        background:
          "linear-gradient(180deg, rgba(255, 255, 255, 0.08) -12.05%, rgba(0, 0, 0, 0.08) 111.83%)",
      }}
    >
      <div className="grid grid-cols-4  2xl:grid-cols-3 md:grid-cols-5 sm:grid-cols-3 gap-4 flex-shrink-0 h-max-content">
        {renderGridLayout()}
      </div>
      <SwapItem item={listVatPham[selectedIndex]} />
    </div>
  );
}

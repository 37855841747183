import { useQuery } from "@tanstack/react-query";
import axios from 'axios';

export function useLstImgTag() {
  return useQuery({
    queryKey: ['lstImgTag'],
    queryFn: () => {
      return axios
        .get("https://apimentor11.cybersoft.edu.vn/api/danh-sach-tag/lay-hinh-tag")
        .then(res => res.data.content);
    },
    staleTime: 24 * 60 * 60 * 1000, // Dữ liệu được coi là mới trong 24h
    cacheTime: 7 * 24 * 60 * 60 * 1000, // Dữ liệu còn trong bộ nhớ cache 7 ngày sau khi không còn được sử dụng
    refetchOnWindowFocus: false,
    retry: false,
  });
}

import React from "react";
import ModelContentIcon from "../../../components/ModalContentIcon/ModalContentIcon";
import { iconTaskPage } from "../../../components/ModalContentIcon/utilities";
import useWindowSize from "../../../hook/useWindowSize";
export default function ItemNoiDungChuyenDe({ item }) {
  const { widthWindow, heightWindow } = useWindowSize();
  const isPhone = widthWindow < 768 ? true : false
  const extractIconModal = (loai) => {
    switch (loai) {
      case "monhoc":
        return <ModelContentIcon loai={iconTaskPage.MonHocCuaChuyenDe} />;
      case "video":
        return <ModelContentIcon loai={iconTaskPage.VideoCuaChuyenDe} />;
      case "nhiemvu":
        return <ModelContentIcon loai={iconTaskPage.NhiemVuCuaChuyenDe} />;
      case "runcode":
        return <ModelContentIcon loai={iconTaskPage.RuncodeCuaChuyenDe} />;
      case "capstone":
        return <ModelContentIcon loai={iconTaskPage.DuAnCuaChuyenDe} />;
      case "tracnghiem":
        return <ModelContentIcon loai={iconTaskPage.TracNghiemCuaChuyenDe} />;
    }
  };
  return (
    <div className="flex items-center">
      <div className="icon_item_theme w-8 h-8 mr-2 icon-main">
        {extractIconModal(item.type)}
        <span className="bg_icon_item_theme"></span>
      </div>
      <p className="text-content uppercase text-left" style={{fontSize:`${isPhone?'9px':''}`}}>{item.about}</p>
    </div>
  );
}

import { useMediaQuery } from "react-responsive";
import React from "react";
import LoginPageDeskTop from "./LoginPageDesktop";
import LoginPageTablet from "./LoginPageTablet";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 640 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ maxWidth: 639 });

  return isTablet ? children : null;
};

export default function LoginPage({}) {
  return (
    <>
      <Desktop>
        <LoginPageDeskTop />
      </Desktop>
      <Tablet>
        <LoginPageTablet />
      </Tablet>
    </>
  );
}

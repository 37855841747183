import React, { useEffect, useState } from 'react'
import './HeaderBottom.css'
import { NavLink } from 'react-router-dom';
const HeaderBottom = () => {
  const [showNav, setShowNav] = useState(false);
  const [hideTimeout, setHideTimeout] = useState(null);
  let lastScrollTop = 0;

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        setShowNav(true);
      }

      if (hideTimeout) {
        clearTimeout(hideTimeout);
      }

      const timeout = setTimeout(() => {
        setShowNav(false);
      }, 1000);

      setHideTimeout(timeout);

      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };
    const handleTouchStart = () => {
      setShowNav(true);
      if (hideTimeout) {
        clearTimeout(hideTimeout);
      }
      const timeout = setTimeout(() => {
        setShowNav(false);
      }, 2000);
      setHideTimeout(timeout);
    };

    window.addEventListener('scroll', handleScroll);

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('touchstart', handleTouchStart);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('touchstart', handleTouchStart);
      if (hideTimeout) {
        clearTimeout(hideTimeout);
      }
    };
  }, [hideTimeout]);
  return (
    <div className={`bottom-nav ${showNav ? 'show h-16' : ''}`}>
      <div className="flex justify-between items-center h-full headerBotom">
        <div className='w-1/3 text-center'>
          <NavLink to='/package' activeClassName="active">
            <i className="text-xl fa-solid fa-cart-shopping"></i>
          </NavLink>
        </div>
        <div className='w-1/3 text-center'>
          <NavLink to='/dash-broad' activeClassName="active">
            <i className='text-xl fa fa-home'></i>
          </NavLink>
        </div>
        <div className='w-1/3 text-center'>
          <div to='' activeClassName="active">
            <i className='text-xl fa-solid fa-bars'></i>
          </div>
        </div>

      </div>
    </div>
  )
}

export default HeaderBottom
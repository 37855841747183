import React, { useEffect, useMemo, useState } from "react";

import CollapseTask from "./CollapseTask/CollapseTask";
import BannerTask from "./Banner/BannerTask";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataTaskPageAction,
  getDataTaskPageFreeAction,
  getDataTaskPageTrialAction,
} from "../../redux/reducer/taskPageSlice";
import SpinnerComponent from "../../components/Spinner";
import { checkDemoUser, checkLearning } from "../../utils/HocDemoUtils";
import * as signalR from "@microsoft/signalr";
import { BASE_URL_CODINGCAMP } from "../../services/axios.service";
import { selectThongTinMoRong } from "../../redux/reducer/authReducer";
import axios from "axios";
import BannerTaskFree from "./Banner/BannerTaskFree";
import {
  getChuyenDeApi,
  getLstByTag,
  getLstByTagApi,
} from "../../redux/reducer/chuyenDeReducer-Chat";
import {
  useLstLoTrinhDangHoc,
  useLstLoTrinhUser,
} from "../../hook/useLstLoTrinhUser";
import { history } from "../../App";
import { t } from "i18next";
import localStorageServ from "../../services/locaStorage.service";
import useWindowSize from "../../hook/useWindowSize";
import { useLstImgTag } from "../../hook/useLstImgTag";
import { API_URL_DOMAIN } from "../../utils/urlDomain";
export default function TaskPageFree() {
  const [arrChuyenDe, setArrChuyenDe] = useState();
  const [arrCyber, setArrCyber] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isCollapse, setIsCollapse] = useState(true);
  const { widthWindow, heightWindow } = useWindowSize();
  const isPhone = widthWindow < 798 ? true : false;
  const userInfo = localStorageServ.userInfo.get();
  let { dataTaskPage, loading } = useSelector((state) => state.taskPageSlice);
  let { lstCDByTag } = useSelector((state) => state.chuyenDe);
  let { chuyenDeId } = useParams();
  const { data, isLoading, error } = useLstLoTrinhUser(userInfo);
  const { data: dataDangHoc } = useLstLoTrinhDangHoc(userInfo?.id);
  const { data: listImgTag } = useLstImgTag();
  let dispatch = useDispatch();
  const isLearning = checkLearning(chuyenDeId, dataDangHoc);

  const handleShowMore = () => {
    setShowAll(!showAll);
};
  const displayedItems = showAll ? arrCyber : arrCyber.slice(0, 3);
  const fetchData = () => {
    if (dataDangHoc) {
      if (!isLearning || !userInfo?.kichHoat) {
        dispatch(getDataTaskPageFreeAction(chuyenDeId));
      } else if (userInfo?.email != "freeacc@gmail.com") {
        dispatch(getDataTaskPageAction(chuyenDeId));
      }
    }
  };
  useEffect(() => {
    fetchData();
    return () => {
      dispatch(getDataTaskPageFreeAction.fulfilled({}));
      dispatch(getLstByTag([]));
    };
  }, [chuyenDeId, dataDangHoc]);
  useEffect(() => {
    axios
      .get(`${API_URL_DOMAIN}/chuyende`)
      .then((res) => {
        setArrChuyenDe(res.data.content);
      })
      .catch((err) => {});
  }, [chuyenDeId]);
  useEffect(() => {
    if (dataTaskPage?.id == chuyenDeId) {
      dispatch(getLstByTagApi(JSON.stringify(dataTaskPage?.danhSachTag)));
    }
    if (dataTaskPage) {
      localStorageServ.khoahoc.set(dataTaskPage.tenChuyenDe);
    }
  }, [dataTaskPage]);
  useEffect(() => {
    axios.get(`https://apicrm.cybersoft.edu.vn/api/gioithieu`).then((res) => {
      setArrCyber(res.data.content);
    });
    dispatch(getChuyenDeApi());
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${BASE_URL_CODINGCAMP}/chat`)
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection
      .start()
      .then(() => {})
      .catch((err) => {});

    connection.on("updateDemoBH", (message) => {
      fetchData();
    });

    // Cleanup function
    return () => {
      connection.off("updateDemoBH");
      connection.stop();
    };
  }, []);
  return (
    <div className="w-full h-full ">
      <div className="c-col-full">
        <p
          className="cursor-pointer underline mb-2 pt-5"
          onClick={() => {
            history.back();
          }}
        >
          <i className="fa-duotone fa-backward"></i>
          {` `} {t("chiTietNhiemVu.quayLai")}
        </p>
        {dataTaskPage != {} && <BannerTaskFree dataTask={dataTaskPage} />}
      </div>
      <div className="flex relative">
        <div
          className={`${
            isPhone ? "c-col-full" : isCollapse ? "c-col-66" : "c-col-95"
          }  space-y-5`}
        >
          {loading && (
            <div className="flex justify-center">
              {" "}
              <div className=" loading_progress"></div>
            </div>
          )}
          {dataTaskPage?.danhSachMonHoc?.map((item, index) => {
            return (
              <CollapseTask
                dataContent={item}
                key={index}
                index={index}
                arrChuyenDe={arrChuyenDe}
              />
            );
          })}
        </div>
        {isPhone ? (
          ""
        ) : isCollapse ? (
          <div className="c-col-34">
            <div className="c-col-full card-theme">
            <p className="font-bold text-title text-left">
                <i className="fa-regular fa-sidebar-flip pr-2 cursor-pointer"  onClick={() => {
                    setIsCollapse(false);
                  }}></i>
                Khoá học kiến tập sự nghiệp tại CyberSoft
              </p>
              <div className="py-5">
                {displayedItems?.map((item, idx) => {
                  if (item.metaData == "Cybersoft") {
                    return (
                      <div
                        key={idx}
                        className="flex  items-center py-3 cursor-pointer"
                        onClick={() => {
                          // history.push(`/task-page-free/${item.id}`)
                          window.open(item.linkMoTa, "_blank");
                        }}
                      >
                        <img
                          className="object-cover rounded-lg w-48 md:rounded-none md:rounded-s-lg"
                          src={`${item.hinhAnh}`}
                          alt={item.tenSanPham}
                        />
                        <div className="flex flex-col justify-between p-2 leading-normal">
                          <p className="mb-2 text-base font-bold tracking-tight text-white">
                            {item.tenSanPham}
                          </p>
                          {/* <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p> */}
                        </div>
                      </div>
                    );
                  }
                })}
                {arrCyber.length > 3 && (
                    <div className="text-center">
                        <button className="text-white hover:underline" onClick={handleShowMore}>
                            {showAll ? 'Thu gọn' : 'Xem thêm'}
                        </button>
                    </div>
                )}
              </div>
              <p className="font-bold text-title text-left pt-4">
                <i
                  className="fa-regular fa-sidebar-flip pr-2 cursor-pointer"
                  onClick={() => {
                    setIsCollapse(false);
                  }}
                ></i>
                Khoá học cao cấp liên quan
              </p>
              <div className="py-5">
                {lstCDByTag
                  ?.filter((item) => {
                    let checkLearn = checkLearning(item.id, dataDangHoc);
                    return !checkLearn && item.id != chuyenDeId;
                  })
                  .slice(0, 3)
                  .map((item, idx) => {
                    let arrTag = JSON.parse(item.danhSachTag);

                    return (
                      <div
                        key={idx}
                        className="flex  items-center py-3 cursor-pointer"
                        onClick={() => {
                          history.push(`/task-page-free/${item.id}`);
                        }}
                      >
                        <img
                          className="object-cover rounded-lg w-48 md:rounded-none md:rounded-s-lg"
                          src={`https://apimentor11.cybersoft.edu.vn/${item.hinhAnh}`}
                          alt={item.tenChuyenDe}
                        />
                        <div className="flex flex-col justify-between p-2 leading-normal">
                          <p className="mb-2 text-base font-bold tracking-tight text-white">
                            {item.tenChuyenDe}
                          </p>
                          {/* <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p> */}
                          <div className="flex flex-wrap mb-2">
                            {arrTag.map((tag) => {
                              let item1 = listImgTag?.find((item1) => {
                                return item1?.value == tag;
                              });
                              return (
                                <div key={tag.value} className={"w-6 h-6 p-1"}>
                                  <img
                                    className="w-full h-full object-cover rounded"
                                    src={item1?.image}
                                    alt={item1?.value}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="text-center">
                        <button className="text-white hover:underline" onClick={() => { 
                          history.push('/package')
                         }}>Xem thêm
                        </button>
                    </div>
              </div>
              
            </div>
          </div>
        ) : (
          <div className="c-col-5">
            <div className="c-col-full">
              <i
                className="fa-regular fa-sidebar-flip pr-2 cursor-pointer"
                onClick={() => {
                  setIsCollapse(true);
                }}
              ></i>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

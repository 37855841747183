import React, { useEffect, useState } from "react";
import "./style.scss";
import StepListRequireNopBai from "../StepProjectInstruction/StepProjectInstruction";
import ContentTab from "../ContentTab/ContentTab";
import ContentGuide, { HINT, SOLUTION, SOLUTION_VIDEO, RESULT } from "./ContentGuide";
import servCodingCamp from "../../services/codingCam.service";
import { parentPage } from "../../routes/utitlity";

import toast from "react-hot-toast";
import classNames from "classnames";
import { updateThongTinMoRongAction } from "../../redux/reducer/authReducer";
import { useDispatch } from "react-redux";
export default function ListRequireNopBai({
  countRequire,
  noiDungNhiemVu,
  required,
  orderNumber,
  trangThaiTongBaiTap,
  monHocId,
  taskId,
  chuyenDeId,
  maLoaiNhiemVu,
  getThongTinBaiTapNop = () => {},
  tongExp,
  tongCoin,
}) {
  const {
    noiDung,
    hint,
    ketQua,
    solution,
    videoSolution,
    requireId,
    solutionCoin,
    videoSolutionCoin,
    hintCoin,
  } = required || {};
  const [listStatus, setListStatus] = useState([]);
  let dispatch = useDispatch();

  useEffect(() => {
    fetchListStatus();
  }, []);
  // check task page or pratice page to call api that suit

  const currentUrl = window.location.href;
  const isPracticePage = currentUrl.includes(parentPage.pratice);
  const isTaskPage = currentUrl.includes(parentPage.task);
  const listGuide = [
    // loaiHint: 0: mở hint, 1: mở solution, 2: mở solution video

    { label: "Đáp án", value: ketQua, type: RESULT },
    { label: "Gợi ý", value: hint, type: HINT, typeId: 0, coin: noiDungNhiemVu.hintCoin },
    {
      label: "Hướng dẫn",
      value: solution,
      type: SOLUTION,
      typeId: 1,
      coin: noiDungNhiemVu.solutionCoin,
    },
    {
      label: "Video hướng dẫn",
      value: videoSolution,
      type: SOLUTION_VIDEO,
      coin: noiDungNhiemVu.solutionCoin,
      typeId: 2,
    },
  ].filter((item) => {
    // if (item.type == RESULT) return true;
    return item.value != undefined && item.value.trim() != "";
  });
  let servCheckHint;
  let servMoHint;
  if (isPracticePage) {
    servCheckHint = servCodingCamp.checkTrangThaiHintPractice;
    servMoHint = servCodingCamp.moHintPratice;
  }
  if (isTaskPage) {
    servCheckHint = servCodingCamp.checkTrangThaiHint;
    servMoHint = servCodingCamp.moHint;
  }
  let fetchListStatus = () => {
    let payload = {
      requireId: requireId,
      loaiHint: 0,
      maLoai: maLoaiNhiemVu,
      monHocId,
      taskId,
      chuyenDeId,
    };
    servCheckHint(payload)
      .then((res) => {
        setListStatus(res.data.content);
      })
      .catch((err) => {
        
      });
  };
  const handleBuyGuide = (payload) => {
    servMoHint(payload)
      .then((res) => {

        if (res.data.content) {
          // mua thành công
          fetchListStatus();
          dispatch(updateThongTinMoRongAction());
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        
      });
    // loaiHint: 0: mở hint, 1: mở solution, 2: mở solution video
  };

  const renderDetailTask = () => {
    return listGuide.map(({ label, value, type, typeId, coin }, index) => {
      let dataBuyHint = {
        chuyenDeId,
        monHocId,
        taskId,
        requireId: requireId,
        maLoai: maLoaiNhiemVu,
        loaiHint: typeId,
      };
      return {
        label,
        key: index,
        children: (
          <div className="c-card-content text-content space-y-1 leading-7">
            <ContentGuide
              handleBuyGuide={() => {
                handleBuyGuide(dataBuyHint);
              }}
              dataBuyHint={dataBuyHint}
              listStatus={listStatus}
              type={type}
              valueGuide={value}
              coin={coin}
              label={label}
            />
          </div>
        ),
      };
    });
  };
  // listGuide

  // check nếu ko có đáp án, hint, video,.. thì ẩn =>widht =0
  return (
    <div className="flex lg:flex-col flex-row  gap-4 h-full md:h-auto" >
      <div
        className={classNames("   h-full tabStepListRequireNopBai", {
          "c-col-50": listGuide.length > 0,
          "c-col-full": listGuide.length == 0,
        })}
      >
        <div className="c-card-content card-theme h-full">
          {/*  yêu cầu  */}
          <StepListRequireNopBai
            getThongTinBaiTapNop={getThongTinBaiTapNop}
            trangThaiTongBaiTap={trangThaiTongBaiTap}
            noiDung={noiDung}
            orderNumber={orderNumber}
            requireId={requireId}
            tongExp={tongExp}
            tongCoin={tongCoin}
            maLoaiNhiemVu={maLoaiNhiemVu}
            required={required}
            countRequire={countRequire}
          />
        </div>
      </div>
      <div id="" style={{ minHeight: 500 }} className="  w-full  h-full  ">
        <div className="c-card-content tab-hint card-theme h-full">
          <ContentTab
            paddingHeaderX={15}
            paddingHeaderY={3}
            paddingTabItemX={15}
            paddingTabItemY={5}
            data={renderDetailTask()}
           
          />
        </div>
      </div>
    </div>
  );
}

import { Button, message, Progress } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { createPayloadNopBai } from "../../utils/taskUtils";
import { typeTask } from "../../routes/utitlity";
import servCodingCamp from "../../services/codingCam.service";
import toast from "react-hot-toast";
import localStorageServ from "../../services/locaStorage.service";
import { typeTrangThaiNop } from "../StepProjectInstruction/util";
import { useDispatch } from "react-redux";
import { updateThongTinMoRongAction } from "../../redux/reducer/authReducer";
import { useTranslation } from "react-i18next";
export default function Navigate_Footer_PraticesResult({
  current,
  total,
  handleClickNextQuestion,
  handleClickPreQuestion,
  isDisableBtn,
  listQuestion,
  onSuccess,
  setNextTask,
  data
}) {
  const { t } = useTranslation()

  // const nguoiDung = LocalStorageUtil.getLoginLocalStorage();

  const handle_PostKetQua = async () => {
    
    // gọi api hoàn thành
  };

  const handleClickNext = () => {handleClickNextQuestion()
  };
  const percent = Math.floor((current / total) * 100);
  const nextBtnCss = isDisableBtn
    ? " bg-blue-theme text-white"
    : "  bg-blue-theme text-white ";
  return (
    <div
      style={{
        background:
          "radial-gradient(91.01% 91.01% at 16.52% 42.45%, #ffc94c 0%, #eebe47 23%, #edb720 52%",
      }}
      className=" bg-white flex items-center h-16 w-full justify-center space-x-10 px-16 border-none rounded-2xl"
    >
      <div className="flex items-cente space-x-5 justify-center max-w-screen-md w-full">
        <Progress
          step={total}
          percent={percent}
          // size="small"
          className="w-full"
          showInfo={false}
          strokeWidth={15}
          // rgb(139, 29, 234) 1.36%, rgb(74, 0, 224) 100%)
          strokeColor={{
            // "0%": "#4A00E0",
            "0%": "#301D09",
            "100%": "#FF0C0C",
            // "100%": "#8E2DE2",
          }}
          trailColor={"rgba(68, 66, 178, 0.1)"}
        />
        <span className="font-bold flex-shrink-0 text-content text-black">
          {current}/{total} câu
        </span>
      </div>
      <Button
        onClick={() => {
          handleClickPreQuestion();
        }}
        className={
          "  text-white duration-150 font-bold px-8 rounded  flex items-center h-10 flex-shrink-0 border-none  focus:border-blue-theme hover:border-transparent hover:shadow-lg" +
          nextBtnCss
        }
        // loading={loading}
      >
        {t('chiTietNhiemVu.cauTruoc')}
      </Button>
      <Button
        onClick={() => {
          return current === total ? handle_PostKetQua() : handleClickNext();
        }}
        className={
          "  text-white duration-150 font-bold px-8 rounded  flex items-center h-10 flex-shrink-0 border-none  focus:border-blue-theme hover:border-transparent hover:shadow-lg" +
          nextBtnCss
        }
        // loading={loading}
      >
        {t('chiTietNhiemVu.cauTiepTheo')}
      </Button>
    </div>
  );
}

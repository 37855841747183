import axios from "axios";
import { AxiosServCodingCamp } from "./axios.service";

/* eslint-disable no-useless-constructor */

class VatPhamRequestService {
  constructor() {}

  showItemGiamThoiGianQuiz = () => {
    const uri = `vatpham/giam-time-quiz-item`;
    return AxiosServCodingCamp.getMethod(uri, false);
  };
  showItemGiamThoiGianBaiTap = () => {
    const uri = `vatpham/mo-nop-bai-tap-item`;
    return AxiosServCodingCamp.getMethod(uri, false);
  };
  moHopQua = () => {
    const uri = `vatpham/su-dung-hop-qua`;
    return AxiosServCodingCamp.postMethod(uri, false);
  };
  homDoCuaBan = () => {
    let uri = `vatpham/hom-do-user`;
    return AxiosServCodingCamp.getMethod(uri);
  };
  giamThoiGianChoQuiz = (nopBaiId) => {
    let uri = `vatpham/giam-time-quiz/${nopBaiId}`;
    return AxiosServCodingCamp.postMethod(uri, false);
  };
  giamThoiGianChoBaiTap = (nopBaiId) => {
    let uri = `vatpham/mo-nop-bai-tap/${nopBaiId}`;
    return AxiosServCodingCamp.postMethod(uri, false);
  };
}

const servVatPham = new VatPhamRequestService();

export default servVatPham;

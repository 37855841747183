export let GuestLogin = () => {
  // rangThai: 0: binh luan, 1: chinh sua binh luan, 2: reply, 3: like binh luan, 4: bo like

  return (
    <div className="flex items-center space-x-5 w-full">
      <div className="w-20 h-20 object-cover rounded-full  bg-gray-300 flex-shrink-0"></div>
      <div
        onClick={() => {
          window.location.href = "/login";
        }}
        className="bg-gray-800 border-none w-full rounded text-red-500 text-xl p-4 font-medium   cursor-pointer"
      >
        {" "}
        Đăng nhập để bình luận
      </div>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";

import ProgressLine from "../../../components/ProgressLine/ProgressLine";
import Badge from "../../../components/Badge/Badge";
import { loaiNhiemVu } from "../../../utils/taskUtils";
import ItemTask, { isIconColappse } from "./ItemTask";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import PackageBottomFix from "../../ServicePakages/PackageBottomFix";
import { history } from "../../../App";
import PackageTable from "../../ServicePakages/PackageTable";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PackageTableV2 from "../../ServicePakages/PackageTableV2";
import axios from "axios";
import { API_URL_DOMAIN, emailFree } from "../../../utils/urlDomain";
import localStorageServ from "../../../services/locaStorage.service";
import useWindowSize from "../../../hook/useWindowSize";
import { useLstImgTag } from "../../../hook/useLstImgTag";

export default function CollapseTask({ dataContent, index,arrChuyenDe }) {
  const { t } = useTranslation();
  let { chuyenDeId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: listImgTag, isLoading, error } = useLstImgTag();

  const { widthWindow, heightWindow } = useWindowSize();
  const isPhone = widthWindow < 768 ? true : false;
  let userInfo = localStorageServ.userInfo.get();
  const [expandedRows, setExpandedRows] = useState({});
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [height, setHeight] = useState(index == 0 ? "auto" :'0');

  let soLuongHoanThanh = dataContent?.danhSachNoiDung.filter((item) => {
    return item.hoanThanh;
  }).length;

  // useEffect(() => {
  //   if (soLuongHoanThanh !== 0 && soLuongHoanThanh !== dataContent.danhSachNoiDung.length) {
  //     setHeight("auto");
  //   }
  //   return () => {
  //     setHeight("0");
  //   };
  // }, [soLuongHoanThanh, dataContent.danhSachNoiDung.length]);
  if (!dataContent) {
    return null;
  }

  const toggleRow = (rowId) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [rowId]: !prevExpandedRows[rowId],
    }));
  };
  let renderDanhSachNhiemVu = () => {
    return (
      <table style={{ width: "100%" , borderCollapse: "collapse" }}>
        <tbody>
          {dataContent.danhSachNoiDung
            .filter(
              ({ maLoaiNhiemVu }) =>
                maLoaiNhiemVu != loaiNhiemVu.HOMEWORK &&
                maLoaiNhiemVu != loaiNhiemVu.RECORD
            )
            .map((item, idx) => (
              <React.Fragment key={item.id}>
                <tr>
                  <td className="pb-1">
                    <span onClick={() => toggleRow(item.id)} className="">
                      <ItemTask key={index} task={item} showModal={showModal} 
                        icon={<i className={`text-white  ${expandedRows[item.id] ? 'fa-regular fa-square-minus' : 'fa-regular fa-square-plus'}`} style={{ marginRight: "8px" }}></i>}/>
                    </span>
                  </td>
                </tr>
                {expandedRows[item.id] && item.xemDemo && userInfo.email==emailFree && isIconColappse(item.maLoaiNhiemVu) && (
                  <tr style={{ cursor: "pointer" }} onClick={showModal}>
                    <td colSpan="4">
                      {item.chiTiet.map((ct, index) => {
                        return (
                          <div
                            className="flex items-center space-x-3"
                            style={{ padding: "0px 0px 10px 30px" }}
                          >
                            <button className="cursor-pointer flex flex-col justify-start">
                              <span style={{ color: "#ffca3a" }}>
                                <div className="icon_item_theme  w-10 h-10  icon-main flex items-center justify-center">
                                  <i className="fa-regular fa-diagram-subtask"></i>
                                  <span className="bg_icon_item_theme "></span>
                                </div>
                              </span>
                            </button>
                            <p className="font-semibold text-left text-content">
                              {ct}
                            </p>
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
        </tbody>
      </table>
    );
    // return dataContent.danhSachNoiDung
    //   .filter(
    //     ({ maLoaiNhiemVu }) =>
    //       maLoaiNhiemVu != loaiNhiemVu.HOMEWORK &&
    //       maLoaiNhiemVu != loaiNhiemVu.RECORD
    //   )
    //   .map((item, index) => {
    //     // let nextItem = listBaiTap[index + 1]; // Lấy item phía sau
    //     return <ItemTask key={index} task={item} showModal={showModal} />;
    //   });
  };
  let renderListBaiTap = () => {
    let listBaiTap = dataContent.danhSachNoiDung.filter(
      ({ maLoaiNhiemVu }) =>
        maLoaiNhiemVu == loaiNhiemVu.HOMEWORK &&
        maLoaiNhiemVu != loaiNhiemVu.RECORD
    );
    if (listBaiTap.length == 0) return;
    return (
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <tbody>
          {listBaiTap.map((item, idx) => (
            <React.Fragment key={item.id}>
              <tr>
                <td className="pb-1">
                  <span onClick={() => toggleRow(item.id)}>
                    <ItemTask key={index} task={item} showModal={showModal}
                    icon={<i className={`text-white  ${expandedRows[item.id] ? 'fa-regular fa-square-minus' : 'fa-regular fa-square-plus'}`} style={{ marginRight: "8px" }}></i>}
                    />
                  </span>
                </td>
              </tr>
              {expandedRows[item.id] && item.xemDemo && userInfo.email==emailFree && (
                <tr style={{ cursor: "pointer" }} onClick={showModal}>
                  <td colSpan="4">
                    {item.chiTiet.map((ct, index) => {
                      return (
                        <p
                          className="font-semibold text-left text-content"
                          style={{ padding: "0px 0px 10px 50px" }}
                        >
                          {ct}
                        </p>
                      );
                    })}
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    );
    // let listBaiTap = dataContent.danhSachNoiDung.filter(
    //   ({ maLoaiNhiemVu }) =>
    //     maLoaiNhiemVu == loaiNhiemVu.HOMEWORK &&
    //     maLoaiNhiemVu != loaiNhiemVu.RECORD
    // );
    // if (listBaiTap.length == 0) return;
    // return (
    //   <div>
    //     <div className="w-5/5 my-2 mx-auto  h-0.5 bg-white"></div>
    //     <h1
    //       className="text-content text-center tenChuyenDe py-5"
    //       style={{ fontSize: 20 }}
    //     >
    //       {t("loTrinh.baiTapVeNha")}
    //     </h1>
    //     {listBaiTap.map((item, index) => {
    //       //  let nextItem = listBaiTap[index + 1]; // Lấy item phía sau
    //       return <ItemTask key={index} task={item} showModal={showModal} />;
    //     })}
    //   </div>
    // );
  };
  let renderListRecord = () => {
    let listBaiTap = dataContent.danhSachNoiDung.filter(
      ({ maLoaiNhiemVu }) => maLoaiNhiemVu == loaiNhiemVu.RECORD
    );
    if (listBaiTap.length == 0) return;
    return (
      <div className="space-y-1.5">
        <div className="w-5/5 my-2 mx-auto  h-0.5 bg-white"></div>
        <h1
          className="text-content text-center tenChuyenDe py-5"
          style={{ fontSize: 20 }}
        >
          {t("loTrinh.xemLaiBuoiHoc")}
        </h1>
        {listBaiTap.map((item, index) => {
          //  let nextItem = listBaiTap[index + 1]; // Lấy item phía sau
          return <ItemTask key={index} task={item} showModal={showModal} />;
        })}
      </div>
    );
  };
  // tenChuyenDe

  // const isHeighAuto =
  //   soLuongHoanThanh !== 0 && soLuongHoanThanh !== dataContent.danhSachNoiDung.length;

  return (
    <div className=" c-col-full card-theme p-0 relative">
      {dataContent.notify && (
        <span class="dot-notify  absolute inline-flex h-4 w-4 -top-1 -right-1 rounded-full  z-10"></span>
      )}

      {!isPhone &&
        dataContent?.danhSachTag.length <= 2 &&
        dataContent?.danhSachTag.map((tag, index) => {
          return <Badge title={tag} right={90 * index + 20} size={70} />;
        })}
      <div className=" text-black overflow-hidden">
        <button
          aria-expanded={height !== 0}
          aria-controls="example-panel"
          onClick={() => setHeight(height == 0 ? "auto" : 0)}
          className="w-full space-y-2 c-card-content "
        >
          <p className="font-bold text-title text-left">
            {dataContent.tenMonHoc}
          </p>
          <div className="flex flex-wrap mb-2">
            {isPhone &&
              dataContent.danhSachTag.map((tag) => {
                let item1 = listImgTag?.find((item1) => {
                  return item1?.value == tag;
                });
                return (
                  <div className={"md:w-8 md:h-8 w-10 h-10 p-1"}>
                    <img
                      className="w-full h-full object-cover rounded"
                      src={item1?.image}
                      alt
                    />
                  </div>
                );
              })}
              {!isPhone && dataContent?.danhSachTag.length > 2 && dataContent.danhSachTag.map((tag) => {
                let item1 = listImgTag?.find((item1) => {
                  return item1?.value == tag;
                });
                return (
                  <div className={"md:w-8 md:h-8 w-10 h-10 p-1"}>
                    <img
                      className="w-full h-full object-cover rounded"
                      src={item1?.image}
                      alt
                    />
                  </div>
                );
              })}
          </div>
          <div className="flex items-center space-x-10 pt-5">
            <p className="text-content flex-shrink-0">{t("loTrinh.tienDo")}</p>
            <ProgressLine
              type="blue"
              percent={
                (soLuongHoanThanh / dataContent.danhSachNoiDung.length).toFixed(
                  1
                ) * 100 || 0
              }
            />
            <p className="flex-shrink-0 text-content">
              {t("loTrinh.nhiemVuHoanThanh")}: {soLuongHoanThanh}/
              {dataContent.danhSachNoiDung.length}
            </p>
            <i
              className={`fa fa-angle-up transform text-white ${
                height === "auto" ? "rotate-180" : "rotate-0"
              }`}
            ></i>
          </div>
        </button>
        <AnimateHeight id="example-panel" duration={500} height={height}>
          <div className="">
            <div className="space-y-1.5 c-card-content">
              {renderDanhSachNhiemVu()}
              {renderListBaiTap()}
              {renderListRecord()}
            </div>
          </div>
        </AnimateHeight>

        <Modal
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          className="modelPackage"
          footer={null}
          closable={true}
          //  bodyStyle={{
          //   overflow: "hidden",
          // }}
          width={"max-content"}
          style={{
            // maxWidth: "900px",
            // minWidth: "400px",
            top: 10,
            // width: "90%",
          }}
        >
          {isPhone && userInfo.email != "freeacc@gmail.com" ? (
            <PackageTable
              id={chuyenDeId}
              arrChuyenDe={arrChuyenDe}
              isCombo={false}
            />
          ) : (
            <PackageTableV2
              id={chuyenDeId}
              arrChuyenDe={arrChuyenDe}
              isCombo={false}
            />
          )}
        </Modal>
      </div>
    </div>
  );
}
import React, { useEffect, useState } from "react";
import axios from "axios";
import servCodingCamp from "../../services/codingCam.service";
import Heatmap from "./Heatmap";

const OnlineTimeTracker = () => {
  const [onlineTime, setOnlineTime] = useState(0);
  useEffect(() => {
    const updateOnlineTimeInterval = setInterval(() => {
      setOnlineTime((prevTime) => prevTime + 1);
    }, 1000);

    const handleBeforeUnload = async () => {
      clearInterval(updateOnlineTimeInterval);

      let data = {
        soPhutHoatDong: formatTime(onlineTime),
      };
      // console.log("😀 - handleBeforeUnload - data", data);
      try {
        await servCodingCamp.postTimeHoatDong(data);
      } catch (error) {
        console.error("Error updating online time:", error);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      clearInterval(updateOnlineTimeInterval);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [onlineTime]);

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    return `${padTime(hours)}:${padTime(minutes)}:${padTime(seconds)}`;
  };

  const padTime = (value) => {
    return value.toString().padStart(2, "0");
  };

  return <div>{/* <p>Online Time: {formatTime(onlineTime)}</p> */}</div>;
};

export default OnlineTimeTracker;

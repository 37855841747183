import { faker } from "@faker-js/faker";
import { Tag } from "antd";
import React from "react";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import servCodingCamp from "../../../services/codingCam.service";
import VideoPlayer from "../../../components/video/VideoPlayer";

export default function List({ videoList }) {
  let { userId } = useParams();
  let navigate = useNavigate();

  let renderList = () => {
    return videoList?.map((video) => {
      const match = video.youtube.match(/v=([^&]+)/);
      const videoId = match ? match[1] : "Video ID not found";
      return (
        <div
          onClick={() => {
            servCodingCamp.postTangViewVideo(video.nopBaiId);
            navigate(`/wall/${video.nopBaiId}/${userId}`);
          }}
          className="card cursor-pointer"
        >
          <div className="  cursor-pointer rounded-lg relative overflow-hidden">
            {/* <img className="w-full h-96 object-cover" src={`http://img.youtube.com/vi/${videoId}/maxresdefault.jpg`} alt="" /> */}
            {/* <ReactPlayer
              playbackRate={1}
              playing={false}
              url={video.youtube}
              // url={
              //   "https://www.youtube.com/watch?v=DfDPJqD3FjI&list=PLkC56g8fboI0HghByzVuD2Vz8ROUXfF_j&ab_channel=AsmrProg"
              // }
              width="100%"
              height="400px"
              controls={false}
              config={{
                file: {
                  forceHLS: true,
                },
              }}
            /> */}

            <VideoPlayer source={video.youtube} />

            <div className="footer space-y-3 ">
              <p className="">
                {faker.datatype.array(3).map(() => {
                  return (
                    <Tag border="none" className="px-2" color={faker.color.rgb()}>
                      ES6
                    </Tag>
                  );
                })}
              </p>
              <p className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 text-white font-medium"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                  />
                </svg>
                <span className="text-lg text-content">{video.luotXem}</span>
              </p>
            </div>
          </div>
          {/* <div className="title text-white mt-2">
            {faker.name.jobTitle()}
            {faker.name.jobTitle()}
            {faker.name.jobTitle()}
          </div> */}
        </div>
      );
    });
  };
  if (!videoList?.length) {
    return <p className="text center text-title">Không có nội dung</p>;
  }
  return (
    <div>
      <div className="grid grid-cols-5 gap-5">{renderList()}</div>
    </div>
  );
}

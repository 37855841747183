import servCodingCamp from "../services/codingCam.service";
import localStorageServ from "../services/locaStorage.service";

export let createPayloadNopBai = (
  taskId = 0,
  maLoai = "",
  baiLam = "",
  requireId = 0,
  chuyenDeId,
  monHocId
) => {
  return {
    id: 0,
    taskId: taskId,
    requireId: requireId,
    baiLam: baiLam,
    maLoai: maLoai,
    chuyenDeId,
    monHocId,
  };
};
export let createPayloadLayThongTinBaiTap = (taskId = 0, chuyenDeId, monHocId) => {
  return {
    nguoiDungId: localStorageServ.userInfo.get().id,
    taskId: taskId,
    chuyenDeId,
    monHocId,
  };
};
export const loaiNhiemVu = {
  VIDEO: "VIDEO",
  BAITAP: "BAITAP",
  QUIZ: "QUIZ",
  CAPS: "CAPS",
  RUNCODE: "RUNCODE",
  TAILIEU: "TAILIEU",
  RECORD: "RECORD",
  HOMEWORK: "HOMEWORK",
};
export const tenNhiemVu = {
  VIDEO: "Video",
  BAITAP: "Bài tập",
  QUIZ: "Trắc nghiệm",
  CAPS: "Capstone",
  RUNCODE: "Live coding",
  TAILIEU: "Tài liệu",
  RECORD: "Video xem lại",
  HOMEWORK: "Bài tập về nhà",
};

export const trangThaiBaiTap = {
  
  CHO_CHAM: "CHO_CHAM",
  DA_CHAM: "DA_CHAM",
  NOP_LAI: "NOP_LAI",
};
export const nhiemVuColor = {
  VIDEO: "#ff595e",
  BAITAP: "#ffca3a",
  QUIZ: "#1982c4",
  CAPS: "#eddea4",
  RUNCODE: "#fbb02d",
  TAILIEU: "#2ec4b6",
  RECORD: "#ffc6ff",
  HOMEWORK: "#ffffff",
};

let createTaskIcon = (icon = "", desc = "") => {
  return {
    icon: (
      <div className="icon_item_theme  w-10 h-10  icon-main flex items-center justify-center">
        {icon}
        <span className="bg_icon_item_theme "></span>
      </div>
    ),
    content: (
      <div className="card_tool_tip">
        <div className="c-card-content">{icon}</div>
        <div className="c-card-content">
          <p className="text-sub-title color-sub-main-task-page flex flex-col text-center">
            {desc}
          </p>
        </div>
      </div>
    ),
  };
};
export let typeTaskIcon = {
  VIDEO: createTaskIcon(
    <i className="fa text-xl fa-video"></i>,
    `Bài giảng dưới dạng video thu sẵn giúp bạn có thể xem đi xem lại
  nhiều lần`
  ),
  RECORD: createTaskIcon(
    <i class="fa fa-file-video text-base"></i>,
    `Bài giảng dưới dạng video thu sẵn giúp bạn có thể xem đi xem lại
    nhiều lần!`
  ),
  HOMEWORK: createTaskIcon(<i className="fa fa-edit text-base"></i>, ""),
  BAITAP: createTaskIcon(
    <i className="fa-solid fa-file-pen"></i>,
    // <i className="fa text-xl fa-desktop"></i>,
    `Bài tập áp dụng Hãy vận dụng kiến thức các bạn đã học để hoàn thành
    các bài tập sau !`
  ),
  QUIZ: createTaskIcon(
    <i className="fa text-xl fa-pencil-ruler"></i>,
    `Trắc nghiệm review kiến thức chắc chắn rằng bạn đã nắm toàn bộ kiến
    thức từ các bại giảng trên ?`
  ),
  RUNCODE: createTaskIcon(
    <i className="fa text-xl fa-code"></i>,
    `      Trắc nghiệm review kiến thức chắc chắn rằng bạn đã nắm toàn bộ kiến
thức từ các bại giảng trên ?`
  ),
  TAILIEU: createTaskIcon(
    <i className="fa text-xl fa-file-alt"></i>,
    `
    Tài liệu của buổi học
`
  ),
  CAPS: createTaskIcon(
    <i className="fa fa-users"></i>,
    ` Làm việc nhóm với 1 partner ! Hiện tại nhóm bạn mới chỉ có 1 thành
    viên ! Bạn cần tìm thêm 1 thành viên nửa để cùng làm project !`
  ),
  HELPFRIEND: createTaskIcon(),
  CHAT: createTaskIcon(),
  TASK: createTaskIcon(<i className="fa fa-book-reader"></i>),
  DUAN: createTaskIcon(<i className="fa fa-gavel"></i>),
  DISCUSS: createTaskIcon(<i className="fa fa-sms"></i>),
};

export const markTaskAsCompleted = (chuyenDeId, monHocId, taskId, maLoai, baiHocId) => {
  let userInfo = localStorageServ.userInfo.get();

  const taskCompletionData = {
    nguoiDungId: userInfo.id,
    chuyenDeId,
    monHocId,
    taskId,
    maLoai,
    baiHocId,
    soPhutThucHien: 10,
  };
  return servCodingCamp.postHoanThanhHoc(taskCompletionData);
};

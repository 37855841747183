import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import servVatPham from "../../services/vatPham.service";

const initialState = {};
export const getVatPhamUserAction = createAsyncThunk("getHomDoUserAction", async (thunkAPI) => {
  const response = await servVatPham.homDoCuaBan();
  return response.data.content;
});
const vatPhamSlice = createSlice({
  name: "vatPhamSlice",
  initialState,
  reducers: {
    vatPhamUser: [],
  },
  extraReducers: {
    [getVatPhamUserAction.fulfilled]: (state, action) => {
      state.vatPhamUser = action.payload;
    },
  },
});

export const {} = vatPhamSlice.actions;

export default vatPhamSlice.reducer;

import React from "react";
import { useLstLoTrinh } from "../../../hook/useLstLoTrinhUser";
import localStorageServ from "../../../services/locaStorage.service";
import { history } from "../../../App";
// import Registed_Users_Bar from "../Registed_Users_Bar/Registed_Users_Bar";

function Static_Public_Desktop({ loTrinhPublic, isBlackFridayDay = false }) {
  let loTrinh = loTrinhPublic;
  
  let userInfo = localStorageServ.userInfo.get()
  let isDemoMode = isBlackFridayDay;
  let widthItem = isDemoMode ? "w-1/4" : "w-1/5";
  const { data, isLoading, error } = useLstLoTrinh();
  
  let ds = JSON.parse(loTrinh.danhSachKhoaHoc)
  let arr1 = ds.map((cd,idx)=>{
    return data?.find(a => a.chuyenDeId === cd)
  })
  const totalTask = (type) => { 
    let total = 0
    arr1?.map((item,idx)=>{
      item?.thongKeChuyenDe.map(({ tongSoLuong, tieuDe, maLoai }, index)=>{
        if(type==maLoai){
          total+=tongSoLuong
        }
      })
    })
    return total
   }

  console.log(totalTask(arr1,"TASK"))
  let textTitle = "font-medium text-base lg:text-lg text-color-title";
  let textNumber = "font-bold text-lg lg:text-2xl text-color-title";
  console.log(loTrinh)
  return (
    <div className="w-full h-full flex flex-col  card_theme md:p-2 lg:p-3 relative">
      <div className="flex absolute w-max space-x-4 lef -top-5 right-3">
        {/* {!isDemoMode ? <GetGameItem types={[VC]} size="12" /> : ""} */}
      </div>
      <div className="w-full p-1 lg:p-3 h-5/6 lg:h-full flex   items-center justify-between space-x-3 lg:space-x-5 ">
        <div
          className={
            "flex  px-3   items-center flex-col justify-between md:h-40 lg:h-44 p-5 bg-purple-theme rounded-xl bg-opacity-25 " +
            widthItem
          }
        >
          <div className="w-12 h-12 flex justify-center items-center bg-purple-theme rounded-2xl bg-opacity-50 border-purple-theme">
            <div className="w-11/12 h-11/12 flex-shrink-0 flex justify-center items-center bg-purple-theme rounded-2xl  ">
              <i className="fa fa-book text-xl text-white "></i>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <p className={textTitle}>Khoá học</p>
            <span className={textNumber}>{ds.length}</span>
          </div>
        </div>
        <div
          className={
            "flex  px-3   items-center flex-col justify-between md:h-40 lg:h-44 p-5 bg-green-mint-theme rounded-xl bg-opacity-25 " +
            widthItem
          }
          style={{background:'rgba(68, 66, 178, 0.1);'}}
        >
          <div className="w-12 h-12 flex justify-center items-center bg-green-mint-theme rounded-2xl bg-opacity-50 border-green-minbg-green-mint-theme">
            <div className="w-11/12 h-11/12 flex-shrink-0 flex justify-center items-center bg-green-mint-theme rounded-2xl  ">
              <i className="fab fa-leanpub text-xl text-white "></i>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <p className={textTitle}> Task</p>
            <span className={textNumber}>{totalTask("TASK")}</span>
          </div>
        </div>
        <div
          className={
            "flex  px-3   items-center flex-col justify-between md:h-40 lg:h-44 p-5 bg-green-mint-theme rounded-xl bg-opacity-25 " +
            widthItem
          }
          style={{background:'rgba(68, 66, 178, 0.1);'}}
        >
          <div className="w-12 h-12 flex justify-center items-center bg-green-mint-theme rounded-2xl bg-opacity-50 border-green-minbg-green-mint-theme">
            <div className="w-11/12 h-11/12 flex-shrink-0 flex justify-center items-center bg-green-mint-theme rounded-2xl  ">
              <i className="fa fa-question-circle text-xl text-white "></i>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <p className={textTitle}> QUIZZ</p>
            <span className={textNumber}>{totalTask("QUIZ")}</span>
          </div>
        </div>
        <div
          className={
            "flex  px-3   items-center flex-col justify-between md:h-40 lg:h-44 p-5 bg-green-mint-theme rounded-xl bg-opacity-25 " +
            widthItem
          }
          style={{background:'rgba(68, 66, 178, 0.1);'}}
        >
          <div className="w-12 h-12 flex justify-center items-center bg-green-mint-theme rounded-2xl bg-opacity-50 border-green-minbg-green-mint-theme">
            <div className="w-11/12 h-11/12 flex-shrink-0 flex justify-center items-center bg-green-mint-theme rounded-2xl  ">
              <i className="fa fa-code text-xl text-white "></i>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <p className={textTitle}> Runcode</p>
            <span className={textNumber}>{totalTask("RUNCODE")}</span>
          </div>
        </div>
        <div
          className={
            "flex  px-3   items-center flex-col justify-between md:h-40 lg:h-44 p-5 bg-green-mint-theme rounded-xl bg-opacity-25 " +
            widthItem
          }
          style={{background:'rgba(68, 66, 178, 0.1);'}}
        >
          <div className="w-12 h-12 flex justify-center items-center bg-green-mint-theme rounded-2xl bg-opacity-50 border-green-minbg-green-mint-theme">
            <div className="w-11/12 h-11/12 flex-shrink-0 flex justify-center items-center bg-green-mint-theme rounded-2xl  ">
              <i className="fa fa-file-alt text-xl text-white "></i>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <p className={textTitle}> Tài liệu</p>
            <span className={textNumber}>{totalTask("TAILIEU")}</span>
          </div>
        </div>
        <div
          className={
            "flex  px-3   items-center flex-col justify-between md:h-40 lg:h-44 p-5 bg-green-mint-theme rounded-xl bg-opacity-25 " +
            widthItem
          }
          style={{background:'rgba(68, 66, 178, 0.1);'}}
        >
          <div className="w-12 h-12 flex justify-center items-center bg-green-mint-theme rounded-2xl bg-opacity-50 border-green-minbg-green-mint-theme">
            <div className="w-11/12 h-11/12 flex-shrink-0 flex justify-center items-center bg-green-mint-theme rounded-2xl  ">
              <i className="fa fa-video text-xl text-white "></i>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <p className={textTitle}> Video</p>
            <span className={textNumber}>{totalTask("VIDEO")}</span>
          </div>
        </div>

       
      </div>

      <div className="flex space-x-5 items-center justify-end  mb-8">
        {/* <p className=" text-base lg:text-lg text-color-title w-max flex-shrink-0">
          Học viên đã đăng kí
        </p>
        <Registed_Users_Bar
          dsAvatar={loTrinh.dsAvatar}
          totalUser={loTrinh.tongHocVien}
        /> */}
        {!userInfo || userInfo?.email=='freeacc@gmail.com'?
        <button className="btn_theme_package m-1" onClick={() => { 
            history.push('/signup-user')
         }}>
        Học thử miễn phí
      </button>
      :
      loTrinh?.info?.map((cd,idx)=>{
        return <button className="btn_theme_package m-1" onClick={() => {
          localStorageServ.regisPackage.set(cd)
          if (userInfo?.email == 'freeacc@gmail.com' || !userInfo) {
              history.push('/signup-user')
          } else {
              history.push('/confirm-payment')
          }
      }}>{cd.hocPhi / 1000000} triệu/<sub>{cd.thang} tháng</sub></button>
      })}
        <button className=" btn-theme text-white text-base lg:text-lg font-medium lg:font-bold md:px-3 lg:px-7 p-2  rounded leading-10 flex-shrink-0  shadow_designCode ">
          <a 
            href="https://www.facebook.com/codezuni"
            target="_blank"
            className="btnTuVan text-white hover:text-white"
          >
            Tư vấn & Đăng kí
          </a>
        </button>
      </div>
    </div>
  );
}

export default Static_Public_Desktop = React.memo(Static_Public_Desktop);

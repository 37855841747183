import React, { useEffect, useState } from "react";
// import BaiTapTracNghiem from "../../components/ContentTracNghiemExtra/BaiTapTracNghiem";
import TracNghiem_Start from "../../components/ContentTracNghiemExtra/TracNghiem_Start";
import servCodingCamp from "../../services/codingCam.service";
import { useParams } from "react-router-dom";
import localStorageServ from "../../services/locaStorage.service";
import { typeTrangThaiNop } from "../../components/StepProjectInstruction/util";
import DaChamContent from "./DaChamContent";
import LamLaiContent from "./LamLaiContent";

export default function DetailScreenQuizz() {
  let { chuyenDeId, taskId, monHocId } = useParams();
  let [nextTask, setNextTask] = useState();
  let [visited, setVisited] = useState(false);
  let [thongTinBaiTap, setThongTinBaiTap] = useState({});
  function fetchThongTinBaiNop() {
    let data = {
      nguoidungId: localStorageServ.userInfo.get().id,
      chuyenDeId,
      taskId,
      monHocId,
    };
    servCodingCamp
      .getLayThongTinBaiTap(data)
      .then((res) => {
        setVisited((res.data.content.trangThai == typeTrangThaiNop.LAM_LAI ||
          res.data.content.trangThai == typeTrangThaiNop.DA_CHAM))
        setThongTinBaiTap(res.data.content);
      })
      .catch((err) => {
        // console.log(err);
      });
  }
  useEffect(() => {
    fetchThongTinBaiNop();
  }, [taskId]);
  if (thongTinBaiTap.trangThai == typeTrangThaiNop.DA_CHAM) {
    return <DaChamContent data={thongTinBaiTap}  nextTask={nextTask} onSuccess={fetchThongTinBaiNop} setNextTask={setNextTask} visited={visited}/>;
  }
  if (thongTinBaiTap.trangThai == typeTrangThaiNop.LAM_LAI) {
    return <LamLaiContent fetchThongTinBaiNop={fetchThongTinBaiNop} data={thongTinBaiTap} onSuccess={fetchThongTinBaiNop} setNextTask={setNextTask} visited={visited}/>;
  }

  return <TracNghiem_Start onSuccess={fetchThongTinBaiNop} setNextTask={setNextTask} visited={visited} />;
}

import React, { useEffect } from "react";
import servCodingCamp from "../../services/codingCam.service";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import { NavLink } from "react-router-dom";
import "./style.scss";
import classNames from "classnames";
import { checkActiveNavlink, parentPage } from "../../routes/utitlity";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectThongTinMoRong } from "../../redux/reducer/authReducer";
import { useLstLoTrinhUser } from "../../hook/useLstLoTrinhUser";
import { useArrCourseCybersoft } from "../../hook/useDataCybersoft";
export default function DropdownLoTrinh() {
  const { userInfo } = useSelector(selectThongTinMoRong);
  const { data, isLoading, error } = useLstLoTrinhUser(userInfo);
  const { t } = useTranslation();

  // const list = useSelector(selectListLoTrinh);
  let dispatch = useDispatch();
  const arrCyber = useArrCourseCybersoft();

  let { chuyenDeId: id } = useParams();
  useEffect(() => {
    // servCodingCamp
    //   .layLotrinhUser()
    //   .then((res) => {
    //     dispatch(setListLoTrinh(res.data.content));
    //     // setListLoTrinh(res.data.content);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);

  const menu = (
    <Menu className=" rounded dropdown-lo-trinh">
      <div className="row">
        <div className="col p-4 space-y-5">
        <div className="shadow cursor-pointer relative">
            <p className="font-bold">
              KHÓA ĐANG HỌC
            </p>
          </div>
          {data?.map(({ tenChuyenDe, chuyenDeId, notify }) => {
            return (
              <div key={chuyenDeId} className="shadow cursor-pointer relative">
                {notify && (
                  <span class="dot-notify  absolute inline-flex h-4 w-4 -top-1 -right-1 rounded-full  z-10 "></span>
                )}
                <NavLink
                  to={`/${parentPage.task}/${chuyenDeId}`}
                  className={classNames({
                    active: chuyenDeId == id,
                  })}
                >
                  {tenChuyenDe.length > 50 ? tenChuyenDe.substring(0, 50) + '...' : tenChuyenDe}
                </NavLink>
              </div>
            );
          })}
          {!data && <div className="shadow cursor-pointer relative">
            <NavLink to={`/package`}>
              Xem tất cả khóa học
            </NavLink>
          </div>
          }
        </div>
        <div className="col p-4 space-y-5">
          <div className="shadow cursor-pointer relative">
            <p className="font-bold">
              KHÓA HỌC KIẾN TẬP SỰ NGHIỆP TẠI CYBERSOFT
            </p>
          </div>
          {arrCyber.data?.map((item, idx) => {
            if (item.metaData == "Cybersoft") {
              return (
                <div key={idx} className="shadow cursor-pointer relative">
                  <a
                    onClick={() => {
                      window.open(item?.linkMoTa, "_blank");
                    }}
                    title={item.tenSanPham}
                  >
                    {item?.tenSanPham.length > 50 ? item?.tenSanPham.substring(0, 50) + '...' : item?.tenSanPham}
                  </a>
                </div>
              )
            }
          })}
        </div>
      </div>
    </Menu>
  );

  return (
    <div className="relative">
      {data?.map(
        ({ notify }) =>
          notify && (
            <span class="dot-notify  absolute inline-flex h-4 w-4 -top-1 -right-1 rounded-full  z-10 "></span>
          )
      )}

      <Dropdown arrow overlay={menu}>
        <p className="p-1 px-3 flex  justify-center text-content text-sm items-center rounded-xl z-10 relative  duration-500 text-button uppercase whitespace-nowrap cursor-pointer space-x-2">
          <span
            className={checkActiveNavlink(parentPage.task) ? "active-link" : ""}
          >
            {" "}
            <i className="pe-2 fa-solid fa-road"> </i>
            {t("header.loTrinh")}
            <i class="pl-2 fa fa-caret-down "></i>
          </span>
        </p>
      </Dropdown>
    </div>
  );
}

import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Checkbox, Select, Radio } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { selectThongTinMoRong } from '../../redux/reducer/authReducer';
import localStorageServ from '../../services/locaStorage.service';
import { getChuyenDeApi } from '../../redux/reducer/chuyenDeReducer-Chat';
import { history } from '../../App';
import { packageService } from '../../services/packageService';
import toast from 'react-hot-toast';
import axios from 'axios';
import moment from 'moment';
import DieuKhoanThanhToan from './ViewPackage/DieuKhoanThanhToan';
import useWindowSize from '../../hook/useWindowSize';
const { Option } = Select;

const ConfirmPayment = () => {
  const { widthWindow, heightWindow } = useWindowSize();
  const isPhone = widthWindow<768?true:false
  const [isShowDieuKhoan, setIsShowDieuKhoan] = useState(false);
  const handleCloseModal = () => {
    setIsShowDieuKhoan(false);
  };
  const handleOpenModal = () => {
    setIsShowDieuKhoan(true);
  };
  let { arrChuyenDe } = useSelector((state) => state.chuyenDe);

  let dispatch = useDispatch();
  const regisPackage = localStorageServ.regisPackage.get();
  const inforSignUp = localStorageServ.inforSignUp.get();
  const userInfo = localStorageServ.userInfo.get();
  const [ipAddress, setIpAddress] = useState(null);
  const currentDate = new Date();
  const futureDate = new Date(currentDate);
  futureDate.setMonth(futureDate.getMonth() + 2);
  const thisDate = currentDate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
  const nextDate = futureDate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
  useEffect(() => {
    // Gửi yêu cầu HTTP GET đến https://jsonip.com để lấy địa chỉ IP
    axios.get('https://jsonip.com')
      .then(response => {
        // Lấy địa chỉ IP từ dữ liệu phản hồi
        const ip_address = response.data.ip;
        setIpAddress(ip_address);
      })
      .catch(error => {
        console.error('Đã xảy ra lỗi:', error);
      });
  }, []);
  const handleRedirect = () => {

    const currentURL = window.location.origin;
    const obj = {
      "hocPhi": regisPackage.hocPhi * 100,
      "urlAgain": currentURL,
      "email": inforSignUp ? inforSignUp.email : userInfo.email,
      "phone": inforSignUp ? inforSignUp.soDt : userInfo.dienThoai,
      "id": inforSignUp ? inforSignUp.id : userInfo.id,
      "orderInfor": `${inforSignUp ? inforSignUp.soDt : userInfo.dienThoai}_${regisPackage.id}`,
      "ticketNo": ipAddress
    }
    console.log(obj)
    if (check) {
      packageService.sendRedirectONEPAY(obj)
        .then((res) => {
          window.location.href = res.data.content
        })
        .catch((err) => {
          toast.error("Có lỗi xảy ra vui lòng thử lại")
          history.push("/")
        })
    } else {
      toast.error("Vui lòng chọn  'Tôi đã đọc và Đồng ý với Điều khoản thanh toán'")
    }
  }
  const [check, setCheck] = useState(false)
  const changeCheck = (e) => {
    setCheck(e.target.checked)
  }
  useEffect(() => {

    dispatch(getChuyenDeApi())
  }, []);
  return (
    <div className='w-full min-h-screen  overflow-y-auto bg-cover  flex-col  bg-fixed p-3 sm:p-1 md:p-7 lg:p-10 flex  '>
      <div className=" h-full w-full flex-grow flex flex-col relative overflow-y-auto overflow-x-hidden 2xl:container mx-auto">
        <div className="w-full flex-grow flex flex-col card-theme">
          <div className="lg:w-full  w-2/3 mx-auto">
            <div className="w-full p-3 flex flex-col items-center space-y-5 text-white" >
              <p className="uppercase  text-lg lg:text-xl ">XÁC NHẬN THÔNG TIN THANH TOÁN</p>
              <div className="text-content text-base md:text-lg">
                <p>* Lưu ý:</p>
                <p>
                  - Vui lòng đọc{" "}
                  kiểm tra lại thông tin thanh toán, bao gồm học phí, thời hạn học tập và các thông tin bên dưới
                </p>

              </div>

              <div className="w-full flex justify-center flex-col items-center h-full">
                <div className="p-2 w-full">
                  {/* <h2 className="uppercase text-center tenChuyenDe">{regisPackage.tenGoi}</h2>
                  <p className='font-bold text-light text-2xl pt-2'>Danh sách khóa học:</p>
                  {JSON.parse(regisPackage.danhSachKhoaHoc).map((item, idx) => {
                    const cdName = arrChuyenDe?.find(a => a.id === item)?.tenChuyenDe || ''
                    return <p key={idx} className='text-xl uppercase'><span className="fa fa-dot mr-2 py-1" />- {cdName}</p>
                  })}


                  <p className="font-bold text-2xl pt-2">Học phí: <i>{regisPackage.hocPhi.toLocaleString()} VNĐ</i></p> */}
                  <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr>
                        <td align="center" style={{ minWidth: '100%', backgroundColor: 'transparent' }}>
                          <table width={isPhone?'100%':'60%'} border={0} align="center" cellPadding={0} cellSpacing={0}>
                            <tbody>
                              <tr>
                                <td align="center" style={{ paddingBottom: 0 }}>
                                  <table align="center" width={'100%'} border={0} cellSpacing={0} cellPadding={0}>
                                    <tbody>
                                      <tr>
                                        <td align="center" style={{ paddingTop: 10, paddingBottom: 15 }}>
                                          <table width="95%" border={0} align="center" cellPadding={0} cellSpacing={0}>
                                            <tbody>
                                              <tr />
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td align="center" style={{ backgroundColor: 'white' }}>
                                          <table width="100%" border={0} align="center" cellPadding={0} cellSpacing={0}>
                                            <tbody>
                                              <tr>
                                                <td style={{ borderTop: '3px solid rgb(255, 210, 32)', borderRadius: '4px 4px 0 0' }} />
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td align="center" style={{ paddingTop: 25, paddingBottom: 25, backgroundColor: 'white' }}>
                                          <a href="https://login.codezuni.com/" target="_blank"><img src="https://apimentor11.cybersoft.edu.vn//images/15-05-2024-03-01-32-codezuni.png" width="100%" alt="Logo Codezuni" style={{ display: 'block', border: 0, fontSize: 20, fontWeight: 'bold', color: '#222222' }} className="CToWUd" data-bit="iit" /></a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td align="center" style={{ backgroundColor: 'white' }}>
                                          <table width="90%" border={0} align="center" cellPadding={0} cellSpacing={0}>
                                            <tbody>
                                              <tr>
                                                <td align="left" style={{ paddingTop: 25, paddingBottom: 25, backgroundColor: 'white' }}>
                                                  <p style={{ margin: 0, color: '#303233', fontSize: 12 }}>
                                                    Xin chào
                                                    <span style={{ color: '#303233', fontSize: 12, fontWeight: 'bold' }}> {inforSignUp ? inforSignUp.hoTen : userInfo.hoTen}</span>
                                                  </p>
                                                  <p style={{ margin: '0 0 0 0', color: '#303233', fontSize: 12 }}>
                                                    <br />
                                                    <br />Vui lòng đọc kỹ và xác nhận các thông tin dưới đây:
                                                  </p>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td align="center" style={{ backgroundColor: 'white' }}>
                                          <table width="90%" border={0} align="center" cellPadding={0} cellSpacing={0} style={{ marginBottom: 12 }}>
                                            <tbody>
                                              <tr>
                                                <td align="left" style={{ paddingTop: 16, paddingBottom: 0, backgroundColor: 'white', border: '1px solid #e8e8e8', borderRadius: 12 }}>
                                                  <table width="100%" border={0} align="center" cellPadding={0} cellSpacing={0}>
                                                    <tbody>
                                                      <tr align="left">
                                                        <td style={{ color: '#303233', fontSize: 16, paddingLeft: 12, paddingBottom: 16 }}>
                                                          <strong>Thông tin sản phẩm</strong>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td style={{ paddingBottom: 12 }}>
                                                          <table width="100%" border={0} align="center" cellPadding={0} cellSpacing={0}>
                                                            <tbody>
                                                              <tr align="center">
                                                                <td style={{ padding: '0px 0px 1px 0px' }} bgcolor="#e8e8e8" width="100%" />
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td style={{ paddingBottom: 12 }}>
                                                          <table width="100%" border={0} align="center" cellPadding={0} cellSpacing={0}>
                                                            <tbody>
                                                              <tr align="center">
                                                                <td align="left" style={{ color: '#727272', fontSize: 14, paddingLeft: 12 }}>
                                                                  Gói bạn đã chọn
                                                                </td>
                                                                <td align="right" style={{ color: '#303233', fontSize: 14, fontWeight: 'bold', textAlign: 'right', paddingRight: 12 }}>
                                                                  {regisPackage?.tenGoi}
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td style={{ paddingRight: 12, paddingLeft: 12, paddingBottom: 12 }}>
                                                          <table width="100%" border={0} align="center" cellPadding={0} cellSpacing={0}>
                                                            <tbody>
                                                              <tr align="center">
                                                                <td style={{ padding: '0px 0px 1px 0px' }} bgcolor="#e8e8e8" width="100%" />
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td style={{ paddingBottom: 12 }}>
                                                          <table width="100%" border={0} align="center" cellPadding={0} cellSpacing={0}>
                                                            <tbody>
                                                              <tr align="center">
                                                                <td align="left" style={{ color: '#727272', fontSize: 14, paddingLeft: 12 }}>
                                                                  Khóa học đã chọn
                                                                </td>
                                                                <td align="right" style={{ color: '#303233', fontSize: 14, fontWeight: 'bold', textAlign: 'right', paddingRight: 12 }}>
                                                                  {JSON.parse(regisPackage?.danhSachKhoaHoc).map((item, idx) => {
                                                                    const cdName = arrChuyenDe?.find(a => a.id === item)?.tenChuyenDe || ''
                                                                    return <p key={idx} classname="text-xl uppercase"><span classname="fa fa-dot mr-2 py-1">{cdName}</span></p>
                                                                  })}
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td style={{ paddingRight: 12, paddingLeft: 12, paddingBottom: 12 }}>
                                                          <table width="100%" border={0} align="center" cellPadding={0} cellSpacing={0}>
                                                            <tbody>
                                                              <tr align="center">
                                                                <td style={{ padding: '0px 0px 1px 0px' }} bgcolor="#e8e8e8" width="100%" />
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td style={{ paddingBottom: 24 }}>
                                                          <table width="100%" border={0} align="center" cellPadding={0} cellSpacing={0}>
                                                            <tbody>
                                                              <tr align="center">
                                                                <td align="left" style={{ color: '#727272', fontSize: 14, paddingLeft: 12 }}>
                                                                  Số tiền thanh toán
                                                                </td>
                                                                <td align="right" style={{ color: '#303233', fontSize: 14, fontWeight: 'bold', textAlign: 'right', paddingRight: 12 }}>
                                                                  {regisPackage.hocPhi.toLocaleString()} VNĐ
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td style={{ paddingRight: 12, paddingLeft: 12, paddingBottom: 16 }}>
                                                  <table width="100%" border={0} align="center" cellPadding={0} cellSpacing={0}>
                                                    <tbody>
                                                      <tr align="center">
                                                        <td style={{ padding: '0px 0px 1px 0px' }} bgcolor="#e8e8e8" width="100%" />
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td align="center" style={{ backgroundColor: 'white' }}>
                                          <table width="90%" border={0} align="center" cellPadding={0} cellSpacing={0}>
                                            <tbody>
                                              <tr>
                                                <td align="left" style={{ paddingTop: 25, paddingBottom: 25, backgroundColor: 'white' }}>
                                                  <p style={{ margin: 0, color: '#303233', fontSize: 12 }}>
                                                    Sau khi bạn tiến hành thanh toán thành công, trung tâm sẽ nhận được học phí và tự động kích hoạt tài khoản học tập của bạn.
                                                  </p>
                                                  <p style={{ margin: '0 0 0 0', color: '#303233', fontSize: 12 }}>
                                                    <br />
                                                    Bạn sẽ học theo lộ trình và thời gian đã đăng ký từ ngày
                                                    <span style={{ fontWeight: 'bold', fontSize: 12 }}>
                                                    {" "}{thisDate}{" "}
                                                    </span>
                                                    đến ngày 
                                                    <span style={{ fontWeight: 'bold', fontSize: 12 }}>
                                                    {" "}{nextDate}{" "}
                                                    </span>. Trung tâm KHÔNG hỗ trợ bảo lưu, hủy hoặc chuyển khóa học khác.
                                                  </p>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td align="left" style={{ paddingTop: 10, paddingBottom: 10, backgroundColor: 'white' }}>
                                                  <p style={{ margin: 0, color: '#303233', fontSize: 12 }}>
                                                    Trung tâm sẽ gửi email xác nhận khi bạn thanh toán thành công.
                                                    <br />
                                                    <br />
                                                    Xin cảm ơn bạn!
                                                    <br />
                                                    <br />
                                                    Trân trọng,<br />
                                                    [Chữ ký của bạn] <br />
                                                    Codezuni
                                                  </p>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td align="center" style={{ backgroundColor: 'white' }}>
                                          <table width="100%" border={0} align="left" cellPadding={0} cellSpacing={0} style={{ marginBottom: 12 }}>
                                            <tbody>
                                              <tr align="center">
                                                <td style={{ padding: '0px 0px 8px 0px' }} bgcolor="#f9f9f9" width="100%" />
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>

                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <Checkbox className="text-white pt-3" onChange={changeCheck}>
                    Tôi đồng ý với các{" "}
                    <span
                      onClick={() => {
                        handleOpenModal();
                      }}
                      className="text-yellow-400 underline cursor-pointer"
                    >
                      ĐIỀU KHOẢN
                    </span>{" "}
                    thanh toán của CodeZuni
                  </Checkbox>
                  <p className='cursor-pointer underline mr-4 pt-1' onClick={() => {
                    history.back()
                  }}><i className="fa-duotone fa-backward"></i> {`Quay về trang trước`}</p>
                </div>
                <div >
                  <button
                    onClick={handleRedirect}
                    className="px-5 mt-4 ml-2 btn_theme_red"
                    title='Tiếp tục đến với trang thanh toán online'
                  >
                    Thanh toán online
                  </button>
                  <button
                    onClick={() => {
                      const arrKH = JSON.parse(regisPackage?.danhSachKhoaHoc).map((item, idx) => {
                        const cdName = arrChuyenDe?.find(a => a.id === item)?.tenChuyenDe || ''
                        return { cdName }
                      })
                      const obj = {
                        hoTen: inforSignUp ? inforSignUp.hoTen : userInfo.hoTen,
                        tenCombo: regisPackage.tenGoi,
                        khoaHoc: arrKH.map((a, i) => a.cdName).join(", "),
                        soTien: regisPackage.hocPhi,
                        ngayBd: currentDate,
                        ngayKt: futureDate,
                        email: inforSignUp ? inforSignUp.email : userInfo.email
                      }
                      const obj2 = {
                        "id": 0,
                        "hoTen": inforSignUp ? inforSignUp.hoTen : userInfo.hoTen,
                        "email": inforSignUp ? inforSignUp.email : userInfo.email,
                        "soDT": inforSignUp ? inforSignUp.soDt : userInfo.dienThoai,
                        "diaDiem": regisPackage.tenGoi,
                        "loaiForm": "codezuni"
                      }

                      if (check) {
                        toast.success("Vui lòng chọn  kiểm tra email để xác nhận lại thông tin!")
                        packageService.sendMail(obj)
                        axios.post('https://apicrm.cybersoft.edu.vn/api/leadform', obj2, {
                          headers: {
                            'Authorization': 'Bearer eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL2V4cGlyZWQiOiI0LzE1LzIwNDcgNDowMjowOCBQTSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWUiOiIxZDcxYmZlMi1jOWQ0LTQxMTEtYjZmMy1mOTJlZGVmZDgxYjIiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9lbWFpbGFkZHJlc3MiOiJwaHVvbmdhMHBuQGdtYWlsLmNvbSIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6WyJRTF9CTSIsIkNIRUNLX01FTlRPUl9MT1AiLCJSX0xPUCIsIlFMX0dDIiwiUUxfQ0hUTCIsIlFMX1JNIiwiUUxfVEtEIiwiUUxfTENOIiwiRF9CVCIsIkZfR0MiLCJSX1ZMIiwiUl9CSCIsIkZfTE9QIiwiQU5ZIiwiRF9ORCIsIlVfTkQiLCJDX05EIiwiUl9ORCIsIkRfS0giLCJVX0tIIiwiQ19LSCIsIlJfS0giLCJHRF9MSCIsIlhfS0hfTCIsIkRfREFOSCIsIlFMX0NMIiwiUUxfQkwiLCJRTF9MVCIsIlFMX0tIIiwiQ19MT1AiLCJVX0xPUCIsIktfQ0hFQ0tfTCIsIlFMX0NDTiIsIlFMX0tLSFYiLCJVX05HQVlfQkgiLCJVX0hBTl9CVCIsIlFMX0hNVCIsIkRLX01UIiwiWF9MRk9STSJdLCJuYmYiOjE3MTMxNzE3MjgsImV4cCI6MTcxMzE3NTMyOH0.84tVM6RM1yIamp-ma1BpiTAysbj1YY99l4TEkyHcMSw',
                          }
                        })
                        setTimeout(function () {
                          history.push('/');
                        }, 3000);
                      } else {
                        toast.error("Vui lòng chọn  'Tôi đã đọc và Đồng ý với Điều khoản thanh toán'")
                      }
                    }}
                    className="px-5 mt-4 btn_theme_red ml-1"
                  >
                    Thanh toán tại văn phòng
                  </button>
                </div>
                <div></div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <DieuKhoanThanhToan isShowDieuKhoan={isShowDieuKhoan} handleCloseModal={handleCloseModal} />
    </div>
  )
}

export default ConfirmPayment
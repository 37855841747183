import React, { useEffect } from 'react';

const FacebookLogin = ({ appId, responseFacebook }) => {

    useEffect(() => {
        // Kiểm tra xem SDK đã được tải chưa
        if (window.FB) {
            initFacebookSDK();
        } else {
            // Đợi SDK tải xong
            window.fbAsyncInit = initFacebookSDK;
        }
    }, []);

    const initFacebookSDK = () => {
        window.FB.init({
            appId: '303284849320308',  // Thay YOUR_APP_ID bằng App ID của bạn
            cookie: true,
            xfbml: true,
            version: 'v12.0' // Sử dụng phiên bản mới nhất
        });
    };

    const handleFBLogin = () => {
        window.FB.login(function (response) {

            if (response.authResponse) {

                window.FB.api('/me', { fields: 'name,email' }, function (response) {
                    responseFacebook(response)
                    console.log('User info: ', response);
                });
            }
        }, { scope: 'public_profile,email' });
    };

    return (
        <div className="App">

            <button className='btn btn-primary p-5' onClick={handleFBLogin}>Login with Facebook</button>
        </div>
    );
}

export default FacebookLogin;

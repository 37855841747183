import { Spin } from "antd";
import "./style.css";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";

export default function SpinnerComponent() {
  const { isLoading } = useSelector((state) => state.spinner);
  return isLoading ? (
    <div className="page-overlay-wrapper">
      <div class="mask">
        <div class="loader">
          <Spin tip="Loading"></Spin>

        </div>
      </div>
    </div>
  ) : (
    <Fragment></Fragment>
  );
}

import React, { useEffect, useState } from "react";
import ItemBanner from "./ItemBanner";
import ListSkill from "./ListSkill";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectThongTinMoRong } from "../../../redux/reducer/authReducer";
export function Item({ data = {} }) {
  let navigate = useNavigate();
  const { t } = useTranslation()
  const { userInfo } = useSelector(selectThongTinMoRong);
  return (
    <div className=" w-full   card-theme">
      <div className="w-full c-card-content flex flex-col  space-y-3 justify-between items-start  h-full ">
        <div className=" text-left flex flex-col justify-between h-full space-y-3 w-full">
          <p className="font-bold  text-left text-title">{data.tenChuyenDe}</p>
          <div className="flex w-full xl:flex-col  justify-between  items-start space-y-5 relative">
            <ListSkill listTag={data.danhSachTag} />

            {data.notify && <span

              class="dot-notify absolute inline-flex h-4 w-4 -top-1 -right-1 rounded-full  z-10"
            ></span>}

            <button
              onClick={(event) => {
                if(userInfo.kichHoat){
                  navigate(`/task-page/${data.chuyenDeId}`);
                }
                else{
                  navigate(`/task-page-free/${data.chuyenDeId}`);
                }
              }}
              className="btn_theme_red"
            >
              {t('trangChu.xemChiTiet')}
            </button>
          </div>
        </div>
        <div className=" flex items-center w-full flex-shrink-0 ">
          <ItemBanner thongKeChuyenDe={data.thongKeChuyenDe} />
        </div>
      </div>
    </div>
  );
}

import React, { useEffect } from 'react';

const ZaloBtn = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://sp.zalo.me/plugins/sdk.js';
    script.async = true;

    document.body.appendChild(script);
  }, []);

  return (
    <div
      className='zalo-chat-widget'
      data-oaid='1427277038490183753'
      data-welcome-message='Rất vui khi được hỗ trợ bạn!'
      data-autopopup='0'
      data-width='150'
      data-height='100'
    ></div>
  );
};

export default ZaloBtn;
import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import SignUpDesktop from "./SignUpDesktop/SignUpDesktop";
import "./Sigup.css";
import localStorageServ from "../../services/locaStorage.service";
var qs = require("qs");

export default function SignUpPage() {
  useEffect(() => {
    let { reffer } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    reffer && localStorageServ.referLink.set(reffer);
  }, []);
  return <SignUpDesktop />;
}

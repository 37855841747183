import { MessageOutlined, UserOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PostChat, PostChatIssue } from "../../../model/Chat";
import { config } from "../../../utils/config";
import { ChangeActiveChat1, ChangeActiveChat2, ChangeActiveChatBot, changeCurrentActive, updateDataChat1, updateDataChat2, updateDataChatBot } from "../../../redux/reducer/stackChatReducer";
import { useTranslation } from "react-i18next";
import { getHinhAnh2 } from "../../../utils/GetHinhanh";

const PopupChat = ({ showDrawer }) => {
  const [arrow, setArrow] = useState("Show");
  const dspopup = config.getStoreJson("LIST_POPUP");
  const [popup, setPopup] = useState(dspopup ?? [])

  // console.log("😀 ~ file: PopupChat.jsx:12 ~ PopupChat ~ dspopup:", dspopup);
  const dispatch = useDispatch();
  const { ActiveChat1, ActiveChat2, dataChat1, dataChat2, ActiveCurrent, ActiveChatBot } = useSelector(
    (state) => state.stackChatReducer
  );
  // console.log(
  //   "😀 ~ file: PopupChat.jsx:11 ~ PopupChat ~ ActiveChat1:",
  //   ActiveChat1
  // );
  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }

    if (arrow === "Show") {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);
  
  const style = { opacity: 1, transform: "translateY(0)", height: "100%" }

  const areObjectsEqual = (obj1, obj2) => {
    if (obj1 === obj2) {
      return true; // Cùng một tham chiếu đến đối tượng
    }

    if (typeof obj1 !== "object" || typeof obj2 !== "object") {
      return false; // Một trong hai không phải là đối tượng
    }

    if (obj1 === null || obj2 === null) {
      return false; // Một trong hai là null
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false; // Số lượng thuộc tính khác nhau
    }

    for (let key of keys1) {
      if (!areObjectsEqual(obj1[key], obj2[key])) {
        return false; // Kiểm tra đệ quy cho các thuộc tính lồng nhau
      }
    }

    return true; // Nếu tất cả các kiểm tra trước đó không trả về false, thì hai đối tượng giống nhau.
  };

  const { chatBots } = useSelector((state) => state.groupChat)
  const PushDataTabChat = (e) => {
    if(e==0){
      !ActiveChatBot && dispatch(ChangeActiveChatBot());
      dispatch(updateDataChatBot(chatBots[0]))
    }
    else if (areObjectsEqual(e, dataChat1)) {
      !ActiveChat1 && dispatch(ChangeActiveChat1());
    } else if (areObjectsEqual(e, dataChat2)) {
      !ActiveChat2 && dispatch(ChangeActiveChat2());
    } else {
      if (ActiveCurrent) {
        dispatch(updateDataChat2(e));
        dispatch(ChangeActiveChat2());
        dispatch(changeCurrentActive());
      } else {
        
        dispatch(updateDataChat1(e));
        dispatch(ChangeActiveChat1());
        dispatch(changeCurrentActive());
      }
    }


  };

  const rederPopup = () => {
    // console.log(popup);
    return popup?.map((e, i) => {
      return (
        <Tooltip
          key={i + " popup"}
          placement="left"
          title={
            e.dsMentor
              ? "Group Chuyên Đề " + e?.tenChuyenDe
              : "Group task " + e?.tenBaiHoc ?? "Group"
          }
          arrow={mergedArrow}
          color="#BF3DC2"
        >
          <div
            className="acc_mess"
            onClick={() => {
              PushDataTabChat(e)
            }}
          >
            <img src={getHinhAnh2(e)} alt={e.id} />
          </div>
        </Tooltip>
      );
    });
  };

  const { t } = useTranslation()

  useEffect(() => {
    setPopup(dspopup)

  }, [dspopup?.length])
  return (
    <div className="popup_chat_container">
      {popup?.length > 0 && <div className="group">

        <div className="popup_mes" style={style}>
          {rederPopup()}

        </div>
      </div>}
      <div className="group">

        <div className="popup_mes" style={style}>
        <Tooltip
          placement="left"
          title={'Chat with bot'}
          arrow={mergedArrow} 
          color="#BF3DC2"
        >
          <div
            className="acc_mess"
            onClick={() => {
              PushDataTabChat(0)
            }}
          >
          <img src='https://play-lh.googleusercontent.com/3dh_AD3dKeum4QVdqjmHILF0JXXuQbe7PxUW9j0VAuOL9R8DXrk06InQhaq0HJ5AeA'  />
          </div>
        </Tooltip>

        </div>
      </div>
      <div className="popup_dsChat" onClick={showDrawer}>
        <Tooltip placement="left" title={t('drawChat.danhSachChat')} arrow={mergedArrow}>
          <UserOutlined />
        </Tooltip>
        
      </div>
    </div>
  );
};

export default PopupChat;

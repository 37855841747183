import React, { useEffect, useRef, useState } from "react";
import RuncodeEditor from "./components/RuncodeEditor/RuncodeEditor";
import ProblemAndSolution from "./components/ProblemAndSolution/ProblemAndSolution";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./runcode.css";
import SplitPane from "react-split-pane";
import { Tag, message } from "antd";
import UserGuilde from "./components/UserGuilde/UserGuilde";
import servCodingCamp from "../../services/codingCam.service";
import { isJsonString, levelClassName } from "./utils";
import { navigatePageByTaskNext, parentPage } from "../../routes/utitlity";
import { useLstLoTrinhDangHoc } from "../../hook/useLstLoTrinhUser";
import { checkLearning } from "../../utils/HocDemoUtils";
import localStorageServ from "../../services/locaStorage.service";
import ModalClickZoomImg from "../../components/ModalClickZoomImg/ModalClickZoomImg";
import ModelZoomImgRunCode from "./components/ModelZoomImgRunCode";

export default function Runcode({}) {
  let { chuyenDeId, taskId, monHocId } = useParams();
  const [detailTask, setDetailTask] = useState();
  const [arrIdRuncode, setArrIdRuncode] = useState([]);
  const [arrIdFinished, setArrIdFinished] = useState([]);
  const [nextTask, setNextTask] = useState();

  let user = localStorageServ.userInfo.get();
  const [questionIndex, setQuestionIndex] = useState(0);
  const [question, setQuestion] = useState(null);
  const [disabledButton, setDisabledButton] = useState(false);
  const thoiGianBatDau = useRef(null);
  const navigate = useNavigate();
  const { data: dataDangHoc } = useLstLoTrinhDangHoc(user?.id);
  const isLearning = checkLearning(chuyenDeId, dataDangHoc);

  const [languages, setLanguages] = useState(question?.init_code[0]._language);


  const increaseQuestionIndex = () => {
    if (questionIndex === arrIdRuncode.length) return;
    setQuestionIndex(questionIndex + 1);
  };
  const decreaseQuestionIndex = () => {
    if (questionIndex === 0) return;
    setQuestionIndex(questionIndex - 1);
  };
  // ! get list id runcode
  const fectdata = (callback) => { 
    console.log('fect');
    servCodingCamp
      .getDetailTask(chuyenDeId, monHocId, taskId)
      .then((res) => {
        setArrIdRuncode(JSON.parse(res.data.content.noiDungNhiemVu));
        setArrIdFinished(JSON.parse(res.data.content.hoanThanhLabel));
        setDetailTask(res.data.content)
        if (callback) callback(); // Call the callback function after data is fetched
      })
      .catch((err) => {
        console.log(err);
      });
   }
  useEffect(() => {
    fectdata()
  }, [taskId]);
  // ! get run code by id
  useEffect(() => {
    if (arrIdRuncode.length > 0) {
      const getQuestion = async () => {
        try {
          const question = await axios({
            method: "GET",
            url: `https://runcode.cyberlearn.vn/getQuestionID/${arrIdRuncode[questionIndex]}`,
            // url: `https://runcode.cyberlearn.vn/getQuestionID/413`,
          });
          let newInitCode = question.data.init_code.map((code) => {
            const { _function, base_code, _answer } = code;
            return {
              ...code,
              base_code: isJsonString(base_code)
                ? JSON.parse(base_code)
                : base_code,
              _function: isJsonString(_function)
                ? JSON.parse(_function)
                : _function,
              _answer: isJsonString(_answer) ? JSON.parse(_answer) : _answer,
            };
          });
          setQuestion({ ...question.data, init_code: newInitCode });
        } catch (error) {
          message.error("Đã có lỗi xảy ra");
          // dispatch(set_request_spinner_ended());
        }
      };
      getQuestion();
      // setDisabledButton(true);
      // dispatch(set_request_spinner_ended());
    }
  }, [questionIndex, arrIdRuncode.length,taskId]);
  useEffect(() => {
    if(question){
      setLanguages(question.init_code[0]._language)
    }
  }, [question]);
  return (
    <>
      {/* <SpinnerComponent /> */}
      <ModelZoomImgRunCode />
      <div
        className="bg-theme-runcode h-screen min-h-screen  space-y-1 px-4  "
        id="layoutRuncode"
      >
        <div className="shadow h-10 flex items-center justify-between ">
          <div className="space-x-4 flex justify-center items-center">
            <button
              onClick={decreaseQuestionIndex}
              disabled={questionIndex === 0}
              className="bg-dark-fill-3 rounded px-2 py-1 text-white h-7 w-7"
            >
              <i className="fa-solid fa-chevron-left"></i>
            </button>
            {/*
            //! render tình trạng đã làm hay chưa
             */}
            <h2 className="text-center text-white text-base mb-0 w-max">
              {question?.vi?.question_title}
            </h2>
            {arrIdFinished?.includes(question?.question_id) ? (
              <Tag className={levelClassName["easy"]}>Đã hoàn thành</Tag>
            ) : (
              <Tag className={levelClassName["hard"]}>Chưa hoàn thành</Tag>
            )}
            {arrIdRuncode.length !== questionIndex + 1 && (
              <button
                disabled={disabledButton}
                onClick={increaseQuestionIndex}
                className={`bg-dark-fill-3 rounded px-2 py-1 text-white h-7 w-7 ${
                  disabledButton ? "cursor-not-allowed" : "cursor-pointer"
                }`}
              >
                <i className="fa-solid fa-chevron-right"></i>
              </button>
              
            )}
            <p className="text-white mb-0 text-base">
              Câu số {questionIndex + 1}/{arrIdRuncode.length}
            </p>
          </div>
          <div className="flex justify-center items-center space-x-4">
            
            <UserGuilde />
            <button
            onClick={() => { 
              console.log(nextTask)
             if(nextTask &&isLearning){
              navigatePageByTaskNext(nextTask, navigate, parentPage.task, monHocId, chuyenDeId);
             }

             }}
          className={` text-white focus:outline-none p-0 px-2 h-8 leading-8  btn_theme_red${nextTask?"":" cursor-not-allowed opacity-50"}`}
        >
           Next
        </button>
          </div>
        </div>
        <div className="content">
          <SplitPane
            split="vertical"
            defaultSize="50%"
            minSize={500}
            maxSize={1000}
          >
            <div className="h-full overflow-y-scroll">
              {question && (
                <RuncodeEditor
                  increaseQuestionIndex={increaseQuestionIndex}
                  disabledButton={disabledButton}
                  setDisabledButton={setDisabledButton}
                  setNextTask={setNextTask}
                  idCauHoi={arrIdRuncode[questionIndex]}
                  question={question}
                  thoiGianBatDau={thoiGianBatDau.current}
                  isShowNextQuestion={arrIdRuncode.length !== questionIndex + 1}
                  languages={languages}
                  setLanguages={setLanguages}
                  fecthdata={fectdata}
                />
              )}
            </div>
            <div className="h-full">
              {question && <ProblemAndSolution detailTask={detailTask} question={question} languages={languages} setLanguages={setLanguages} idCauHoi={arrIdRuncode[questionIndex]}/>}
            </div>
          </SplitPane>
        </div>
      </div>
    </>
  );
}

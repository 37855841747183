import React from "react";
import Lottie from "lottie-react";
import bgAnimate from "./success.json";
import { useNavigate, useParams } from "react-router-dom";
import { navigatePageByTaskNext, parentPage } from "../../routes/utitlity";
import { useLstLoTrinhDangHoc } from "../../hook/useLstLoTrinhUser";
import { checkLearning } from "../../utils/HocDemoUtils";
import localStorageServ from "../../services/locaStorage.service";
import TracNghiem_Start from "../../components/ContentTracNghiemExtra/TracNghiem_Start";
import ResultTracNghiem_Start from "../../components/ContentTracNghiemExtra/ResultTracNghiem_Start";

export default function DaChamContent({ data,nextTask,onSuccess,setNextTask,visited }) {
  
  let user = localStorageServ.userInfo.get();
  let { chuyenDeId, taskId, monHocId } = useParams();
  const navigate = useNavigate();
  const { data: dataDangHoc } = useLstLoTrinhDangHoc(user?.id);
  const isLearning = checkLearning(chuyenDeId, dataDangHoc);
  
  return (
    <div  className="flex flex-col items-center ">
      <Lottie loop={false} animationData={bgAnimate} style={{ width: "20%", height: "30%" }} />
      <p className="text-title text-xl transform -translate-y-10"> Điểm của bạn: {data.diem}</p>
      {nextTask && <button
            onClick={() => {
             if(nextTask && isLearning){
              navigatePageByTaskNext(nextTask, navigate, parentPage.task, monHocId, chuyenDeId);
             }

             }}
          className={`btn_theme_red text-white focus:outline-none p-0 px-2 h-8 leading-8 ${nextTask?"":"opacity-50"}`}
        >
           Next
        </button>}

        <ResultTracNghiem_Start data={data} onSuccess={onSuccess} setNextTask={setNextTask} visited={visited}/>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import ItemRank from "./ItemRank";
import "./style.scss";
export default function RankedSystem({ list, currentRank }) {
  let renderList = () => {
    return list?.map((item, index) => {
      let isActive = item.capDo == currentRank?.capDo;
      let owner = item.capDo<=currentRank.capDo
      return <ItemRank index={index} isActive={isActive} item={item} owner={owner} />;
    });
  };
  return (
    <div12
      style={{ height: 430, padding: "2% 5% 2% 5%" }}
      className=" flex items-center flex-wrap justify-between gap-2 w-full  bg-cover overflow-hidden"
    >
      {renderList()}
    </div12>
  );
}

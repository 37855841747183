import axios from "axios";
import { AxiosServCodingCamp, BASE_URL_CODINGCAMP } from "./axios.service";

/* eslint-disable no-useless-constructor */

class CodingCampRequestService {
  constructor() { }
  postDangKyUser = (data, setLoading = true) => {
    const uri = `nguoidung/dang-ky-nguoi-dung`;
    return AxiosServCodingCamp.postMethod(uri, data, setLoading);
  };
  postDangKyUserV2 = (data, setLoading = true) => {
    const uri = `nguoidung/dang-ky-nguoi-dung-v2`;
    return AxiosServCodingCamp.postMethod(uri, data, setLoading);
  };
  login = (data, setLoading = true) => {
    const uri = "nguoidung/facebook";
    return AxiosServCodingCamp.postMethod(uri, data, setLoading);
  };
  getDataTaskPage = (maChuyenDe, setLoading = true) => {
    const uri = `data-main/${maChuyenDe}`;
    return AxiosServCodingCamp.getMethod(uri, setLoading);
  };
  loginTrialService = (model, time) => {
    const uri = `nguoidung/loginTrial?time=${time}`;
    return AxiosServCodingCamp.postMethod(uri, model);
  };
  loginUserService = (model) => {
    const uri = `nguoidung/login-user`;
    return AxiosServCodingCamp.postMethod(uri, model);
  };
  getDataTaskPageTrial = (maChuyenDe, setLoading = true) => {
    const uri = `data-main/trial/${maChuyenDe}`;
    return AxiosServCodingCamp.getMethod(uri, setLoading);
  };
  getDataTaskFreePage = (maChuyenDe, data, setLoading = true) => {
    const uri = `data-main/free/${maChuyenDe}`;
    return AxiosServCodingCamp.postMethod(uri, data, setLoading);
  };
  getDetailTask = (chuyenDeId, monHocId, taskId, setLoading = true) => {
    const uri = `data-main/${chuyenDeId}/${monHocId}/${taskId}`;
    return AxiosServCodingCamp.getMethod(uri, setLoading);
  };
  getDetailTaskTrial = (chuyenDeId, monHocId, taskId, setLoading = true) => {
    const uri = `data-main/trial/${chuyenDeId}/${monHocId}/${taskId}`;
    return AxiosServCodingCamp.getMethod(uri, setLoading);
  };
  getListThongBao = () => {
    const uri = `thongbao/laythongbao`;
    return AxiosServCodingCamp.getMethod(uri);
  };
  getLichSuTuongTac = () => {
    const uri = `lichsutuongtac/laylichsu`;
    return AxiosServCodingCamp.getMethod(uri);
  };
  postHoanThanhHoc = (data) => {
    const uri = "lich-su-hoc-tap/hoan-thanh-hoc";
    return AxiosServCodingCamp.postMethod(uri, data);
  };
  postLayDanhSachHoanThanhHoc = (data) => {
    const uri = "lich-su-hoc-tap/get-hoan-thanh-hoc";
    return AxiosServCodingCamp.postMethod(uri, data);
  };
  postlayDanhSachHoangThanh = (data) => {
    const uri = "lich-su-hoc-tap/get-task-hoan-thanh";
    return AxiosServCodingCamp.postMethod(uri, data);
  };
  // nop bai tap
  batDauLamBaiTap = (data) => {
    let uri = "nopbai/bat-dau-bai-tap";
    return AxiosServCodingCamp.postMethod(uri, data, false);
  };
  postNopBaiTap = (data) => {
    let uri = "nopbai";
    return AxiosServCodingCamp.postMethod(uri, data);
  };
  postNopLaiBaiTap = (data) => {
    let uri = "nopbai/nop-bai-lai";
    return AxiosServCodingCamp.postMethod(uri, data);
  };

  getLayThongTinBaiTap = (data) => {
    let uri = `nopbai/get-diem-bai-tap`;
    return AxiosServCodingCamp.postMethod(uri, data);
  };
  getLayThongTinBaiTapNop = (data) => {
    let uri = `nopbai/get-diem-bai-tap-nop`;
    return AxiosServCodingCamp.postMethod(uri, data);
  };

  // diem danh
  getLayDanhSachDiemDanh = () => {
    let uri = "diemdanh/get-diem-danh-tuan";
    return AxiosServCodingCamp.getMethod(uri);
  };
  postDiemDanh = () => {
    let uri = "diemdanh";
    return AxiosServCodingCamp.postMethod(uri);
  };
  getThongKeHocDashboard = (chuyenDeId, thoiGian) => {
    let uri = `thong-ke/thong-ke-hoc-tap-theo-ngay/${chuyenDeId}/${thoiGian}`;
    return AxiosServCodingCamp.getMethod(uri, false);
  };
  // thong tin user : thành tích
  getThongTinMoRong = () => {
    let uri = "lichsutuongtac/lay-thongtin-user";
    return AxiosServCodingCamp.getMethod(uri);
  };

  // best practice
  getBestPractice = (chuyenDeId) => {
    let uri = `best-practice/${chuyenDeId}`;
    return AxiosServCodingCamp.getMethod(uri);
  };
  postToggleSaveBestPratice = (option, data) => {
    // remove: 0: lưu bài , 1 = xóa bài lưu
    let uri = `best-practice/save-best-practice/${option}`;
    return AxiosServCodingCamp.postMethod(uri, data);
  };
  postViewsBestPratice = (data) => {
    // tang luot xem
    let uri = `best-practice/hoan-thanh-hoc`;
    return AxiosServCodingCamp.postMethod(uri, data);
  };

  // luyện tập
  getListPratice = (chuyenDeId) => {
    let uri = `practice/${chuyenDeId}`;
    return AxiosServCodingCamp.getMethod(uri);
  };
  // mục tiêu học
  postThayDoiMucTieuHoc = (data) => {
    let uri = `dangkyhoc/thay-doi-muc-tieu`;
    return AxiosServCodingCamp.postMethod(uri, data);
  };

  // tài liệu
  getDocumentByOption = (option) => {
    // option=0 : lấy tất cả
    // option = id in list : detail
    let uri = `/bai-viet/lay-baiviet/1/${option}`;
    return AxiosServCodingCamp.getMethod(uri);
  };

  // cuộc thi
  getListCompetition = () => {
    let uri = `/cuocthi/get-cuocthi/1`;
    return AxiosServCodingCamp.getMethod(uri);
  };
  // xep hang tong
  getXepHangTong = (chuyenDeId = 1, loai = 0) => {
    let uri = `thong-ke/xep-hang-tong-chuyende/${chuyenDeId}/${loai}`;
    return AxiosServCodingCamp.getMethod(uri, false);
  };
  // chart thong ke thoi gian hoc
  // ThoiGian: 0: Tuần , 1: ThángTop: true: lấy top 3, false : lấy theo user
  getDataChartThongKe = (thoiGian, top = false) => {
    const uri = `thong-ke/lay-thoi-gian-hoc/${thoiGian}/${top}`;
    return AxiosServCodingCamp.getMethod(uri);
  };
  getThongKeTask_ThoiGian = (chuyeDeId) => {
    const uri = `thong-ke/thong-ke-hoc-tap/${chuyeDeId}`;
    return AxiosServCodingCamp.getMethod(uri);
  };
  getXepHangTheoChuyenDe = (chuyenDeId, loai) => {
    const uri = `thong-ke/xep-hang-chuyende/${chuyenDeId}/${loai}`;
    return AxiosServCodingCamp.getMethod(uri);
  };
  getXepHangTheoChuyenDeFree = (id, chuyenDeId, loai) => {
    const uri = `thong-ke/xep-hang-chuyende-free/${id}/${chuyenDeId}/${loai}`;
    return AxiosServCodingCamp.getMethod(uri);
  };
  // thong ke task va thoi gian

  // vật phẩm
  getDanhSachVatPham = () => {
    const uri = `vatpham/danh-sach-vatpham`;
    return AxiosServCodingCamp.getMethod(uri);
  };
  ghepVatPham = (vatPhamId) => {
    const uri = `vatpham/ghep-vatpham/${vatPhamId}`;
    return AxiosServCodingCamp.postMethod(uri, false);
  };
  muaVatPham = (vatPhamId) => {
    const uri = `vatpham/mua-vatpham/${vatPhamId}`;
    return AxiosServCodingCamp.postMethod(uri);
  };
  homDoCuaBan = () => {
    let uri = `vatpham/hom-do-user`;
    return AxiosServCodingCamp.getMethod(uri);
  };
  loaiVatPham = () => {
    let uri = `vatpham/loai-vatpham`;
    return AxiosServCodingCamp.getMethod(uri);
  };
  // bài test
  getDataBaiTest = (type) => {
    let uri = `bai-test/quiz-test/${type}`;
    return AxiosServCodingCamp.getMethod(uri);
  };
  getDataBaiTestAdmin = (type) => {
    let uri = `bai-test/quiz-test-all/${type}`;
    return AxiosServCodingCamp.getMethod(uri);
  };

  // runcode
  getDataRunCodeById = (id) => {
    let uri = `https://runcode.cyberlearn.vn/getQuestionID/${id}`;
    return axios.get(uri);
  };
  putNopBaiTestDauVao = (data, setLoading = true) => {
    const uri = "bai-test/nopbaiTest";
    return AxiosServCodingCamp.putMethod(uri, data, setLoading);
  };
  // hint
  checkTrangThaiHint = (data) => {
    const uri = "lichsutuongtac/check-hint";
    return AxiosServCodingCamp.postMethod(uri, data, false);
  };
  moHint = (data) => {
    const uri = "lichsutuongtac/mo-hint";
    return AxiosServCodingCamp.postMethod(uri, data, false);
  };
  moHintPratice = (data) => {
    const uri = "lichsutuongtac/mo-hint-pratice";
    return AxiosServCodingCamp.postMethod(uri, data, false);
  };
  checkTrangThaiHintPractice = (data) => {
    const uri = "lichsutuongtac/check-hint-practice";
    return AxiosServCodingCamp.postMethod(uri, data, false);
  };
  // tường nhà
  getThongTinTuongNha = (userId, pageIndex) => {
    let uri = `nguoidung/get-thongtin-user/${userId}/${pageIndex}`;
    return AxiosServCodingCamp.getMethod(uri, true);
  };
  postMoHopQuaPratice = (luyenTapId, point) => {
    let uri = `practice/nhan-thuong/${luyenTapId}/${point}`;
    return AxiosServCodingCamp.postMethod(uri, {}, false);
  };
  getDetailVideoWall = (nopBaiId) => {
    let uri = `/tuong-tac-video/chi-tiet-video/${nopBaiId}`;
    return AxiosServCodingCamp.getMethod(uri, false);
  };
  getBinhLuanVideo = (nopBaiId, binhLuanId) => {
    let uri = `tuong-tac-video/lay-binh-luan/${nopBaiId}/${binhLuanId}`;
    return AxiosServCodingCamp.getMethod(uri, false);
  };
  postCommentVideo = (trangThaiId, data) => {
    let uri = `tuong-tac-video/binh-luan/${trangThaiId}`;
    return AxiosServCodingCamp.postMethod(uri, data, false);
  };
  // tăng tương tác
  postTangViewVideo = (nopBaiId) => {
    let uri = `${BASE_URL_CODINGCAMP}/api/tuong-tac-video/them-view/${nopBaiId}`;
    return axios.post(uri);
  };

  // check follow
  checkFollow = (userId) => {
    let uri = `tuong-tac-video/check-follow/${userId}`;
    return AxiosServCodingCamp.postMethod(uri, {}, false);
  };
  toggleFlollow = (userId, status) => {
    let uri = `tuong-tac-video/follow-user/${userId}/${status}`;
    return AxiosServCodingCamp.postMethod(uri, {}, false);
  };

  toogleLikeVideo = (nopBaiId, status) => {
    let uri = `tuong-tac-video/like-video/${nopBaiId}/${status}`;
    return AxiosServCodingCamp.postMethod(uri, {}, false);
  };
  toogleSaveVideo = (nopBaiId, status) => {
    let uri = `tuong-tac-video/luu-video/${nopBaiId}/${status}`;
    return AxiosServCodingCamp.postMethod(uri, {}, false);
  };

  // like comment
  // rangThai
  // phân task0
  chiaTaskCapstone = (data) => {
    let uri = `requireforstudent/get-task-user`;
    return AxiosServCodingCamp.postMethod(uri, data);
  };
  layLotrinhUser = (id) => {
    let uri = `dangkyhoc/get-lo-trinh-user/?id=${id}`;
    return AxiosServCodingCamp.getMethod(uri);
  };
  layAllChuyenDeTrial = () => {
    let uri = `chuyende/trial`;
    return AxiosServCodingCamp.getMethod(uri);
  }
  getCheckGmail = (gmail, setLoading = true) => {
    const uri = `nguoidung/email/${gmail}`;
    return AxiosServCodingCamp.getMethod(uri, setLoading);
  };
  // thanhTich
  getListThanhTich = () => {
    const uri = `thanh-tich`;
    return AxiosServCodingCamp.getMethod(uri, false);
  };
  getUrlVideoRecord_FPT = (noiDung, setLoading = true) => {
    const uri = `file/ftp-video-record/${noiDung}`;
    return AxiosServCodingCamp.getMethod(uri, setLoading);
  };

  getUrlVideo_FPT = (noiDung, setLoading = true) => {
    const uri = `file/ftp-video/${noiDung}`;
    return AxiosServCodingCamp.getMethod(uri, setLoading);
  };

  getUrlVideoSolution_FPT = (noiDung, setLoading = true) => {
    const uri = `file/ftp-video-solution/${noiDung}`;
    return AxiosServCodingCamp.getMethod(uri, setLoading);
  };
  // huy hieu
  layMocHuyHieu = () => {
    const uri = `cauhinh/get-moc-huy-hieu`;
    return AxiosServCodingCamp.getMethod(uri, false);
  };
  // lưu time hoạt động=
  postTimeHoatDong = (data) => {
    const uri = `diemdanh/time-hoat-dong`;
    return AxiosServCodingCamp.postMethod(uri, data, false);
  };
  postDPutNguoiDung = (data, setLoading = true) => {
    const uri = `nguoidung/post-put-nguoidung`;
    return AxiosServCodingCamp.postMethod(uri, data, setLoading);
  };
  getNguoiDungById = (id, setLoading = true) => {
    const uri = `nguoidung/${id}`;
    return AxiosServCodingCamp.getMethod(uri, setLoading);
  };


  // get video cyberlearn
  getFileFtpCyberLearn = (noiDung) => {
    return axios.get(`https://backend.cyberlearn.vn/api/file/ftp-video/${noiDung}`)
  }

}

const servCodingCamp = new CodingCampRequestService();

export default servCodingCamp;
import React, { useState } from "react";
import Lottie from "lottie-react";
import login_bg from "../../assets/lottie_json/login-bg.json";
import "../../css/loginPage.css";
import BtnLoginFacebook from "../../components/BtnLoginFacebook/BtnLoginFacebook";
import { history } from "../../App";
import FormLogin from "../LoginPageEmail/FormLogin";
import FormLoginEmailPhone from "../LoginPageEmail/FormLoginEmailPhone";

export default function LoginPageTablet() {
  return (
    <div className="w-screen h-screen  overflow-hidden bg-cover flex flex-col  bg-fixed py-10  ">
      <div className=" w-full flex-grow flex flex-col relative">
        <div className="w-full flex flex-grow flex-col relative overflow-hidden">
          <div className="absolute bottom-3 space-x-3 left-1/2 transform -translate-x-1/2">
            <a
              href="https://cybersoft.edu.vn/"
              target="_blank"
              className="text-content border-b-1 border-gray-700"
            >
              CyberSoft
            </a>
            <a
              href="https://cyberlearn.vn/"
              target="_blank"
              className="text-content border-b-1 border-gray-700"
            >
              CodeZuni
            </a>
            q
          </div>
          <div className=" w-full flex-shrink-0 border-none p-5 flex flex-col items-start ">
            <div className="  flex-grow  w-full flex flex-col justify-center space-y-7 pb-24 relative z-10 items-center">
              <p className="title_login text-3xl text-white text-center">Hệ thống học tập CodeZuni</p>
              <BtnLoginFacebook />
              <span className="pt-3">HOẶC</span>
              <div style={{width:'90%'}}>
              <FormLoginEmailPhone/>
              </div>
              <p className="cursor-pointer" onClick={() => { 
                history.push('/signup-user')
               }}>Chưa có tài khoản. <span className="underline">Đăng ký</span></p>
            </div>
          </div>
          <div className="w-full flex items-center justify-center relative  ">
            <img
              src="https://hubspot.iqonic.design/hubfs/raw_assets/public/xamin-theme/images/banner/circle-white.png"
              className="absolute border-t-0 -right-1/3  object-contain  transform"
              alt=""
            />
            <div className="h-96 w-96 flex items-center justify-center ">
              <div className=" transform  scale-150">
                <Lottie animationData={login_bg} style={{ width: 500, height: 500 }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

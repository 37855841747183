import React, { Fragment, useEffect, useMemo, useState } from "react";

import CollapseTask from "./CollapseTask/CollapseTask";
import BannerTask from "./Banner/BannerTask";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataTaskPageAction,
  getDataTaskPageFreeAction,
  getDataTaskPageTrialAction,
} from "../../redux/reducer/taskPageSlice";
import SpinnerComponent from "../../components/Spinner";
import { checkDemoUser, checkLearning } from "../../utils/HocDemoUtils";
import * as signalR from "@microsoft/signalr";
import { BASE_URL_CODINGCAMP } from "../../services/axios.service";
import { selectThongTinMoRong } from "../../redux/reducer/authReducer";
import {
  getChuyenDeApi,
  getListTagAction,
  getLstByTag,
  getLstByTagApi,
} from "../../redux/reducer/chuyenDeReducer-Chat";
import {
  useLstLoTrinhDangHoc,
  useLstLoTrinhUser,
} from "../../hook/useLstLoTrinhUser";
import { API_URL_DOMAIN } from "../../utils/urlDomain";
import useWindowSize from "../../hook/useWindowSize";
import { useLstImgTag } from "../../hook/useLstImgTag";
import { history } from "../../App";
import axios from "axios";
import "./utils/taskPage.scss";
export default function TaskPage() {
  //collapse list_course
  const [isCollapse, setIsCollapse] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const [arrCyber, setArrCyber] = useState([]);
  const { widthWindow, heightWindow } = useWindowSize();
  const isPhone = widthWindow < 798 ? true : false;
  let { dataTaskPage, loading } = useSelector((state) => state.taskPageSlice);
  let { lstCDByTag } = useSelector((state) => state.chuyenDe);
  let { chuyenDeId } = useParams();
  let dispatch = useDispatch();
  let isDemoUser = useMemo(() => checkDemoUser(), []);
  const { userInfo } = useSelector(selectThongTinMoRong);

  const { data, isLoading, error } = useLstLoTrinhUser(userInfo);
  const { data: dataDangHoc } = useLstLoTrinhDangHoc(userInfo?.id);
  const { data: listImgTag } = useLstImgTag();

  const [arrChuyenDe, setArrChuyenDe] = useState();
  const isLearning = checkLearning(chuyenDeId, dataDangHoc);

  const handleShowMore = () => {
    setShowAll(!showAll);
  };
  const displayedItems = showAll ? arrCyber : arrCyber.slice(0, 3);
  useEffect(() => {
    if (dataDangHoc) {
      isDemoUser || !userInfo.kichHoat || !isLearning
        ? dispatch(getDataTaskPageTrialAction(chuyenDeId))
        : dispatch(getDataTaskPageAction(chuyenDeId));
      return () => {
        dispatch(getDataTaskPageAction.fulfilled({}));
        dispatch(getLstByTag([]));
      };
    }
  }, [chuyenDeId, dataDangHoc]);
  useEffect(() => {
    axios
      .get(`${API_URL_DOMAIN}/chuyende`)
      .then((res) => {
        setArrChuyenDe(res.data.content);
      })
      .catch((err) => {});
  }, [chuyenDeId]);
  useEffect(() => {
    if (dataTaskPage) {
      dispatch(getLstByTagApi(JSON.stringify(dataTaskPage?.danhSachTag)));
    }
  }, [dataTaskPage]);
  useEffect(() => {
    axios.get(`https://apicrm.cybersoft.edu.vn/api/gioithieu`).then((res) => {
      setArrCyber(res.data.content);
    });
    dispatch(getChuyenDeApi());

    if (isDemoUser || !userInfo.kichHoat) {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${BASE_URL_CODINGCAMP}/chat`)
        .configureLogging(signalR.LogLevel.Information)
        .build();

      connection
        .start()
        .then(() => {})
        .catch((err) => {});

      connection.on("updateDemoBH", (message) => {
        dispatch(getDataTaskPageTrialAction(chuyenDeId));
      });

      // Cleanup function
      return () => {
        connection.off("updateDemoBH");
        connection.stop();
      };
    }
  }, []);
  return (
    <div className="w-full h-full ">
      <div className="c-col-full">
        <BannerTask dataTask={dataTaskPage} />
      </div>
      <div className="flex relative taskPageCourse">
        <div
          className={`${
            isPhone ? "c-col-full" : isCollapse ? "c-col-66" : "c-col-95"
          }  space-y-5`}
        >
          {loading && (
            <div className="flex justify-center">
              {" "}
              <div className=" loading_progress"></div>
            </div>
          )}
          {dataTaskPage?.danhSachMonHoc?.map((item, index) => {
            return (
              <CollapseTask
                dataContent={item}
                key={index}
                index={index}
                arrChuyenDe={arrChuyenDe}
              />
            );
          })}
        </div>
        {isPhone ? (
          ""
        ) : (
          <Fragment>
            <div
              className={`csm34 ${isCollapse ? " c-col-34" : "collapsed w-0"}`}
            >
              <div className="c-col-full card-theme">
                <i
                  className="fa-regular fa-sidebar-flip pr-2 cursor-pointer text-right d-block font-bold text-title"
                  onClick={() => setIsCollapse(false)}
                ></i>
                <p className="font-bold text-title text-left">
                  Khoá học kiến tập sự nghiệp tại CyberSoft
                </p>
                <div className="py-5">
                  {displayedItems?.map((item, idx) => {
                    if (item.metaData === "Cybersoft") {
                      return (
                        <div
                          key={idx}
                          className="flex items-center py-3 cursor-pointer"
                          onClick={() => {
                            window.open(item.linkMoTa, "_blank");
                          }}
                        >
                          <img
                            className="object-cover rounded-lg w-48 md:rounded-none md:rounded-s-lg"
                            src={`${item.hinhAnh}`}
                            alt={item.tenSanPham}
                          />
                          <div className="flex flex-col justify-between p-2 leading-normal">
                            <p className="mb-2 text-base font-bold tracking-tight text-white">
                              {item.tenSanPham}
                            </p>
                          </div>
                        </div>
                      );
                    }
                  })}
                  {arrCyber.length > 3 && (
                    <div className="text-center">
                      <button
                        className="text-white hover:underline"
                        onClick={handleShowMore}
                      >
                        {showAll ? "Thu gọn" : "Xem thêm"}
                      </button>
                    </div>
                  )}
                </div>
                <p className="font-bold text-title text-left pt-4">
                  <i
                    className="fa-regular fa-sidebar-flip pr-2 cursor-pointer"
                    onClick={() => setIsCollapse(false)}
                  ></i>
                  Khoá học cao cấp liên quan
                </p>
                <div className="py-5">
                  {lstCDByTag
                    ?.filter((item) => {
                      let checkLearn = checkLearning(item.id, dataDangHoc);
                      return !checkLearn && item.id !== chuyenDeId;
                    })
                    .slice(0, 3)
                    .map((item, idx) => {
                      let arrTag = JSON.parse(item.danhSachTag);

                      return (
                        <div
                          key={idx}
                          className="flex items-center py-3 cursor-pointer"
                          onClick={() => {
                            history.push(`/task-page-free/${item.id}`);
                          }}
                        >
                          <img
                            className="object-cover rounded-lg w-48 md:rounded-none md:rounded-s-lg"
                            src={`https://apimentor11.cybersoft.edu.vn/${item.hinhAnh}`}
                            alt={item.tenChuyenDe}
                          />
                          <div className="flex flex-col justify-between p-2 leading-normal">
                            <p className="mb-2 text-base font-bold tracking-tight text-white">
                              {item.tenChuyenDe}
                            </p>
                            <div className="flex flex-wrap mb-2">
                              {arrTag.map((tag) => {
                                let item1 = listImgTag?.find(
                                  (item1) => item1?.value === tag
                                );
                                return (
                                  <div key={tag.value} className="w-6 h-6 p-1">
                                    <img
                                      className="w-full h-full object-cover rounded"
                                      src={item1?.image}
                                      alt={item1?.value}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  <div className="text-center">
                    <button
                      className="text-white hover:underline"
                      onClick={() => history.push("/package")}
                    >
                      Xem thêm
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={`csm34 ${isCollapse ? "expanded w-0 " : "c-col-5"}`}>
              <div className="c-col-full">
                <p className="text-end">
                  <i
                    className="text-title fa-regular fa-sidebar-flip pr-2 cursor-pointer"
                    onClick={() => setIsCollapse(true)}
                  ></i>
                </p>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

import React from "react";
import FormThongTinChiTietTV from "./FormThongTinChiTietTV";

export default function ThongTinChiTietTV() {
  return (
    <div className="w-full p-3 flex flex-col items-center space-y-5">
      <p className="uppercase text-xl text-white">ĐĂNG KÝ THÔNG TIN CHÍNH</p>
      <FormThongTinChiTietTV />
    </div>
  );
}

import React, { useEffect } from "react";
import "./Sigup.css";
import localStorageServ from "../../services/locaStorage.service";
import SignUpDesktopFree from "./SignUpFree/SignUpDesktopFree";
var qs = require("qs");

export default function SignUpPageFree() {
  // useEffect(() => {
  //   let { reffer } = qs.parse(window.location.search, {
  //     ignoreQueryPrefix: true,
  //   });

  //   reffer && localStorageServ.referLink.set(reffer);
  // }, []);


  return <SignUpDesktopFree />;
}

import { useQuery } from "@tanstack/react-query";
import servCodingCamp from "../services/codingCam.service";
import { checkDemoUser } from "../utils/HocDemoUtils";
import { useMemo } from "react";
import { API_URL_DOMAIN } from "../utils/urlDomain";
import axios from "axios";
import { useParams } from "react-router-dom";

export function useLstLoTrinhUser(userInfo) {
  const shouldActivateQuery =
    !!userInfo && userInfo.email !== "freeacc@gmail.com";
  let isDemoUser = useMemo(() => checkDemoUser(), []);

  let { id } = useParams("id")


  return useQuery({
    queryKey: ["loTrinhUser", userInfo?.id], // Dùng id người dùng để đảm bảo tính duy nhất của key
    queryFn: () => {
      if (isDemoUser || !userInfo?.kichHoat) {
        return servCodingCamp
          .layAllChuyenDeTrial()
          .then((res) => res.data.content);
      } else {
        return servCodingCamp.layLotrinhUser(id ? "1" : "0").then((res) => res.data.content);
      }
    },
    staleTime: 60 * 60 * 1000, // Dữ liệu được coi là mới trong 60 phút
    cacheTime: 24 * 60 * 60 * 1000, // Dữ liệu còn trong bộ nhớ cache 30 phút sau khi không còn được sử dụng
    refetchOnWindowFocus: false,
    retry: false,
    enabled: shouldActivateQuery, // Chỉ kích hoạt query khi có thông tin người dùng
  });
}
export function useLstLoTrinh() {
  return useQuery({
    queryKey: ["loTrinh"],
    queryFn: () => {
      return servCodingCamp
        .layAllChuyenDeTrial()
        .then((res) => res.data.content);
    },
    staleTime: 60 * 60 * 1000, // Dữ liệu được coi là mới trong 60 phút
    cacheTime: 24 * 60 * 60 * 1000, // Dữ liệu còn trong bộ nhớ cache 30 phút sau khi không còn được sử dụng
    refetchOnWindowFocus: false,
    retry: false,
  });
}
export function useLstLoTrinhDangHoc(userId) {
  return useQuery({
    queryKey: ["loTrinhDangHoc", userId],
    queryFn: () => {
      return axios
        .get(`${API_URL_DOMAIN}/dangkyhoc/get-theo-user/${userId}`)
        .then((res) => res.data.content)
    },
    staleTime: 60 * 60 * 1000, // Dữ liệu được coi là mới trong 60 phút
    cacheTime: 24 * 60 * 60 * 1000, // Dữ liệu còn trong bộ nhớ cache 30 phút sau khi không còn được sử dụng
    refetchOnWindowFocus: false,
    retry: false,
  });
}

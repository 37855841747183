import React from "react";
let styleBtn = {
  left: "1137px",
  top: "658px",
  background:
    "radial-gradient(226.46% 280.26% at 41.98% 268.42%, #FF0C0C 8.4%, #301D09 89.92%) ",
  borderRadius: "11px",
  border: "2px solid #F5BC7A",
};
export default function ButtonNopBai({ text = "Nộp bài", onClick = () => {} }) {
  return (
    <div class=" text-content mt-2">
      <button onClick={onClick} style={styleBtn} className="px-8 py-2">
        {text}
      </button>
    </div>
  );
}

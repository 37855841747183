import React, { useEffect, useState } from "react";
import DailiActive from "./DailyActive/DailiActive";
import HourlyAcive from "./HourlyAcive/HourlyAcive";
import servCodingCamp from "../../services/codingCam.service";
import Bonous from "./Bonous";
import { useParams } from "react-router-dom";

export default function PracticPage() {
  const [dataPratice, setDataPratice] = useState({
    danhSachNoiDung: [],
    phanThuong: [],
  });
  let { chuyenDeId } = useParams();

  useEffect(() => {
    servCodingCamp
      .getListPratice(chuyenDeId)
      .then((res) => {
        // console.log("🚀 - .then - res", res);
        setDataPratice(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className=" h-full    ">
      <Bonous dataPratice={dataPratice} />
      <div className="flex">
        <DailiActive dataPratice={dataPratice} />
        {/* <HourlyAcive /> */}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, DatePicker, Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import httpServ from "../../../services/http.service";
import moment from "moment";
import "moment/locale/vi";
import { setCurrentStep, setUserInfor } from "../../../redux/reducer/signUpReducer";
import localStorageServ from "../../../services/locaStorage.service";
import servCodingCamp from "../../../services/codingCam.service";
import { history } from "../../../App";
import UploadImg from "../SignUpDesktop/UploadImg";
import Modal_MinhHoa from "../SignUpDesktop/Modal_MinhHoa";

export default function FormThongTinChiTietTV() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const regisPackage = localStorageServ.regisPackage.get();
  useEffect(() => {
    form.setFieldsValue({
      congViecHienTai1: "SV",
      luongMongMuon: 0,
    });
  }, []);
  let { currentStep } = useSelector((state) => state.signUp);
  const [listLoTrinh, setListLoTrinh] = useState([]);
  const [listCMND_IMG, setListCMND_IMG] = useState([]);
  const { userInfo } = useSelector((state) => state.signUp);
  useEffect(() => {
    httpServ
      .getMaVaTenLoTrinh()
      .then((res) => {
        setListLoTrinh(res.data.content);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);
  const hadleGetImgAfterConver = (value) => {
    setListCMND_IMG(value);
  };
  const onFinish = (values) => {
    // console.log("Success form:", values);
    let newUserInfor = JSON.parse(JSON.stringify(userInfo));
    newUserInfor.thongTinMoRong.namSinh = moment(values.namSinh).format("MM/DD/YYYY");
    // newUserInfor.thongTinMoRong.hinhCmnd = JSON.stringify(listCMND_IMG);
    newUserInfor.thongTinMoRong.hinhCmnd = listCMND_IMG;
    newUserInfor.thongTinMoRong.luongMongMuon = values.luongMongMuon ? values.luongMongMuon * 1 : 0;
    // newUserInfor.maLoTrinh = values.maLoTrinh;
    newUserInfor.thongTinMoRong.facebookUrl = values.facebookUrl;
    newUserInfor.thongTinMoRong.soCmnd = values.soCmnd;
    newUserInfor.thongTinMoRong.predictiveIndexRequire = [];
    newUserInfor.thongTinMoRong.predictiveIndex = [];
    // newUserInfor.thongTinMoRong = JSON.stringify(newUserInfor.thongTinMoRong);
    newUserInfor.maGioiThieu = localStorageServ.referLink.get() || "";
    
    servCodingCamp
      .postDPutNguoiDung(newUserInfor)
      .then((res) => {
        localStorageServ.inforSignUp.set(res.data.content)
        dispatch(setCurrentStep(currentStep + 1));
        if(regisPackage!=0){
          history.push('/confirm-payment')
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("😀 - onFinishFailed - errorInfo", errorInfo);
  };
  return (
    <div className="flex w-full ">
      <div className="w-full  flex-grow ">
        <Form
          form={form}
          layout="vertical"
          name="basic"
          className="container "
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="wfull flex flex-col ">
            <div className=" w-full  pr-5">
              <Form.Item
                label="Ngày Sinh"
                name="namSinh"
                rules={[
                  {
                    type: "object",
                    required: true,
                    message: "Trường này không được để trống",
                  },
                ]}
              >
                <DatePicker placeholder="Nhập ngày sinh" className="w-full" />
              </Form.Item>

              <Form.Item
                label="Lương mong muốn khi xin việc"
                name="luongMongMuon"
                rules={[
                  () => ({
                    validator(_, value) {
                      if (isNaN(value)) {
                        return Promise.reject("Lương mong muốn phải là chữ số, ví dụ: 1000000");
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className=" w-full  pr-5">
              <Form.Item
                label="Link facebook"
                name="facebookUrl"
                rules={[
                  { required: true, message: "Trường này không được để trống" },
                  () => ({
                    validator(_, value) {
                      let data =
                        value != undefined ? value.toLowerCase().indexOf("facebook.com") : "";
                      if (value != undefined && value != "" && data == -1) {
                        return Promise.reject("Facebook không hợp lệ");
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Số CMND (Căn cước, Hộ chiếu)"
                name="soCmnd"
                rules={[
                  { required: true, message: "Trường này không được để trống" },
                  {
                    max: 12,
                    message: "Số giấy tờ tuỳ thân có độ dài 8 - 12 chữ số",
                  },
                  {
                    min: 8,
                    message: "Số giấy tờ tuỳ thân có độ dài 8 - 12 chữ số",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {/*

              file
               */}
              <UploadImg hadleGetImgAfterConver={hadleGetImgAfterConver} />
              <div className="text-white">
                <p>- Chỉ chấp nhận CMND, CCCD hoặc giấy phép lái xe</p>
                <p>
                  - Hình chụp phải rõ, thấy khuôn mặt nằm thẳng đứng <Modal_MinhHoa />.
                </p>
                <p>
                  * Nếu bạn có vấn đề khi upload hãy liên hệ{" "}
                  <a
                    className="underline ml-1 text-blue-600 font-medium"
                    target="_blank"
                    href="https://www.facebook.com/codezuni"
                  >
                    Link
                  </a>{" "}
                  này
                </p>
                Lưu ý: Việc cung cấp các hình ảnh trên để xác minh học viên và phục vụ việc cấp
                chứng nhận cũng như để đảm bảo việc bảo mật tài nguyên của CodeZuni. CodeZuni cam đoan sẽ{" "}
                <b> KHÔNG </b>
                cung cấp hình ảnh này cho bất kỳ bên nào khác.
              </div>
            </div>
          </div>
          <Form.Item>
            <div className="w-full flex justify-center space-x-3 ">
              <Button
                onClick={() => {
                  let prev = currentStep - 1;
                  dispatch(setCurrentStep(prev));
                }}
                // type="primary"
                htmlType="submit"
                className="w-24 rounded-sm"
              >
                Quay lại
              </Button>
              <Button
                className="px-5 bg-yellow-400 border-none text-black"
                type="primary"
                htmlType="submit"
              >
                Hoàn thành
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

import { getStoreJson, http } from "../utils/config";

const GET_PATH = "/api/nopbai";

const GetDSByGtaskId = function (gtaskId, mentorId) {
    return http.get(`${GET_PATH}/get-ds-gtaskid-mentorid?gtaskid=${gtaskId}&mentorId=${mentorId}`)
}

const mentorChamBai = function (mentorId, mentorChamBai) {
    return http.put(`${GET_PATH}/mentorchambai?mentorId=${mentorId}`, mentorChamBai)
}

const GetNopBaisByNguoiDungService = function (mentorId) {
    return http.get(`${GET_PATH}/mentorchambai?mentorId=${mentorId}`)
}

const GetNopBaisByNguoiDungViewModelService = function (mentorId) {
    return http.get(`${GET_PATH}/mentorchambaiviewmodel?mentorId=${mentorId}`)
}

export const nopbaiService = {
    GetDSByGtaskId,
    mentorChamBai,
    GetNopBaisByNguoiDungViewModelService,
    GetNopBaisByNguoiDungService
}

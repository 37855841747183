import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import httpServ from "../../services/http.service";
import servCodingCamp from "../../services/codingCam.service";
import { loaiNhiemVu } from "../../utils/taskUtils";
import classNames from "classnames";
import { Mark_Video } from "../../helper/watermark";
import localStorageServ from "../../services/locaStorage.service";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import axios from 'axios'
import VideoPlayer from "../../components/video/VideoPlayer";
import { useSelector } from "react-redux";
import { selectThongTinMoRong } from "../../redux/reducer/authReducer";
import useWindowSize from "../../hook/useWindowSize";

export default function ScreenVideo({
  baiHoc,
  handleHoanThanhBaiHoc,
  handleChangeNext,
  handleChangePrevious,
  listBaiHoc
}) {
  const { widthWindow, heightWindow } = useWindowSize();

  const { t } = useTranslation()

  const [urlVideo, setUrlVideo] = useState("");
  const [isDisableHoanThanh, seIsDisableHoanThanh] = useState(true);
  const user = localStorageServ.userInfo.get();

  const { userInfo } = useSelector(selectThongTinMoRong);
  useEffect(() => {
    console.log(baiHoc.content?.metaData)

    if (baiHoc.content?.noiDung) {

      let layCyberLearn = false;

      if (baiHoc && baiHoc.content?.metaData) {
        let metaData = JSON.parse(baiHoc.content?.metaData)
        if (metaData.videoCyberLearn && metaData.videoCyberLearn)
          layCyberLearn = true
      }


      let { noiDung, type } = baiHoc.content;
      let api =
        baiHoc.type == loaiNhiemVu.RECORD
          ? servCodingCamp.getUrlVideoRecord_FPT(noiDung)
          :
          layCyberLearn ?
            userInfo.nuocNgoai ? axios.get(`https://apicrm.cybersoft.edu.vn/api/file/ftp-video-digital/${noiDung}`) : axios.get(`https://backend.cyberlearn.vn/api/file/ftp-video/${noiDung}`)

            :
            servCodingCamp.getUrlVideo_FPT(noiDung)


      api.then((res) => {
        setUrlVideo(res.data);
      });

    }
  }, [baiHoc.content?.id]);
  useEffect(() => {
    let infor = user.hoTen + " - " + user.dienThoai;
    let myInterval = Mark_Video(infor);
    seIsDisableHoanThanh(true);
    setTimeout(() => {
      seIsDisableHoanThanh(false);
    }, 0 * 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, [baiHoc?.content?.id]);

  let renderVideo = () => {

    return <VideoPlayer source={urlVideo} />;
  };

  let isHoanThanh = baiHoc.content?.hoanThanh;
  return (
    <section className="h-full w-full">
      <div className="border water-mark-wrapper">
        {renderVideo()}
      </div>
      <div className={` ${widthWindow<768?'flex-col-reverse ':'justify-between items-center'} flex c-card-content `}>
        <div className="text-title font-medium w-3/5">
          {baiHoc.content?.tenBaiHoc}
        </div>
        <div className={`${widthWindow<768?'text-left': 'text-right'} space-x-2 text-black w-100 h-16`}>
          <button
            onClick={() => {
              handleChangePrevious();
            }}
            className={classNames("text-content card-theme font-bold  px-1   text-center rounded-lg py-1 flex-1",
            {
              "bg-gray-500 cursor-not-allowed": listBaiHoc?.length==1 || baiHoc.viTri==1
            }
            )}
          >
            <i class="icon-main fa fa-angle-double-left"></i>
            {t('chiTietNhiemVu.baiTruocDo')}
          </button>
          <button
            onClick={() => {
              isHoanThanh && handleChangeNext();
            }}
            className={classNames(
              "text-content card-theme font-bold  px-1   text-center rounded-lg py-1 flex-1",
              {
                "bg-gray-500 cursor-not-allowed": !isHoanThanh,
              }
            )}
          >
            <i className="icon-main fa fa-angle-double-right"></i>
            {t('chiTietNhiemVu.baiTiepTheo')}

          </button>
          <button
            onClick={() => {
              !isHoanThanh && !isDisableHoanThanh && handleHoanThanhBaiHoc();
            }}
            className={`text-content card-theme   px-1   text-center rounded-lg py-1 flex-1 ${isHoanThanh ? "bg-gray-400 cursor-not-allowed" : "text-yellow"
              }`}
          >
            <i className=" fa fa-check text-white"></i>

            {isHoanThanh ? (
              "Đã hoàn thành"
            ) : isDisableHoanThanh ? (
              <Tooltip
                placement="topLeft"
                title="Bạn cần xem tiếp để hoàn thành"
                arrowPointAtCenter
                defaultVisible={false}
              >
                <span className=" text-gray-600">
                  {t('chiTietNhiemVu.hoanThanh')}


                </span>
              </Tooltip>
            ) : t('chiTietNhiemVu.hoanThanh')
            }
          </button>
        </div>
      </div>
    </section>
  );
}
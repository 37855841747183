import { Pagination, Popconfirm, Tooltip } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import HtmlParser from "react-html-parser";
import ReactPlayer from "react-player";
import ButtonNopBai from "../ButtonNopBai/ButtonNopBai";
import servCodingCamp from "../../services/codingCam.service";
import VideoPlayer from "../video/VideoPlayer";

export const HINT = "HINT";
export const SOLUTION = "SOLUTION";
export const SOLUTION_VIDEO = "SOLUTION_VIDEO";
export const RESULT = "RESULT";

let styleBtn = {
  left: "1137px",
  top: "658px",
  background:
    "radial-gradient(226.46% 280.26% at 41.98% 268.42%, #FF0C0C 8.4%, #301D09 89.92%) ",
  borderRadius: "11px",
  border: "2px solid #F5BC7A",
};

export default function ContentGuide({
  type,
  valueGuide,
  listStatus,
  handleBuyGuide,
  coin,
  label = "",
}) {

  const [source, setSource] = useState("");

  let guide = _.find(listStatus, { maTieuDe: type });


  let renderContentGuide = () => {

    if (type == RESULT) {
      return HtmlParser(valueGuide);
    }
    if (guide?.trangThai) {
      // trangThai false =>  mở khoá

      switch (type) {
        case "SOLUTION_VIDEO":
          let lstVideo = JSON.parse(valueGuide)

          if (source == "") {
            if (lstVideo[0]?.solutionCyberlearn) {
              servCodingCamp.getFileFtpCyberLearn(lstVideo[0].noiDung).then(res => {
                setSource(res.data)
              })
              return;

            }

            servCodingCamp.getUrlVideoSolution_FPT(lstVideo[0].noiDung).then(res => {
              setSource(res.data)
            })
          }


          return <div>
            {/* <ReactPlayer

              playbackRate={1}
              playing={true}
              url={source}
              width="100%"
              height="100%"
              // style={{ borderRadius: "100px" }}
              controls={true}
              config={{
                file: {
                  forceHLS: true,
                },
              }}
            /> */}
            <VideoPlayer source={source} />
            {Array.from({ length: lstVideo.length }, (_, index) => {

              return <button onClick={() => {


                if (lstVideo[index]?.solutionCyberlearn) {
                  servCodingCamp.getFileFtpCyberLearn(lstVideo[index].noiDung).then(res => {
                    setSource(res.data)
                  })
                  return;

                }

                servCodingCamp.getUrlVideoSolution_FPT(lstVideo[index].noiDung).then(res => {
                  setSource(res.data)
                })

              }} style={styleBtn} className="m-2 px-3 py-1">
                {index + 1}
              </button>
            }
            )
            }

          </div >;
      }

      return <div> {HtmlParser(valueGuide)}</div>;
    }
    return (
      <div className=" h-full flex justify-center">
        <Popconfirm
          title="Bạn chắc có muốn tiếp tục ?"

          onConfirm={handleBuyGuide}
        >
          <button
            className="card-theme rounded px-5 py-1 capitalize space-x-2 border-2 font-medium"
          >
            <i class="fa fa-lightbulb"></i>{" "}
            <span className="capitalize"> Xem {label}</span>{" "}
            <span>(<i className="fa fa-coins"></i> {coin} coin)</span>
          </button>
        </Popconfirm>
      </div>
    );
  };

  return <div>{renderContentGuide()}</div>;
}
